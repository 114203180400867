import { useEffect, useState } from 'react';
import { useForm, useFormContext } from "react-hook-form";
import { AnswerTypeHighschoolProps } from "../../store/form/fields";
import { compositeFieldname } from "../../utils";
import { FieldRow, FieldSection, FieldSlot } from "../../components/Forms/FieldSection";
import Checkbox from "../../components/Checkbox";
import Input from "../../components/Input";
import DropdownSearch from '../DropdownSearch';
import AnswerLock from './status/AnswerLock';
import AddressPicker from '../DataDriven/AddressPicker';
import { Alert, AlertTitle, Link } from '@material-ui/core';
import apiClient from '../../utils/API';



/**
 * whether or not to display the city dropdown, based on address field state
 * 
 * in long-form for easier deugging
 */
function isCityDisplayed(states, counties, cities, state, county, city) {
    if (!county)
        return false;
    if (cities && cities.length > 0)
        return true;
    return false;
}


/**
 * whether or not to display the unlisted city field, based on address field state
 * 
 * in long-form for easier deugging
 */
function isUnlistedCityDisplayed(states, counties, cities, state, county, city) {
    if (state && state.value === -1)
        return true;
    if (city && city.value === -1)
        return true;
    return false;
}

/**
 * display sort order for high school options
 * @param {*} h1 high school DTO
 * @param {*} h2 high school DTO
 * @returns 
 */
function highSchoolOrder(h1, h2) {
    const compareState1 = h1.State === 'OR' ? 'A' : h1.State;
    const compareState2 = h2.State === 'OR' ? 'A' : h2.State;

    if (compareState1 > compareState2) {
        return 1;
    }
    if (compareState1 < compareState2) {
        return -1;
    }

    if (h1.Name > h2.Name) {
        return 1;
    }
    if (h1.Name < h2.Name) {
        return -1;
    }
    return 0;
}

/**
 * 
 * @param {string} hs 
 */
function describeHighSchool(hs) {
    const locations = [hs.City, hs.State];
    let locationDescriptor = locations.filter(x => x?.trim()).join(', ');
    if (locationDescriptor.trim())
        locationDescriptor = `(${locationDescriptor})`;

    return `${hs.Name} ${locationDescriptor}`;
}

function levenshteinDistance(s, t) {
    if (!s.length) return t.length;
    if (!t.length) return s.length;
    const arr = [];
    for (let i = 0; i <= t.length; i++) {
        arr[i] = [i];
        for (let j = 1; j <= s.length; j++) {
            arr[i][j] =
                i === 0
                    ? j
                    : Math.min(
                        arr[i - 1][j] + 1,
                        arr[i][j - 1] + 1,
                        arr[i - 1][j - 1] + (s[j - 1] === t[i - 1] ? 0 : 1)
                    );
        }
    }
    return arr[t.length][s.length];
};

/**
 * just rote content words distinct to the school
 * @param {string} hsName 
 */
function stripContentWords(hsName){
    const skipWords = [ "school", "academy", "high", "charter", "learning", "university", "college", "center", "completion", "community", "middle", "highschool", "comm", ''];  

    const contentWords = hsName?.split(' ').map(x => x.trim().toLowerCase()).filter(x => x.length > 2 && !skipWords.includes(x));
    const rehydratedName = contentWords.join(' ');
    return rehydratedName;
}

/**
 * 
 * @param {*} h1 
 * @param {*} h2 
 * @returns 
 */
function possibleHighSchoolSort(h1, h2) {
    if (h1.unlistedDistance > h2.unlistedDistance) 
        return 1;
    
    if (h1.unlistedDistance < h2.unlistedDistance) 
        return -1;    

    return 0;
}

/**
 * full prompt for an highchool type question
 * @param {*} param0.answer
 * @param {String} param0.hstype high school type, selected elsewhere
 * @param {Boolean} param0.disabled disable fields for the whole block
 * @returns {Component}
 */
const HighSchoolAnswer = ({ answer, hstype, disabled }) => {

    const name = answer.FieldKey;
    const unlistedSchoolFieldName = "UnlistedHS"; //is school unlisted or not
    const schoolCodeFieldName = compositeFieldname(name, AnswerTypeHighschoolProps.SCHOOL_CODE);  //selected school code
    const unlistedSchoolNameFieldName = compositeFieldname(name, AnswerTypeHighschoolProps.UNLISTED_NAME); //if unlisted, hand-entered name

    const methods = useForm({ mode: "onBlur", });

    const { setValue, getValues } = useFormContext({ mode: 'onBlur' });

    const isInitialSchoolUnlisted = !answer.SchoolCode && !!answer.UnlistedName;
    const [isUnlistedHs, setUnlistedHs] = useState(isInitialSchoolUnlisted);
    const [listedSchool, setListedSchool] = useState(answer.SchoolCode);
    const [unlistedName, setUnlitedName] = useState(answer.UnlistedName);
    const [highschools, setHighschools] = useState([]);

    /**
     * track unlisted name where we can use it later
     * TODO: only because watches are broken :-(
     * @param {Event} e 
     */
    const handleUnlistedNameChange = (e) => {
        setUnlitedName(e.target.value);
        setValue(unlistedSchoolNameFieldName, e.target.value);
    }

    /**
     * 
     * @param {Event} e 
     */
    const handlePossibleSchoolClick = (schoolCode, e) => {
        const school = highschools.find(h => h.Id === schoolCode);
        const schoolSelection = {
            value: schoolCode,
            label: school.Name
        };
        setUnlistedHs(false);
        setListedSchool(schoolSelection);
        setValue(schoolCodeFieldName, schoolSelection);
    }

    /**
     * handle check/uncheck of unlisted hs box
     * @param {boolean} isUnlisted 
     */
    const updateUnlistedHs = (isUnlisted) => {
        setUnlistedHs(isUnlisted);
        if (isUnlisted) {
            const currentSchoolCode = getValues(schoolCodeFieldName);
            setListedSchool(currentSchoolCode);
            setValue(schoolCodeFieldName, '');
        }
        else
            setValue(schoolCodeFieldName, listedSchool);

    }

    /**
     * 
     */
    async function loadHighSchools() {

        let selectableTypes = [];
        if (hstype == 'CC') {
            selectableTypes = ['CC'];
        }
        else if (hstype == 'ALT' || hstype == 'TRD') {
            selectableTypes = ['ALT', 'TRD']
        }
        else if (hstype == 'GED' || hstype == 'GEDHM') {
            selectableTypes = ['GED']
        }
        const schoolResults = await Promise.all(selectableTypes.map(x => apiClient.get(`high-school/high-schools/${x}`)));
        const highschools = schoolResults.flatMap(x => x);

        setHighschools(highschools);
    }

    /**
     * 
     */
    useEffect(() => {
        if (!hstype)
            return;

        loadHighSchools();

    }, [hstype])



    const isDisabled = disabled || answer.IsLocked;

    const items = highschools.sort(highSchoolOrder).map(r => {
        const label = describeHighSchool(r);
        return {
            label: label,
            value: r.Id
        }
    });

    
    // const possibleListedHighSchools = contentWords ? highschools.filter(x => contentWords.some(w => x.Name.toLowerCase().includes(w))) : [];
    let possibleListedHighSchools = [];
    if (unlistedName) {
        for (var hs of highschools) {
            hs.unlistedDistance = levenshteinDistance(stripContentWords(hs.Name), stripContentWords(unlistedName));
        }

        possibleListedHighSchools = highschools.sort(possibleHighSchoolSort).slice(0, 10);
    }
    
     

    return (
        <div>
            {hstype !== 'HMSCH' && hstype !== 'UNSTD' &&
                <>
                    <FieldSlot>
                        <DropdownSearch
                            name={schoolCodeFieldName}
                            inputLabel={<>High School <AnswerLock answer={answer} /></>}
                            isRequired={!isUnlistedHs}
                            disabled={isDisabled || isUnlistedHs}
                            key={schoolCodeFieldName}
                            listItems={items}
                        />
                    </FieldSlot>
                    <FieldSlot>
                        <Checkbox disabled={isDisabled} Controller={false}
                            checked={isUnlistedHs}
                            value={isUnlistedHs}
                            name={unlistedSchoolFieldName}
                            defaultValue={isUnlistedHs}
                            label="My high school is not listed"
                            onChange={updateUnlistedHs}
                        />
                    </FieldSlot>
                </>
            }

            {(isUnlistedHs || hstype == 'HMSCH') && (
                <FieldRow>
                    <FieldSlot>
                        <FieldSection
                            title="High School Information"
                            withDivider={false}
                        ></FieldSection>
                        <Input
                            onChange={handleUnlistedNameChange}
                            isRequired={true}
                            name={unlistedSchoolNameFieldName}
                            inputLabel="Enter high school name"
                            disabled={isDisabled}
                            value={unlistedName}
                            defaultValue={answer.UnlistedName}
                        />

                        {possibleListedHighSchools.length > 0 &&
                            <Alert severity="info">

                                <AlertTitle>Did you mean...?</AlertTitle>
                                <ul className="list">
                                    {possibleListedHighSchools.map(h =>
                                        <li className="li" onClick={(e) => handlePossibleSchoolClick(h.Id, e)}>
                                            <Link>{h.Name}</Link> ({h.City}, {h.State}) 
                                        </li>
                                    )}
                                </ul>
                            </Alert>
                        }


                        <AddressPicker name={name}>
                            {({
                                states,
                                counties,
                                cities,
                                state,
                                county,
                                city,
                                noCounties,
                            }) => {                               
                                return (
                                    <div>
                                        <FieldRow>
                                            <FieldSlot>
                                                <DropdownSearch
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    isRequired={true}
                                                    inputLabel="State"
                                                    placeholder="State"
                                                    name={compositeFieldname(name, AnswerTypeHighschoolProps.STATE)}
                                                    listItems={states}
                                                    variant="outlined"
                                                />
                                            </FieldSlot>
                                        </FieldRow>

                                        {state?.value == -1 &&
                                            <div style={{
                                                backgroundColor: 'lightgrey',
                                                padding: '1rem',
                                                marginBottom: '1rem'
                                            }}>
                                                <label><strong>Unlisted State or Foreign Country<span className="required">*</span></strong></label>
                                                <Input name={compositeFieldname(name, AnswerTypeHighschoolProps.UNLISTED_STATE_NAME)}
                                                    placeholder='Enter the name of your unlisted state/province here.'></Input>
                                            </div>
                                        }

                                        {state && !noCounties &&
                                            <FieldRow>
                                                <FieldSlot>
                                                    <DropdownSearch
                                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                        isRequired={true}
                                                        inputLabel="County"
                                                        placeholder="County"
                                                        name={compositeFieldname(name, AnswerTypeHighschoolProps.COUNTY)}
                                                        listItems={counties}
                                                        variant="outlined"
                                                    />
                                                </FieldSlot>
                                            </FieldRow>
                                        }
                                        {isCityDisplayed(states, counties, cities, state, county, city) && (
                                            <FieldRow>
                                                <FieldSlot>
                                                    <DropdownSearch
                                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                        isRequired={true}
                                                        inputLabel="City"
                                                        placeholder="City"
                                                        name={compositeFieldname(name, AnswerTypeHighschoolProps.CITY)}
                                                        listItems={cities}
                                                        variant="outlined"
                                                    />
                                                </FieldSlot>
                                            </FieldRow>
                                        )}

                                        {isUnlistedCityDisplayed(states, counties, cities, state, county, city) &&
                                            <div
                                                style={{
                                                    backgroundColor: 'lightgrey',
                                                    padding: '1rem',
                                                }}>
                                                <label><strong>Unlisted City<span className="required">*</span></strong></label>
                                                <Input
                                                    name={compositeFieldname(name, AnswerTypeHighschoolProps.UNLISTED_CITY_NAME)}
                                                    placeholder='Enter the name of your unlisted city here.'></Input>
                                            </div>
                                        }

                                    </div>
                                );
                            }}
                        </AddressPicker>
                    </FieldSlot>
                </FieldRow>
            )}

        </div>
    );
}
export default HighSchoolAnswer;
