import Spinner from "../Utility/Spinner";
import SuccessIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/WarningRounded';

/**
 * standard display icon for section progress
 */
export default ({section}) => {
    if (section.isCompletionLoading)
        return (<Spinner size="25px"/>);
    if (section.isComplete)
        return (<SuccessIcon sx={{ color: "#429D5B" }}/>);
    
    return (<WarningIcon sx={{ color: "#da3754" }}/>);
}

