import ProgramInfo from "../../utils/dtos/ProgramInfo";
import PrintIcon from "../PrintIcon";

/**
 * 
 * @param {ProgramInfo} param0.program program info DTO
 * @param {number} param0.year specific year being addressed
 * @returns {Component}
 */
function AppHeader({ program, year }) {

    const programInfo = new ProgramInfo(program);

    return (
        <div className="page-intro">
            <div className="wrapper">
                <div className="page-intro__layout">
                    <div className="page-intro__content rhythm">
                        <h1 className="heading heading--h1">
                            {programInfo.name}
                            <PrintIcon /> 
                        </h1>
                        <p className="heading"> {programInfo.description}</p>
                        <h2 className="heading heading--h2">{year} – {year + 1}</h2>
                        <a href="/dashboard">Return to Dashboard</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AppHeader;