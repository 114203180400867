import apiClient from '../../utils/API';

export const getHistory = async () => {
  const applications = await apiClient.get(`/application`);

  let history = [];

  for (let app of applications) {    
    if (app.ProgramCode == "FAFSA" || app.ProgramCode == "OOG") //supress these programs for now
      continue;

    const program = await apiClient.get(`/program/${app.ProgramCode}`);
    const obj = {
      name: program?.Name ?? app.ProgramCode,
      code: app.ProgramCode,
      year: app.Year,
      appNumber: app.AppId  
    };
    history.push(obj)
  }

  return history;
};