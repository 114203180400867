import { useEffect, useState } from "react";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Button from './Button';
import { useHistory } from 'react-router-dom';
import TribalApplicationPdf from "./TribalApplicationPdf";
import { Component } from 'react';
import apiClient from "../utils/API";

const submissionTime = new Date();
const date = submissionTime.toLocaleDateString();
const time = submissionTime.toLocaleString('en-US', { hour: 'numeric',minute: 'numeric', hour12: true });

/**
 * block to give summary of an application that was just submitted to give a feeling of completion
 * @param {*} param0 
 * @returns {Component}
 */
export default function CompleteSubmission({ applicationHelper, details, pdfData, onEdit, ...props }) {

  const history = useHistory();

  const programCode = applicationHelper?.programCode ?? details?.code;
  const applicationYear = applicationHelper?.year ?? details?.year;
  const programTitle = applicationHelper?.title ?? details?.title;
  
  const goToDashboard = () => {
    history.push('/dashboard')
  }

  const [scholarshipsSuccessMessage, setScholarshipsSuccessMessage] = useState(null)

  useEffect(() => {
    (async () => {
          const result = await apiClient.get("code?lookup.code=ScholarshipCompleted&lookup.codeType=StudentMessage");
          setScholarshipsSuccessMessage(result?.Message);                
    })()
  }, [])

  return (
    <div name='ApplicationsPage' className="wrapper wrapper--narrow">
      <div className="component rhythm">
        <h3 className="heading heading--h3">Application Successfully Submitted!</h3>
        <h4 className="heading heading--h4"><b>{programTitle} {programCode != "SCH" && "Grant"} {applicationYear}</b></h4>
        <p><em>{date} at {time}</em></p>

        <div className="">
          <div className="rhythm text text--align-center">
            <h5 className="heading heading--h5">Application Progress</h5>
            <div style={{height: '3rem', background: '#4ea9b0', borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <div style={{color: 'white'}}>100% Completed</div>
            </div>
          </div>
        </div>

        { programCode === 'OTSG' &&
          <div className="component">
            <div className="rhythm text">
              <div style={{marginBottom: '2rem'}}>
                <h5 className="heading heading--h5" style={{marginBottom: '1rem'}}>Instructions</h5>
                Sign and send the Enrollment Verification Form to your tribe. The form can be electronically signed and emailed to your tribe’s
                Enrollment Office (or designated office by your tribe) tribe email address. Please contact your enrollment office if you would like to mail, fax or submit the form in person.
              </div>

              <TribalApplicationPdf data={pdfData} />

            </div>
          </div>
        }
        { programCode === 'SCH' &&
          <div dangerouslySetInnerHTML={{ __html: scholarshipsSuccessMessage }} />       
        }


        <div className="component">
          {applicationHelper && 
            <ul className="list list--blank list--checklist">
              <li className="li">
                <CheckCircleIcon style={{color: '#429D5B'}}/>
                Profile
              </li>                  
              {applicationHelper.sections.map(section => 
                <li className="li">
                  <CheckCircleIcon style={{color: '#429D5B'}}/>
                  {section.title}
                </li>  
              )}
            </ul>
          }

          { programCode === 'CH'  &&
          <ul className="list list--blank list--checklist">
            <li className="li">
              <CheckCircleIcon style={{color: '#429D5B'}}/>
              Profile
            </li>          
            <li className="li">
              <CheckCircleIcon style={{color: '#429D5B'}}/>
              Foster Care
            </li>
            <li className="li">
              <CheckCircleIcon style={{color: '#429D5B'}}/>
              College Terms
            </li>
          </ul>
          }

          { programCode === 'ORP' &&
          <ul className="list list--blank list--checklist">
            <li className="li">
              <CheckCircleIcon style={{color: '#429D5B'}}/>
              Profile
            </li>
            <li className="li">
              <CheckCircleIcon style={{color: '#429D5B'}}/>
              College Terms
            </li>
            <li className="li">
              <CheckCircleIcon style={{color: '#429D5B'}}/>
              GPA/Transcripts
            </li>
          </ul>
          }


          { programCode === 'ONGSTA' &&
            <ul className="list list--blank list--checklist">
              <li className="li">
                <CheckCircleIcon style={{color: '#429D5B'}}/>
                Profile
              </li>
              <li className="li">
                <CheckCircleIcon style={{color: '#429D5B'}}/>
                Service History
              </li>
              <li className="li">
                <CheckCircleIcon style={{color: '#429D5B'}}/>
                College Plans
              </li>
              <li className="li">
                <CheckCircleIcon style={{color: '#429D5B'}}/>
                Planned Enrollment
              </li>
            </ul>
          }
        </div>

        <div className="component">
          <Button onClick={goToDashboard} name='BackToDashboard' label='Back to dashboard'/>
          { <Button onClick={onEdit} name='Edit' label='Edit application' variant='outline'/> }
        </div>
      </div>
    </div>
  );
};
