import react, { useEffect } from 'react';
import { useState } from 'react';
import apiClient from '../../utils/API';
import DropdownSearch from '../DropdownSearch';
import SpinnerSection from "../../components/Utility/SpinnerSection";


export default ({ listItems, ...props}) => {

    const [items,setItems] = useState([]);
    const [loading, setLoading] = useState(true)

    useEffect(()=>{
        (async ()=>{
            setLoading(true)
            const majors = await apiClient.get(`/major/majors`);
            setItems(majors.map(r => ({
                ...r,
                label: r.Title,
                value: r.Id
            })))
            setLoading(false)
        })();
    },[]);

    return (
    <div>
        {loading &&
          <div>
              Loading Majors (this may take a moment)
              <SpinnerSection/>
          </div>
        }
        {!loading &&
            <DropdownSearch {...props} placeholder="Major" listItems={items}/>
        }
    </div>
    )
}
