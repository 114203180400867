/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect } from 'react';
import { useState } from 'react';
import apiClient from '../../utils/API';
import Dropdown from '../Dropdown';

export const STATE_RETURN_TYPE = {
    ABBREVIATION: 'Abbreviation',
    ID: 'Id'
}

export default ({ listItems, codeType, returnType = STATE_RETURN_TYPE.ABBREVIATION, shouldExclude = () =>false, ...props}) => {

    const [items,setItems] = useState([]);
    
    useEffect(()=>{
        (async ()=>{
            const states = await apiClient.get(`/state-and-territory/states-and-territories`);
            setItems(states.reduce((_states,state)=>{
                if(!shouldExclude(state)){
                    _states.push({
                        ...state,
                        label: state.Name,
                        value: state[returnType]
                    });
                }
                return _states;
            },[]))
        })();
    },[]);

    return <Dropdown placeholder="State" {...props} listItems={items}/>
        
}