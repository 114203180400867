import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputDecorator from './InputDecorator';
import { Controller } from "react-hook-form";
import { makeStyles } from '@material-ui/core/styles';
import Input from './Input';


const zipRules= {
  pattern: {
    value: /(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/ ,
    message: 'Invalid zipcode'
  }
};


export default ({ ...props }) => <Input onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} rules={zipRules} {...props}/>;
