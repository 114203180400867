import { FormProvider, useForm } from "react-hook-form";
import FormSection from "../../components/FormSection";
import CertifyAnswer from "../../components/answer/CertifyAnswer";
import { FIELDS } from "../../store/form/fields";
import SectionStateIcon from "../../components/progress/SectionStateIcon";
import { useDispatch } from "react-redux";
import CollegeChoiceAnswer from "../../components/answer/CollegeChoiceAnswer";
import ClassStanding from "../../components/answer/question/ClassStanding";
import RequirementSet from "../../utils/dtos/RequirementSet";
import { useState, useEffect } from "react";
import apiClient from "../../utils/API";
import Alert from "@material-ui/lab/Alert";


/**
 * 
 * @param {SectionHelper} param0.section 
 * @returns {Component}
 */
function EditCollegeChoice({ section, season }) {

    const dispatch = useDispatch();
    const methods = useForm({ mode: 'onBlur' });
    const { handleSubmit } = methods;

    const [completionData, setCompletionData] = useState(null);
    const sectionCompletion = new RequirementSet(completionData);
    const collegeCompletion = sectionCompletion.getField(FIELDS.COLLEGE_CHOICE);

    async function getCompletionReport() {
        var result = await apiClient.getCompletionReport("OTSP", section.year, [FIELDS.COLLEGE_CHOICE]);
        setCompletionData(result);    
    }
    useEffect(() => {
        if (section.year){
            getCompletionReport();
        }        
    }, [section.year]);

    const onSubmit = (data) => {
        (async () => {
            await section.legacySaveAnswers(data, dispatch);
            await getCompletionReport();
        })();
    }

    const onError = () => {

    }

    /**
     * display for deadline of a given term
     * @param {*} param0 
     * @returns {Component}
     */
    function DeadlineStatus({ term }) {

        var deadline = season?.getDeadline(term);
        if (!deadline)
            return null;

        if (deadline.isPassed)
            return (
                <span style={{ color: 'gray' }}>{deadline.displayDate} (Passed)</span>
            );

        return (
            <span>{deadline.displayDate}</span>
        );
    }

    return (
        <FormProvider {...methods} >
            <form onSubmit={handleSubmit(onSubmit, onError)} >
                <FormSection title={section.title} isEditable={true} onSaveClick={handleSubmit(onSubmit, onError)}
                    status={<SectionStateIcon section={section} />}
                    loading={section.isLoading} >

                    {collegeCompletion?.isDisqualifying === true &&
                        <div item>
                            <Alert severity="error" className="alert alert--error">
                                <p>
                                    <strong>
                                        The&nbsp;
                                        <a href="https://oregonstudentaid.gov/grants/oregon-opportunity-grant/list-of-participating-schools">college you selected</a>
                                        &nbsp;is not eligible to receive the Oregon Teacher Scholars Program.
                                    </strong>
                                </p>
                            </Alert>
                        </div>
                    }


                    <CollegeChoiceAnswer answer={section.getAnswerByField(FIELDS.COLLEGE_CHOICE)} />

                    <ClassStanding answer={section.getAnswerByField(FIELDS.COLLEGE_STANDING)} />

                    <table className="table">
                        <thead>
                            <tr>
                                <th>Term</th>
                                <th>Attending?</th>
                                <th>Application Deadline</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Fall Term</td>
                                <td><CertifyAnswer answer={section.getAnswerByField(FIELDS.OTSG__ATTENDING_FALL_TERM)} label='yes' /></td>
                                <td><DeadlineStatus term='Fall' /></td>
                            </tr>
                            <tr>
                                <td>Winter Term</td>
                                <td><CertifyAnswer answer={section.getAnswerByField(FIELDS.OTSG__ATTENDING_WINTER_TERM)} label='yes' /></td>
                                <td><DeadlineStatus term='Winter' /></td>
                            </tr>
                            <tr>
                                <td>Spring Term</td>
                                <td><CertifyAnswer answer={section.getAnswerByField(FIELDS.OTSG__ATTENDING_SPRING_TERM)} label='yes' /></td>
                                <td><DeadlineStatus term='Spring' /></td>
                            </tr>
                        </tbody>
                    </table>

                </FormSection>
            </form>
        </FormProvider>
    );
}

export default EditCollegeChoice;