import { FIELDS, REPEATABLE_FIELDS } from './fields';

const initialFormState = {
  isLoading: false,
  year: null,
  profileComplete: false,
  fields: {},
  deletedRepeatableFieldIds: {
    ...REPEATABLE_FIELDS.reduce((dict,repeatableFieldKey) => {
      dict[repeatableFieldKey] = [];
      return dict;
    },[])
  }
}

export { initialFormState };
