import React, { Fragment } from "react";
import Button from "../../../components/Button";
import {
  FieldRow,
  FieldSlot,
} from "../../../components/Forms/FieldSection";
import { FIELDS } from "../../../store/form/fields";
import Card from "../../../components/Card";
import { CardActions, CardContent } from "@material-ui/core";
import MaterialButton from "@material-ui/core/Button";
import RepeatableQuestion from "../../../components/answer/question/RepeatableQuestion";
import DegreeAnswer from "../../../components/answer/DegreeAnswer";
/**
 * 
 * @param {*} param0 
 */
function DegreesQuestion({section}){

    return (
        <RepeatableQuestion section={section} name={FIELDS.DEGREES} >
        {(answers, handleAdd, handleDelete) => {
          return (
            <>
              {answers.map((answer) =>
                <Fragment key={answer.Id}>
                  <Card>
                    <CardContent style={{ margin: "-.5rem 0 -1rem 0" }}>
                      <DegreeAnswer answer={answer} />
                    </CardContent>
                    <CardActions style={{ justifyContent: "flex-end" }}>
                      <MaterialButton
                        size="small"
                        color="secondary"
                        disabled={answer.IsLocked}
                        style={{ position: "relative", bottom: "-1rem" }}
                        onClick={handleDelete(answer)}
                      >
                        Remove
                      </MaterialButton>
                    </CardActions>
                  </Card>
                </Fragment>
              )}
              <FieldRow>
                <FieldSlot md={6} className="mt-1">
                  <Button
                    name="AddDegreePlan"
                    type='button'
                    label="+ Add degree plan"
                    variant="text"
                    style={{ textTransform: "capitalize" }}
                    onClick={handleAdd({ Id: 0, AnswerType: 'Degree' })}
                  />
                </FieldSlot>
              </FieldRow>
            </>
          );
        }}
      </RepeatableQuestion>
    );
}

export default DegreesQuestion;