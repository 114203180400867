import {FieldRow, FieldSection, FieldSlot} from "../../components/Forms/FieldSection";
import CodeDropdown from "../../components/DataDriven/CodeDropdown";
import {FIELDS} from "../../store/form/fields";

function CitizenshipStatus({ onChange, onCancel }) {
  return (
    <FieldSection title="Select Your Citizenship Status">
    <FieldRow>
      <FieldSlot md={8}>
        <CodeDropdown name={FIELDS.CITIZENSHIP_STATUS} label='Citizenship Status' variant='outlined' codeType="CITIZEN" isRequired={true}/>
      </FieldSlot>
    </FieldRow>
  </FieldSection>
  );
}

export default CitizenshipStatus;
