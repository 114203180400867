import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from './Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

export default function MaxWidthDialog({title, buttonLabel, children}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button label={buttonLabel} variant='text' onClick={handleClickOpen}/>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title"><h4 className="heading heading--h3">{title}</h4></DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
        <DialogActions>
          <Button label='close' onClick={handleClose} style={{padding: '1rem'}}/>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
