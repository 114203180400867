import React from 'react';
import StatusButtons from '../AppStatusButtons';
import NotificationAlert, { NOTIFICATION_VARIANT } from '../../views/dashboard/NotificationAlert';
/**
 * 
 * @param {*} param0 
 * @returns 
 */
export default function Grants({details, notifications}) {


  const year = details.Code === "ORP" ? "" : details.year + "-" + ((details.year + 1)%100);

  return (
    <div className="grant">
      <div className="grant__layout">
        <div className="grant__content rhythm">
          <h4 className="heading heading--h5">{year} { details.Name }</h4>
          <div dangerouslySetInnerHTML={ { __html: details.Description }}></div>
        </div>
        {notifications && 
        <div>
            {notifications.map((item, i) => 
                <NotificationAlert key={i} notification={item} variant={NOTIFICATION_VARIANT.CARD}/>
            )}
        </div>
        }
        
        <StatusButtons {...details}/>
      </div>
    </div>
  );
}
