import InfoIcon from '@material-ui/icons/InfoOutlined';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/WarningRounded';
import AwardIcon from '@material-ui/icons/EmojiEvents';
import PendingIcon from '@material-ui/icons/PauseCircleFilled';
import SuccessIcon from '@material-ui/icons/CheckCircle';

export const NOTIFICATION_VARIANT = {
    DASHBOARD: 'HEADER',
    CARD: 'CARD',
}
/**
 * standard notification display
 * @param {*} param0
 * @returns {Component}
 */
function NotificationAlert({ notification, key, variant }) {

    //notification just isn't ready uet
    if (!notification)
        return null;
    
    /**
     * code the icon to the attention level
     * @param {*} param0.sx styles
     * @returns 
     */
    function Icon({sx}) {
     
        switch (notification.AttentionLevel) {
            case 'Error':
                return <ErrorIcon sx={{ ...sx, color: "#da3754" }} />;

            case 'Pending':
                return <PendingIcon sx={{ ...sx, color: "#a2ba69" }} />;

            case 'Warning':
                return <WarningIcon sx={{ ...sx, color: "#da3754" }} />;

            case 'Success':
                return <SuccessIcon sx={{ ...sx, color: "#429D5B" }} />;

            case 'Award':
                return <AwardIcon sx={{ ...sx, color: "#f7db6a" }} />;

            default:
                return <InfoIcon sx={{ ...sx, color: "#33797e" }} />;
        }
    }

    switch (variant) {
        case NOTIFICATION_VARIANT.CARD:
            return  (
                <div key={`Notification${key}`} className="notification" style={{border: 'none', padding: '0.5em', fontSize: '.875rem'}} >
                    <div className="notification__layout" style={{gridTemplateColumns:'1.5rem 1fr'}} >
                        <div>
                            <div><Icon sx={{width: '0.7em;'}} /></div>
                        </div>
                        <div className="rhythm">
                            {notification.Title && <span dangerouslySetInnerHTML={{ __html: notification.Title }} style={{fontWeight: 'bold', paddingRight: '1em'}}/>}
                            {notification.Message && <span dangerouslySetInnerHTML={{ __html: notification.Message }} />}                          
                        </div>
                    </div>
                </div>
            );
        case NOTIFICATION_VARIANT.DASHBOARD:
        default:
            return  (
                <div key={`Notification${key}`} className="notification" >
                    <div className="notification__layout" >
                        <div>
                            <div><Icon /></div>
                        </div>
                        <div className="rhythm">
                            {notification.Title && <h4 className="heading heading--h5 notification__title" dangerouslySetInnerHTML={{ __html: notification.Title }} />}
                            {notification.Message && <div className="notification__message" dangerouslySetInnerHTML={{ __html: notification.Message }} />}
                        </div>
                    </div>
                </div>
            );

    }


}

export default NotificationAlert;