import React from 'react';
import { AnswerTypeMemberProps } from '../../store/form/fields';
import { compositeFieldname } from '../../utils';
import { FieldSection, FieldRow, FieldSlot } from '../Forms/FieldSection';
import Input from '../Input';
import CodeDropdown from '../DataDriven/CodeDropdown';
import StatePicker, { STATE_RETURN_TYPE } from '../DataDriven/StatePicker';


const MembershipAnswer = ({ question, disabled, answer }) => {

    const name = question.name;
    const isMemberFormDisabled = disabled;

    const relationshipPrompt = question.getDisplayProperty("RelationshipCode");
    const namePrompt = question.getDisplayProperty("FullName");
    const statePrompt = question.getDisplayProperty("UnlistedStateName");
    const cityPrompt = question.getDisplayProperty("UnlistedCityName");
    const locationPrompt = question.getDisplayProperty("LocationName");

    return (
        <FieldSection withDivider={false}>
            {relationshipPrompt &&
                <FieldRow>
                    <FieldSlot md={12}>
                        <CodeDropdown disabled={isMemberFormDisabled}
                            isSoftRequired={true}
                            codeType="RELATION" name={compositeFieldname(name, AnswerTypeMemberProps.RELATION_UNLISTED)} label={relationshipPrompt} />
                    </FieldSlot>
                </FieldRow>
            }
            {namePrompt &&
                <FieldRow>
                    <FieldSlot md={12}>
                        {/* Backend treats "Last Name" as Full Name */}
                        <Input name={compositeFieldname(name, AnswerTypeMemberProps.FULL_NAME)} inputLabel={namePrompt} placeholder={namePrompt}
                            isSoftRequired={true}
                            disabled={isMemberFormDisabled} variant='outlined' />
                    </FieldSlot>
                </FieldRow>
            }
            {locationPrompt &&
                <FieldRow>
                    <FieldSlot md={12}>
                        <Input name={compositeFieldname(name, AnswerTypeMemberProps.LOCATION_NAME)} inputLabel={locationPrompt} 
                            isSoftRequired={true}
                            placeholder={locationPrompt} disabled={isMemberFormDisabled} variant='outlined' />
                    </FieldSlot>
                </FieldRow>
            }
            {statePrompt &&
                <FieldRow>
                    <FieldSlot md={12}>
                        <StatePicker
                            name={compositeFieldname(name, AnswerTypeMemberProps.UNLISTED_STATE_NAME)}
                            returnType={STATE_RETURN_TYPE.ABBREVIATION}
                            label='State'
                            isSoftRequired={true}
                            variant='outlined'
                        />
                    </FieldSlot>
                </FieldRow>
            }
            {cityPrompt &&
                <FieldRow>
                    <FieldSlot md={12}>
                        <Input
                            name={compositeFieldname(name, AnswerTypeMemberProps.UNLISTED_CITY_NAME)}
                            inputLabel={cityPrompt}
                            isSoftRequired={true}
                            placeholder={cityPrompt}
                            disabled={isMemberFormDisabled}
                            variant='outlined'
                        />
                    </FieldSlot>
                </FieldRow>
            }

        </FieldSection>
    )
}

export default MembershipAnswer;