import { useSelector } from 'react-redux';
import Input from './../../components/Input';
import Checkbox from './../../components/Checkbox';
import { useFormContext } from "react-hook-form";
import { useEffect } from 'react';
import { FieldSection, FieldRow, FieldSlot } from '../../components/Forms/FieldSection';
import { FIELDS, AnswerTypeCollegeProps } from '../../store/form/fields';
import { compositeFieldname } from "../../utils";
import CollegeDropdown, {UNLISTED_COLLEGECODE} from '../../components/DataDriven/CollegeDropdown';
import HiddenInput from '../../components/Utility/HiddenInput';
import StatePicker from './../../components/DataDriven/StatePicker';
import _get from 'lodash.get';

/**
 * Standard college of choice section
 * 
 * @param {*} param0 
 * @returns 
 */
function CollegeChoiceAnswer({ answer }) {

    const year = answer.Year;
    // const authState = useSelector((state) => state.auth);
    const reduxFormState = useSelector((state) => state.form);
    const methods = useFormContext({
        mode: 'onBlur'
    });
    const { setValue, watch } = methods;

    const noUnlistedCollegeCodeFlag = "N/A";
    const unlistedCollegeCodeFieldName = compositeFieldname(FIELDS.COLLEGE_CHOICE, AnswerTypeCollegeProps.UNLISTED_CODE);

    const collegeCode = watch(compositeFieldname(FIELDS.COLLEGE_CHOICE, AnswerTypeCollegeProps.CODE));
    const unlistedCollegeCode = _get(reduxFormState, `fields.${unlistedCollegeCodeFieldName}.value`);
    const preserveNoCollegeCodeflag = unlistedCollegeCode === noUnlistedCollegeCodeFlag; //Unlisted College Choice can exist with & without college code.
    const noCollegeCodeFlag = watch('noCollegeCode');
    const isUnlistedCollegeSelected = collegeCode?.value === UNLISTED_COLLEGECODE;

    useEffect(() => {
        if (noCollegeCodeFlag) {
            setValue(unlistedCollegeCodeFieldName, noUnlistedCollegeCodeFlag)
        }
        else{
            setValue(unlistedCollegeCodeFieldName, "");
        }
    }, [noCollegeCodeFlag])


    if (answer === null || answer.IsLoading) {
        return (
            <FieldSection title={`Your current college choice for the school year`}>

            </FieldSection>
        );
    }



    return (

        <FieldSection title={`Your current college choice for the ${year}-${(year + 1).toString().substr(-2)} school year`}>
            <FieldRow>
                <FieldSlot>
                    <HiddenInput name={compositeFieldname(FIELDS.COLLEGE_CHOICE, AnswerTypeCollegeProps.TYPE)} defaultValue="College" />
                    <CollegeDropdown isRequired={true} placeholder="College" name={compositeFieldname(FIELDS.COLLEGE_CHOICE, AnswerTypeCollegeProps.CODE)} includeUnlisted={true} inputLabel='College or University Name' variant='outlined' />
                </FieldSlot>
            </FieldRow>
            {isUnlistedCollegeSelected && (
                <>
                    <FieldRow>
                        <FieldSlot>
                            <Input isRequired={!noCollegeCodeFlag} name={compositeFieldname(FIELDS.COLLEGE_CHOICE, AnswerTypeCollegeProps.UNLISTED_CODE)} disabled={noCollegeCodeFlag} inputLabel='College Code' />
                        </FieldSlot>
                        <FieldSlot>
                            <div>
                                <Checkbox name='noCollegeCode' label="College is not a Title IV college and has no college code" defaultValue={preserveNoCollegeCodeflag} />
                            </div>
                        </FieldSlot>
                    </FieldRow>
                    <FieldRow>
                        <FieldSlot>
                            <Input isRequired={true} name={compositeFieldname(FIELDS.COLLEGE_CHOICE, AnswerTypeCollegeProps.UNLISTED_NAME)} inputLabel='College Name' />
                        </FieldSlot>
                    </FieldRow>
                    <FieldRow>
                        <FieldSlot>
                            <StatePicker isRequired={true} name={compositeFieldname(FIELDS.COLLEGE_CHOICE, AnswerTypeCollegeProps.UNLISTED_STATE_NAME)} placeholder="State" label="State" variant="outlined" />
                        </FieldSlot>
                        <FieldSlot>
                            <Input isRequired={true} name={compositeFieldname(FIELDS.COLLEGE_CHOICE, AnswerTypeCollegeProps.UNLISTED_CITY_NAME)} inputLabel='City' placeholder='City' variant='outlined' />
                        </FieldSlot>
                    </FieldRow>
                    <FieldRow className="rhythm">
                        <FieldSlot className="rhythm rhythm--deep">
                            <div className="rhythm">
                                <p>If unlisted college above, check the <a href="https://studentaid.gov/fafsa-app/FSCsearch" target="_blank">Federal Database</a> to get the college code, college name, and college city.  Select the state of your college choice from the first drop-down list above.</p>
                                <p>If you can't find the college in the Federal database, contact your college's financial aid staff to ensure that the college is eligible to receive Title IV funds.</p>
                            </div>
                        </FieldSlot>
                    </FieldRow>
                </>
            )}

       
        </FieldSection>


    );
}

export default CollegeChoiceAnswer;
