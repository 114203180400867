import React, { useEffect, useState } from 'react';
import { Checkbox } from "@material-ui/core";

function ReadOnlyCheckbox({ value }) {
  const [updatedVal, setUpdatedVal] = useState(false);
  useEffect(() => {
    setUpdatedVal(value);
  }, [value]);

  return (
    <Checkbox
      checked={updatedVal}
      disabled
      style={{ paddingLeft: 0 }}
    />
  );
}

export default ReadOnlyCheckbox;
