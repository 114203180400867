import { useSelector } from 'react-redux';
import PortalView from './../../components/Global/PortalView';
import Scholarships from '../../components/DashboardDisplay/Scholarships';
import apiClient from '../../utils/API';
import { useState, useEffect, Component } from 'react';
import { enrichProgramsWithDocInfo } from '../../utils';
import { FIELDS } from '../../store/form/fields';
import { actions } from '../../store';
import GrantsDisplay from "../../components/DashboardDisplay/Grants";
import Spinner from '../../components/Utility/Spinner';
import Button from "../../components/Button"
import WarningIcon from '@material-ui/icons/WarningRounded';
import ProgramInfo from '../../utils/dtos/ProgramInfo';
import CheckIcon from '@material-ui/icons/Check';
import ProgramApplicationInfo from '../../utils/dtos/ProgramApplicationInfo';
import NotificationAlert, {NOTIFICATION_VARIANT}  from './NotificationAlert';



function findRecentScholarshipYear(programList) {
    const [scholarshipRecentYear] = programList.filter(p => p.Code === "SCH").flatMap(p => p.ActiveSeasons).map(p => p.Year).sort().reverse();
    return scholarshipRecentYear;
}

/**
 * 
 * @returns {Component}
 */
function Dashboard() {

    const formState = useSelector((state) => state.form);
    const get = (fieldKey) => formState.fields[fieldKey]?.value;
    const isLoading = false

    const [loaded, setStatus] = useState(false);

    document.onreadystatechange = () => {
        setStatus(true);
    }


    const [profileComplete, setProfileComplete] = useState(true); // setting this to true as default so incomplete profile warning does not errantly display at first
    /**
     * determine if user can proceed past profile section
     */
    useEffect(async () => {
        const status = await apiClient.getProfileStatus();
        setProfileComplete(status);
    }, []);

    /**
     * initialize any displayed answers on this page
     */
    useEffect(() => {
        actions.fetchBasicFields([FIELDS.FIRST_NAME]);
    }, []);


    const [programsData, setProgramsData] = useState([]);
    const [applicationsData, setApplicationsData] = useState([]);
    const [scholarshipDigests, setScholarshipDigests] = useState([]);
    const [awardedScholarships, setAwardedScholarships] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [state, setState] = useState({
        isScholarshipsLoading: false,
        isSchRenewalsLoading: false
    })

    const appDigests = applicationsData.map(a => new ProgramApplicationInfo(a, programsData.find(p => p.Code === a.ProgramCode)));
    const listGrants = appDigests.filter(a => a.isGrant);
    const scholarshipRecentYear = findRecentScholarshipYear(programsData);
    const activePriorAwardedYears = programsData.filter(p => p.Code === "SCH").flatMap(p => p.ActiveSeasons).map(p => p.Year).filter(y => y != scholarshipRecentYear);

    const listScholarships = scholarshipDigests.filter(a => a.Source !== 'Renew');
    const listSchRenewals = scholarshipDigests.filter(a => a.Source === 'Renew');
    const showSchRenewalsSectionFlag = listSchRenewals.length > 0;
    const schDigest = appDigests.find(a => a.programCode === 'SCH' && a.year === scholarshipRecentYear); //updated status object

    function getNotificationFromReviewStatus(){
        if (!schDigest)
            return null;
        if (!schDigest.reviewStatus)
            return null;
        if (schDigest.reviewStatus === 'PENDING' || schDigest.reviewStatus === 'EMPTY' || schDigest.reviewStatus === 'NA')
            return null; //don't want to display these statuses at this time
        if (schDigest.reviewStatus === 'REVIEW1' || schDigest.reviewStatus === 'REVIEW2')
            return null; //effectively "pending" from student perspective
        
        let level = 'Error';
        if (schDigest.reviewStatus === 'VALID')
            level = 'Success';
        if (schDigest.reviewStatus.startsWith('PEND'))
            level = 'Pending';

        return {
            Title: null,
            Message: schDigest.reviewMessage,
            Location: 'DashboardCard',
            ProgramCode: schDigest.programCode,
            Year: schDigest.year,
            AttentionLevel: level
        };
    }

    const scholarshipReviewAsNotification =  getNotificationFromReviewStatus();

    


    /**
     * initialize page with program/app info
     */
    useEffect(async () => {
        setState({ ...state, isScholarshipsLoading: true, isSchRenewalsLoading: true, isGrantStatusLoading: true, scholarships: [] });

        // search for all active "featured" applications
        const featuredApplications = await apiClient.get("/application/search", { isActive: true, mode: "Featured" });
        setApplicationsData(featuredApplications);

        // get program info for each application
        const programCodes = [...new Set(featuredApplications.map(a => a.ProgramCode))]; //unique list of codes
        const programRequests = programCodes.map(x => apiClient.getProgram(x));
        const programs = await Promise.all(programRequests);
        setProgramsData(programs);
        setState({ ...state, isGrantStatusLoading: false });

        const appDigests = featuredApplications.map(a => new ProgramApplicationInfo(a, programs.find(p => p.Code === a.ProgramCode)));

        const scholarshipRecentYear = findRecentScholarshipYear(programs);
        const scholarshipApps = appDigests.filter(a => a.isScholarship && a.year === scholarshipRecentYear).map(a => a.legacyEnrichedData);
        const scholarshipsWithDocs = await enrichProgramsWithDocInfo(scholarshipApps);
        setScholarshipDigests(scholarshipsWithDocs);

        setState({ ...state, isSchRenewalsLoading: false, isScholarshipsLoading: false, isGrantStatusLoading: false });

        // get awarded scholarships information.
        const awardedScholarships = appDigests.filter(a => a.isScholarship && a.year !== scholarshipRecentYear && a.isAwarded === true);
        setAwardedScholarships(awardedScholarships);

    }, []);

    /**
     * grab all featured notifications
     */
    useEffect(async () => {
        const allNotifications = await apiClient.get('/application/notifications', {});
        allNotifications.sort((a) => (a.AttentionLevel === 'Warning') ? 11 : 1); // Notifications of AttentionLevel: 'Warning' at the top
        setNotifications(allNotifications);

    }, []);

    const firstName = get(FIELDS.FIRST_NAME);

    const goToProfile = async () => {
        document.location.href = '/profile'
    }

    /**
     * get card-level notifications for a given program
     * @param {string} programCode 
     * @returns {Array<*>}
     */
    function getCardNotifications(programCode, year){
        if (!programCode || !year) {
            return [];
        }
            

        return notifications.filter(n => n.Location === 'DashboardCard' && n.ProgramCode === programCode && n.Year === year);
    }

    /**
     * @returns {Component}
     */
    function ActiveScholarshipButton(){
        if (!schDigest)
            return null;

        const appLink = `/additional-scholarship-info/${schDigest.year}`;
        
        if (schDigest.isSubmitted)
            return <a href={appLink} className='button' >View</a>

        if (schDigest.isDeadlinePassed)
            return <p style={{margin: '1em' }}>Scholarships has closed. Come back next fall to see our {schDigest.year + 1} catalog.</p>

        if (schDigest.isStarted)      
            return <a href={appLink} className='button' >Edit</a>
                    
        if (schDigest.isSeasonOpen)
            return <a href={appLink} className='button' >Start</a>
       
        return <p style={{margin: '1em' }}> Scholarships is currently closed.  Come back on {schDigest.startEvent?.displayDate} to see our {schDigest.year} catalog.</p>                      
    }

    return (
        <PortalView name="DashboardPage">
            <div name='DashboardPage' className="rhythm">
                <div className="rhythm">
                    {/* Page Intro */}
                    <div className="page-intro">
                        <div className="wrapper">
                            <div className="page-intro__layout">
                                <div className="page-intro__icon">
                                    <img src="/dashboard.svg" alt="Student Portal" />
                                </div>
                                <div className="page-intro__content rhythm">
                                    <h1 className="heading heading--h1">Student Portal Dashboard</h1>
                                    <h2 className="heading heading--h2">
                                        Welcome{firstName && (<>, {firstName}! </>) || (<span>!</span>)}
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Notifications */}
                    <div className="notifications component rhythm">
                        <div className="wrapper rhythm">
                            <div className="notifications__header">
                                <h3 className="heading heading--h3">Notifications {notifications.length > 0 && <div style={{ height: '9px', width: '9px', borderRadius: '50%', background: '#429D5B', display: 'inline-block', margin: '0 0 3px 3px' }} />}</h3>
                            </div>
                            <div className="notifications__content">
                                {profileComplete === false &&
                                    <div className="notification">
                                        <div className="notification__layout">
                                            <div>
                                                <div><WarningIcon sx={{ color: "#da3754" }} /></div>
                                            </div>
                                            <div className="rhythm">
                                                <h4 className="heading heading--h5 notification__title">Please Complete Your Profile</h4>
                                                <div className="notification__message rhythm">
                                                    <p>In order to apply for grants and scholarships, your profile questions need to be completed.</p>
                                                    <Button name="getStarted" label="Get Started" type="button" onClick={goToProfile} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {notifications.filter(n => n.Location === 'DashboardHeader' || n.Location === null).map((item, i) =>
                                    <NotificationAlert key={i} notification={item} variant={NOTIFICATION_VARIANT.DASHBOARD} />                                  
                                )}
                            </div>

                            {/* TODO: this appears while notifications data is loading and should only appear when there are 0 notifications */}
                            {!notifications.length > 0 && <p className="paragraph">There are currently no notifications.</p>}
                        </div>
                    </div>
                </div>

                {profileComplete &&
                    <div className="grants wrapper rhythm component">
                        <div className="notifications__header">
                            <h3 className="heading heading--h3">Grants</h3>
                            {state.isGrantStatusLoading &&
                                <Spinner />
                            }
                        </div>
                        <div name="GrantsDisplay" className="grants__content">
                            {listGrants.map((item, i) =>
                                <GrantsDisplay key={`GrantsDisplay${i}`} details={item.legacyEnrichedData} className="card" 
                                    notifications={getCardNotifications(item.programCode, item.year)} />
                            )}
                            {!listGrants.length > 0 && <div>No grants to display.</div>}
                        </div>
                    </div>
                }

                {
                    /* #3951/SP-347: RENEWAL: Create separate application for renewals */
                    profileComplete && showSchRenewalsSectionFlag &&
                    <div className="grants wrapper rhythm component">
                        <div className="notifications__header">
                            <h3 className="heading heading--h3">Scholarship Renewals</h3>

                            {// TODO: isSchRenewalsLoading 
                                state.isSchRenewalsLoading &&
                                <Spinner />
                            }
                        </div>
                        <div name="SchRenewalsDisplay" className="grants__content">
                            {isLoading && <Spinner />}
                            {!isLoading && listSchRenewals.map((item, i) =>
                                <Scholarships key={`SchRenewals${i}`} details={item} 
                                    notifications={getCardNotifications(item.ProgramCode, item.Year)}/>
                            )}
                        </div>
                    </div>
                    /* END: RENEWAL: Create separate application for renewals  */
                }

                {profileComplete &&
                    <div className="grants wrapper rhythm component">
                        <div className="notifications__header">
                            <h3 className="heading heading--h3">Scholarship Application</h3>
                            {schDigest?.isSubmitted && <div><div style={{ color: '#429D5B' }}><CheckIcon />Submitted</div></div>}

                            <NotificationAlert notification={scholarshipReviewAsNotification} variant={NOTIFICATION_VARIANT.CARD} />   

                            {getCardNotifications('SCH', scholarshipRecentYear).map((item, i) =>
                                <NotificationAlert key={i} notification={item} variant={NOTIFICATION_VARIANT.CARD} />                          
                            )}

                            <ActiveScholarshipButton />
                                                       
                            {activePriorAwardedYears.map(y => (awardedScholarships?.filter(p => p.year === y)?.length > 0) &&
                                <div style={{ margin: "20px 0" }}>
                                    <a href={`/additional-scholarship-info/${y}`} className='button' >{y} Update</a>
                                    <span style={{ paddingLeft: '2em' }}>Keep OSAC informed of any important changes for your {y} - {y + 1} awards.</span>
                                </div>
                            )}



                            {state.isScholarshipsLoading &&
                                <Spinner />
                            }
                        </div>
                        <div name="ScholarshipsDisplay" className="grants__content">
                            {isLoading && <Spinner />}
                            {!isLoading && listScholarships.map((item, i) =>
                                <Scholarships key={`ScholarshipsDisplay${i}`} details={item} notifications={getCardNotifications(item.ProgramCode, item.Year)} />
                            )}
                            {!listScholarships.length > 0 && <div>No scholarships to display.</div>}
                        </div>
                    </div>
                }
            </div>
        </PortalView>
    );
}

export default Dashboard;
