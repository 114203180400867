import React from 'react';

function PrintIcons({tooltip = 'Print', description, ...props}) {

  return (
    <a href="#" onClick={() => {window.print(); return false;}}><svg viewBox="0 0 24 24" height="24" width="24"><path d="M19 8H5c-1.66 0-3 1.34-3 3v6h4v4h12v-4h4v-6c0-1.66-1.34-3-3-3zm-3 11H8v-5h8v5zm3-7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-1-9H6v4h12V3z" fill="#ffffff"></path></svg> <span className="sr-only">Print</span></a>
  );
};

export default PrintIcons;

