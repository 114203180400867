import { useEffect, useState } from "react";
import apiClient from "../../../utils/API";

/**
 * quick summary of selected high school
 * @param {*} param0 answer DTO
 */
function SchoolDisplay({ answer }) {

    const [schoolName, setSchoolName] = useState(null);

    useEffect(() => {
        (async () => {
            if (!answer.SchoolCode)
                setSchoolName(answer.UnlistedName);

            else if (answer.Type == 'College') {
                const highSchool = await apiClient.getCollege(answer.SchoolCode);
                setSchoolName(highSchool.Name);
            }
            else {
                const highSchool = await apiClient.getHighSchool(answer.SchoolCode);
                setSchoolName(highSchool.Name);
            }

        })();
    }, []);

    return (
        <>
            {schoolName}
        </>
    )

}

export default SchoolDisplay;
