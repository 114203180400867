import { initialFormState } from './state';
import { FormActionTypes } from './actions';
import { REPEATABLE_FIELDS } from './fields';

export const formReducer = (state = initialFormState, action) => {
  switch (action.type) {
    case FormActionTypes.SET_FIELD_LOADING_STATE:
      const { isLoading } = action;
      return {
        ...state,
        isLoading
      }
    case FormActionTypes.PURGE_DELETED_REPEATABLE_FIELD_IDS:
      return {
        ...state,
        deletedRepeatableFieldIds: {
          ...REPEATABLE_FIELDS.reduce((dict,repeatableFieldKey)=>{
            dict[repeatableFieldKey] = []
            return dict;
          },{})
        }
      }
    case FormActionTypes.ADD_DELETED_REPEATABLE_FIELD_ID:
      return (()=>{
        const { name , id } = action;
        if(name && id && Array.isArray(state.deletedRepeatableFieldIds[name]) && !state.deletedRepeatableFieldIds[name].includes(id)){
          return {
            ...state,
            deletedRepeatableFieldIds: {
              ...state.deletedRepeatableFieldIds,
              [name]: [...state.deletedRepeatableFieldIds[name], id]
            }
          }
        }
        else {
          return state;
        }
      })();
    case FormActionTypes.UPDATE_FIELD:
      return (()=>{
        const field = action.field;
        const { name, value } = field;
        // console.log("NAME", name)
        if (name) {
          return {
            ...state,
            fields: {
              ...state.fields,
              [name]: {
                ...state.fields[name],
                value
              }
            }
          };
        }
        else {
          return state;
        }
      })();
    case FormActionTypes.BULK_UPDATE_FIELDS:
      const fields = action.fields;
      return { 
        ...state,
        fields
       }

    default:
      return { ...state };
  }
};

