import { useSelector } from "react-redux";
import Skeleton from 'react-loading-skeleton';

function ReadOnly({ description, name, value, ...props }) {
  const formState = useSelector((state) => state.form);
  const isLoading = formState.isLoading;
  return (
    isLoading ?
      <Skeleton />
      : (
        <p aria-label={description || name} style={{ whiteSpace: 'pre-line' }} {...props}>
          {value}
        </p>
      )
  );
}

export default ReadOnly;
