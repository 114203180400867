import { combineReducers } from "redux";
//import { routerReducer } from 'react-router-redux'
import { authReducer } from "./auth/reducers";
import { formReducer } from "./form/reducers";
import { snackReducer } from "./snack/reducers";
// Keys will correspond to folder name within store

export default combineReducers({
  auth: authReducer,
  form: formReducer,
  snack: snackReducer,
  //  routing: routerReducer,
});
