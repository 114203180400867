import ReadOnly from "./ReadOnly"

const ReadOnlyField = ({ name, value, description = '' }) => {
  return (
    <div className="read-only">
      <div className="read-only__title">{name}</div>
      <div className="read-only__data">
        <ReadOnly name={name} value={value} description={description}/>
      </div>
    </div>
  )
}

export default ReadOnlyField;