import { initialUserState } from './state';
import { AuthActionTypes } from './actions'
  
export const authReducer = (state = initialUserState, action) => {
    switch (action.type) {
      
      case AuthActionTypes.LOGIN:
        // Oddly, idToken passes as undefined when logging in and somehow ends up in local storage as undefined but parseable
        localStorage.setItem('idToken',action.idToken);

        return {
          ...state,
          isLoggedIn: true,
          idToken: action.idToken
        }

      case AuthActionTypes.LOGOUT:

        localStorage.removeItem('idToken');
        return {
          ...state,
          isLoggedIn: false,
          idToken: '',
        }

      case AuthActionTypes.SET_MIGRATION:
        const { status } = action
        // console.log("Set Migration called", status)
        return {
          ...state,
          migration: status
        }

      default:
        return {...state};
    }
};