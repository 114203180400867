import sumBy from 'lodash/sumBy'

/**
 * wrapper & interpretor for Award DTO from API
 */
class Award {

    /**
     * 
     * @param {Array<*>} data raw dtos from API
     */
    constructor(data) {
        this._data = data;
    }

    /**
     * total authorized amount
     * 
     * @returns {number}
     */
    get authorizedAmount() {
        if (!this._data)
            return null;

        const totalAuthAmount = sumBy(this._data, 'AuthorizedAmount');       
        return totalAuthAmount;
    }

    /**
     * do any of the awards have the given status
     * @param {String} awardStatus 
     */
    hasStatus(awardStatus) {
        if (!this._data)
            return null;

        for (let award of this._data)
            for (let status of award.Statuses)
                if (status.StatusCode === awardStatus)
                    return true;

        return false;
    }

}

export default Award;