import ResponseAnswerDisplay from "./ResponseAnswerDisplay";
import BooleanDisplay from "./BooleanDisplay";
import ContactDisplay from "./ContactDisplay";
import SchoolDisplay from "./SchoolDisplay";
import DegreeDisplay from "./DegreeDisplay";
import DateDisplay from "./DateDisplay";
import ActivityDisplay from "./ActivityDisplay";

/**
 * 
 * @param {*} param0 
 * @returns 
 */
function AnswerDisplay({answer}) {

    switch (answer.AnswerType) {

        case 'Date':
            return <DateDisplay answer={answer} />
        case 'Text':        
        case 'Integer':
        case 'Decimal':
            return <ResponseAnswerDisplay answer={answer} />
        case 'Boolean':
            return <BooleanDisplay answer={answer} />
        case 'Contact':
        case 'Address':
            return <ContactDisplay answer={answer} />
        case 'HighSchool':
        case 'College':
            return <SchoolDisplay answer={answer} />
        case 'Degree':
            return <DegreeDisplay answer={answer} />
        case 'Activity':
            return <ActivityDisplay answer={answer} />
        default:
            return <> </>

    }
}

export default AnswerDisplay;