import PortalView from "./../../components/Global/PortalView";
import Button from "../../components/Button";
import { FieldSection, FieldRow, FieldSlot } from "../../components/Forms/FieldSection";
import Input from "../../components/Input";
import Telephone from "../../components/Telephone";
import Dropdown from "../../components/Dropdown";
import Email from "../../components/Email";
import { useForm, FormProvider } from "react-hook-form";
import Numeric from "../../components/Numeric";
import LargeTextEditor from "../../components/LargeTextEditor";
import apiClient from "../../utils/API";
import { open } from "../../store/snack/actions";
import { useDispatch } from "react-redux";
import { useState } from "react";

function Feedback() {
  const dispatch = useDispatch();
  const methods = useForm({
    mode: "onBlur",
  });
  const [loading, setLoading] = useState(false);
  const { handleSubmit } = methods;
  // const authState = useSelector((state) => state.auth);

  const onSubmit = async (data) => {
    const {
      Email,
      SenderName,
      PhoneNumber,
      ApplicationNumber,
      FeedbackType,
      Subject,
      Feedback,
    } = data;

    const Body =
      `Phone: ${PhoneNumber}\n` +
      `Application Number: ${ApplicationNumber}\n` +
      `Feedback Type: ${FeedbackType}\n` +
      `Feedback: ${Feedback}`;

    try {
      setLoading(true);
      await apiClient.post(`/communication`, {
        Email,
        SenderName,
        Subject,
        Body,
      });
      dispatch(
        open({
          message: "Feedback sent successfully.",
          severity: "success",
        })
      );
      // show toast or UI action given it was successful
    } catch (err) {
      // show toast or UI action on fail
      dispatch(
        open({
          message: err.message,
          severity: "success",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const onError = (e) => console.log(e);

  return (
    <FormProvider {...methods}>
      <PortalView>
        <div name="FeedbackPage" className="feedback rhythm">
          {/* Page Intro */}
          <div className="page-intro">
            <div className="wrapper">
              <div className="page-intro__layout">
                <div className="page-intro__icon">
                  <img src="/dashboard.svg" alt="Student Portal" />
                </div>
                <div className="page-intro__content rhythm">
                  <h1 className="heading heading--h1">Feedback</h1>
                  <ul className="list">
                      <li className="li">When filling out the form, if you have created a user profile, use the same information that you entered in your user profile.</li>
                      <li className="li">If you are having{" "} <strong>problems with the OSAC Student Portal</strong>, check out the{" "} <a href="https://oregonstudentaid.gov/scholarships-faq.aspx">FAQ</a> to see if your problem is answered there.</li>
                      <li className="li">Be sure to check your e-mail for OSAC's response to your question.</li>
                    </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="form-wrapper">
            <div className="component rhythm">
              <h2 className="heading heading--h3">Please fill out the following form</h2>
              <FieldSection withDivider={false}>
                <FieldRow>
                  <FieldSlot>
                    <Email name="Email" inputLabel="Email" isRequired={true} />
                  </FieldSlot>
                </FieldRow>
                <FieldRow>
                  <FieldSlot>
                    <Input
                      name="SenderName"
                      inputLabel="First Name and Last Name"
                    />
                  </FieldSlot>
                  <FieldSlot>
                    <Telephone
                      name="PhoneNumber"
                      inputLabel="Phone Number"
                      placeholder="Phone Number"
                    />
                  </FieldSlot>
                </FieldRow>
                <FieldRow>
                  <FieldSlot>
                    <Numeric
                      name="ApplicationNumber"
                      inputLabel="Application Number"
                      placeholder="Application Number"
                    />
                  </FieldSlot>
                  <FieldSlot>
                    <Dropdown
                      name="FeedbackType"
                      label="Feedback Type"
                      listItems={[{ value: "Example", label: "Example" }]}
                    />
                  </FieldSlot>
                </FieldRow>
                <FieldRow>
                  <FieldSlot>
                    <Input
                      name="Subject"
                      inputLabel="Subject"
                      isRequired={true}
                    />
                  </FieldSlot>
                </FieldRow>
                <FieldRow>
                  <FieldSlot>
                    <LargeTextEditor
                      name="Feedback"
                      inputLabel="Feedback or Comments"
                      placeholder="Start writing here..."
                      wordLimit="10000"
                    />
                  </FieldSlot>
                </FieldRow>
              </FieldSection>

              <Button  name="Submit" label="Submit" onClick={handleSubmit(onSubmit, onError)} loading={loading} />
            </div>
          </div>
        </div>
      </PortalView>
    </FormProvider>
  );
}

export default Feedback;
