import { actions } from "./../../store";
import Checkbox from "./../../components/Checkbox";
import { useForm, FormProvider } from "react-hook-form";
import RadioGroup from "../../components/RadioGroup";
import SSN from "./../../components/SSNtext";
import {
    FieldSection,
    FieldSlot,
    FieldRow,
} from "../../components/Forms/FieldSection";
import {
    FIELDS,
} from "../../store/form/fields";
import FormSection from "../../components/FormSection";
import { open } from "../../store/snack/actions";
import { useDispatch } from "react-redux";
import React, { useState } from "react";
import HiddenInput from "../../components/Utility/HiddenInput";
import { useEffect } from "react";
import { getReduxField } from "../../utils";
import { Alert, AlertTitle } from '@material-ui/lab';
import CertifyAnswer from "../../components/answer/CertifyAnswer";
import DropdownAnswer from "../../components/answer/DropdownAnswer";
import BooleanAnswer from "../../components/answer/BooleanAnswer";
import TextAnswer from "../../components/answer/TextAnswer";
import RadioGroupAnswer from "../../components/answer/RadioGroupAnswer";

function EditAboutInfo({ section, onChange, onCancel }) {
   
    const fafsaYear = section.year;
 
    const methods = useForm({
        mode: "onBlur",
    });
    const {
        errors,
        formState,
        getValues,
        setValue,
        handleSubmit,
        watch,
    } = methods;

    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const onSubmit = async (data, e) => {
        let exclude = ["SSN-confirm", "RacialGroupValidate"]
        if (getReduxField(FIELDS.SSN) == '*********') {
            exclude = ["SSN-confirm", "RacialGroupValidate", "appstudentlogin.ssn"]
        }
        if (data.OTSG__ENROLLED_TRIBE === null) {
            exclude.push("OTSG__ENROLLED_TRIBE")
        }

        try {
            setLoading(true);
            const result = await actions.submitForm(
                data,
                [],
                exclude
            );
            // console.log(data, e);
            if (typeof onChange === "function") {
                onChange(true);
            }
        } catch (e) {
            dispatch(
                open({
                    message: e.message,
                    severity: "success",
                })
            );
        } finally {
            setLoading(false);
        }
    };

    const onError = (errors) => {
        console.log("ERROR", errors);
        dispatch(
            open({
                message: "This section is not complete. Be sure to resolve all errors above.",
                severity: "success",
            }));
    };

    const racialFields = [
        FIELDS.ETHNICITY__AM_INDIAN,
        FIELDS.ETHNICITY__ASIAN,
        FIELDS.ETHNICITY__BLACK,
        FIELDS.ETHNICITY__HAWAIIAN_PI,
        FIELDS.ETHNICITY__WHITE,
        FIELDS.ETHNICITY__NA,    
        FIELDS.GENDER__NA,
    ];

    const [racialError, setRacialError] = useState(null);
    const validateRacialGroup = () => {
        const racialValues = racialFields.map((f) => getValues(f));
        const isRacialFilled = racialValues.some(
            (gv) => gv === "True" || gv === true
        );
        if (
            isRacialFilled === true
        ) {
            setRacialError(null);
            return true;
        } else {
            setRacialError("Please select at least one option");
            return false;
        }
    };

    const watchPreferNotToAnswer = watch(FIELDS.ETHNICITY__NA);
    useEffect(() => {
        if (watchPreferNotToAnswer === true) {
            racialFields.map((rf) => {
                if (rf !== FIELDS.ETHNICITY__NA) {
                    setValue(rf, false);
                }
            });
        }
    }, [watchPreferNotToAnswer]);

    const watchAmericanIndian = watch(FIELDS.ETHNICITY__AM_INDIAN);
    const watchAsian = watch(FIELDS.ETHNICITY__ASIAN);
    const watchBlack = watch(FIELDS.ETHNICITY__BLACK);
    const watchHawiian = watch(FIELDS.ETHNICITY__HAWAIIAN_PI);
    const watchWhite = watch(FIELDS.ETHNICITY__WHITE);
    useEffect(() => {
        if (watchAmericanIndian === true || watchAsian === true || watchBlack === true || watchHawiian === true || watchWhite === true) {
            setValue(FIELDS.ETHNICITY__NA, false);
        };
    }, [watchAmericanIndian, watchAsian, watchBlack, watchHawiian, watchWhite]);

    const [editSSN, setEditSSN] = useState(false);
    const watchNoSsn = watch('noSsn');
    const watchSsnLater = watch('ssnLater');
    const watchOregonResident = watch(FIELDS.OREGON_RESIDENT)
    const watchSSN = watch(FIELDS.SSN)

    // #3712: Social Security Questions are Exclusive
    // Social security question should not allow both check boxes. It should allow either or neither.
    // Otherwise stated: Should be exclusive true or neither true to show edit box.
    useEffect(() => {
        if (watchNoSsn === true) {

            setValue('ssnLater', false);
        }

    }, [watchNoSsn]);

    useEffect(() => {
        if (watchSsnLater === true) {

            setValue('noSsn', false);
        }

    }, [watchSsnLater]);

    const validateConfirm = (value, compareValue) => {
        if (value === compareValue) {
            return true
        } else {
            return "Does not match"
        }
    }

    const toggleSSN = (e) => {
        e.preventDefault()
        setEditSSN(!editSSN)
    }
    var isSsnProvided = getReduxField(FIELDS.SSN) === '*********';

    //=====( FAFSA  LOGIC )

    const fafsaAnswer = section.getAnswerByField(FIELDS.FAFSA_FILING_DATE)?.Response?.split('T')[0];
    const [fafsaStatus, setFafsaStatus] = useState(null);
    const watchFafsa = watch(FIELDS.FAFSA_FILING_DATE);

    useEffect(() => {
        if(watchFafsa !== "1801-01-01"){
            setFafsaStatus(false)
        }
        else{
            setFafsaStatus(true)
        }
    }, [watchFafsa]);

    const validateFafsaChoice = (value) => {
        if (value) {
            return true;
        } else {
            return false;
        }
    };


    //=====( RENDER RETURN )
    
    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <FormSection
                    title="About Me"
                    isEditable={true}
                    onSaveClick={handleSubmit(onSubmit, onError)}
                    onCancelClick={onCancel}
                    loading={loading}
                >
                    <FieldSection title="Social Security Number" isRequired>
                        <FieldRow>
                            <FieldSlot md={4}>
                                
                                {!watchNoSsn && !watchSsnLater &&
                                    <div>
                                        {isSsnProvided ? '***–**–****' : 'Not provided'}
                                        &nbsp; &nbsp;
                                        <button className="button"  onClick={toggleSSN} 
                                            style={{
                                                fontSize: '0.7em',
                                                lineHeight: '0.7em',
                                                padding: '0.5em 1em',
                                                minWidth: 'fit-content',
                                                minHeight: 'fit-content'
                                                }}>Edit</button>                                      
                                    </div>
                                }

                                {!watchNoSsn && !watchSsnLater && editSSN && 
                                    <SSN
                                        name={FIELDS.SSN}
                                        inputLabel="SSN"
                                        placeholder="SSN"
                                        variant="outlined"
                                        isRequired={!watchSsnLater && !watchNoSsn}//{true}
                                    />
                                } 
                                {!watchNoSsn && !watchSsnLater && editSSN && 
                                    <FieldSlot md={4}>
                                        <SSN
                                            name={`${FIELDS.SSN}-confirm`}
                                            inputLabel="Confirm SSN"
                                            placeholder="Confirm SSN"
                                            variant="outlined"
                                            isRequired={!watchSsnLater && !watchNoSsn}//{true}
                                            rules={(!watchSsnLater && !watchNoSsn) ? { validate: value => validateConfirm(value, watchSSN) } : {}}
                                        />
                                    </FieldSlot>
                                }
                            </FieldSlot>
                        </FieldRow>
                        <FieldRow>
                            <FieldSlot md={4}>
                                <div className="mt-05">
                                    <Checkbox name="noSsn" label="I do not have a SSN" />
                                </div>
                                {!isSsnProvided &&
                                    <div>
                                        <Checkbox name="ssnLater" label="I will enter my SSN later" />
                                    </div>
                                }
                                
                            </FieldSlot>
                        </FieldRow>

                    </FieldSection>

                    <FieldSection>
                        <FieldRow>
                            <FieldSlot md={4}>
                             <RadioGroupAnswer label="Are you hispanic or latino/a/x?" answer={section.getAnswerByField(FIELDS.HISPANIC)} isRequired={true} />
                            </FieldSlot>
                        </FieldRow>
                    </FieldSection>

                    <FieldSection title="Racial Group or Ethnicity?" isRequired>
                        <FieldRow>
                            <FieldSlot>
                                <HiddenInput
                                    name="RacialGroupValidate"
                                    rules={{ validate: (value) => validateRacialGroup(value) }}
                                ></HiddenInput>
                                {racialError && (
                                    <div style={{ marginTop: ".75rem", color: "red" }}>
                                        {racialError}
                                    </div>
                                )}
                                <CertifyAnswer answer={section.getAnswerByField(FIELDS.ETHNICITY__AM_INDIAN)} label="American Indian or Alaska Native" />
                                <CertifyAnswer answer={section.getAnswerByField(FIELDS.ETHNICITY__ASIAN)} label="Asian" />
                                <CertifyAnswer answer={section.getAnswerByField(FIELDS.ETHNICITY__BLACK)} label="Black or African-American" />
                                <CertifyAnswer answer={section.getAnswerByField(FIELDS.ETHNICITY__HAWAIIAN_PI)} label="Native Hawaiian or Pacific Islander" />
                                <CertifyAnswer answer={section.getAnswerByField(FIELDS.ETHNICITY__WHITE)} label="White" />
                                <CertifyAnswer answer={section.getAnswerByField(FIELDS.ETHNICITY__NA)} label="Choose not to say" />

                                <TextAnswer answer={section.getAnswerByField(FIELDS.ETHNICITY__OTHER)}                                  
                                    placeholder="Enter additional race/ethnicity info"
                                    inputLabel="Other Race/Ethnicity Categories (optional)?"
                                />
                            </FieldSlot>
                        </FieldRow>
                    </FieldSection>

                    <FieldSection title="Enrolled Tribe Status">
                        <FieldRow>
                            <div className="tribe-selection">
                                <h3 className="heading heading--h4 academic-year">
                                    Select your Enrolled Tribe
                                </h3>
                                <br />
                                <DropdownAnswer answer={section.getAnswerByField(FIELDS.OTSG__ENROLLED_TRIBE)} label="Select Your Tribe" isRequired={true} />
                            </div>
                        </FieldRow>
                    </FieldSection>

                    <FieldSection title="Select all that apply">
                        <FieldRow>
                            <FieldSlot>
                                <CertifyAnswer answer={section.getAnswerByField(FIELDS.HAS_DEPENDENT_CHILDREN)} label="I have dependent children." />
                                <CertifyAnswer answer={section.getAnswerByField(FIELDS.IS_NATIONAL_GUARD_MEMBER)}
                                    label="I am currently serving in the Oregon National Guard." />
                                <CertifyAnswer answer={section.getAnswerByField(FIELDS.IS_TEACHER_CANDIDATE_MEMBER)}
                                    label="I will be enrolled in a TSPC approved Educator Preparation Program that results in 1) Preliminary teacher licensure, OR 2) School Personnel licenses, including school counseling, social work, and school psychology." />
                                <CertifyAnswer answer={section.getAnswerByField(FIELDS.FOSTER_CARE)}
                                    label='I am currently or formerly in foster care or participating in an independent living program.' />
                                <CertifyAnswer answer={section.getAnswerByField(FIELDS.EARLY_LEARNING_MAJOR)}
                                    label='My degree will be related to working with or supporting work in the field of Early Learning and Care.' />                              
                            </FieldSlot>
                        </FieldRow>
                    </FieldSection>

                    <FieldSection
                        title={`Have you filed the FAFSA/ORSAA for ${fafsaYear
                            }-${fafsaYear + 1}?`}
                        isRequired={true}
                    >
                        <FieldRow>
                            <FieldSlot md={4}>
                                <RadioGroup
                                    name={FIELDS.FAFSA_FILING_DATE}
                                    defaultValue={fafsaAnswer}
                                    rules={{ validate: (value) => validateFafsaChoice(value) }}
                                    listItems={[
                                        { value: "1801-01-01", label: "Yes, I have filed" },
                                        { value: "1802-01-01", label: "I will file the FAFSA/ORSAA" },
                                        { value: "1803-01-01", label: "No, I will not file the FAFSA/ORSAA", },

                                    ]}
                                />
                            </FieldSlot>
                        </FieldRow>
                    </FieldSection>

                    {/* #3748/SP220: Show a message "If you do not file the FAFSA/ORSAA, you will not be considered for scholarships or grants that require the FAFSA/ORSAA." 
              when student answers "I will file the FAFSA/ORSAA" or "No, I will not file the FAFSA/ORSAA". */
                        (!fafsaStatus) ?
                            <div>
                                <Alert severity="warning" color="warning" >
                                    <AlertTitle>FAFSA/ORSAA Warning</AlertTitle>
                                    If you do not file the FAFSA/ORSAA, you will not be considered for scholarships or grants that require the FAFSA/ORSAA.
                                </Alert>
                            </div> : ""
                    }

                 

                    <FieldSection title="Applicant Type">
                        <FieldRow>
                            <FieldSlot>
                                <CertifyAnswer answer={section.getAnswerByField(FIELDS.TEST_APPLICATION)} isRequired={false}
                                    label="This is a test application. (I am a counselor, parent of an applicant, volunteer, educator, a high school junior or other non-applicant.)" />
                            </FieldSlot>
                            <Alert severity="warning" color="warning">
                                If test application is selected, this application will not be considered for any financial aid.
                            </Alert>
                        </FieldRow>
                    </FieldSection>

                    <FieldSection>
                        <h4 className="heading heading--h5">As of Fall Term {fafsaYear}, have you been an Oregon resident for 12 months preceding college enrollment? <span style={{ color: 'red' }}>*</span></h4>
                        <FieldRow>
                            <FieldSlot>
                                <BooleanAnswer answer={section.getAnswerByField(FIELDS.OREGON_RESIDENT)} value={watchOregonResident} isRequired={true} />
                            </FieldSlot>
                        </FieldRow>
                    </FieldSection>

                </FormSection>
            </form>
        </FormProvider>
    );
}

export default EditAboutInfo;
