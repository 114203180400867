import RadioGroup from './RadioGroup';
import { stringy } from '../utils'
import { useSelector } from "react-redux";

/*
  This requires a little voodoo on the calling component or view,
  until we can figure out a better way to intercept
  the state change here and convert it back to boolean

  Example:

  In the calling component/page, if you need to do any true/false
  evaluations on this variable, make sure you import the 'truthy'
  method from the 'utils' library:

    import { truthy } from "../../utils";

  Next, in the calling component/view, you will need to set a watch
  variable for the field, as such:

    const watchOregonResident = watch(FIELDS.OREGON_RESIDENT)

  When you call this component, pass that watch variable in as the value,
  with the actual field name as the name prop:

    <BooleanRadioGroup name={FIELDS.OREGON_RESIDENT} value={watchOregonResident} isRequired={false} />

  And use the 'truthy' function as a filter for your evaluation, as
  it will check for a boolean true, or a string 'True' and return a boolean:

    if (truthy(watchOregonResident)) {
      // do something
    }
*/

const defaultListItems = [
    { value: 'True', label: 'Yes' },
    { value: 'False', label: 'No' },
]

export default function BooleanRadioGroup({ name, defaultValue, listItems = defaultListItems, ...props }) {
    const fieldNameValue = useSelector((state) => state.form.fields[name]);

    if (!defaultValue && fieldNameValue && fieldNameValue.value !== null) {
        // transform boolean to string before passing down to radiogroup component
        defaultValue = stringy(fieldNameValue.value)
    }

    return (
        <RadioGroup
            name={name}
            listItems={listItems}
            labelStyle={{ paddingRight: 1 + 'rem' }}
            defaultValue={defaultValue}
            {...props} />
    )
};
