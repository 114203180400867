import PortalView from "./../../components/Global/PortalView";

function Glossary() {
  // const authState = useSelector((state) => state.auth);

  return (
    <PortalView>
      <div name="GlossaryPage" className="content">
        <div className="content-title">Glossary</div>
      </div>
    </PortalView>
  );
}

export default Glossary;
