import React, { useState } from "react";
import { InputLabel, useTheme } from '@material-ui/core';
import { get } from 'lodash'
import { useFormContext } from 'react-hook-form';
import moment from 'moment';
import { useEffect } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import HiddenInput from './Utility/HiddenInput';

/**
 * prompt for a date from student only providing a month and a year
 * @param {*} param0 
 * @returns {Component}
 */
function MonthYearPicker({ label, helperText = null, name, isDisabled, defaultValue, isRequired, ...props }) {

    const { errors } = useFormContext({ mode: 'onBlur' }); 
    const theme = useTheme();
    const error = get(errors, name);

    const defaultValueDate = defaultValue ? new Date(defaultValue) : null;
    const defaultMonth = defaultValueDate ? (defaultValueDate.getMonth() + 1).toString() : null;
    const defaultYear = defaultValueDate?.getFullYear()?.toString();   
   
    const [month, setMonth] = useState(defaultMonth);
    const [year, setYear] = useState(defaultYear);
    const [registeredDate, setRegisteredDate] = useState(null);

    function getMonthYear(){
        return month && year ?  `${year}-${month}`: null;
    }
   
    useEffect(() => {
        setRegisteredDate(getMonthYear())
    }, [month, year]);

    //compose a reasonable list of years relative to the current year (mostly in the past, a little in the future)
    const currentYear = (new Date()).getFullYear();
    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
    const years = range(currentYear + 3, currentYear - 100, -1);
  
   
    const monthOptions = moment.months().map((month, i) => {
        return {
            label: month,
            value: (i + 1).toString(),
        };
    });

    const yearOptions = years.map((year) => {
        return {
            label: year,
            value: year,
        };
    });
    
    function handleChange(value,type){
        switch(type) {
            case 'month':
                setMonth(value)
                break;
            case 'year':
                setYear(value)
                break;
            default:
                break;
        }
    }

    return (
        <div onBlur={props.onBlur} style={{ marginTop: theme.spacing(2), position: 'relative' }}>          
            <InputLabel>
                {label}
                {isRequired && 
                    <span style={{ color: 'red' }}>&nbsp;*</span>
                } 
            </InputLabel>
            <HiddenInput isRequired={isRequired} name={name} defaultValue={registeredDate} value={registeredDate}/>   
            <div>
                <span style={{display: "inline-block", width:'10em'}}>

                    <Select error={ error? true : false } defaultValue={defaultMonth} fullWidth={true} label={"month"} style={{ height: '42px', borderRadius: "5px", margin: 0, background: 'white' }} onChange={(x)=>handleChange(x.target.value,'month')}>
                        {monthOptions.map((item,i) => <MenuItem key={`${name}-${i}}`} value={item.value} label={item.label}>{item.label}</MenuItem>)}
                    </Select>

                </span>
                <span  style={{display: "inline-block", width:'10em'}}>

                    <Select error={ error? true : false } defaultValue={defaultYear} fullWidth={true} aria-label={"year"} style={{ height: '42px', borderRadius: "5px", margin: 0, background: 'white' }} onChange={(x)=>handleChange(x.target.value,'year')}>
                        {yearOptions.map((item,i) => <MenuItem key={`${name}-${i}}`} value={item.value} label={item.label}>{item.label}</MenuItem>)}
                    </Select>

                </span>         
            </div>
            {
                error?.message && (
                <div style={{ color: 'red', fontSize: '0.875rem' }}>
                    {error?.message}
                </div>
                )
            }
         
        </div>
    );
};

export default MonthYearPicker;