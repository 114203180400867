
/**
 * answer dto wrapper
 */
class AnswerDto {

    constructor(data){
        this._data = data;
        this._isDeleted = false;
    }

    get questionKey(){
        return this._data.QuestionKey;
    }
    
    get year(){
        return this._data.Year;
    }

    /**
     * full DTO, with all the status fluff
     */
    get dto(){
        delete this._data.section; //make sure all answers are json-serializable DTOs, no meta info
        return this._data;
    }

    /**
     * get clean dto for looking up answer fresh or doing file/delete/other manipulations
     * @returns {*}
     */
    get locatorDto(){
        return {
            id: this.id,
            year: this.year,
            questionKey: this.questionKey
        };
    }

    /**
     * get _clean_ dto for doing full updates
     * 
     * @return {*}
     */
    get updateDto(){
        var dto = {...this._data};

        //these things don't hurt anything by sending them back, but they do make the request harder to read in troubleshooting
        delete dto.section;
        delete dto.ReviewStatus;
        delete dto.ReviewStatusDescription;
        delete dto.ReviewInstructions;
        delete dto.IsValid;
        delete dto.Description;
        delete dto.SelectedOption;
        delete dto.Status;
        delete dto.IsLocked;
        delete dto.LockReason;
        delete dto.LockingPrograms;
        delete dto.IsLoading;
        delete dto.PlaceholderId;
        return dto;       
    }

    get id(){
        return this._data.Id;
    }

    set id(value){
        this._data.PlaceholderId = this.id;
        this._data.Id = value;
    }

    get fieldId(){
        return this._data.PlaceholderId ?? this.id;
    }

    get isNew(){
        return this.id < 0; //never a legit answer Id
    }

    get isDeleted(){
        return this._isDeleted;
    }
    /**
     * answer type 
     * @returns {string}
     */
    get type(){
        return this._data?.AnswerType;
    }

    /**
     * is answer recommended for read-only state
     * @returns {boolean}
     */
    get isLocked(){
        return this._data?.IsLocked;
    }


    /**
     * create a clean, udated version of the answer for sending back to the API
     * @param {*} fieldData 
     */
    updateFromField (fieldData){   
        
        const self = this;

        /**
         * try to locate a singular value from form field value
         * @param {*} response 
         * @returns {string|number|boolean}
         */
        function findValue(response){
            if (!response)
                return null;
            if (typeof response === 'object'){
                //Note: Removed Object.hasOwn(). It is not available until node.js v16.9.0 +
                if (Object.keys(response).includes("Response")){
                    return response.Response;
                }
                if (Object.keys(response).includes("value")){           
                    // for some reason some dropdown types are returning bizarely complex responses instead of the rote value. Keeping it like this for backwards-compatibility
                    // with old revtech answer updater
                    return response.value;
                }
            }
            return response;
        }

        /**
         * attempt to locate a single-field response from the form data
         * @returns {*}
         */
        function findResponse(){
            let response = fieldData; //by default forms tend to have simple name/value pairs
            if (Array.isArray(fieldData)) {
                //more than one option for answer, attempt to locate the correct one
                response = fieldData.find(x => x.Id === self.id); 
            }                
           
            return findValue(response);
        }

        /**
         * when fielddata is a whole colleciton of answers in one, find the entry that best describes this specific answer
         * @returns {*}
         */
        function findFieldEntry(){
            return fieldData.find(x => x.Id === self.id || x.Id === self.fieldId);
        }
        
        // based on answer type, attempt to locate best fieldData to describe what's being updated
        switch (this.type) {
            case 'Boolean':
                let response = findResponse();
                if (response === 'True') 
                    response = true;
                else if (response === 'False') 
                    response = false;

                this._data.Response = response;
                break;
            case 'Date':
            case 'Text':        
            case 'Integer':
            case 'Decimal':
                this._data.Response = findResponse();
                break;
            
            case 'HighSchool':
                this._data.SchoolCode = findValue(fieldData.SchoolCode);                                 
                this._data.CityId = findValue( fieldData.CityId);
                this._data.CountyId = findValue(fieldData.CountyId);
                this._data.StateId = findValue(fieldData.StateId);
                this._data.UnlistedName = findValue(fieldData.UnlistedName);                
                this._data.UnlistedStateName = findValue(fieldData.UnlistedStateName);
                this._data.UnlistedCityName = findValue(fieldData.UnlistedCityName);
                break;     
            case 'Degree':     
                const myField = findFieldEntry();
                if (myField) {                
                    this._data.DegreeId = findValue(myField.DegreeId);                      
                    this._data.GraduationDate = findValue(myField.GraduationDate);               
                    this._data.CIPMajorId = findValue(myField.CIPMajorId);             
                    this._data.CIPMajorId2 = findValue(myField.CIPMajorId2);   
                }             
                else {
                    this._isDeleted = true;
                }   
                break;
            case 'Contact':
            case 'Address':                                            
            case 'College':                           
            case 'Activity':
                throw new Error("Answer type not yet supported: " + this.type);
            default:
                throw new Error("Unknown answer type " + this.type);
        }
    }

    /**
     * genreate a stub answer of a given type
     * @param {string} key question key
     * @param {number} id temporary or permanent answer id if known
     * @returns {AnswerDto}
     */
    static createOfType(key, id = 0) {
        var dto = {
            Id: id, 
            QuestionKey: key,
            IsLocked: false,
        }
        switch (key) {
            case 'Degrees':
                dto.AnswerType = 'Degree';
                break;
            default:
                dto.Response = null;
        }
        return new AnswerDto(dto);
    }

    
}




export default AnswerDto;