import { actions } from "./../../store";
import { useSelector } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import React, { useState, useEffect } from "react";
import { FieldSection, FieldRow, FieldSlot } from "../../components/Forms/FieldSection";
import FormSection from "../../components/FormSection";
import { FIELDS, TranscriptDeliveryTypes, AnswerTypeTranscriptProps, TRANSCRIPT_QUESTIONS } from "../../store/form/fields";
import { compositeFieldname } from "../../utils";
import _get from "lodash.get";
import { open } from "../../store/snack/actions";
import { useDispatch } from "react-redux";
import apiClient from "../../utils/API";
import TranscriptQuestion from "../../components/answer/question/TranscriptQuestion";
import HSTranscriptRequestForm from '../../docs/HSTranscriptRequestForm.pdf';

/**
 * scholarships program transcript editor
 * @param {*} param0 
 * @returns {Component}
 */
function EditTranscripts({ displayInstructions, section, onChange, onCancel, fafsaYear = null, transcriptRequirements = null, schStatus=null, trascriptEndDate=null }) {

  const methods = useForm({ mode: "onBlur", });
  const { setValue, handleSubmit, watch } = methods;
  const reduxFormState = useSelector((state) => state.form);

  /**
   * check if this question is even being prompted by student answers.
   * @returns {Boolean}
   */
  const isQuestionRequested = (fieldKey) => {
    if (!_get(reduxFormState, `fields.${fieldKey}`))
      return false;
    return true;
  }
  /**
   * get readable description of hs type for transcript prompts
   * @returns {String}
   */
  const getHighSchoolTypeName = () => {
    const highschool = _get(reduxFormState, `fields.${FIELDS.HIGHSCHOOL}`);
    if (!highschool)
      return "High School or Eequivalent";
    const highschoolType = _get(highschool, "Type.value");

    switch (highschoolType) {
      case 'Traditional':
        return 'Public/Private Traditional high school'
      case 'Alternative':
        return 'Alternative School';
      case 'CommunityCollege':
        return 'Community College';
      case 'Ged':
      case 'GedHomeSchool':
        return 'GED';
      case 'HomeSchool':
        return 'Home School';
      case 'Unlisted':
      default:
        return "High School or Equivalent";
    }
  }
  /**
   * human readable description of transcript requirements
   * @param {*} question 
   * @returns {String}
   */
  const getRequirementDescription = (question) => {
    const requirements = transcriptRequirements.get(question.name);
    
    return requirements?.isRequired ? "Required"
      : requirements?.isOptional ? "Optional"
        : requirements?.isEitherOr ? 'Either College or Public or ' + getHighSchoolTypeName() + ' Transcripts Required'
          : "";
  }

  /**
   * determine appropriate descriptor for transcript-type prompts given it's requirement status
   * @param {*} question 
   * @returns 
   */
  const getTranscriptTitle = (question) => {

    return question.description + " -- " + getRequirementDescription(question);
  }

  useEffect(() => {
    (async () => {
      const collegeTranscripts = [
        _get(
          reduxFormState,
          `fields.${FIELDS.FALL_COLLEGE_TRANSCRIPTS_DIGITAL}`
        ),
        _get(reduxFormState, `fields.${FIELDS.FALL_COLLEGE_TRANSCRIPTS_MAIL}`),
      ];
      const fileAvailableColleges = collegeTranscripts.reduce(
        (available, transcripts) => {
          (transcripts || []).map((ts) => {
            if (ts.IsFileAvailable.value === true) {
              available.push(ts);
            }
          });
          return available;
        },
        []
      );

      for (let i = 0; i < fileAvailableColleges.length; i++) {
        const collegeTranscript = fileAvailableColleges[i];
        const schoolCode = _get(collegeTranscript, "SchoolCode.value");
        try {
          const college = await apiClient.getCollege(schoolCode);
          collegeTranscript["_name"] = college && college.Name;
        } catch (e) { }
      }

    })();
  }, [reduxFormState]);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = async (data, e) => {
   
    try {
      setLoading(true);
      const result = await actions.submitForm(
        data,
        [],
        ["HSTranscriptSubmissionMethod", "DELIVERY_METHOD"]
      );
      if (typeof onChange === "function") {
        onChange(true);
      }
    } catch (e) {
      dispatch(
        open({
          message: e.message,
          severity: "success",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const onError = (errors) => {
    console.log("ERR", errors);    
  };
  
  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  const getReduxField = (fieldKey) => {
    return _get(reduxFormState, `fields.${fieldKey}.value`);
  };

  const hsDigitalSelected = getReduxField(
    compositeFieldname(
      FIELDS.FALL_HS_TRANSCRIPTS_DIGITAL,
      AnswerTypeTranscriptProps.IS_SELECTED
    )
  );
  const hsMailSelected = getReduxField(
    compositeFieldname(
      FIELDS.FALL_HS_TRANSCRIPTS_MAIL,
      AnswerTypeTranscriptProps.IS_SELECTED
    )
  );
  const hsRegistrarSelected = getReduxField(
    compositeFieldname(
      FIELDS.FALL_HS_TRANSCRIPTS_REGISTRAR,
      AnswerTypeTranscriptProps.IS_SELECTED
    )
  );

  useEffect(() => {
    if (hsDigitalSelected) {
      setValue("HSTranscriptSubmissionMethod", TranscriptDeliveryTypes.UPLOAD);
    } else if (hsMailSelected) {
      setValue("HSTranscriptSubmissionMethod", TranscriptDeliveryTypes.MAIL);
    } else {
      setValue(
        "HSTranscriptSubmissionMethod",
        TranscriptDeliveryTypes.REGISTRAR
      );
    }
  }, [hsDigitalSelected, hsMailSelected, hsRegistrarSelected]);

  const orderedTranscriptQuestions = [
    TRANSCRIPT_QUESTIONS.FALL_COLLEGE_RECENT,
    TRANSCRIPT_QUESTIONS.FALL_COLLEGE_PRIOR,
    TRANSCRIPT_QUESTIONS.FALL_COLLEGE_DURING_HS,
    TRANSCRIPT_QUESTIONS.FALL_HS
  ];

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <FormSection
          title="Transcripts"
          isEditable={true}
          onSaveClick={handleSubmit(onSubmit, onError)}
          onCancelClick={onCancel}
          loading={loading}
        >
          {/* Transcript Instructions */}
          {displayInstructions}

          {/*#4155: SCH - External Review Message & Error message showed on Dashboard. */}
          {schStatus?.IsSubmitted && <FieldSlot md={12}>
            <div className="mt-1">
            <strong>Scholarship Application Review Status</strong> 
            {schStatus?.IsSubmitted && (() => {
              switch (schStatus.ReviewStatus){
                  case 'VALID':
                    return <div className="rhythm" >Review Valid</div>
                  case 'REJECT':
                    return (
                      <div className="rhythm" style={{ color: '#da3754' }}>
                        <div>Corrections required.  Fix errors by <b>{trascriptEndDate}</b> to be considered for scholarships.</div>
                        <div dangerouslySetInnerHTML={{__html: schStatus.ReviewInstructions}}></div>
                      </div>)                  
                  case 'PENDING':                    
                  case 'PENDINGNEWDOCREJECT':
                  case 'PENDINGNEWDOCVALID':
                  case 'REVIEW1':
                  case 'REVIEW2':
                    return <div className="rhythm" >Awaiting Review</div>
                  default:
                    return <></>
              }
            })()}                            
            </div>
          </FieldSlot>
          }  

          <FieldSection>
            <FieldRow>
              <FieldSlot md={12}>
                <div id="TranscriptSection" className="mt-05">
                  {orderedTranscriptQuestions.filter(x => isQuestionRequested(x.uploadField)).map(question =>
                        <TranscriptQuestion
                          titleHeader={getTranscriptTitle(question)}
                          section={section}
                          transcriptField={question}
                          isRequired={transcriptRequirements.isRequired(question.name)}
                          formLink={
                            <div>
                                Print out the&nbsp;                                
                                <a className="button" href={HSTranscriptRequestForm} download="HSTranscriptRequestForm.pdf" >transcript request form</a>                                
                                &nbsp;and give it to your high school registrar.                                
                            </div>
                          }
                    />
                  )}
                </div>
              </FieldSlot>
            </FieldRow>
          </FieldSection>

        </FormSection>
      </form>
    </FormProvider>
  );
}

export default EditTranscripts;
