import { initialSnackState } from "./state";

export const snackReducer = (state = initialSnackState, action) => {
  switch (action.type) {
    case "open":
      const { message, severity } = action.payload;
      return {
        ...state,
        message,
        severity,
        open: true,
      };
    case "close": {
      return {
        ...state,
        open: false,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
