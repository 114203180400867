import { useEffect, useState } from 'react';
import apiClient from '../../utils/API';
import RadioGroup from "../RadioGroup";
import AnswerLock from './status/AnswerLock';

/**
 * presents an answer response as a form field with selectable options
 * 
 * @param {*} param0 
 */
function RadioGroupAnswer({answer, disabled, label, ...props}){

    const [items, setItems] = useState([]);

    useEffect(async ()=>{
       
        if (!answer.Year)
            return; //not ready to specify answer yet
  
        const options = await apiClient.get('/answer/options', {
            questionKey: answer.QuestionKey,
            year: answer.Year
        });
        setItems(options.map(r => ({
            ...r,
            label: r.Name,
            value: r.Value
        })));
 
    },[answer.Year]);

    const isDisabled = disabled || answer.IsLocked;

    const fullLabel = (
        <>
            {label}
            <AnswerLock answer={answer} />
        </>
    );
  
    return <RadioGroup listItems={items} name={answer.FieldKey} disabled={isDisabled} label={fullLabel} {...props} />
}

export default RadioGroupAnswer;