
/**
 * all the "top-tier" programs of algorithmic interest
 */
export const CODES = {
  SCHOLARSHIPS: 'SCH',
  OREGON_PROMISE: 'ORP',
  CHAFEE: 'CH',
  CHILD_CARE: 'CC',
  NATIONAL_GUARD: 'ONGSTA',
  FAFSA: 'FAFSA',
  OTSG: 'OTSG',
  ELP: 'ELP',
  OTSP: 'OTSP'
};

/**
 * Ideally we should be pulling the exact text of these names from the program definitions in the API. Staff may have need to fine tune these
 * @deprecated use api to fetch display properties for programs
 */
export const NAMES = {
  OREGON_PROMISE: 'Oregon Promise Grant',
  CHAFEE: 'Chafee Education & Training Grant',
  CHILD_CARE: 'Student Child Care Grant',
  NATIONAL_GUARD: 'Oregon National Guard Grant',
  OTSG: 'Oregon Tribal Student Grant',
  OTSP: 'Oregon Teacher Scholars Program',
};
