import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';


const useStyles = makeStyles((theme) => ({
    root: props => ({
      marginTop: props.paddingTop ? theme.spacing(1.5) : 0,
    })
  })); 

const FieldSection = ({ md = 3, xs = 12, children, className = '', ...props })=>{
    const classes = useStyles();
    return (
      <div className="fieldslot rhythm" {...props}>
        {children}
      </div>
    )
}

export default FieldSection;