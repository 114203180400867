
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import PortalView from '../../components/Global/PortalView';
import Checkbox from './../../components/Checkbox';
import { FIELDS } from '../../store/form/fields';
import { actions } from '../../store';
import { FormProvider, useForm } from 'react-hook-form';
import { FieldRow, FieldSection, FieldSlot } from '../../components/Forms/FieldSection';
import SSNtext from '../../components/SSNtext';
import Input from '../../components/Input';
import { getReduxField } from '../../utils';
import MaskedDatePicker from '../../components/MaskedDatePicker';
import { open } from '../../store/snack/actions';
import { getLatestActiveYear } from "../../utils";

function Registration() {

  const history = useHistory();
  const dispatch = useDispatch();
  const methods = useForm({
    mode: 'onBlur'
  });

  const [isSaving, setSaving] = useState(false);

  const { errors, formState, getValues, setValue, handleSubmit, watch } = methods;
  const [activeYear, setActiveYear] = useState(null)

  const onSubmit = async (data, e) => {
      // console.log('submitted', data)
      try {
        setSaving(true);

        let exclusionArray = [
          `${FIELDS.LAST_NAME}-confirm`,
          `${FIELDS.BIRTHDATE}-confirm`,
          `${FIELDS.SSN}-confirm`
        ]; 

        // ssn field is not populated with existing value, so if user didn't enter data - we will not update it with empty values.
        // We can just check for an empty value and skip it, since defaultValue needs to set for dirtyFields to work, however there is a bug with it, it does not reset.
        if (data[FIELDS.SSN] === '' || data[FIELDS.SSN] === null) {
            exclusionArray.push(FIELDS.SSN);
        }
        await actions.submitForm(data, [], exclusionArray, activeYear);
        history.push('/profile');
      } catch (e) {
        dispatch(open({
          message: e.message,
          severity: "success",
        }))
        console.error({ e })
      } finally {
        setSaving(false)
      }
  };

  const onError = (err) => {
    dispatch(open({
      message: 'There was an error saving, please check all required fields are populated.',
      severity: "error",
    }))
  }

  const watchLastName = watch(FIELDS.LAST_NAME, false)
  const watchBirthday = watch(FIELDS.BIRTHDATE, false)
  const watchSSN = watch(FIELDS.SSN)
  const watchNoSSN = watch('noSSN')
  const watchSSNLater = watch('SSNlater')

  // #3712: Social Security Questions are Exclusive
  // Social security question should not allow both check boxes. It should allow either or neither.
  // Otherwise stated: Should be exclusive true or neither true to show edit box.
  useEffect(() => {
    if (watchNoSSN === true) {
      
          setValue('SSNlater', false);
        }     
    
  }, [watchNoSSN]);

  useEffect(() => {
    if (watchSSNLater === true) {
      
          setValue('noSSN', false);
        }     
    
  }, [watchSSNLater]);

  const validateConfirm = (value, compareValue) => {
      // console.log(value, compareValue)
      if (value === compareValue) {
        return true
      } else {
        return "Does not match"
      }
  }

  const handleBirthdayChange = (value) => {
    setValue(FIELDS.BIRTHDATE, value)
  }

  useEffect(() => {
    (async () => {
      const year = await getLatestActiveYear('SCH')
      console.log('year', year)
      setActiveYear(year)
      await actions.fetchBasicFields([
        FIELDS.FIRST_NAME,
        FIELDS.LAST_NAME,
        FIELDS.BIRTHDATE,
        FIELDS.SSN
      ], year);
    })();
  }, []);



  return (
    <PortalView>
      <FormProvider {...methods} >
        <div className="content registration">
          <div className="registration__header rhythm component">
              <h1 className='heading heading--h1'>Registration</h1>
              <p>We just need a few more details from you to get started.</p>
              <a href="#print" className='button button--has-icon' onClick={() => { window.print(); return false; }}>
                <svg viewBox="0 0 24 24" width="24" height="24">
                  <path fill="#ffffff" d="M19 8H5c-1.66 0-3 1.34-3 3v6h4v4h12v-4h4v-6c0-1.66-1.34-3-3-3zm-3 11H8v-5h8v5zm3-7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-1-9H6v4h12V3z"></path>
                </svg>
                Print
              </a>
          </div>

          <div>
            <form onSubmit={handleSubmit(onSubmit, onError)} >
              <fieldset></fieldset>
              <FieldSection>
                <FieldRow>
                  <FieldSlot>
                    <Input name={FIELDS.FIRST_NAME} inputLabel='Legal First Name' placeholder="Legal First Name" variant='outlined' isRequired={true} />
                  </FieldSlot>
                  <FieldSlot>
                    <Input name={FIELDS.MIDDLE_INITIALS} inputLabel='Legal Middle Initial' 
                          placeholder="Legal Middle Initial" 
                          inputProps={{ maxLength: 1 }}
                          variant='outlined' />
                  </FieldSlot>
                  <FieldSlot>
                    <Input name={FIELDS.LAST_NAME} inputLabel='Legal Last Name' placeholder="Legal Last Name" variant='outlined' isRequired={true} />
                  </FieldSlot>
                  <FieldSlot>
                    <Input
                      name={`${FIELDS.LAST_NAME}-confirm`}
                      inputLabel='Confirm Legal Last Name'
                      placeholder="Legal Last Name"
                      variant='outlined'
                      isRequired={true}
                      defaultValue={getReduxField(FIELDS.LAST_NAME)}
                      rules={{ validate: value => validateConfirm(value, watchLastName) }}
                    />
                  </FieldSlot>
                </FieldRow>
                <FieldRow>
                  <FieldSlot>
                    <MaskedDatePicker name={FIELDS.BIRTHDATE} label='Birthday' className="input" style={{ marginTop: 0 }} onChange={handleBirthdayChange} isRequired={true} />
                  </FieldSlot>
                  <FieldSlot>
                    <MaskedDatePicker
                      className="input"
                      name={`${FIELDS.BIRTHDATE}-confirm`}
                      label='Confirm Birthday'
                      style={{ marginTop: 0 }}
                      isRequired={true}
                      defaultValue={getReduxField(FIELDS.BIRTHDATE)}
                      rules={{ validate: value => validateConfirm(value, watchBirthday) }} />
                  </FieldSlot>
                </FieldRow>
              </FieldSection>
              <FieldSection title="Social Security Number (optional)" withDivider={false}>
                <FieldRow>
                  <FieldSlot md={4}>
                    <SSNtext isRequired={!watchSSNLater && !watchNoSSN} name={FIELDS.SSN} inputLabel='SSN' placeholder='SSN' variant='outlined' rules={{}} defaultValue=""/>
                      <Checkbox name='noSSN' label="I do not have a SSN" />
                      <br/>
                      <Checkbox name='SSNlater' label="I will enter my SSN later" />
                  </FieldSlot>
                  <FieldSlot>
                  <SSNtext isRequired={!watchSSNLater && !watchNoSSN} name={`${FIELDS.SSN}-confirm`} inputLabel='Confirm SSN' placeholder='Confirm SSN' variant='outlined'
                    rules={(!watchSSNLater && !watchNoSSN) ? { validate: value => validateConfirm(value, watchSSN) }: {}} defaultValue="" />
                  </FieldSlot>
                </FieldRow>
              </FieldSection>

              <FieldSection>
                <FieldRow>
                  <button type="submit" className="button" onClick={handleSubmit(onSubmit, onError)}>Save</button>
                </FieldRow>
              </FieldSection>
            </form>
          </div>
        </div>
      </FormProvider>
    </PortalView>
  );
}

export default Registration;
