import { actions } from './../../store';
import { useSelector } from 'react-redux';
import { useForm, FormProvider } from "react-hook-form";
import { useState, useEffect } from 'react';
import FormSection from '../../components/FormSection';
import { open } from "../../store/snack/actions";
import { useDispatch } from 'react-redux';
import { FieldRow, FieldSection, FieldSlot } from "../../components/Forms/FieldSection";
import Checkbox from "../../components/Checkbox";
import { AnswerTypeHighschoolProps, FIELDS } from "../../store/form/fields";
import { compositeFieldname } from "../../utils";
import DropdownAnswer from '../../components/answer/DropdownAnswer';
import DateAnswer from '../../components/answer/DateAnswer';
import HighSchoolAnswer from '../../components/answer/HighSchoolAnswer';
import CertifyAnswer from '../../components/answer/CertifyAnswer';
import DecimalAnswer from '../../components/answer/DecimalAnswer';
import IntegerAnswer from '../../components/answer/IntegerAnswer';
import TextAnswer from '../../components/answer/TextAnswer';
import BooleanAnswer from '../../components/answer/BooleanAnswer';

/**
 * high school section of the scholarshpis additional-info page
 * 
 * @param {*} param0 
 * @returns {Component}
 */
function EditHighSchoolInfo({ section, embedded = false, onChange, onCancel }) {

  // const authState = useSelector((state) => state.auth);
  const reduxFormState = useSelector((state) => state.form);
  const isFetchingData = reduxFormState.isLoading;
  // const [highschool, setHighschool] = useState('');
  const methods = useForm({
    mode: 'onBlur'
  });

  const { errors, formState, getValues, setValue, handleSubmit, watch } = methods;

  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch();
  const watchNoLetterGrades = watch("NoLetterGrades")

  const onSubmit = async (data, e) => {
    // console.log("Submit data", data)

    try {
      setLoading(true)
      if (watchNoLetterGrades === true) {
        data[FIELDS.HIGHSCHOOL_GPA] = "0.01"
      }

      const excludedFields = ["NoLetterGrades"]
      const isUnlistedHs = data["UnlistedHS"]; //TODO: what type is this?
      if (isUnlistedHs) {
        excludedFields.push(compositeFieldname(FIELDS.HIGHSCHOOL, AnswerTypeHighschoolProps.SCHOOL_CODE))
      }

      const result = await actions.submitForm(data, [], excludedFields);
      if (typeof onChange === 'function') {
        onChange(true);
      }
    } catch (e) {
      dispatch(open({
        message: e.message,
        severity: "success",
      }))
    } finally {
      setLoading(false)
    }
  };

  const onError = (errors, e) => {
    // console.log('ERROR',errors, e);
  };

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [])

  const alertUser = e => {
    e.preventDefault()
    e.returnValue = ''
  }

  const watchHighSchoolType = watch(FIELDS.HIGHSCHOOL_TYPE)
  const [hsType, setHsType] = useState(null)
  useEffect(() => {
    const type = getValues(FIELDS.HIGHSCHOOL_TYPE)
    // console.log("HS TYPE", type)
    setHsType(type)
  }, [watchHighSchoolType])



  const [gedSectionRequired, setGedSectionRequired] = useState(false)
  useEffect(() => {
    if (hsType === 'GED' || hsType === 'GEDHM') {
      setGedSectionRequired(true)
    } else {
      setGedSectionRequired(false)
    }
  }, [hsType])

  const [gedScoreRequired, setGedScoreRequired] = useState(true)
  const watchGedScoreFlag = watch(FIELDS.GED_IN_PROGRESS_FLAG)
  useEffect(() => {
    // console.log("GED<sup style={{ verticalAlign: "super", fontSize: ".9rem"}}>&reg;</sup> SCORE FLAG CHANGED", watchGedScoreFlag)
    if (watchGedScoreFlag === true || watchGedScoreFlag === 'True') {
      setGedScoreRequired(false)
      setValue(FIELDS.GED_SCORE, "")
    } else {
      setGedScoreRequired(true)
    }
  }, [watchGedScoreFlag])



  const validateHsGradDate = (date) => {
    if (!date || date === 'Invalid date') {
      return 'Field Required'
    } else {
      return true
    }
  }

  const seniorYear = section.year;

  return (
    <FormProvider {...methods} >
      <form onSubmit={handleSubmit(onSubmit, onError)} >
        <FormSection title='Edit High School Info' isEditable={true} onSaveClick={handleSubmit(onSubmit, onError)} onCancelClick={onCancel} loading={loading}>
          <FieldRow>
            <strong>Please be advised:</strong> 
            Some of the information in this section can only be edited in the  <a href="/profile">Profile</a>  section.
          </FieldRow>
          <FieldSection title="High School, Home School or GED">
            <FieldRow>
              <FieldSection title="School or Equivalent" withDivider={false}>
                <FieldRow>
                  <FieldSlot>
                    <DropdownAnswer answer={section.getAnswerByField(FIELDS.HIGHSCHOOL_TYPE)}
                      disabled={true}
                      isRequired={true}
                      onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                      label="Type of High School or Equivalent"
                    />
                  </FieldSlot>

                  <HighSchoolAnswer disabled={true} answer={section.getAnswerByField(FIELDS.HIGHSCHOOL)} hstype={watchHighSchoolType} />

                  <FieldSlot>
                    <DateAnswer
                      disabled={true}
                      answer={section.getAnswerByField(FIELDS.HIGHSCHOOL_GRAD_DATE)}
                      onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                      isRequired={true}
                      label="Date of High School Completion"
                      resolution="MONTH"                     
                    />
                  </FieldSlot>
                </FieldRow>

              </FieldSection>
            </FieldRow>
          </FieldSection>

          {gedSectionRequired === true &&
            <FieldSection title="GED&reg; Score">
              <FieldRow>
                <FieldSlot>
                  <DecimalAnswer disabled={true} answer={section.getAnswerByField(FIELDS.GED_SCORE)} inputLabel='GED&reg; Score'
                    isRequired={gedScoreRequired} placeholder="GED&reg; Score" />
                </FieldSlot>
              </FieldRow>
              <FieldRow>
                <FieldSlot>
                  <CertifyAnswer disabled={true} answer={section.getAnswerByField(FIELDS.GED_IN_PROGRESS_FLAG)} label="I will not have my GED&reg; requirements completed by the time I submit this application." />
                </FieldSlot>
              </FieldRow>
            </FieldSection>
          }

          {watchHighSchoolType !== 'GED' && watchHighSchoolType !== 'UNSTD' &&
            <FieldSection title="HIGH SCHOOL 5TH YEAR">
              <CertifyAnswer answer={section.getAnswerByField(FIELDS.FIFTH_YEAR_SENIOR)} label={<>I will be a high school 5th year senior attending college in Fall term {seniorYear}.</>} />
            </FieldSection>
          }

          {gedSectionRequired === false ?
            <FieldSection title="high school gpa (unweighted, cumulative 7-semester)">
              <FieldRow>
                <FieldSlot>
                  <DecimalAnswer disabled={true} isRequired={!watchNoLetterGrades}
                    answer={section.getAnswerByField(FIELDS.HIGHSCHOOL_GPA)} inputLabel='High School GPA' placeholder='High School GPA' precision={0.01} allowNegative={false} />
                </FieldSlot>
                <FieldSlot container alignItems="flex-end">
                  <div>
                    <Checkbox disabled={true} name='NoLetterGrades' label="My high school did not assign letter grades." />
                  </div>
                </FieldSlot>
              </FieldRow>
            </FieldSection>
            : ""
          }

          <FieldSection title="SAT Highest Overall Score">
            <FieldRow>
              <FieldSlot>
                <IntegerAnswer
                  answer={section.getAnswerByField(FIELDS.SAT)}
                  inputLabel="SAT Score"
                  precision={0.01}
                  max={4}
                />
              </FieldSlot>
            </FieldRow>
          </FieldSection>

          <FieldSection title="ACT Highest Overall Score">
            <FieldRow>
              <FieldSlot>
                <IntegerAnswer
                  answer={section.getAnswerByField(FIELDS.ACT)}
                  inputLabel="ACT Score"
                  max={2}
                />
              </FieldSlot>
            </FieldRow>
          </FieldSection>

          <FieldSection title="Did you work with the ASPIRE program in high school?">
            <FieldRow>
              <FieldSlot>
                <BooleanAnswer answer={section.getAnswerByField(FIELDS.ASPIRE__MEMBER)} />
              </FieldSlot>
            </FieldRow>
          </FieldSection>

          <FieldSection withDivider={true}>
            <FieldRow>
              <FieldSlot xs={12}>
                <TextAnswer answer={section.getAnswerByField(FIELDS.ASPIRE__ADVISOR)} inputLabel='ASPIRE Mentor’s Name' placeholder='ASPIRE Mentor’s Name' />
              </FieldSlot>
              <FieldSlot xs={12}>
                <TextAnswer answer={section.getAnswerByField(FIELDS.ASPIRE__SITE)} inputLabel='ASPIRE Site Name' placeholder='ASPIRE Site Name' />
              </FieldSlot>
            </FieldRow>
          </FieldSection>

        </FormSection>
      </form>
    </FormProvider>
  );
}

export default EditHighSchoolInfo;
