import { actions } from "../../../store";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _get from 'lodash.get';

/**
 * standardize the way we prompt for multi-answer questions
 * 
 * @param {SectionHelper} param0.section the section maintaining original answer state
 * @param {String} param0.name the field key for this answer collection
 * @returns {Component}
 */
function RepeatableQuestion({ section, name, answerFilter, children, ...props }) {

    const originalAnswers = section.getAllAnswersByField(name, answerFilter).filter(x => !x.PlaceholderId);
    const [addedAnswers, setAddedAnswers] = useState([]);
    const [deletedAnswers, setDeletedAnswers] = useState([]);
    const answers = [...originalAnswers, ...addedAnswers].filter(x => !deletedAnswers.some(a => a.Id == x.Id));

    const count = answers.length;
    
    const [deletedIndices, setDeletedIndices] = useState([]);
    const [availableIndices, setAvailableIndices] = useState([...new Array(count)].map((_, i) => i));

    // keep track of previously deleted indices to disable auto population of value from redux store
    const [previouslyDeletedIndices, setPreviouslyDeletedIndices] = useState({});

    // get redux state and enuerate field array if exists.
    const formState = useSelector(state => state.form);
    const existingFieldLength = _get(formState, `fields.${name}.length`, 0);

    useEffect(() => {
        if (existingFieldLength > 0) {
            setAvailableIndices([...new Array(existingFieldLength)].map((_, i) => i))
        }
    }, [existingFieldLength])

    function generateRandomId(){
        var minm = 1000000;
        var maxm = 9999999;
        return Math.floor(Math.random() * (maxm - minm + 1)) + minm;
    }

    const handleAdd = (answerTemplate) => () => {
        let newId = -generateRandomId();
        let newIndex = 0;
        // re-use past deleted indices
        if (deletedIndices.length) {
            // gets the lowest valued index in deletedIndices
            newIndex = Math.min(...deletedIndices);
            setDeletedIndices(deletedIndices.filter(i => i !== newIndex));
        }
        else {
            newIndex = count;
        }
        setAvailableIndices([...availableIndices, newIndex])

        // Give new activity a unique temp id
        answerTemplate.Id = newId
        answerTemplate.FieldKey = name;
        answerTemplate.Index = newIndex;
        // Flag activity as new
        answerTemplate.isNew = true;
             
        setAddedAnswers([...addedAnswers, answerTemplate]);
    }
    const handleDelete = (answer) => () => { 
        const index = answer.Index; 
        setDeletedAnswers([...deletedAnswers, answer]);

        if (!answer?.isNew) {
            actions.addDeletedRepeatableFieldId({ name, id : answer.Id  });
        }

        setDeletedIndices([...deletedIndices, index]);
        setAvailableIndices(availableIndices.filter(i => i !== index));
        setPreviouslyDeletedIndices({...previouslyDeletedIndices, [index]: 1})
    }

    return children(answers, handleAdd, handleDelete, previouslyDeletedIndices);
       
}

export default RepeatableQuestion;