import NumericDecimal from "../NumericDecimal";
import AnswerLock from "./status/AnswerLock";

/**
 * prompt for a whole-number answer (Integer answer type)
 * 
 * @param {*} param0 
 * @returns 
 */
function IntegerAnswer({answer, disabled, inputLabel, ...props}){


    return <NumericDecimal    
            inputLabel={<>{inputLabel} <AnswerLock answer={answer} /></>}
            placeholder={inputLabel}
            precision={1}
            disabled={disabled || answer.IsLocked}
            allowNegative={false}
            name={answer.FieldKey}
            {...props}        
        />
    
}

export default IntegerAnswer;