import React, { Fragment } from "react";
import { useFormContext } from "react-hook-form";
import { FieldSection, FieldRow, FieldSlot } from './Forms/FieldSection';
import Button from './Button';
import MaterialButton from '@material-ui/core/Button'
import Card from './Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { FIELDS } from '../store/form/fields';
import { useSelector } from 'react-redux';
import { useEffect } from "react";
import RepeatableQuestion from "./answer/question/RepeatableQuestion";
import CollegeHistoryAnswer from "./answer/CollegeHistoryAnswer";

function CollegePicker({ collegePickerCallback, section }) {

  const reduxFormState = useSelector((state) => state.form);
  const methods = useFormContext({ mode: 'onBlur' });
  const { getValues, watch } = methods;


  const watchCurrent = watch(FIELDS.CURRENT_COLLEGE);
  const watchPast = watch(FIELDS.PAST_COLLEGE);

  const collegeHistorySections = [
    {
      field: FIELDS.CURRENT_COLLEGE,
      filter: a => a.AttendedStop == null,
      title: 'Currently Attending College(s)',
      isPrior: false
    },
    {
      field: FIELDS.PAST_COLLEGE,
      filter: a => a.AttendedStop != null,
      title: 'Previously Attended College(s)',
      isPrior: true
    },
  ];

  useEffect(() => {
    // Callback to set "Cumulative Undergraduate GPA" as required when appropriate.
    collegePickerCallback && collegePickerCallback(watchCurrent, watchPast)

  }, [watchCurrent, watchPast, collegeHistorySections]);

  return (
    <>
      {collegeHistorySections.map(partition =>
        <FieldSection title={partition.title} withDivider={false}>
          <RepeatableQuestion section={section} name={partition.field} answerFilter={partition.filter}>
            {(answers, handleAdd, handleDelete) => {
              return <>
                {answers.map((answer) => {

                  return (
                    <Fragment key={answer.Id}>
                      <Card style={{ marginTop: '1.5rem', padding: '2rem' }}>
                        <CardContent style={{ margin: '-.5rem 0 -3rem 0' }}>
                          <CollegeHistoryAnswer answer={answer} isPrior={partition.isPrior} />
                        </CardContent>
                        <CardActions style={{ justifyContent: 'flex-end' }}>                        
                          <MaterialButton size="small" color="secondary" 
                            disabled={answer.IsLocked}
                            style={{ position: 'relative', bottom: '-1rem' }} 
                            onClick={handleDelete(answer)} >Remove</MaterialButton>
                        </CardActions>
                      </Card>
                    </Fragment>
                  )
                })}
                {(typeof reduxFormState.fields[partition.field] == "undefined" || reduxFormState.fields[partition.field].length < 10) &&
                  <FieldRow style={{ marginTop: '-.5rem' }}>
                    <FieldSlot>
                      <Button type='button' name='AddCollege' label='+ Add College/University' variant='text' style={{ textTransform: "capitalize" }}
                        onClick={handleAdd({ Id: 0, AnswerType: 'College' })} />
                    </FieldSlot>
                  </FieldRow>
                }
              </>
            }}
          </RepeatableQuestion>
        </FieldSection>
      )}

    </>
  )
};

export default CollegePicker;
