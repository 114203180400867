import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { uniqWith, isEqual } from "lodash";
import PortalView from './../../components/Global/PortalView';
import apiClient from '../../utils/API';
import Spinner from '../../components/Utility/Spinner';
import AwardSummary from '../../components/award/AwardSummary';
import StatusButtons from '../../components/AppStatusButtons';
import { combineProgramStatus, combineWithLocalAwards } from '../../utils';




/**
 * page summarizing all active awards for student
 * 
 * @returns {Component} full awards page
 */
function Awards() {
    
    const location = useLocation();
    const anchor = location.hash;

    const [allAwardsData, setAllAwardsData] = useState([]);
    const [allApplicationData, setAllApplicationData] = useState([]);
    const [isLoading, setLoading] = useState(true);

    const awardedPrograms = allApplicationData.filter(app => app.AwardSummaryStatus !== 'Offer' || app.ReviewStatus === 'RNWEMSENT');
    const pendingPrograms = allApplicationData.filter(app => app.AwardSummaryStatus === 'Offer' && app.Source !== 'Renew');

    useEffect(() => {
        (async () => {
            const awards = await apiClient.get("/award/search");
            const appLookups = awards.map(a => {return {Code: a.ProgramCode, Year: a.ApplicationYear}});
            const distinctApps = uniqWith(appLookups, isEqual);

            const recentApps = distinctApps.filter(a => {
                let controlValues = distinctApps.filter(b => b.Code === a.Code)
                let recentAppYear = Math.max(Math.max(...controlValues.map(c => c.Year)))
                if(controlValues.length === 1){
                    return a
                }
                if(controlValues.length > 1 && a.Year === recentAppYear){
                    return a
                }
                return null
            });

            const appRequests = recentApps.map(a => apiClient.getApplication(a.Code, a.Year));
            const apps = await Promise.all(appRequests);

            setAllAwardsData(awards);
            setAllApplicationData(apps);
            setLoading(false);

        })();
    }, []);

    /**
     * 
     * @param {*} programCode 
     * @returns {Array<*>}
     */
    function getProgramAwards(programCode) {
        return allAwardsData.filter(a => a.ProgramCode === programCode);
    }

    /**
     * 
     * @param {*} param0.program program DTO from api
     * @returns 
     */
    function AwardAccordian({ application, defaultExpanded }) {

        const programCode = application.ProgramCode;
        const [expanded, setExpanded] = useState(defaultExpanded);
        const [program, setProgram] = useState(null);

        useEffect(() => {
            (async () => {
    
                const program = await apiClient.getProgram(programCode);               
                setProgram(program);
    
            })();
        }, []);

        if (program == null)
            return null;


        const awards = getProgramAwards(programCode);
        const buttonDetails = combineWithLocalAwards(combineProgramStatus(program, application), awards);   
                       
        return (
            <div className="grant">
                <div className="grant__layout">
                    <div className="grant__content rhythm">
                        <Accordion square onChange={() => setExpanded(!expanded)} style={{ borderRadius: expanded ? '4px 4px 0 0' : '4px' }} defaultExpanded={defaultExpanded}>
                            <AccordionSummary
                                expandIcon={expanded ? <RemoveIcon style={{ color: 'white' }} /> : <AddIcon style={{ color: 'white' }} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                style={{ backgroundColor: 'transparent', borderRadius: expanded ? '4px 4px 0 0' : '4px' }}
                            >
                                <h4 className="heading heading--h5">{program.Name}</h4>
                            </AccordionSummary>
                            <AccordionDetails style={{ alignText: 'left' }}>
                                <AwardSummary program={program} awards={awards} key={program.Code} application={application} />
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <StatusButtons {...buttonDetails} />
                    
                </div>
            </div>
        );
    }


    return (
        <PortalView name="AwardPage">
            <div name='AwardPage' className="awards-page rhythm rhythm--large">
                <div className="rhythm">
                    {/* Page Intro */}
                    <div className="page-intro">
                        <div className="wrapper">
                            <div className="page-intro__layout">
                                <div className="page-intro__icon">
                                    <img src="/dashboard.svg" alt="Student Portal" />
                                </div>
                                <div className="page-intro__content rhythm">
                                    <h1 className="heading heading--h1">Your Awards</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Offers */}
                <div className="grants wrapper rhythm component">
                    <div className="notifications__header">
                        <h3 className="heading heading--h3">Offers (not yet accepted or declined)</h3>
                        {isLoading &&
                            <Spinner className='floatRight'></Spinner>
                        }
                    </div>
                    <div name="GrantsDisplay" className="grants__list grant--scholarship">
                        {!isLoading && pendingPrograms.length === 0 &&
                            <div>You currently have no offered awards.</div>
                        }
                        {!isLoading && pendingPrograms.map(program =>
                            <AwardAccordian application={program} key={program.ProgramCode} />
                        )}
                    </div>
                </div>

                {/* Awarded */}
                <div className="grants wrapper rhythm component">
                    <div className="notifications__header">
                        <h3 className="heading heading--h3">Awarded</h3>
                        {isLoading &&
                            <Spinner className='floatRight'></Spinner>
                        }
                    </div>
                    <div name="GrantsDisplay" className="grants__list grant--scholarship">
                        {!isLoading && awardedPrograms.length === 0 &&
                            <div>You currently have no awarded applications.</div>
                        }
                        {!isLoading && awardedPrograms.map(program =>
                            <AwardAccordian application={program} key={program.ProgramCode} defaultExpanded={anchor === `#${program.ProgramCode}`} />
                        )}
                    </div>
                </div>

            </div>
        </PortalView>
    );
}

export default Awards;
