import { useSelector } from 'react-redux';
import { useForm, FormProvider } from "react-hook-form";
import { useState, useEffect } from 'react';
import { FieldSection, FieldRow, FieldSlot } from '../../components/Forms/FieldSection';
import { FIELDS } from '../../store/form/fields';
import FormSection from '../../components/FormSection';
import { open } from "../../store/snack/actions";
import { useDispatch } from 'react-redux';
import { actions } from './../../store';
import DropdownAnswer from '../../components/answer/DropdownAnswer';
import BooleanAnswer from '../../components/answer/BooleanAnswer';

function EditFamilyInfo({ onChange, onCancel, section }) {

    const methods = useForm({
      mode: 'onBlur'
    });
    const { handleSubmit } = methods;

    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();

    const onSubmit = async (data) => {
      try {
        setLoading(true)
        const result = await actions.submitForm(data,[],[]);
        if(typeof onChange === 'function'){
          onChange(true);
        }
        // console.log(data, e);
      } catch (e) {
        dispatch(open({
          message: e.message,
          severity: "success",
        }))
      } finally {
        setLoading(false)
      }
    };

    const onError = () => {
      // console.log('ERROR',errors, e);
    }

    useEffect(() => {
      window.addEventListener('beforeunload', alertUser)
      return () => {
        window.removeEventListener('beforeunload', alertUser)
      }
    }, [])
    const alertUser = e => {
      e.preventDefault()
      e.returnValue = ''
    }

    return (
      <FormProvider {...methods} >
        <form onSubmit={handleSubmit(onSubmit, onError)} >
          <FormSection title='My Family Information' isEditable={true} onSaveClick={handleSubmit(onSubmit, onError)} onCancelClick={onCancel} loading={loading}>
  
            <FieldSection title="Parent or Guardians Highest Level of Completed Education" withDivider={true}>
              <FieldRow>
                <FieldSlot>
                  <DropdownAnswer isRequired answer={section.getAnswerByField(FIELDS.ED_LEVEL_FATHER)} label='Parent 1' />
                </FieldSlot>
                <FieldSlot>
                  <DropdownAnswer isRequired answer={section.getAnswerByField(FIELDS.ED_LEVEL_MOTHER)} label='Parent 2'  />
                </FieldSlot>
                <FieldSlot>
                    <BooleanAnswer isRequired answer={section.getAnswerByField(FIELDS.FIRST_GEN_IMMIGRANT)} label='Are you a first-generation immigrant?'  />
                </FieldSlot>
              </FieldRow>
            </FieldSection>

          </FormSection>
        </form>
      </FormProvider>
    );
}

export default EditFamilyInfo;
