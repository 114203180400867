import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FIELDS, AnswerTypeAddressProps } from '../../store/form/fields';
import { capitalizeAll, compositeFieldname } from '../../utils';
import apiClient from '../../utils/API';


const AddressPicker = ({ name, only = '', children }) => {

    const methods = useFormContext({ mode: 'onBlur' });
    const { watch } = methods;

    const [statesOriginal, setStatesOriginal] = useState([]);

    const [states, setStates] = useState([]);
    const [counties, setCounties] = useState([]);
    const [noCounties, setNoCounties] = useState(false);
    const [cities, setCities] = useState([]);


    const state = watch(compositeFieldname(name,AnswerTypeAddressProps.STATE));
    const county = watch(compositeFieldname(name,AnswerTypeAddressProps.COUNTY));
    const city = watch(compositeFieldname(name,AnswerTypeAddressProps.CITY));
    const zip = watch(compositeFieldname(name,AnswerTypeAddressProps.ZIP));
    const streetAddress = watch(compositeFieldname(name,AnswerTypeAddressProps.ADDRESS));

    const getValue = (value) => {
        if(typeof value === 'object' && value) return value?.value;
        return value;
    }

    useEffect(()=>{
        (async ()=>{
            if(only && only !=='state') return;
            try{
                const states = await apiClient.get('/state-and-territory/states-and-territories');
                states.unshift({
                    "Id": -1,
                    "StateCode": null,
                    "AnsiCode": null,
                    "Abbreviation": null,
                    "Name": "Unlisted/Foreign Country"
                })
                setStatesOriginal(states)
                setStates(states.map(state => ({
                    label: state.Name,
                    value: Number(state.Id),
                    fedId: Number(state.StateCode),
                })));
            } catch(err){
                console.error(err);
            }
        })();
    },[]);

    useEffect(()=>{
        (async ()=>{
            if(only && only !=='county') return;
            try {
                if(!state) 
                    return;
                let stateCode = state.fedId
                if (stateCode < 10) {
                    stateCode = `0${stateCode}`;
                }
                const counties = await apiClient.get('/county/counties', {
                    "lookup.stateCode": stateCode
                });
                if (counties.length === 0) {
                    setNoCounties(true)
                } else {
                    setNoCounties(false)
                }

                setCounties(counties.map(county => {
                    return {
                        label: county.CountyName,
                        value: Number(county.Id)
                    }
                }))

                // -1 lets us indicate unlisted is selected
                if(state?.value == null) {
                    state.value = -1
                }
                // -1 lets us indicate unlisted is selected
                if (city && city?.value == null) {
                    city.value = -1
                }

            } catch(err){
                console.error(err);
            }
        })();
    },[state])

    useEffect(()=>{
        (async ()=>{
            if(only && only !=='city') return;
            try {
                if(!county && !noCounties) return;
                let cities = []
                if (noCounties) {
                    const stateCode = getValue(state);
                    // console.log("State Code", stateCode, states)
                    const chosenState = statesOriginal.find(state => parseInt(state.Id) === stateCode)
                    // console.log("Chosen State", chosenState)
                    cities = await apiClient.get(`/city/search?lookup.stateAbbreviation=${chosenState.Abbreviation}`)
                } else {
                    cities = await apiClient.get(`/city/cities/${getValue(county)}`);
                }

                cities.unshift({
                    "Id": -1,
                    "Name": "Unlisted City",
                    "State": null
                })
                setCities(cities.map(city => {
                    return {
                        label: city.Name,
                        value: Number(city.Id)
                    }
                }))
            } catch(err){
                console.error(err);
            }
        })();
    },[county, state]);

    return (
        <>
            {children({
                states,
                counties,
                cities,
                streetAddress,
                state,
                county,
                city,
                zip,
                noCounties,
            })}
        </>
    );
}

export default AddressPicker;
