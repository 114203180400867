import { useEffect, useState } from "react";
import apiClient from "../../utils/API";
import Dropdown from "../Dropdown";
import FormSerializer from "../../store/form/serializer";
import { FIELDS } from "../../store/form/fields";

export default ({ ...props }) => {
  const [items, setItems] = useState([]);
  let initialized = false;
  // const [tmp, setTmp] = useState(false);

  useEffect(async () => {
  
        const tribes = await apiClient.get("/answer/options", {
          "lookup.questionKey": FormSerializer.getQuestionKey(FIELDS.OTSG__ENROLLED_TRIBE),
        }); 
        const listItems = [];    
        for (let tribe of tribes){
          const item = {};
          item.label = tribe.Name;
          item.value = tribe.Value;
          listItems.push(item);
        }       
        setItems(listItems); 
        
  }, [])


  return <Dropdown {...props} placeholder="Tribe" listItems={items} />;
};
