/**
 * interpretation of a question descriptor dto from the program api
 */
class Question {

    /**
     * 
     * @param {*} data raw dto from API
     */
    constructor(data){
        this._data = data;
    }

    /**
     * just an alias for name
     */
    get key() {
        return this.name;
    }

    get name(){
        return this._data.Name;
    }

    get title()
    {
        return this._data.Title;
    }

    get type(){
        return this._data.Type;
    }

    get description(){
        return this._data.Description;
    }

    get stage(){
        return this._data.Stage;
    }

    get requirementType(){
        return this._data.RequirementType;
    }

    get isEitherOr(){
        return this.requirementType == "EitherOr";
    }

    /**
     * is this question strictly required
     */
    get isRequired(){
        return this.requirementType == "Required";
    }

    /**
     * is this question to be asked during the main application to it's program. Assumes program context
     * @returns {Boolean}
     */
    get isApplicationStage(){
        return this.stage == 'Application';
    }
    /**
     * is this question to be asked during  the renewal application to it's program. Assumes program context
     * @returns {Boolean}
     */
    get isRenewalStage(){
        return this.stage == 'Renewal';
    }

    /**
     * determine the actual amount of characters we intend to enforce in the text input/area given any constraints that have been configured for this question
     */
    get maxCharacters(){
        switch (this._data.LengthUnit)
        {
            case "Character":
                return this._data.MaximumLength;
            case "Word":
                return this._data.MaximumLength * 8;
            default:
                return 10000;
        }
    }

    /**
     * 
     * @param {string} category 
     * @returns {boolean}
     */
    hasCategory(category){
        if (!this._data || !this._data.Categories)
            return false;

        return this._data.Categories.some(c => c.toLowerCase() == category.toLowerCase());
    }

    /**
     * locate the given display property
     * @param {string} name 
     * @returns {string}
     */
      getDisplayProperty(name) {
        return this._data?.DisplayProperties.find(p => p.Name === name)?.Value;
    }
}

export default Question