import React, { Fragment } from "react";
import Lock from "@material-ui/icons/Lock";
//import EditIcon from '@material-ui/icons/Edit';
import CheckCircle from "@material-ui/icons/CheckCircle";
import { ErrorOutline } from "@material-ui/icons";
import {Tooltip} from "@material-ui/core";

/**
 * present answer lock status to student
 * 
 * @param {*} param0
 */
function AnswerLock({ answer }) {

    if (!answer) {
        // if no answer return nothing.
        return (<Fragment/>);
    }

    if (!answer.IsLocked)
    {        
        if (answer.IsValid === false)
            //TODO: can we use some variation of {<div dangerouslySetInnerHTML={{__html: answer.ReviewStatusDescription}}></div>} reliably?
            return (
                <Tooltip title="Staff review of application indicates this information may be incorrect. Please check answer.">            
                    <ErrorOutline />
                </Tooltip>
            );
        return null;
    }        

    let lockReason = "Answer has been locked by OSAC";
    if (answer.LockReason === "Verified")
        lockReason = <><CheckCircle />Answer has been verified.</>;
    else if (answer.LockReason === "Eligibility")
        lockReason = "Answer is currently being used to determine your eligibility for financial aid."      
    else if (answer.LockReason === "Outdated")
        lockReason = "Answer is outdated and no longer being used";

    //lockReason += " " + answer.QuestionKey;

    return (
        <Tooltip title={lockReason}>            
            <Lock />
        </Tooltip>
    );

    
    

}
export default AnswerLock;