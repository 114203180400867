import { useEffect, useState } from "react";
import ReadOnlyDesktop from "./ReadOnlyDesktop";
import { getCode } from "../Utility/ConvertCode";
import moment from "moment";

const ReadOnly = ({ activities }) => {
  const [listActivities, setListActivities] = useState([]);

  useEffect(() => {
    (async () => {
      const _activities = [];
      for (const i in activities) {
        const frequency = await getCode(
          "ActivityFrequency",
          activities[i]?.FrequencyCode?.value
        );
        const activity = {
          title: activities[i]?.Title?.value,
          frequency,
          dates: {
            start: moment(activities[i]?.StartDate?.value).format("L"),
            end: activities[i]?.StopDate?.value
              ? moment(activities[i]?.StopDate?.value).format("L")
              : " Current",
          },
          weeklyHr: activities[i]?.HoursPerUnit?.value,
          totalHr: activities[i]?.TotalHours?.value,
          responsibilities: activities[i]?.Accomplishments?.value,
        };
        _activities.push(activity);
      }
      setListActivities(_activities);
    })();
  }, [activities]);

  return (
    <ReadOnlyDesktop listActivities={listActivities.slice(0, activities?.length)} />
  );
};

export default ReadOnly;
