import PortalView from "./../../components/Global/PortalView";
import { FieldSection, FieldRow, FieldSlot } from "../../components/Forms/FieldSection";
import { useForm, FormProvider } from "react-hook-form";
import MaskedDatePicker from "../../components/MaskedDatePicker";
import Button from "../../components/Button";
import apiClient from "../../utils/API";
import { Migration } from "../../store/auth/state";
import { setMigration } from "../../store/auth/actions";
import Input from "../../components/Input";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { open } from "../../store/snack/actions";

const MigrationView = (props) => {
  const dispatch = useDispatch();
  const methods = useForm({ mode: "onBlur", });
  const { handleSubmit } = methods;
  const [loading, setLoading] = useState(false);
  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const result = await apiClient.verifyAccount(data);
      if (result.IsAccountVerified === true){
        dispatch(setMigration(Migration.complete));
        dispatch(
          open({
            message: "Verification complete",
            severity: "success",
          })
        );
      }
      else if (result.IsAccountVerified === null){
        dispatch(
          open({
            message: "Verification failed. Please try again.",
            severity: "success",
          })
        );
      }
     
    } catch (e) {
      // console.log({e})
      if (e.request.status === 403) {
        dispatch(setMigration(Migration.failed));
      } else {
        dispatch(
          open({
            message: e.message,
            severity: "success",
          })
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const onError = (e) => {
    // console.log("On Error");
  };

  const Failed = () => {
    return (
      <div>
        <p>Verification Failed, please contact HECC.</p>
        <ul className="list">          
          <li className="li">Email: SAFeedback@hecc.oregon.gov</li>
        </ul>
      </div>
    );
  };

  return (
    <PortalView>
      <div name="MigrationsPage" className="">
        <div className="rhythm">
          <h2>Account Validation</h2>
          {props.status === Migration.failed && <Failed />}
          {props.status === Migration.required && (
            <div>
              <p>
                This account requires one time validation. If the correct
                information isn't provided, you will need to contact HECC.
              </p>
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                  <FieldSection
                    withDivider={false}
                    title="One Time Account Verification"
                  >
                    <FieldRow>
                      <FieldSlot>
                        <Input
                          name="Username"
                          placeholder="Username"
                          inputLabel="Username"
                          variant="outlined"
                          isRequired={true}
                        ></Input>
                      </FieldSlot>
                    </FieldRow>
                  </FieldSection>

                  <FieldSection title="Birthday">
                    <FieldRow>
                      <FieldSlot>
                        <MaskedDatePicker
                          name="BirthDate"
                          label="Birthday"
                          isRequired={true}
                        />
                      </FieldSlot>
                    </FieldRow>
                    <FieldRow>
                      <FieldSlot>
                        <Button
                          loading={loading}
                          label="Validate"
                          type="submit"
                        ></Button>
                      </FieldSlot>
                    </FieldRow>
                  </FieldSection>
                </form>
              </FormProvider>
            </div>
          )}
        </div>
      </div>
    </PortalView>
  );
};

export default MigrationView;
