import { actions } from './../../store';
import { useSelector } from 'react-redux';
import { useForm, FormProvider } from "react-hook-form";
import { useState, useEffect } from 'react';
import { FieldSection, FieldRow, FieldSlot } from '../../components/Forms/FieldSection';
import FormSection from '../../components/FormSection';
import { open } from "../../store/snack/actions";
import { useDispatch } from 'react-redux';
import DropdownAnswer from '../../components/answer/DropdownAnswer';
import {FIELDS} from "../../store/form/fields";

function EditCitizenShip({ onChange, onCancel, section }) {

    const reduxFormState = useSelector((state)=> state.form);
  
    const methods = useForm({
      mode: 'onBlur'
    });
    const { errors, formState, getValues, setValue, handleSubmit, watch } = methods;

    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();

    const onSubmit = async (data, e) => {
      try {
        setLoading(true)
        const result = await actions.submitForm(data,[],[]);
        if(typeof onChange === 'function'){
          onChange(true);
        }
        // console.log(data, e);
      } catch (e) {
        dispatch(open({
          message: e.message,
          severity: "success",
        }))
      } finally {
        setLoading(false)
      }
    };

    const onError = (errors, e) => {
      // console.log('ERROR',errors, e);
    }

    useEffect(() => {
      window.addEventListener('beforeunload', alertUser)
      return () => {
        window.removeEventListener('beforeunload', alertUser)
      }
    }, [])
    const alertUser = e => {
      e.preventDefault()
      e.returnValue = ''
    }

    return (
      <FormProvider {...methods} >
        <form onSubmit={handleSubmit(onSubmit, onError)} >
          <FormSection title='Citizenship Status' isEditable={true} onSaveClick={handleSubmit(onSubmit, onError)} onCancelClick={onCancel} loading={loading}>

          <FieldSection title="Select Your Citizenship Status" withDivider={true}>
            <FieldRow>
              <FieldSlot md={8}>
                  <DropdownAnswer answer={section.getAnswerByField(FIELDS.CITIZENSHIP_STATUS)} label='Citizenship Status' variant='outlined' codeType="CITIZEN" isRequired={true}/>
                </FieldSlot>
              </FieldRow>
            </FieldSection>
          </FormSection>
        </form>
      </FormProvider>
    );
}

export default EditCitizenShip;
