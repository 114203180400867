import React, {useEffect, useState, memo} from 'react';
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Font } from '@react-pdf/renderer';
import apiClient from "../../utils/API";
import { useSelector } from "react-redux";
import Spinner from '../Utility/Spinner';

function TranscriptCoverPagePdf(props) {

//=====( DATA FIELDS )
    
    const fieldValues = useSelector((state) => state.form.fields);    
    const {transcriptsMail, isHS} = props;
    const firstName = fieldValues.FIRST_NAME?.value;
    const lastName = fieldValues.LAST_NAME?.value;
    const otherHighschool = fieldValues.HIGHSCHOOL?.UnlistedName?.value;

    const [updating, setUpdating] = useState(false)
    const [highschool, setHighschool] = useState()
    const [appId, setAppId] = useState()
    
    const submissionTime = new Date();
    const date = submissionTime.toLocaleDateString();
    const time = submissionTime.toLocaleString('en-US', { hour: 'numeric',minute: 'numeric', hour12: true });

//=====( API CALLS )

  useEffect(() => {
    (async () => {
      setUpdating(true)
      let highschoolResult = isHS ? await apiClient.getHighSchool(transcriptsMail?.SchoolCode) : await apiClient.getCollege(transcriptsMail?.SchoolCode); 
      setHighschool(highschoolResult);
      const app = await apiClient.get(`/application?`+'lookup.ProgramCode=sch&lookup.year='+transcriptsMail?.Year);
      
      if(app && app[0]){
        setAppId(app[0]?.AppId);
      }

      setUpdating(false)
    })()
  }, [fieldValues])

//=====( STYLES )

  Font.register({
    family: 'Open Sans',
    fonts: [
      { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
      { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 },
      { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf', fontWeight: 800 }
    ]
  });

  const styles = StyleSheet.create({
    page: {
        alignContent:'center',
      backgroundColor: '#FFFFFF',
      marginLeft: 20,
      marginRight: 30,
      fontFamily: 'Open Sans',
      fontSize:9
    },
    section: {
      marginTop: 10,
      marginBottom: 0,
      marginLeft: 20,
      marginRight: 30,
      padding: 0,
    },
    sectionHeading: {
      width: '95%',
      color: '#000000',
      fontSize: 12,
      fontWeight: 800,
      textAlign: 'center'
    },
    paragraph: {
      width: '95%',
      fontSize: 9,
      marginBottom: 10
    },
    headerParagraph: {
        width: '95%',
        fontSize: 9,
    },
    dateTimeParagraph: {
        width: '95%',
        marginBottom: 10,
    },
    bold: {
      fontWeight: 800
    },
    boxed: {
      border: 1,
      marginTop: 5,
      marginBottom: 10,
      marginLeft: 20,
      marginRight: 40,
      paddingRight: 0,
      paddingLeft: 15,
      paddingTop: 10,
      paddingBottom: 70,
      width: '87%'
    },
    dateTime: {
        fontSize: 8,
    },
    appStamp: {
        fontSize: 8,
    },
    appStampId: {
        fontSize: 8,
        textAlign: 'right',
        marginRight: 35
    },
  });

//=====( JSX FUNCTIONS )

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
            <Text style={styles.dateTimeParagraph}>
                <Text style={styles.dateTime}>{`${date}, ${time}`}</Text>
            </Text>
            <Text style={styles.headerParagraph}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Text style={styles.sectionHeading}>{`${transcriptsMail?.Year}-${transcriptsMail?.Year+1} OSAC Application Document`}</Text>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Text style={styles.appStamp}>Application #:</Text>
            </Text>
                <Text style={styles.appStampId}>{`${appId}`}</Text>

        </View>
        <View style={styles.section}>
          <Text style={styles.paragraph}>
            Staple this cover sheet to the front of your document. All documents must be received at OSAC by the deadline
          </Text>
          <Text style={styles.paragraph}>
            Send to: Oregon Office of Student Access and Completion, 3225 25th Street SE, Salem, OR 97302
          </Text>
          <Text style={styles.paragraph}>
            Keep a copy for yourself. If you want verification that OSAC received your transcript, send using return receipt requested service
          </Text>

          <Text style={styles.paragraph}>
            <Text style={styles.bold}>Name:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Text> 
            <Text>{`${lastName?.charAt(0).toUpperCase()+lastName?.slice(1)} ${firstName?.charAt(0).toUpperCase()+firstName?.slice(1)}`}</Text>
          </Text>
          <Text style={styles.paragraph}>
            <Text style={styles.bold}>Scan Doc Name:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Text> 
            <Text>{`${transcriptsMail?.ScanCode}`}</Text>
          </Text>
        </View>

        <View style={styles.boxed}>
          <Text style={styles.paragraph}>
            {`${highschool?highschool.Name:otherHighschool} ${transcriptsMail?.AnswerType}`}
          </Text>
        </View>

      </Page>
    </Document>
  );

//=====( RENDER RETURN )

  return (
    updating? 
      <Spinner/>:
    <PDFDownloadLink className='button' document={<MyDocument />} fileName="transcript_cover_page.pdf">
      {({ blob, url, loading, error }) => (loading || updating ? 'Generating PDF...' : 'Download or print the cover letter PDF')}
    </PDFDownloadLink>
    
  );
}

export default memo(TranscriptCoverPagePdf);