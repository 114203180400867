import { actions } from "./../../store";
import { FIELDS } from "../../store/form/fields";
import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import Button from "../../components/Button";
import PrintIcon from "../../components/PrintIcon";
import PortalView from "./../../components/Global/PortalView";
import ProgressBar from "../../components/progress/ProgressBar";
import Checkbox from "../../components/Checkbox";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import CompleteSubmission from "../../components/CompleteSubmission";
import Card from "../../components/Card";
import CardContent from "@material-ui/core/CardContent";
import { goBack, truthy } from "../../utils";
import ProgramInfo from '../../utils/dtos/ProgramInfo';
import ApplicationInfo from '../../utils/dtos/ApplicationInfo';
import ProgramSeason from '../../utils/dtos/ProgramSeason';
import Input from "../../components/Input";
import StatePicker from "../../components/DataDriven/StatePicker";
import apiClient from "../../utils/API";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { open } from "../../store/snack/actions";
import { Alert, AlertTitle } from '@material-ui/lab';
import EditCollegeOfChoice from "../field-groups/EditCollegeOfChoice";
import CitizenshipStatus from "../field-groups/CitizenshipStatus";
import BooleanRadioGroup from "../../components/BooleanRadioGroup";
import ApplicationSubmitButton from "../../components/Utility/ApplicationSubmitButton";
import SectionFormHelper from "../../utils/helper/SectionHelper";


const PROGRAM_CODE = "CH";

/**
 * 
 * @returns {Component}
 */
function Chafee() {
    let { year } = useParams();    
    year = parseInt(year);

    const dispatch = useDispatch();

    const sectionGroup = new SectionFormHelper(useState(null));
    const allChafeeSection = sectionGroup.create(useState(null), null, [
        FIELDS.CHAFEE__PRESUMMER_1,
        FIELDS.CHAFEE__FALL_1,
        FIELDS.CHAFEE__WINTER_1,
        FIELDS.CHAFEE__SPRING_1,
        FIELDS.CHAFEE__POSTSUMMER_1,
        FIELDS.CHAFEE__PRESUMMER_2,
        FIELDS.CHAFEE__FALL_2,
        FIELDS.CHAFEE__WINTER_2,
        FIELDS.CHAFEE__SPRING_2,
        FIELDS.CHAFEE__POSTSUMMER_2,
        FIELDS.CHAFEE__FOSTER_DHS,
        FIELDS.CHAFEE__FOSTER_OTHER_STATE,
        FIELDS.CHAFEE__FOSTER_TRIBAL,
        FIELDS.CHAFEE__FOSTER_CARE,
        FIELDS.CHAFEE__ADOPTED,
        FIELDS.CHAFEE__FOSTER_QUALIFIED,
        FIELDS.CHAFEE__OTHER_STATE_ID,
        FIELDS.CHAFEE__TRIBE,
        FIELDS.CHAFEE__ADOPTION_FINALIZED,
        FIELDS.CITIZENSHIP_STATUS,

        FIELDS.COLLEGE_CHOICE,
        FIELDS.COLLEGE_STANDING
    ]);

    const [grantDetails, setGrantDetails] = useState(null);
    const [nextSeasonData, setNextSeason] = useState(null);
    const [dhsContactInfo, setDhsContactInfo] = useState("");

    const grantInfo = new ProgramInfo(grantDetails);
    const currentSeason = grantInfo.getSeason(year);
    const nextSeason = new ProgramSeason(nextSeasonData);

    const [applicationData, setApplicationData] = useState(null);
    const app = new ApplicationInfo(applicationData);

    useEffect(() => {
        (async () => {
            try {                  
                const result = await apiClient.getProgram(PROGRAM_CODE);
                const currentSeason = new ProgramInfo(result).getSeason(year);
                if (!currentSeason){
                    dispatch(
                        open({
                            message: "This application is no longer available",
                            severity: "success",
                        })
                    );
                    history.push({
                        pathname: '/dashboard', state: {
                            from: history.location.pathname
                        }
                    })
                    return;
                }
                grantInfo.updateDto(result);
                setGrantDetails(result);

                const [priorResult, nextResult, dhsResult, appResult, answers] = await Promise.all([                  
                    apiClient.getSeason(PROGRAM_CODE, year - 1),                    
                    apiClient.getSeason(PROGRAM_CODE, year + 1),
                    apiClient.get("code?lookup.code=FosterEntityInstruct&lookup.codeType=StudentMessage"),
                    apiClient.getApplication(PROGRAM_CODE, year),
                    sectionGroup.fetchAnswers(year)
                ]);
                
                           
                setNextSeason(nextResult);
                setDhsContactInfo(dhsResult.Message);
                setApplicationData(appResult);


            } catch (e) {
                console.log(e);
            }
        })()
    }, [])

    
    const preSummerTerm2 = currentSeason?.getDeadlineDisplay('Summer');
    const fallTerm2 = currentSeason?.getDeadlineDisplay('Fall');
    const winterTerm2 = currentSeason?.getDeadlineDisplay('Winter');
    const springTerm2 = currentSeason?.getDeadlineDisplay('Spring');
    
    const history = useHistory();
    const handleCancel = () => {
        goBack({
            history,
            defaultRoute: "/dashboard",
        });
    };
    const methods = useForm({
        mode: "onBlur",
    });
    const { setValue, handleSubmit, watch, getValues } = methods;
    const formState = useSelector((state) => state.form);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [progress, setProgress] = useState({
        profile: "complete",
        foster: "inProgress",
        terms: "incomplete",
    });

    let progressPercent = 0;
    for (const i in progress) {
        if (progress[i] === "complete") progressPercent += 1;
    }
    progressPercent =
        progressPercent === 3 ? 99 : (progressPercent / 3).toFixed(2) * 100;

    

    const [saveLoading, setSaveLoading] = useState(false);

    const onSave = async (data, e) => {
        try {
            setSaveLoading(true)
            await actions.submitForm(data, [], ["Agreement"], year);
            dispatch(
                open({
                    message: "Saved Successfully",
                    severity: "success",
                })
            );
        } catch (e) {
            console.error(e);
            dispatch(
                open({
                    message: e.message,
                    severity: "success",
                })
            );
        } finally {
            setSaveLoading(false);
        }
    };

    const onError = (errors) => {
        console.log("ERROR", errors);
        dispatch(
            open({
                message: "Your application is not complete. Be sure to resolve all errors above.",
                severity: "success",
            }));
    };

    // Foster Care - Progress
    //TODO: use API to atually get REAL progrss information as defined by the program directly
    if (
        progress.foster === "inProgress" &&
        watch(FIELDS.CHAFEE__FOSTER_CARE) == true &&
        truthy(watch(FIELDS.CHAFEE__ADOPTED)) == true &&
        truthy(watch(FIELDS.CHAFEE__ADOPTION_FINALIZED)) == true
    ) {
        setProgress({ ...progress, foster: "complete", terms: "inProgress" });
    } else if (
        progress.foster === "inProgress" &&
        watch(FIELDS.CHAFEE__FOSTER_CARE) == true &&
        truthy(watch(FIELDS.CHAFEE__ADOPTED)) == false
    ) {
        setProgress({ ...progress, foster: "complete", terms: "inProgress" });
    } else if (
        progress.foster === "inProgress" &&
        watch(FIELDS.CHAFEE__FOSTER_CARE) === false &&
        watch(FIELDS.CHAFEE__FOSTER_QUALIFIED) == true &&
        truthy(watch(FIELDS.CHAFEE__ADOPTED)) === true &&
        truthy(watch(FIELDS.CHAFEE__ADOPTION_FINALIZED)) == true
    ) {
        setProgress({ ...progress, foster: "complete", terms: "inProgress" });
    } else if (
        progress.foster === "inProgress" &&
        watch(FIELDS.CHAFEE__FOSTER_CARE) === false &&
        watch(FIELDS.CHAFEE__FOSTER_QUALIFIED) == true &&
        truthy(watch(FIELDS.CHAFEE__ADOPTED)) === false
    ) {
        setProgress({ ...progress, foster: "complete", terms: "inProgress" });
    } else if (
        progress.foster === "inProgress" &&
        (watch(FIELDS.CHAFEE__FOSTER_DHS) == true ||
            watch(FIELDS.CHAFEE__FOSTER_TRIBAL) == true ||
            watch(FIELDS.CHAFEE__TRIBE) == true)
    ) {
        setProgress({ ...progress, foster: "complete", terms: "inProgress" });
    }

    // Current Academic Year - Progress
    if (
        progress.terms == "inProgress" &&
        (watch(FIELDS.CHAFEE__PRESUMMER_1) == true ||
            watch(FIELDS.CHAFEE__FALL_1) == true ||
            watch(FIELDS.CHAFEE__WINTER_1) == true ||
            watch(FIELDS.CHAFEE__SPRING_1) == true ||
            watch(FIELDS.CHAFEE__POSTSUMMER_1) == true ||
            watch(FIELDS.CHAFEE__PRESUMMER_2) == true ||
            watch(FIELDS.CHAFEE__FALL_2) == true ||
            watch(FIELDS.CHAFEE__WINTER_2) == true ||
            watch(FIELDS.CHAFEE__SPRING_2) == true ||
            watch(FIELDS.CHAFEE__POSTSUMMER_2) == true)
    ) {
        setProgress({ ...progress, terms: "complete" });
        console.log('set terms complete')
    }

    const [submitLoading, setSubmitLoading] = useState(false);

    /**
     * submit the application for consideration for an award
     */
    const actualSubmit = async () => {
        const actualSubmission = async (data, e) => {
            try {
                await actions.submitForm(data, [], ["Agreement"], year);
                await apiClient.submitApplication(PROGRAM_CODE, year);
                dispatch(
                    open({
                        message: e.message,
                        severity: "success",
                    }));
            }
            catch (e) {
                dispatch(
                    open({
                        message: "Submitted Successfully",
                        severity: "success",
                    }));
            }
            setShowConfirmation(true);

        };
        const submitError = (errors) => {
            dispatch(open({
                message: "Your application is not complete. Be sure to resolve all errors above.",
                severity: "success",
            }))
        };
        try {
            setSubmitLoading(true);
            const execSubmission = handleSubmit(actualSubmission, submitError);
            await execSubmission();
        } catch (e) {
            console.error(e);
            dispatch(
                open({
                    message: e.message,
                    severity: "success",
                })
            );
        } finally {
            setSubmitLoading(false);
        }

    };

    const watchFosterOtherState = watch(FIELDS.CHAFEE__FOSTER_OTHER_STATE)
    const watchFosterTribal = watch(FIELDS.CHAFEE__FOSTER_TRIBAL)
    const watchFosterDHS = watch(FIELDS.CHAFEE__FOSTER_DHS)
    useEffect(() => {
        if (watchFosterOtherState === true) {
            setValue(FIELDS.CHAFEE__FOSTER_TRIBAL, false);
            setValue(FIELDS.CHAFEE__FOSTER_DHS, false);
        }
    }, [watchFosterOtherState])
    useEffect(() => {
        if (watchFosterTribal === true) {
            setValue(FIELDS.CHAFEE__FOSTER_OTHER_STATE, false);
            setValue(FIELDS.CHAFEE__FOSTER_DHS, false);
        }
    }, [watchFosterTribal])
    useEffect(() => {
        if (watchFosterDHS === true) {
            setValue(FIELDS.CHAFEE__FOSTER_OTHER_STATE, false);
            setValue(FIELDS.CHAFEE__FOSTER_TRIBAL, false);
        }
    }, [watchFosterDHS])


    const watchFosterCare = watch(FIELDS.CHAFEE__FOSTER_CARE)
    const watchFosterQualified = watch(FIELDS.CHAFEE__FOSTER_QUALIFIED)
    const watchFosterAdopted = watch(FIELDS.CHAFEE__ADOPTED)
    const watchFosterAdoptionFinalized = watch(FIELDS.CHAFEE__ADOPTION_FINALIZED)
    const watchCitizenship = watch(FIELDS.CITIZENSHIP_STATUS)

    const [isEligible, setIsEligible] = useState(true);

    useEffect(() => {
        if (
            truthy(watchFosterCare) === false &&
            truthy(watchFosterQualified) === false
        ) {
            if (truthy(watchFosterAdopted) == false) {
                setIsEligible(false);
            } else if (truthy(watchFosterAdopted) == true && truthy(watchFosterAdoptionFinalized) == false) {
                setIsEligible(true);
            } else {
                setIsEligible(true);
            }
        } else {
            setIsEligible(true);
        }
    }, [watchFosterCare, watchFosterQualified, watchFosterAdopted, watchFosterAdoptionFinalized])

    const citizenShipErrors = {
        3: "Based on your citizenship status, you are ineligible for Chafee ETG. To be eligible you must be able to select U.S. Citizen or Eligible Non-Citizen (includes U.S. Permanent Resident, U.S. Refugee, Asylee, or U.S. Dual Citizen)"
    }

    const [showCitizenshipError, setShowCitizenshipError] = useState(false)

    useEffect(() => {
        let citizenshipAnswer = getValues(FIELDS.CITIZENSHIP_STATUS)
        if (citizenshipAnswer && (parseInt(citizenshipAnswer) === 3)) {
            setIsEligible(false);
            setShowCitizenshipError(true)
        }
        else {
            setIsEligible(true);
            setShowCitizenshipError(false)
        }
    }, [watchCitizenship])

    const onEdit = () => {
        setShowConfirmation(false);
        window.location.reload(true); /* 3722/SP236: Calling components aren't keeping application state so, reload the page from server. */
    }



    return (
        <FormProvider {...methods}>
            <PortalView>
                <div name="ApplicationsPage">
                    <div className="page-intro">
                        <div className="wrapper">
                            <div className="page-intro__layout">
                                <div className="page-intro__icon">
                                    <img src="/MyApplictionsIcon.svg" alt="Applications icon" style={{ marginTop: ".5rem" }} />
                                </div>
                                <div className="page-intro__content rhythm">
                                    <h1 className="heading heading--h1">
                                        {grantInfo.name}
                                        <PrintIcon />
                                    </h1>
                                    <p className="heading"> {grantInfo.description}</p>
                                    <h2 className="heading heading--h2">{year} – {year + 1}</h2>
                                    <a href="/dashboard">Return to Dashboard</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    {showConfirmation ? (
                        <CompleteSubmission onEdit={onEdit} details={{
                            title: grantInfo.name,
                            description: grantInfo.description,
                            code: PROGRAM_CODE
                        }} />
                    ) : (
                        <div className="wrapper">
                            <div className="application-progress component">

                                <div className="progress-bar rhythm">
                                    <h3 className="heading heading--h3">
                                        Application Progress
                                    </h3>

                                    <ProgressBar progress={progressPercent} />

                                    <div className="progress-bar__labels">
                                        <div className="progress-bar__label">
                                            <div className="progress-bar__icon">
                                                {(progress.profile === "complete" && (
                                                    <CheckCircleIcon
                                                        style={{ color: "#429D5B" }}
                                                    />
                                                )) ||
                                                    (progress.profile === "inProgress" && (
                                                        <RadioButtonCheckedIcon
                                                            style={{ color: "#E59B6F" }}
                                                        />
                                                    )) ||
                                                    (progress.profile === "incomplete" && (
                                                        <CheckCircleIcon
                                                            style={{ color: "#D8D8D8" }}
                                                        />
                                                    ))}
                                            </div>
                                            <span>Profile</span>
                                        </div>
                                        <div className="progress-bar__label">
                                            <div className="progress-bar__icon">
                                                {(progress.foster === "complete" && (
                                                    <CheckCircleIcon style={{ color: "#429D5B" }} />
                                                )) ||
                                                    (progress.foster === "inProgress" && (
                                                        <RadioButtonCheckedIcon style={{ color: "#E59B6F" }} />
                                                    )) ||
                                                    (progress.foster === "incomplete" && (
                                                        <CheckCircleIcon style={{ color: "#D8D8D8" }} />
                                                    ))}
                                            </div>
                                            <span>Foster Care</span>
                                        </div>
                                        <div className="progress-bar__label">
                                            <div className="progress-bar__icon">
                                                {(progress.terms === "complete" && (
                                                    <CheckCircleIcon
                                                        style={{ color: "#429D5B" }}
                                                    />
                                                )) ||
                                                    (progress.terms === "inProgress" && (
                                                        <RadioButtonCheckedIcon
                                                            style={{ color: "#E59B6F" }}
                                                        />
                                                    )) ||
                                                    (progress.terms === "incomplete" && (
                                                        <CheckCircleIcon
                                                            style={{ color: "#D8D8D8" }}
                                                        />
                                                    ))}
                                            </div>
                                            <span>College Terms</span>
                                        </div>
                                    </div>
                                </div>
                            </div>{/* application-progress */}

                            <div className="divider" />

                            <div className="form-wrapper rhythm">

                                {/* OTHER section  */}
                                <div className="other">
                                    <div className="form-section">
                                        <div className="form-section__header">
                                            <h2 className="heading heading--h3">Citizenship</h2>
                                        </div>
                                        <div className="wrapper rhythm">
                                            {/*CITIZENSHIP*/}
                                            <CitizenshipStatus />

                                            {watchCitizenship > 0 &&
                                                <div style={{ color: 'red', padding: '1rem', fontWeight: 'bold' }}>
                                                    {citizenShipErrors[watchCitizenship]}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {!showCitizenshipError && (
                                    <div className="foster-care">
                                        <div className="form-section">
                                            <div className="form-section__header">
                                                <h2 className="heading heading--h3">Foster Care</h2>
                                            </div>
                                            <div className="wrapper">
                                                <div className="rhythm">
                                                    {watchFosterOtherState && (
                                                        <div className="rhythm">
                                                            <Card>
                                                                <CardContent>
                                                                    <div dangerouslySetInnerHTML={{ __html: dhsContactInfo }} />
                                                                </CardContent>
                                                            </Card>
                                                        </div>
                                                    )
                                                    }

                                                    <div className="input-field rhythm">
                                                        <p>
                                                            Are you currently in Child Welfare foster care at least 180 days (six months) after your 13th birthday AND did not leave your foster care placement on or after your 14th birthday?
                                                        </p>
                                                        <div>
                                                            <BooleanRadioGroup name={FIELDS.CHAFEE__FOSTER_CARE} value={watchFosterCare} isRequired={true} />
                                                        </div>
                                                        <div>
                                                        </div>
                                                        {truthy(watchFosterCare) === false && (
                                                            <>
                                                                <p>
                                                                    As a former foster youth, were you in Child Welfare foster care for at least 180 days (six months) of foster care after your 13th birthday and that you exited foster care on or after your 14th birthday?
                                                                </p>
                                                                <div>
                                                                    {/*<Checkbox name={FIELDS.CHAFEE__FOSTER_QUALIFIED} isRequired={false} label="Yes" />*/}
                                                                    <BooleanRadioGroup name={FIELDS.CHAFEE__FOSTER_QUALIFIED} value={watchFosterQualified} isRequired={true} />
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>

                                                    <div className="input-field rhythm">
                                                        <p>
                                                            Which entities provided care?
                                                        </p>
                                                        <div className="rhythm">
                                                            <Checkbox name={FIELDS.CHAFEE__FOSTER_DHS} label="Oregon Department of Human Services Child Welfare (DHS, formerly referred to as SCF)" />
                                                        </div>
                                                        <div className="rhythm">
                                                            <Checkbox name={FIELDS.CHAFEE__FOSTER_TRIBAL} label="Tribal Foster Care" />
                                                            {watchFosterTribal && (
                                                                <div>
                                                                    <Input name={FIELDS.CHAFEE__TRIBE} inputLabel="Please list the tribe:" isRequired={true} />
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="rhythm">
                                                            <Checkbox name={FIELDS.CHAFEE__FOSTER_OTHER_STATE} label="Child Welfare system in another state (not Oregon)" />
                                                            {watchFosterOtherState && (
                                                                <div>
                                                                    <StatePicker
                                                                        name={FIELDS.CHAFEE__OTHER_STATE_ID}
                                                                        label="Please list the other state:"
                                                                        isRequired={true}
                                                                        returnType={"Id"}
                                                                        shouldExclude={(state) => state.Abbreviation === 'OR'}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="input-field rhythm">
                                                        <p>
                                                            Oregon Child Welfare Adoption or Guardianship Finalized on
                                                            or after your 13th birthday?
                                                        </p>
                                                        <div>
                                                            {/*<Checkbox name={FIELDS.CHAFEE__ADOPTED} label="Yes" />*/}
                                                            <BooleanRadioGroup name={FIELDS.CHAFEE__ADOPTED} value={watchFosterAdopted} isRequired={true} />
                                                        </div>
                                                        {truthy(watchFosterAdopted) && (
                                                            <>
                                                                <p>
                                                                    Did your adoption or guardianship finalize on or after
                                                                    9/1/2015?
                                                                </p>
                                                                <div>
                                                                    {/*<Checkbox name={FIELDS.CHAFEE__ADOPTION_FINALIZED} isRequired={false} label="Yes" />*/}
                                                                    <BooleanRadioGroup name={FIELDS.CHAFEE__ADOPTION_FINALIZED} value={watchFosterAdoptionFinalized} isRequired={true} />
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>

                                                    {!isEligible &&
                                                        <div className="input-field">
                                                            <Alert severity="error" className="alert alert--error">
                                                                <AlertTitle>Not eligible</AlertTitle>
                                                                You must have been in foster care for at least 180 days after your 13th birthday OR adopted or entered into a guardianship on or after your 13th birthday, and your adoption/guardianship finalized after 9/1/2015 to be eligible for Chafee.
                                                            </Alert>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {!showCitizenshipError && (
                                    <div>
                                        <EditCollegeOfChoice section={allChafeeSection} year={year} />
                                    </div>
                                )}
                                {!showCitizenshipError && (
                                    <div className="college-terms">
                                        <div className="form-section">
                                            <div className="form-section__header">
                                                <h2 className="heading heading--h3">College Terms</h2>
                                            </div>

                                            <div className="wrapper rhythm">
                                              
                                                <h3 className="heading heading--h4 academic-year">{year} – {year + 1} Academic Year</h3>
                                                <div className="table-wrapper">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th><b>Term</b></th>
                                                                <th><b>Attending?</b></th>
                                                                <th><b>Application Deadline</b></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>{preSummerTerm2?.term}</td>
                                                                <td><Checkbox name={FIELDS.CHAFEE__PRESUMMER_2} label="Yes" /></td>
                                                                <td>{preSummerTerm2?.deadline}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>{fallTerm2?.term}</td>
                                                                <td><Checkbox name={FIELDS.CHAFEE__FALL_2} label="Yes" /></td>
                                                                <td>{fallTerm2?.deadline}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>{winterTerm2?.term}</td>
                                                                <td><Checkbox name={FIELDS.CHAFEE__WINTER_2} label="Yes" /></td>
                                                                <td>{winterTerm2?.deadline}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>{springTerm2?.term}</td>
                                                                <td><Checkbox name={FIELDS.CHAFEE__SPRING_2} label="Yes" /></td>
                                                                <td>{springTerm2?.deadline}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>{preSummerTerm2?.term.replace(year, year+1)}</td>
                                                                <td><Checkbox name={FIELDS.CHAFEE__POSTSUMMER_2} label="Yes" /></td>
                                                                <td>{preSummerTerm2?.deadline.replace(year, year+1)}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div> {/* end wrapper */}
                                        </div> {/* end form-section */}
                                    </div>
                                )}
                                {!showCitizenshipError && (
                                    <div className="grant-action-buttons">
                                        <Button
                                            name="Cancel"
                                            label="Cancel"
                                            variant="text"
                                            onClick={handleCancel}
                                        />
                                        <Button
                                            loading={saveLoading}
                                            name="Save"
                                            label="Save"
                                            disabled={!isEligible}
                                            type="submit"
                                            onClick={handleSubmit(onSave, onError)}
                                        />
                                        <ApplicationSubmitButton loading={submitLoading} app={app} sections={{ isComplete: isEligible }} onClick={actualSubmit} />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </PortalView>
        </FormProvider>
    );
}

export default Chafee;
