import React from 'react';
import Input from './Input';

function LargeTextEditor({ characterLimit = 10000, rows, description, control, ...props }) {
  const [value, setValue] = React.useState(0);
  const [overLimit, setOverLimit] = React.useState(false);
  const handleChange = event => {
    setValue(event.target.value.trim().length)
  };

  const handleInput = () => {
    value > characterLimit ? setOverLimit(true) : setOverLimit(false)
  };

  return (
    <Input
      onInput={handleChange}
      aria-label={description}
      multiline
      rows={rows || 12}
      helperText={`${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} characters/${characterLimit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} characters`}
      defaultValue=""
      onKeyUp={handleInput}
      error={overLimit}
      inputProps={{ maxLength: characterLimit }}
      {...props}
    />
  );
}

export default LargeTextEditor;
