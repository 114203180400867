import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import Dialog from '../../components/Dialog';
import AppSnapshotPromise from './AppSnapshotPromise';
import AppSnapshotGuard from './AppSnapshotGuard';
import AppSnapshotChafee from './AppSnapshotChafee';
import AppSnapshotChild from './AppSnapshotChild';
import { CODES } from '../Utility/ProgramCodes';
import AppSnapshotScholarship from './AppSnapshotScholarship';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  heading: {
  },
  details: {
  },
  section: {
  },

}));

function MobileHistoryRow({ details, key, year }) {
  const classes = useStyles();
  const [expanded] = React.useState(false);

  return (
    <div className="rhtyhm">
      <Accordion>
        <AccordionSummary
          expandIcon={expanded ? <RemoveIcon /> : <AddIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="accordion__summary"
        >
          <h5 className="heading heading--h5">
            {details.name}
          </h5>
        </AccordionSummary>
        <AccordionDetails className="accordion__details rhythm">
          <dl>
            <dt><strong>Grant or scholarship:</strong></dt>
            <dd>{details.name}</dd>
          </dl>
          <dl>
            <dt><strong>Application #</strong></dt>
            <dd>{details.appNumber}</dd>
          </dl>

          <div className={classes.section}>
          {details.isAwarded && 
            <div style={{color: '#51C1B0'}}>
              <StarRoundedIcon style={{paddingLeft: '2px'}} fontSize='smaller'/>
              <strong>Awarded!</strong>
            </div>
          }
          </div>

          <div className={classes.section}>
            <Dialog title={`${details.name} ${details.year}-${details.year + 1}`} buttonLabel='view'>
            {
              details.code === CODES.OREGON_PROMISE ?
                <AppSnapshotPromise year={details.year}/>
              :
              details.code === CODES.NATIONAL_GUARD ?
                <AppSnapshotGuard year={details.year}/>
              :
              details.code === CODES.CHAFEE ?
                <AppSnapshotChafee year={details.year}/>
              :
              details.code === CODES.CHILD_CARE ?
                <AppSnapshotChild year={details.year}/>
              :
                <AppSnapshotScholarship year={details.year} code={details.code} />
            }
            </Dialog>
          </div>

        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default ({ listApplications })=>{
  return (
    <div className="history-list rhythm">
      {listApplications.map((item, i) =>
        <div className="component component--bottom-small-padding rhythm">
          <h3 className="heading heading--h3">{item.year}</h3>
          {(item.applications).map((app, j) =>
            <MobileHistoryRow key={j} details={app} year={item.year}/>
          )}
        </div>
      )}
    </div>
  )
}