import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  root: {
    //padding: '2px 4px',
    position: 'relative',
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    //minWidth: '20%',
    boxShadow: 'none',
    border: '1px solid rgba(194, 201, 209, 1)',
    borderRadius: "5px",
    height: '40px',

  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));

function SearchBar({ label, description, ...props }) {
  const classes = useStyles();

  return (
    <Paper component="form" className={classes.root}>
    <IconButton /*type="submit"*/ className={classes.iconButton} aria-label="search">
      <SearchIcon />
    </IconButton>
    <InputBase
      onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
      type={'search'}
      className={classes.input}
      placeholder="Search..."
      inputProps={{ 'aria-label': description }}
      value
      {...props}
    />
    </Paper>
  );
}

export default SearchBar;
