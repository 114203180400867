function ProgressBar({ progress }) {
  
  return (
    <div className="progress-bar__chart">
      <div style={{height: '3rem', background: 'rgba(247, 219, 106, .5)', borderRadius: '5px'}}>
        <div style={{width: `${progress}%`, height: '100%', background: '#a2ba69', borderRadius: '5px 0 0 5px'}}>
          <div align='left' style={{height: '3rem', lineHeight: '3rem', paddingLeft: '1rem', color: '#ffffff', fontWeight: 'bold'}}>{progress}% Completed</div>
        </div>
      </div>
    </div>
  );
}

export default ProgressBar;