import React, {useEffect, useState} from 'react';
import { Page, Text, View, Image, Document, StyleSheet, PDFDownloadLink, Font } from '@react-pdf/renderer';
import apiClient from "../utils/API";
import {FIELDS} from "../store/form/fields";
import {actions} from "../store";
import {getLatestActiveYear, getReduxField} from "../utils";

function TribalApplicationPdf() {

  const [tribeName, setTribeName] = useState('')
  const [appId, setAppId] = useState()
  const [updating, setUpdating] = useState(false)

  const fieldKeys = [
    FIELDS.OTSG__ENROLLED_TRIBE,
    FIELDS.FIRST_NAME,
    FIELDS.MIDDLE_INITIALS,
    FIELDS.LAST_NAME,
    FIELDS.OTHER_NAMES,
  ];

  useEffect(() => {
    (async () => {
      setUpdating(true)
      const yearRetrieved = await getLatestActiveYear('otsg');
      await actions.fetchBasicFields(fieldKeys, yearRetrieved);
      const tribes = await apiClient.get(`/tribe/tribes`);
      tribes.forEach(r => {
        if(r.Id == getReduxField(FIELDS.OTSG__ENROLLED_TRIBE)) {
          setTribeName(r.Name)
          return false
        }
      })
      const app = await apiClient.get(`/application?`+'lookup.ProgramCode=otsg&lookup.year='+yearRetrieved);
      console.log('app', app)
      console.log('appid', app[0].AppId)
      setAppId(app[0].AppId)
      setUpdating(false)
    })()
  }, [])

// Create styles
  Font.register({
    family: 'Open Sans',
    fonts: [
      { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
      { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 },
      { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf', fontWeight: 800 }
    ]
  });

  const styles = StyleSheet.create({
    page: {
      backgroundColor: '#FFFFFF',
      marginLeft: 20,
      marginRight: 30,
      fontFamily: 'Open Sans',
      fontSize:9
    },
    section: {
      marginTop: 10,
      marginBottom: 0,
      marginLeft: 20,
      marginRight: 30,
      padding: 0,
    },
    header: {
      width: '95%',
      height: 'auto'
    },
    sectionHeading: {
      width: '95%',
      color: '#000000',
      fontSize: 10,
      fontWeight: 800,
      borderBottom: 1
    },
    paragraph: {
      width: '95%',
      fontSize: 9,
      marginBottom: 10
    },
    thinParagraph: {
      width: '95%',
      fontSize: 9,
      marginBottom: 0
    },
    paragraphCenter: {
      width: '90%',
      fontSize: 9,
      marginBottom: 10,
      textAlign: 'center'
    },
    bold: {
      fontWeight: 800
    },
    underlined: {
      textDecoration: 'underline',
      marginLeft: 10,
      marginRight: 10
    },
    boxed: {
      backgroundColor: '#dddddd',
      border: 1,
      marginTop: 5,
      marginBottom: 10,
      marginLeft: 20,
      marginRight: 40,
      paddingRight: 0,
      paddingLeft: 15,
      paddingTop: 10,
      textAlign: 'center',
      width: '90%'
    },
    centerHeading: {
      width: '95%',
      color: '#000000',
      fontSize: 9,
      fontWeight: 800,
      borderBottom: 1,
      textAlign: 'center'
    },
    box: {
      width: 10,
      height: 10,
      display: 'inline-block',
      border: 1
    },
    footNote: {
      fontSize: 6,
    },
    footNoteRight: {
      fontSize: 6,
      textAlign: 'right'
    },
    emptySpace: {
      height: 50,
      width: '100%'
    }
  });

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Image src={'/pdf_header.png'} style={styles.header}></Image>
        </View>
        <View style={styles.section}>
          <Text style={styles.sectionHeading}>STUDENT COMPLETE THIS SECTION</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.paragraph}>
            Please sign and submit this form to your tribe's Enrollment Officials.
          </Text>
          <Text style={styles.paragraph}>
            <Text style={styles.bold}>Release Authorization:</Text>
          </Text>
          <Text style={styles.paragraph}>
            “By signing this form, I authorize my tribal office to release my tribal enrollment information to the Higher Education
            Coordinating Commission (HECC) and the Office of Student Access and Completion (OSAC) for the purposes of determining
            eligibility for the Oregon Tribal Student Grant. The HECC and the OSAC will only share membership verification status
            information with eligible Oregon colleges or universities for the purpose of determining eligibility and awarding for the
            Oregon Tribal Student Grant.”
          </Text>
          <Text style={styles.paragraph}>
            <Text style={styles.bold}>First Name:&nbsp;</Text> <Text style={styles.underlined}>&nbsp;&nbsp;&nbsp;{ getReduxField(FIELDS.FIRST_NAME) }&nbsp;&nbsp;&nbsp;</Text>
            <Text style={styles.bold}>&nbsp;M.I.:&nbsp;</Text> <Text style={styles.underlined}>&nbsp;&nbsp;&nbsp;{ getReduxField(FIELDS.MIDDLE_INITIALS) }&nbsp;&nbsp;&nbsp;</Text>
            <Text style={styles.bold}>&nbsp;Last Name:&nbsp;</Text> <Text style={styles.underlined}>&nbsp;&nbsp;&nbsp;{ getReduxField(FIELDS.LAST_NAME) }&nbsp;&nbsp;&nbsp;</Text>
          </Text>
          <Text style={styles.paragraph}>
            <Text style={styles.bold}>Former Names:&nbsp;</Text> <Text style={styles.underlined}>&nbsp;&nbsp;&nbsp;{ getReduxField(FIELDS.OTHER_NAMES) }&nbsp;&nbsp;&nbsp;</Text>
          </Text>
          <Text style={styles.paragraph}>
            <Text style={styles.bold}>Signature:&nbsp;</Text>
            <Text style={styles.underlined}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Text>
            <Text style={styles.bold}>&nbsp;Date:</Text>
            <Text style={styles.underlined}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </Text>
          </Text>
        </View>
        <View style={styles.boxed}>
          <Text style={styles.centerHeading}>
            TRIBAL ENROLLMENT VERIFICATION
          </Text>
          <Text style={styles.paragraphCenter}>
            To be completed by tribe's enrollment official or office
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.sectionHeading}>ENROLLMENT OFFICER COMPLETE THIS SECTION</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.paragraph}>
            The above named student is applying for the Oregon Tribal Student Grant from the Office of Student Access and
            Completion, an office of the Higher Education Coordinating Commission. OSAC is requesting verification of tribal enrollment
            from your office. Please return this form within 2 weeks of receiving it from the student. OSAC reserves the right to validate
            all enrollments.
          </Text>
          <Text style={styles.paragraph}>
            <Text style={styles.bold}>Name of Tribe:&nbsp;</Text> <Text style={styles.underlined}>&nbsp;&nbsp;&nbsp;{ tribeName }&nbsp;&nbsp;&nbsp;</Text>
          </Text>
          <Text style={styles.paragraph}>
            <Text style={styles.bold}>
              Is this applicant an enrolled member of the tribe? Yes [ ]  No [ ]
            </Text>
          </Text>
          <Text style={styles.paragraph}>
            <Text style={styles.footNote}>
              *Please do not supply blood quantum or CDIB or roll numbers. We only need verification of enrollment.
            </Text>
          </Text>
          <Text style={styles.paragraph}>
            <Text style={styles.bold}>Enrollment Officer Name:&nbsp;</Text>
            <Text style={styles.underlined}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </Text>
          </Text>
          <Text style={styles.paragraph}>
            <Text style={styles.bold}>Address:&nbsp;</Text>
            <Text style={styles.underlined}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </Text>
            <Text style={styles.bold}>&nbsp;Title:&nbsp;</Text>
            <Text style={styles.underlined}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </Text>
          </Text>
          <Text style={styles.paragraph}>
            <Text style={styles.bold}>City/State/Zip:&nbsp;</Text>
            <Text style={styles.underlined}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </Text>
            <Text style={styles.bold}>&nbsp;Date:&nbsp;</Text>
            <Text style={styles.underlined}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </Text>
          </Text>
          <Text style={styles.paragraph}>
            <Text style={styles.bold}>Office Phone:&nbsp;</Text>
            <Text style={styles.underlined}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </Text>
            <Text style={styles.bold}>&nbsp;Office Email:&nbsp;</Text>
            <Text style={styles.underlined}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </Text>
          </Text>
          <Text style={styles.paragraph}>
            <Text style={styles.footNote}>
              Supplying an email allows us to send an electronic version of this form directly to the enrollment office, if acceptable.
            </Text>
          </Text>
          <Text style={styles.paragraph}>
            <Text style={styles.underlined}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </Text>
            <Text style={styles.bold}>Initial here if you would like to participate in OSAC's electronic verifictiaon system in the future.</Text>
          </Text>
          <Text style={styles.paragraph}>
            <Text style={styles.bold}>&nbsp;Signature:&nbsp;</Text>
            <Text style={styles.underlined}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </Text>
          </Text>
          <Text style={styles.paragraph}>
            <Text style={styles.bold}>&nbsp;If applicable, affix the tribal seal here:&nbsp;</Text>
          </Text>
          <Text style={styles.paragraph}></Text>
          <Text style={styles.paragraph}></Text>
          <Text style={styles.paragraph}></Text>
          <Text style={styles.paragraph}></Text>
          <Text style={styles.paragraph}></Text>
          <Text style={styles.thinParagraph}>
            <Text style={styles.footNote}>
              <Text>
                Completed forms can be submitted to OSAC by the tribal enrollment verification official:
              </Text>
            </Text>
          </Text>
          <Text style={styles.thinParagraph}>
            <Text style={styles.footNote}>
              • Emailed as an attachment to PublicPrograms@hecc.oregon.gov<br/>
            </Text>
          </Text>
          <Text style={styles.thinParagraph}>
            <Text style={styles.footNote}>
              • Mailed to the Office of Student Access and Completion, 3225 25th Street SE, Salem, OR 97302<br/>
            </Text>
          </Text>
          <Text style={styles.thinParagraph}>
            <Text style={styles.footNoteRight}>
             App# {appId}
            </Text>
          </Text>
        </View>
      </Page>
    </Document>
  );

  return (
    <PDFDownloadLink className='button' document={<MyDocument />} fileName="enrollment_verification_form.pdf">
      {({ blob, url, loading, error }) => (loading || updating ? 'Generating PDF...' : 'Download or print the Enrollment Verification Form PDF')}
    </PDFDownloadLink>
  );
}
export default TribalApplicationPdf;
