import React from "react";

const ReadOnlyActivityPicker = ({ listActivities }) => {
  return (
    <div className="table-wrapper">
      <table className="table">
        <tr className="table__header">
          <th>Title</th>
          <th>Frequency</th>
          <th>Dates</th>
          <th>Weekly Hr.</th>
          <th>Total Hr.</th>
          <th>Responsibilities</th>
        </tr>

        {listActivities.map((item, i) => (
          <tr className="table__row" container>
            <td>{item.title}</td>
            <td>{item.frequency}</td>
            <td>{`${item.dates.start} - ${item.dates.end}`}</td>
            <td>{item.weeklyHr}</td>
            <td>{item.totalHr}</td>
            <td>{item.responsibilities}</td>
          </tr>
        ))}
      </table>
    </div>
  );
};

export default ReadOnlyActivityPicker;
