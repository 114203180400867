import React from "react";
import Button from "./BasicButton";
import PropTypes from "prop-types";

const TextButton = (props) => <Button variant="text" {...props} />;
const SolidButton = (props) => <Button {...props} />;
const OutlinedButton = (props) => <Button variant="outlined" {...props} />;
const WhiteButton = (props) => <Button {...props} />;

const ButtonVariants = {
  SOLID: "solid",
  OUTLINE: "outline",
  TEXT: "text",
  WHITE: "white",
};

const OSACButton = ({ variant = ButtonVariants.SOLID, ...props }) => {
  switch (variant) {
    case ButtonVariants.OUTLINE:
      return <OutlinedButton {...props} />;
    case ButtonVariants.SOLID:
      return <SolidButton {...props} />;
    case ButtonVariants.TEXT:
      return <TextButton {...props} />;
    case ButtonVariants.WHITE:
      return <WhiteButton {...props} />;
    default:
      throw new Error(
        `Invalid OSAC Button variant provided: ${variant}. Is not one of: ${Object.values(
          ButtonVariants
        ).join(", ")}`
      );
  }
};

OSACButton.propTypes = {
  variant: PropTypes.oneOf(Object.values(ButtonVariants)),
};

export default OSACButton;
