import React from 'react';

import ImgHECClogo from '../../images/hecc-logo-white.png';

function Footer() {
  return (
      <div className="global-footer">
        <div className="global-footer__layout">
          <div className="global-footer__brand">
            <img
              alt="Higher Education Coordinating Commission"
              src={ImgHECClogo}
            />
            <p className="global-footer__legal">© {new Date().getFullYear()} Higher Education Coordinating Commission</p>
          </div>

          <div className="global-footer__navigation rhythm">
            <h5 className="heading heading--h6">Quick Links</h5>
            <ul className="global-footer__nav">
              <li className="li">
                <a href="#about" className="link">About</a>
              </li>
              <li className="li">
                <a href="#contact" className="link">Contact</a>
              </li>
              <li className="li">
                <a href="#privacy" className="link">Privacy &amp; Disclaimers</a>
              </li>
              <li className="li">
                <a href="#oregon.gov" className="link">oregon.gov</a>
              </li>
            </ul>
          </div>

          <div className="global-footer__connect rhythm">
            <h5 className="heading heading--h6">Connect</h5>
            <p>
              OSAC Office
              <br/>
              3225 25th Street SE 
              <br/>
              Salem, OR 97302​
            </p>

            <div className="global-footer__social-links">
              <a className="link global-footer__connect-facebook" href="#facebook">
                <span className="sr-only">facebook</span>
              </a>
              <a className="link global-footer__connect-instagram" href="#instagram">
                <span className="sr-only">Instagram</span>
              </a>
              <a className="link global-footer__connect-twitter" href="#twitter">
                <span className="sr-only">Twitter</span>
              </a>
            </div>
          </div>

        </div>
      </div>
  );
};

export default Footer;