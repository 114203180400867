import { useState, useEffect } from "react";
import PortalView from "./../../components/Global/PortalView";
import HistoryIcon from "@material-ui/icons/History";
import AppHistoryMobile from "../../components/AppHistory/AppHistoryMobile";
import { getHistory } from "../../components/Utility/GetAppHistory";
import SpinnerSection from "./../../components/Utility/SpinnerSection";

function History() {
  const [applications, setApplications] = useState([]);

  const [isLoading, setLoading] = useState(true);
  let listApps = [];

  useEffect(() => {
    (async () => {
      const value = await getHistory();
      setApplications(value);
      setLoading(false);
    })();
  }, []);

  const groupedApps = applications.reduce((obj, v) => {
    obj[v.year] = obj[v.year] || [];
    obj[v.year].push(v);
    return obj;
  }, {});

  for (const i in groupedApps) {
    let temp = {
      year:
        groupedApps[i][0].year.toString() +
        "-" +
        (groupedApps[i][0].year + 1).toString(),
      applications: Object.values(groupedApps[i]),
    };
    listApps.push(temp);
  }

  return (
    <PortalView>
      <div name="HistoryPage" className="history">

        <div className="page-intro">
          <div className="wrapper">
            <div className="page-intro__layout">
              <div className="page-intro__icon">
                <HistoryIcon fontSize="large" style={{ color: "#f7db6a", marginTop: ".5rem" }} />
              </div>
              <div className="page-intro__content rhythm">
                <h1 className="heading heading--h1">
                  Application History
                </h1>
                <a href="/dashboard">Return to Dashboard</a>
              </div>
            </div>
          </div>
        </div>
        <div className="component rhythm">
          <div className="wrapper">
            {!isLoading && (
              <AppHistoryMobile listApplications={listApps} />
            )}
            {isLoading && <SpinnerSection />}
          </div>
        </div>
      </div>
    </PortalView>
  );
}

export default History;
