import StarRoundedIcon from '@material-ui/icons/StarRounded';
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';
import SpinnerSection from './Utility/SpinnerSection';
import apiClient from '../utils/API';
import ProgramInfo from '../utils/dtos/ProgramInfo';
import ApplicationInfo from '../utils/dtos/ApplicationInfo';
import { CODES } from './Utility/ProgramCodes';
import { open } from "../store/snack/actions";
import { useDispatch } from "react-redux";
import moment from 'moment';
import Button from './Button';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export const STATUS = {
    READY: 'ready',
    IN_PROGRESS: 'inProgress',
    INELIGIBLE: 'ineligible',
    ELIGIBLE: 'eligible',
    AWARDED: 'awarded',
    SUBMITTED: 'submitted',
    QUALIFIED: 'Qual',
    QUALIFIED_PREF: 'QualPref'
};

export const APP_SOURCE = {
    RENEW: 'Renew',
    APPLY: 'Apply',
    AUTO: 'Auto',
    SCOOP: 'Scoop'
}


/**
 * 
 * @param {*} param0 
 * @returns 
 */
function LinkButton({ href, label }) {

    return (
        <a href={href} className="button">{label}</a>
    );
}


/**
 * determine next actions and overal status to display for ANY proram that might be fed into this
 * 
 * @param {*} param0 
 * @returns {Component}
 */
function StatusButtons({ onAccept, status, Name, Code, awardState, onClick, errorMessage, toggleExpand, year, statusState, isScholarship = false, count = 0, isSCHTab=false, ...details }) {   
    const programInfo = isScholarship ? null : new ProgramInfo(details); //TODO: use common data object for grants and scholarships so we can interpret them the same
    const currentSeason = programInfo?.getSeason(year);

    const appInfo = new ApplicationInfo(details?.programStatusResult);

    const history = useHistory();
    const dispatch = useDispatch()

    function getPath() {
        switch (Code) {
            case CODES.OREGON_PROMISE:
                return '/oregon-promise';
            case CODES.CHAFEE:
                return `/chafee/${year}`;
            case CODES.CHILD_CARE:
                return `/child-care/${year}`;
            case CODES.NATIONAL_GUARD:
                return `/national-guard/${year}`;
            case CODES.OTSG:
                return `/otsg/${year}`;
            case CODES.ELP:
                return `/early-learning/${year}`;
            case CODES.OTSP:
                return `/otsp/${year}`;
            default:
                return `/scholarship/${Code}/${year}`;
        }
    }

    /**
     * links that look like buttons
     * @param {*} param0 
     * @returns {Component}
     */
    function AppButton({ label }) {        
        const link = getPath();
        return <LinkButton href={link} label={label} />
    }

    function AwardNotificationButton({ label }) {
        switch (Code) {
            case CODES.OREGON_PROMISE:           
            case CODES.NATIONAL_GUARD:
            case CODES.OTSG:
            case CODES.ELP:
            case CODES.OTSP:
                return <LinkButton label={label} href={`/awards#${Code}`} />
            default:
                return <LinkButton label={label} href={'/offer/' + Code + '/' + year} />
        }        
    }

    function AwardAcceptButton({label}){
        return <LinkButton label={label} href={'/offer/' + Code + '/' + year} />
    }

    function RenewButton({label}){
        return <LinkButton label={label} href={'/renew/' + Code + '/' + year} />
    }

    function DeclineButton({label}){
        return <LinkButton label={label} href={'/decline/' + Code + '/' + year} />
    }

    const handleRemoveAward = async () => {     
        await apiClient.removeApplication(Code, year);
        document.location.reload();        
    }

    const handleStartApplication = async () => {

        if (Code) {
            try {
                await apiClient.startApplication(Code, year);
                // go to corresponding application
                const path = getPath();
                history.push({
                    pathname: path, state: {
                        from: history.location.pathname
                    }
                })
            } catch (e) {
                dispatch(
                    open({
                        message: e.message,
                        severity: "success",
                    })
                );
            }
        } else {
            console.warn(`Code is not set on status button for application ${Name}`)
        }
    }
  
    /**
     * short snappy renewal-friendly summary of review status
     * #3858/SP-305, SP3-317: Mapping between 'ReviewStatus' and 'Renewal Messages' for "Renew Scholarships cards". 
     * @returns {String}
     */
    const getRenewalMessage = () => {

        switch (details?.programStatusResult?.ReviewStatus) {
            case "XINELG":
                return "Ineligible";

            case "HNOFAFSA":
                return "FAFSA/ORSAA Required";

            case "XCHGMAJ":
                return "Ineligible Major";

            case "XNONEED":
                return "Ineligible Need";

            case "XCHGCLG":
                return "Ineligible College";

            case "PEND_RENEWALREVIEW":
                return "Submitted Pending Review";

            case "HVFYFNDS":
                return "Pending Committee Review";

            case "RNW":
                return "Renewed";

            case "HCHGCLG":
                return "Pending College Approval";

            case "XNOLTR":
                return "No Response from Applicant by Deadline";

            case "RNWEMSENT":
                return "Renewal Available";

            case "XDECLINE":
                return "Declined Renewal";

            default:
                return "";
        }

    }


    /**
     * Source should be 'Renew' for  a renewal application ('Source=Renew'). 
     * #4096: 2/16/2023: Do not use 'ReviewStatus' to decide the application is a Renewal or not. Use only 'Source=Renew'  
     * @returns {bool}
     */
    const isRenewScholarshipApp = () => {

        // If source == 'Renew' will come from API when 'Renew' Checkbox is checked, 'Award letter Sent' date is set, It should have Fund account & 'Amount' > 0. 
        return details?.programStatusResult?.Source === 'Renew' ? true : false;

    }

    /**
     * #3944/SP-358: RENEWAL: Application End Date
     * Method returns renewal card buttons visibility (true/false) based on renewal card deadline [RNW - END date]. 
     * Return value - false: Student log-in before renewal deadline, they can 'Renew/Decline' the card. Renew and Decline Buttons Not disabled. 
     * Return value - true: Student log-in after renewal deadline they can't 'Renew/Decline' the card. Renew and Decline Buttons will be disabled. 
     * @returns {boolean}   
     */
    const disableRenewalButtons = () => {

        const dtNow = moment();

        const dtRNWdeadline = moment(details?.programStatusResult?.Deadline);

        // Compare 'now' with Renewal card deadline.   
        let retVal = dtNow.isBefore(dtRNWdeadline);
        return (retVal === true) ? false : true;
    }

   
    // if program does not have status
    if (!status) {
        return <SpinnerSection />;
    }

    const source = details?.programStatusResult?.Source;
    const reviewStatus = statusState?.ReviewStatus;

    // student has already declined scholarship. They got off the train, but leave them with a little note for feedback
    if (source === APP_SOURCE.RENEW && reviewStatus === 'XDECLINE') {
        return <div className="rhythm award-cta award-cta--declined">
            Declined Renewal
        </div>;
    }

    // all button combinations valid for first-time applicants to scholarships managed by the umbrella Scholarship Application program
    if (isScholarship && source !== APP_SOURCE.RENEW) {
        switch (awardState?.awardStatus) {
            case 'Declined':
                return (
                    <div className="rhythm award-cta award-cta--declined">
                        Declined Award
                    </div>
                );
            case 'Offer':
                return (
                    <div className="rhythm award-cta award-cta--accept-decline">
                        <AwardNotificationButton label='Accept/Decline'/>
                    </div>
                );
            case 'Authorized':
            case 'Hold':
                return (
                    <div className="rhythm award-cta award-cta--awarded" style={{ color: 'rgba(81, 193, 176, 1)' }}>
                        <div className="award-cta__status">
                            <StarRoundedIcon />
                            <span>Awarded!</span>
                        </div>
                        <AwardNotificationButton label='View'/>
                    </div>
                );
            case 'NotAuthorized':
                return (
                    <div className="rhythm award-cta award-cta--declined">
                        Awarded to other applicants.
                    </div>
                );
            case 'None':
            default:
                //NOTE: any any state above with an award evaluation, we no longer allow student to remove the award
                break;
        }
        //NOTE: will will assume programInfo isStarted is irrelevant, sinc the umbrella application should gatekeep access 
        if (statusState?.IsStarted) {
            return (
                <div className="rhythm award-cta award-cta--inprogress">
                    {details.isComplete === true || details.isComplete === null //either docs are certified done or we determined we don't care about completion
                        ? <div className="award-cta__status">Added</div>
                        : <div className="award-cta__status" style={{ color: '#da3754' }}>In Progress</div>
                    }
                    <AppButton label='Edit'/>
                    {/* TODO: Adjust edit / remove buttons. These buttons are used in multiple places and do not line up in the scholarship selection area*/}
                    {appInfo.isRemovable &&
                        <Button onClick={handleRemoveAward} label='Remove' />
                    }
                </div>
            );

        }
        else if (count > 40)
            return <div>You have reached your limit of 40 scholarships.</div>

        else {
            return (
                <>  {/*#4951: Remove the word “Eligible” from all of the scholarships listed on the “recommended” tab in the application. 
                    #5066: Remove the word “Eligible” from all the scholarships listed on the “Memberships/Employers” and “All” tabs in the application*/}
                    {status === STATUS.ELIGIBLE && !isSCHTab && 
                        <div className="award-cta__status">
                            Eligible
                        </div>
                    }
                    <AppButton label='View/Apply'/>
                </>
            );
        }
    }


    // TODO: we need to start partitioning out the different program types form this rats nest
    // consider the whole section below ***DEPRECATED**** moving forward

    // NOTE: all of the scholarship references below should not be hit. Scholarships instead are handled above
    return (
        <>
            {/*<div>is Scholarship: { isScholarship }</div>*/}
            {/*<div>Total Scholarships: { count }</div>*/}
            {/* #3858/SP-305, SP3-317:	DO NOT use SENT, EMSENT, or AWD for Renewals.  We do not have an 'accept' & 'decline' process for renewals.      
          Use isRenewScholarshipApp() method to check given application is 'Renew app' or not.  */ }
            {!isRenewScholarshipApp() && statusState?.ReviewStatus === 'EMSENT' ?
                <div className="rhythm award-cta award-cta--accept-decline">
                    <AwardAcceptButton label='Accept'/>
                    <AwardNotificationButton label='Decline'/>
                </div> :
         
                statusState?.ReviewStatus === 'AWD' ?
                    <div className="rhythm award-cta award-cta--accepted">
                    </div> :

                    status === STATUS.READY ?
                        <div className="rhythm award-cta award-cta--ready" style={{ color: '#429D5B' }}>
                            <div className="award-cta__status">
                                <CheckIcon />
                                Ready to Submit
                            </div>
                            <Button onClick={onClick} label='Submit' />
                            <AppButton label='Edit'/>
                        </div>
                        :
                        (status === STATUS.IN_PROGRESS && source !== 'Auto' && !isScholarship && !isRenewScholarshipApp()) ?
                            <div className="rhythm award-cta award-cta--inprogress">
                                {/* #3828/SP275:	
                                Scholarship with no additional requirements – once saved, Application progress should say “Complete” and remove “In Progress”
                                Scholarship with additional requirements
                                    1. Student adds info, hits save – change to “Complete”.
                                    2. Student doesn’t have info, hits save – change to “In Progress” in red (#da3754). 
                                Auto selected (change to 'Complete'). Change “View” button to say “Complete” and don’t make it clickable.
                                */}
                                <div className="award-cta__status">In Progress</div>
                                <AppButton label='Edit'/>
                            </div>
                            :
                            // Use isRenewScholarshipApp() method to check given application is 'Renew app' or not.
                            (status === STATUS.IN_PROGRESS && !isRenewScholarshipApp() && source !== 'Auto' && isScholarship && (details.docsRequired === null || details.docsRequired === true)) ?
                                <div className="rhythm award-cta award-cta--inprogress">
                                    <div className="award-cta__status">Added</div>
                                    <AppButton label='Edit'/>
                                </div>
                                :
                                (status === STATUS.IN_PROGRESS && !isRenewScholarshipApp() && source !== 'Auto' && isScholarship && details.docsRequired === false) &&
                                <div className="rhythm award-cta award-cta--inprogress">
                                    <div className="award-cta__status" style={{ color: '#da3754' }}>In Progress</div>
                                    <AppButton label='Edit'/>
                                </div>
            }
            {/* #3911/SP-333 For renew applications do not dispaly buttons, when source = 'Auto' */}
            {!isRenewScholarshipApp() && source == 'Auto' &&
                <div>{/* #3828/SP275:	Auto Selected (change to complete).  Change “view” button to say “complete” and don’t make it clickable */}
                    <div className="rhythm award-cta award-cta--inprogress">
                        <div className="award-cta__status">Added</div>
                        <AppButton style={{ marginBottom: '1rem' }}  label='View' disabled />
                    </div>
                </div>
            }
            {((status === STATUS.IN_PROGRESS || status === STATUS.SUBMITTED) && isScholarship && source == 'Apply' && !isRenewScholarshipApp()) &&
                <div className="rhythm award-cta award-cta--remove">
                    <Button onClick={handleRemoveAward} label='Remove' />
                </div>
            }
            {
                (status === STATUS.SUBMITTED) &&
                (awardState && awardState?.isAwarded ?
                    <div className="rhythm award-cta award-cta--awarded" style={{ color: 'rgba(81, 193, 176, 1)' }}>
                        <div className="award-cta__status">
                            <StarRoundedIcon />
                            {Code === CODES.OREGON_PROMISE ? "Tentatively Approved" : <span>Awarded!</span>}
                        </div>

                        {Code !== CODES.OREGON_PROMISE && awardState?.ReviewStatus !== 'AWD' ?
                            <></>
                            : Code === CODES.OREGON_PROMISE ?
                                <AwardNotificationButton label='View'variant='outline' />
                                : <AwardNotificationButton label='Decline' variant='outline' />
                        }
                        {Code != CODES.OREGON_PROMISE &&
                            <AppButton label='View'/>
                        }
                    </div> :
                    <div className="rhythm award-cta award-cta--submitted" style={{ color: '#429D5B' }}>
                        <div className="award-cta__status">
                            <CheckIcon />
                            Submitted
                        </div>
                        <AppButton label='View'/><br />
                    </div>
                )
            }

            {status === STATUS.INELIGIBLE &&
                <div className="rhythm award-cta award-cta--ineligible" style={{ color: '#F44336' }}>
                    <div className="award-cta__status">
                        <WarningIcon />
                        Ineligible{errorMessage && <span>:</span>}
                        {errorMessage &&
                            <div>
                                <br />
                                {errorMessage}
                            </div>
                        }
                    </div>
                    {count < 40 || !isScholarship &&
                        <Button label='Start' disabled />
                    }
                    {count > 40 && isScholarship &&
                        <div>You have reached your limit of 40 scholarships.</div>
                    }
                </div>
            }
            {/* Do not dispaly View/Start buttons for Renew application.*/}
            {!isRenewScholarshipApp() && status === STATUS.ELIGIBLE && (!awardState || awardState.isAwarded == false) && ((statusState && statusState.IsStarted == false && statusState.IsSubmitted == false) || !statusState) &&
                <div className="rhythm award-cta award-cta--eligible">
                    <div className="award-cta__status">
                        Eligible
                    </div>
                    {currentSeason?.isStarted === false &&
                        <>
                            <div className="award-cta__status">
                                Application opens {currentSeason?.startEvent?.displayDate}
                            </div>
                            <Button label='Start' disabled />
                        </>
                    }
                    {currentSeason?.isStarted !== false && count < 40 &&
                        <div>
                            {// #3965: Delete "VIEW' button and change START to "VIEW/APPLY"
                                isScholarship &&
                                <AppButton label='View/Apply'/>
                            }
                            {!isScholarship &&
                                <Button onClick={handleStartApplication} label='Start' />
                            }
                        </div>
                    }
                    {count > 40 &&
                        <div>You have reached your limit of 40 scholarships.</div>
                    }
                </div>
            }
            {/* #3858/SP-305, SP3-317:	DO NOT use SENT, EMSENT, or AWD for Renewals. 
            'XDECLINE' is handled in #3855/SP-308 No need to diaplay any buttons.
            { details?.programStatusResult?.Source == 'Apply' && details?.programStatusResult?.ReviewStatus !== 'XDECLINE' && details?.programStatusResult?.ReviewStatus !== 'SENT' && details?.programStatusResult?.ReviewStatus !== 'EMSENT' && details?.programStatusResult?.ReviewStatus !== 'PEND' && details?.programStatusResult?.ReviewStatus !== 'AWD' && */}
            {/* Display if renewal and not in any x status */}
            {isRenewScholarshipApp() && !reviewStatus.slice(0,1).toLowerCase().includes('x')?
                <div className="rhythm award-cta award-cta--eligible">
                    <div className="award-cta__status">
                        {getRenewalMessage()}
                    </div>
                    {/* Display Renew button if also not in HVFYFNDS or RWD status*/}
                    {reviewStatus !== 'HVFYFNDS' &&  reviewStatus !== 'RNW'?
                        <RenewButton label='Renew' disabled={disableRenewalButtons()} />
                    :null}

                    <div className="rhythm award-cta award-cta--accept-decline">
                        <div className="award-cta__status">
                            Decline Renewal
                        </div>
                        <DeclineButton label='Decline'/>
                    </div>

                </div>
            :null}

            {/* If renewal hide Renew & Decline buttons if in any x code status. */}
            {isRenewScholarshipApp() && reviewStatus.slice(0,1).toLowerCase().includes('x')?
                <div className="rhythm award-cta award-cta--eligible">
                    <div className="rhythm award-cta award-cta--declined">
                        {getRenewalMessage()}
                    </div>
                </div>
            :null}
            
            {!isRenewScholarshipApp() && details?.programStatusResult?.ReviewStatus === 'AWD' &&
                <div className="rhythm award-cta award-cta--submitted" style={{ color: '#429D5B' }}>
                    <div className="award-cta__status">
                        <CheckIcon />
                        Renewal Accepted
                    </div>
                    <AppButton label='View'/>
                </div>
            }
            { //status === STATUS.IN_PROGRESS &&
                (Code === CODES.CHILD_CARE && (status === STATUS.QUALIFIED || status === STATUS.QUALIFIED_PREF)) &&
                <div className="rhythm award-cta award-cta--childcare">
                    <div className="award-cta__status" style={{ color: 'rgb(249 161 0)' }}>
                        <WarningIcon />
                    </div>
                    <p>
                        Please submit your transcripts and proof of childcare expense
                    </p>
                    <AppButton label='Edit'/>
                </div>
            }
            {
                (statusState && statusState?.ReviewInstructions !== null) &&
                <div className="rhythm award-cta award-cta--childcare">
                    <div className="award-cta__status" style={{ color: 'rgb(249 161 0)' }}>
                        <WarningIcon />
                    </div>
                    <p>
                        Corrections Required: {statusState.ReviewInstructions} / {Code}
                    </p>
                    <AppButton label='Edit'/>
                </div>
            }
        </>
    );
};

export default StatusButtons;
