export const open = ({ message, severity }) => async (dispatch) => {
  // console.log("Called");
  dispatch({
    type: "open",
    payload: {
      message,
      severity,
    },
  });
};

export const close = () => async (dispatch) => {
  dispatch({
    type: "close",
  });
};
