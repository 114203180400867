import ProgressBar from "../progress/ProgressBar";
import ProgressBarLabel from "../progress/ProgressBarLabel";

function ScholarshipProgressHeader ({program, answers, app, completedSections}) {
    const numberOfAnswers = answers? answers.length : 0;
    const numberOfCompletedSections= completedSections? completedSections.length : 0;

    const itemizeSections = 100 / numberOfAnswers;
    const isComplete = numberOfAnswers === numberOfCompletedSections;
    const pecentComplete = isComplete? 100 : Math.round((itemizeSections * numberOfCompletedSections));

    return (
        <div>
            <div className="progress-bar rhythm">
                Note: Progress is calculated upon successful save
                <ProgressBar progress={pecentComplete} />
                <div className="progress-bar__labels">
                    <ProgressBarLabel title={`Additional Required Documents - ${program.name}`} state={isComplete ? "complete" : "incomplete"}/> 
                </div>
            </div>
        </div>
    );
}

export default ScholarshipProgressHeader;