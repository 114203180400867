import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiTabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ScholarshipsDisplay from './ScholarshipsDisplay';
import SearchBar from '../SearchBar';
import { FieldRow, FieldSlot } from '../Forms/FieldSection';
import Pagination from '../Pagination';
import AllScholarships from './AllScholarships';
import Spinner from './../../components/Utility/Spinner';
import { useHistory } from "react-router";
import apiClient from "../../utils/API";
import { enrichProgramsWithDocInfo, combineWithLocalApp } from '../../utils';

const Tabs = withStyles({
  root: {
    borderBottom: '1px solid #ddd',
    boxShadow: 'none',
  },
  indicator: {
    backgroundColor: '#ddd',
    height: '6px',
  },
})(MuiTabs);

const Tab = withStyles((theme) => ({
  root: {
    marginRight: '1.5rem',
    fontSize: '.875rem',
    '&$selected': {
      color: '#333333',
    },
    '&:focus': {
      color: '#333333',
    },
  },
  selected: {},
}))((props) => <MuiTab disableRipple {...props} />);

/**
 * specify tabbing styles for scholarship processing
 * @param {*} props 
 * @returns {Component}
 */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  pageText: {
  }
}));

const PAGE_SIZE = 10;

const FilteringOptions = {
  MOST_RECENT: "Most Recent",
  HIDDEN: "Hidden"
};

/**
 * little helper to track common work of each search scope's state management
 */
class SearchScope {

  constructor(state, year, isMembershipVerified, enrichFunction) {
    this._state = state[0];
    this._setState = state[1];
    this._year = year;
    this._isMembershipVerified = isMembershipVerified;
    this._enrichWithApplication = enrichFunction;
  }

  get page() {
    return this._state.page ?? 1;
  }

  updatePhrase(event) {
    this._state.phrase = event.target.value;
    this._state.page = 1; //reset back to first page for new search scope
    const newState = { ...this._state, phrase: this.phrase, page: this.page };
    this._setState(newState);
    let phrase = this._state.phrase

    if (isNaN(phrase) && phrase.length < 3) {
      // if not a number and length is less than 3, return     
      return;
    }

    if (!isNaN(phrase) && phrase.length < 1) {
      // if  a number and length is less than 1, return    
      return;
    }

    this.doSearch();
  }

  updatePage(page) {
    this._state.page = page;
    const newState = { ...this._state, page: this.page };
    this._setState(newState);

    this.doSearch();
  }

  get results() {
    return this._state.results ?? [];
  }

  get isStarted() {
    return this._state.results ? true : false;
  }

  get totalPages() {
    return Math.ceil(this._state.totalCount / PAGE_SIZE)
  }

  get phrase() {
    return this._state.phrase;
  }
 
  async doSearch() {

    const results = await apiClient.get('/program/search', {
      phrases: this.phrase ?? "",
      parentProgram: 'SCH',
      isMembershipVerified: this._isMembershipVerified,
      currentPage: this.page - 1, //0-indexed in API
      year: this._year
    });
    const resultApps = results.PageOfPrograms.map(p => this._enrichWithApplication(p));
    const resultAppsWithDocs = await enrichProgramsWithDocInfo(resultApps, this._year);
    const newState = {
      page: this.page,
      phrase: this.phrase,
      results: resultAppsWithDocs,
      totalCount: results.TotalCount
    }
    this._setState(newState);
  }
}

/**
 * render the tab suite for which students can find and process their scholarhips
 * 
 * @param {*} param0 
 * @returns {Component}
 */
export default function SimpleTabs({
  startedScholarshipGetter,
  isLoading = false,
  year,
  recommendationGetter
}) {

  const history = useHistory();
  const classes = useStyles();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  
  const [startedScholarships, setStartedScholarships] = useState([]);  
  const upcomingScholarships = startedScholarships.filter(a => a.Source !== 'Renew');
  const renewals = startedScholarships.filter(a => a.Source === 'Renew');
  const renewalTabOffset = renewals.length > 0 ? 1 : 0;
  const recommendedTabIndex = 1 + renewalTabOffset;
  const memberTagIndex = 2 + renewalTabOffset;
  const catalogTabIndex = 3 + renewalTabOffset;

  useEffect(async () => {
    const apps = startedScholarshipGetter();
    const appsWithDocs = await enrichProgramsWithDocInfo(apps);
    setStartedScholarships(appsWithDocs);
  }, []);




  //local stashed copy of recommendations
  const [recommendations, setRecommendations] = useState(null);
  const handleChange = async (event, newValue) => {

    setCurrentTabIndex(newValue);
    
    if (newValue == recommendedTabIndex) {
      const {loadStatus, recommendations} = await recommendationGetter();    
      if  (loadStatus == "NEW"){
        const recommendedApps = recommendations.map(p => combineWithLocalApp(startedScholarships, p, {IsRecommended:true, Year: year}));
        
        const recommendedAppsWithDocs = await enrichProgramsWithDocInfo(recommendedApps, year);
        setRecommendations(recommendedAppsWithDocs); //ensure that recommendations are available
      }      
    }
    else if (newValue == memberTagIndex && !memberSearch.isStarted){ 
      memberSearch.doSearch();
    }
    else if (newValue == catalogTabIndex && !catalogSearch.isStarted){    
      catalogSearch.doSearch();
    }
    

  };

  const onSelect = (number) => {
    history.push({
      pathname: `/scholarship/${number}`,
      state: {
        from: history.location.pathname,
      },
    });
  };

  let tabIndex = 0;
  function nextTabIndex(){
    return tabIndex++;
  }

  const catalogSearch = new SearchScope(useState({}), year, null, (p) => combineWithLocalApp(startedScholarships, p, {IsRecommended: false, Year: year}));
  const memberSearch = new SearchScope(useState({}), year, true, (p) => combineWithLocalApp(startedScholarships, p, {IsRecommended: false, Year: year}));

  if (isLoading) {
    return (
      <div className="flex center">
        <Spinner />
      </div>
    )
  }

  return (
    <div className="scholarship-tabs">
      <Tabs value={currentTabIndex} onChange={handleChange} style={{ marginTop: '1.5rem', flexWrap: 'wrap' }}>
        <Tab label="My Selected Scholarships" {...a11yProps(0)} />
        {renewals.length > 0 &&
          <Tab label="Renewals" {...a11yProps(1)} />
        }
        <Tab label="Recommended" {...a11yProps(2)} />
        <Tab label="Memberships/Employers" {...a11yProps(3)} />
        <Tab label="All" {...a11yProps(4)} />
      </Tabs>
      <TabPanel value={currentTabIndex} index={nextTabIndex()}>
        <div name='ScholarshipsDisplaySelected'>
          {upcomingScholarships.map((item, i) =>
            <div key={`listsch${i}`} style={{ paddingBottom: '2rem' }}>
              <ScholarshipsDisplay key={i} details={item} onSelect={onSelect} count={startedScholarships.length} />
            </div>
          )
          }

        </div>
        <div className="grants-display__grid">
          {upcomingScholarships.length == 0 &&
            <div>&nbsp;&nbsp;&nbsp;No scholarships to display.</div>
          }
        </div>
      </TabPanel>
      {/* #3951/SP-347: Add Separate tab for scholarship renewals. */}
      {renewals.length > 0 &&
        <TabPanel value={currentTabIndex} index={nextTabIndex()}>
          <div name='ScholarshipRenewalsSelected'>
            {renewals.map((item, i) =>
              <div key={`listschrnw${i}`} style={{ paddingBottom: '2rem' }}>
                <ScholarshipsDisplay key={i} details={item} onSelect={onSelect} />
              </div>
            )
            }

          </div>
          <div className="grants-display__grid">
            {renewals.length == 0 &&
              <div>&nbsp;&nbsp;&nbsp;No scholarship renewals to display.</div>
            }
          </div>
        </TabPanel>
      }
      <TabPanel value={currentTabIndex} index={nextTabIndex()}>
        <div name="RecommendedScholarships">
          {/* #4951: Remove the word “Eligible” from all of the scholarships listed on the “recommended” tab in the application. */}
          {recommendations && recommendations.map((item, i) =>
            <AllScholarships key={i} details={item} isSCHTab={true} />
          )}
        </div>
        <div className="grants-display__grid">
          {recommendations && recommendations.length == 0 &&
            <div>&nbsp;&nbsp;&nbsp;No scholarships recommended.</div>
          }
          {!recommendations &&
            <>
              <div>Loading scholarship recommendations</div>
              <Spinner />
            </>
          }
        </div>
      </TabPanel>
      <TabPanel value={currentTabIndex} index={nextTabIndex()}>
        <div name='MembershipScholarships'>
          <FieldRow className='search space-between' style={{ marginBottom: '2rem' }}>
            <div style={{ width: '50%' }}>
              <FieldRow >
                <FieldSlot md={6}>
                  <SearchBar description='Search Member Scholarships' onChange={(e) => memberSearch.updatePhrase(e)} value={memberSearch.phrase} />
                </FieldSlot>
              </FieldRow>
            </div>
            <Pagination count={memberSearch.totalPages} onChange={(e) => memberSearch.updatePage(e)} page={memberSearch.page} />
          </FieldRow>
          <div className="grants-display__grid">
          {/* #5066: Remove the word “Eligible” from all the scholarships listed on the “Memberships/Employers” and “All” tabs in the application */}
            {memberSearch.results.map((item, i) =>
              <AllScholarships key={i} details={item} isSCHTab={true} />
            )}
            {memberSearch.isStarted && memberSearch.results.length == 0 &&
              <div>No scholarships were found matching your selected keyword(s).</div>
            }
            {!memberSearch.isStarted &&
              <div>Use search bar to look for scholarships by name or description </div>
            }
          </div>
        </div>
      </TabPanel>
      <TabPanel value={currentTabIndex} index={nextTabIndex()}>
        <FieldRow className='search space-between' style={{ marginBottom: '2rem' }}>
          <div style={{ width: '50%' }}>
            <FieldRow >
              <FieldSlot md={6}>
                <SearchBar description='Search Selected Scholarships' onChange={(e) => catalogSearch.updatePhrase(e)} value={catalogSearch.phrase} />
              </FieldSlot>
            </FieldRow>
            <Pagination count={catalogSearch.totalPages} onChange={(e) => catalogSearch.updatePage(e)} page={catalogSearch.page} />
          </div>
          <div name="grants-display__grid">
            {/* #5066: Remove the word “Eligible” from all the scholarships listed on the “Memberships/Employers” and “All” tabs in the application */}
            {catalogSearch.results.map((item, i) =>
              <AllScholarships key={i} details={item} isSCHTab={true} />
            )}
            {catalogSearch.isStarted && catalogSearch.results.length == 0 &&
              <div>No scholarships were found matching your selected keyword(s).</div>
            }
            {!catalogSearch.isStarted &&
              <div>Use search bar to look for scholarships by name or description </div>
            }
          </div>
        </FieldRow>
      </TabPanel>
    </div>
  );
}
