import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import CheckIcon from '@material-ui/icons/Check';
import Tooltip from '@material-ui/core/Tooltip';
import StatusButtons from '../AppStatusButtons';
import Dialog from '@material-ui/core/Dialog';
import AwardAnnouncement from '../AwardAnnouncement';
import apiClient from '../../utils/API';
import { useDispatch } from 'react-redux';
import { open } from "../../store/snack/actions";
import moment from 'moment'

export default function ScholarshipsDisplay({details, onSelect = ()=>{}, count = 0,  ...props}) {
  const [expanded, setExpanded] = React.useState(true);
  const listRequirements = details.listRequirements;
  const handleSelect = () => { onSelect(details); }
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const handleAccept = async () => { setOpenDialog(true); }

  const acceptAward = async () => {
    setLoading(true)
    try {
      await apiClient.acceptApplicationAward(details.Code, details.year);
      setLoading(false);
      setOpenDialog(false)
      window.location.reload();
    }
    catch (error) {
      dispatch(open({
        message: error.message,
        severity: "success",
      }))
    } finally {
      setLoading(false);
      setOpenDialog(false)
    }
  }

  const source = details?.programStatusResult?.Source;

  // Mapping between 'ReviewStatus' and 'Renewal Messages' for "Renew Scholarships cards". 
  let renewalMessage = "";
    
  const displayRenewalMessages = () => {
    
    switch(details?.programStatusResult?.ReviewStatus){
      case "XINELG":
        renewalMessage = "Ineligible";
        break;
    
      case "HNOFAFSA":
        renewalMessage = "FAFSA/ORSAA Required";
        break;
    
      case "XCHGMAJ":
        renewalMessage = "Ineligible Major";
        break;
    
      case "XNONEED":
        renewalMessage = "Ineligible Need";
        break;
    
      case "XCHGCLG":
        renewalMessage = "Ineligible College";
        break;
    
      case "PEND_RENEWALREVIEW":
        renewalMessage = "Submitted Pending Review";
        break;
    
      case "HVFYFNDS":
        renewalMessage = "Pending Committee Review";
        break;
          
      case "RNW":
        renewalMessage = "Renewed";
        break; 
              
      case "HCHGCLG":
        renewalMessage = "Pending College Approval";
        break;
          
      case "XNOLTR":
        renewalMessage = "No Response from Applicant by Deadline";
        break;   
    
      case "RNWEMSENT":
        renewalMessage = "Renewal Available"; 
        break;  
      
      case "XDECLINE":
        renewalMessage = "Declined Renewal"; // To dispaly 'Application Progress' on 'Application' page. This is handled differently in 'DashBoard'. 
        break; 
          
      default:
        renewalMessage = "";
        break;      
    }

    return renewalMessage;
  }
    
  // Source should be 'Renew' for  a renewal application ('Source=Renew'). 
  // #4096: 2/16/2023: Do not use 'ReviewStatus' to decide the application is a Renewal or not. Use only 'Source=Renew'  
  let renewalSCHAppFlag = false;
    
  const isRenewScholarshipApp = () => {

    // If source == 'Renew' will come from API when 'Renew' Checkbox is checked, 'Award letter Sent' date is set, It should have Fund account & 'Amount' > 0. 
    renewalSCHAppFlag = details?.programStatusResult?.Source === 'Renew' ? true : false;

    return renewalSCHAppFlag;
  }

  return (
    <>
    <Dialog open={openDialog} onClose={() => setOpenDialog(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <AwardAnnouncement onAccept={acceptAward} details={details} open={openDialog} loading={loading} onClose={() => setOpenDialog(false)}/>
    </Dialog>

    <div className="grants-display grants-display--scholarships">
      <Accordion expanded={expanded} square onChange={() => setExpanded(!expanded)} style={{boxShadow: 'none'}}>
        <AccordionSummary aria-controls="panel1a-content"id="panel1a-header">
          <div className="scholarships__header grants-body__title">
            <h4 className="heading heading--h4">
              {expanded ? <ArrowDropDownIcon style={{color: 'rgb(14, 149, 188)'}}/> : <ArrowRightIcon style={{color: 'rgb(14, 149, 188)'}}/>}
              {details.Code && <span>#{details.Code} &nbsp;</span>}
              {details.Name}
            </h4>
            <span className="icons">
              {details.matched && <Tooltip title='Matched to Your Profile'><img style={{margin: '4px 3px -3px 3px'}} src='/person_search_black.svg'/></Tooltip>}
              {details.docsRequired && <Tooltip title='Documents Required for This Scholarship'><img style={{margin: '4px 3px -3px 3px'}} src='/text_snippet_black.svg'/></Tooltip>}
              {details.membershipRequired && <Tooltip title='Membership or Employment Required'><img style={{margin: '4px 3px -3px 3px'}} src='/card_membership_black.svg'/></Tooltip>}
            </span>
          </div>
        </AccordionSummary>
        <AccordionDetails className="scholarship-body">
          <div className="rhythm">
            <div className="flex">
              <div>
                <dl>
                  <dt>Application Progress</dt>
                  {isRenewScholarshipApp() && <div style={{color: '#377eda'}}>{displayRenewalMessages()}</div>  }
                  {  !isRenewScholarshipApp() && details.statusState?.IsSubmitted &&
                    <div style={{color: '#377eda'}}>Applied</div>
                  }              
                  {  !isRenewScholarshipApp() && (details.statusState?.IsStarted && source !== 'Auto' &&  (details.docsRequired === null || details.docsRequired === true)) && 
                    <div style={{color: '#377eda'}}>Added</div>
                  }
                  {  !isRenewScholarshipApp() && (details.statusState?.IsStarted && source !== 'Auto' &&  details.docsRequired === false) && 
                    <div style={{color: '#da3754'}}>In Progress </div>
                  }
                  {  !isRenewScholarshipApp() && source == 'Auto' &&
                    <div style={{color: '#377eda'}}>Added</div> 
                  }
                  {  !isRenewScholarshipApp() && (!details.statusState?.IsStarted && !details.statusState?.IsSubmitted) && details.status == 'eligible' &&
                    <div style={{color: '#377eda'}}>Eligible</div>
                  }
                </dl>
              </div>
              <div>
                <dl>
                  <dt>Deadline</dt>
                  <dd style={{color: 'rgba(51, 51, 51, 1)', fontWeight: 'bold'}}>{moment(details?.statusState?.Deadline).format("MMMM Do, YYYY h:mm a")}</dd>
                </dl>
              </div>
              {details?.Source?.toLowerCase() !== "renew" &&
              <div>
                <dl>
                  <dt>Additional Documents</dt>
                  {details.isComplete && details.docsRequired? <dd style={{ color: '#429D5B' }}>Completed</dd> : null}
                  {details.isComplete === false && details.docsRequired? <dd style={{ color: '#da3754' }}>Not Completed</dd> : null}
                  {details.isComplete === null? <dd>None Required</dd> : null}
                </dl>
              </div>
              }
            </div>

            {/* JJB, adding this section as it seems appropriate to display the descriptions if they exist */}
            {details.Desciption &&
            <div className="rhythm">
              <h4 className="heading heading--h4">Description:</h4>
              <p>{details.Description}</p>
            </div>
            }

            <div className="rhythm">
              <h4 className="heading heading--h4">Requirements:</h4>
              <ul className="list list--blank" style={{columnCount: '2'}}>
              {listRequirements.map((item, i) =>
                <li className="li" key={i}>
                  <CheckIcon fontSize='.75rem' style={{color: 'rgba(66, 157, 91, 1)'}}/>
                  &nbsp;
                  {item.value}
                </li>
              )}
              </ul>
            </div>
            {/* #3911/SP-333: Scholarship Renewal Process: Renewal Card not consistent with Dashboard cards. 
            incase of 'Renew' applications dispaly award amount only, When it is Renewed i.e. ReviewStaus is 'RNW'.  */}
            {(!isRenewScholarshipApp() || details?.programStatusResult?.ReviewStatus === 'RNW') && details?.awardState?.totalAmountAwarded > 0 &&
            <div className="rhythm">
              <h4 className="heading heading--h4">
                Awarded Amount ({details?.awardState?.semesterYearDescriptor})
              </h4>
              <strong>${details?.awardState?.totalAmountAwarded}</strong>
            </div>
            }
          </div>
        </AccordionDetails>
      </Accordion>
      <div>
        <StatusButtons {...details} onAccept={handleAccept} onClick={handleSelect} isScholarship={true} count={count}/>
      </div>
    </div>
    </>
  );
}
