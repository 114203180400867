import React from 'react';

const FieldSection = ({ title, subtitle, children, isRequired, isSoftRequired, ...props })=>{
  return (
    <div className="field-section rhythm">
      {
        !!title && (
          <h4 className="heading heading--h5">{title}
          { isRequired && <span style={{color: 'red'}}> *</span> }
          { isSoftRequired && !isRequired? <span style={{color: 'red'}}> *</span> : null}
          </h4>
        )
      }
      {
        !!subtitle && (
          <p dangerouslySetInnerHTML={{__html: subtitle}}></p>
        )
      }
      { children && 
        <div className="field-section__content rhythm">
          {children}
        </div> 
      }
    </div>
  )
}

export default FieldSection;