import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";

/**
 * standard label & icon for indicating progress on a multi-step process
 * @param {*} param0 
 * @returns 
 */
function ProgressBarLabel({ title, state, sectionRef }) {

    const jumpToSection = () => {
        if (!sectionRef)
            return;
        if (!sectionRef.current)
            return;
        sectionRef.current.scrollIntoView();
    };

    return (
        <div className="progress-bar__label" onClick={jumpToSection} style={{ cursor: sectionRef == null ? "auto" : "pointer" }}>
            <div className="progress-bar__icon">
                {(state === "complete" && (
                    <CheckCircleIcon style={{ color: "#429D5B" }} />
                )) ||
                    (state === "inProgress" && (
                        <RadioButtonCheckedIcon style={{ color: "#E59B6F" }} />
                    )) ||
                    (state === "incomplete" && (
                        <CheckCircleIcon style={{ color: "#D8D8D8" }} />
                    ))}
            </div>
            <span>{title}</span>
        </div>
    );
}

export default ProgressBarLabel;