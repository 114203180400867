/**
 * 
 * @param {*} param0 
 * @returns 
 */
function ContactDisplay({answer}){

    return (
        <address>
            <div>{answer?.FirstName} {answer?.LastName}</div>
            <div>{answer.Address}</div>
            <div>{answer.Address2}</div>
        </address>
    );

}

export default ContactDisplay;