import BooleanRadioGroup from "../BooleanRadioGroup";
import AnswerLock from "./status/AnswerLock";
import { stringy } from "../../utils";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

/**
 * prompt for a standard boolean answer. 
 * 
 * Presents as a yes/no radio group
 * 
 * @param {*} param0 
 * @returns {Component}
 */
function BooleanAnswer({ answer, disabled, label, ...props }) {

    const isDisbled = disabled || answer.IsLocked;

    const { errors, formState, getValues, setValue, handleSubmit, watch } = useFormContext();

    useEffect(() => {
        if (answer.IsLoading !== false)
            return;

        const defaultValue = answer.Response === true ? 'True' : answer.Response === false ? 'False' : answer.Response;
        setValue(answer.FieldKey, defaultValue);

    }, [answer.IsLoading]);

    return (
        <>
            <p>
                {label} {
                    (label !== undefined && props.isRequired) && (
                        <span style={{ color: 'red' }}>&nbsp;*</span>
                    )
                } <AnswerLock answer={answer} />
            </p>
            <BooleanRadioGroup
                disabled={isDisbled}
                name={answer.FieldKey}
                {...props}
            />
        </>
    );
}

export default BooleanAnswer;