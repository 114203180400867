import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";

export function useGetTranscriptInstructions(props) {

    // Pass programCode to get program instructions.
    const { programCode } = props;
    const highschoolType= useSelector((state) => state.form.fields?.HIGHSCHOOL_TYPE?.value);
    const [transcriptInstructions, setTranscriptInstructions] = useState(null);

    const programLinks = {
        ORP: 'https://oregonstudentaid.gov/grants/oregon-promise-grant/transcripts/',
    }

    // TODO:
    //      1. Add instructions for other programs if needed.
    //      2. Possibly make the messages / links dynamic.
    const instuctions = {
        ORP: {
            TRD: (
                <div style={{ paddingBottom:'20px' }}>
                    <div style={{ paddingBottom:'10px' }}>
                        Please submit a complete transcript with grades 9 through first semester/second quarter/first trimester (depending on school calendar) of your senior year.
                        These transcripts are typically available in January/February. 
                    </div>
                    <div>
                        For more information, please view our website <a href={programLinks.ORP}>{programLinks.ORP}</a>.
                    </div>
                </div>),
            GED: (
                <div style={{ paddingBottom:'20px' }}>
                    <div style={{ paddingBottom:'10px' }}>
                        Please submit your official GED test transcript. This is a one-page document with student information at the top and test scores at the bottom.
                    </div>
                    <div>
                        For more information, please view our website <a href={programLinks.ORP}>{programLinks.ORP}</a>.
                    </div>
                </div>),
            HMSCH: (
                <div style={{ paddingBottom:'20px' }}>
                    <div style={{ paddingBottom:'10px' }}>
                        Homeschool students must submit the following three documents:
                    </div>
                    <ul style={{ paddingBottom:'10px', paddingLeft:'30px' }}>
                        <li>1. A copy of the Confirmation of Enrollment letter on file at your local Educational Service District (ESD) (also called "Letter of Intent to homeschool"). A DMV receipt or record will NOT suffice.</li>
                        <li>2. A copy of the results of a tenth-grade standardized achievement test, required for all homeschool students who have registered with their ESD.</li>
                        <li>3. A high school transcript that includes grade 9 through at least first semester/second quarter/first trimester of grade 12. Transcript must also have the student's cumulative, unweighted GPA</li>
                    </ul>
                    For more information, please view our website <a href={programLinks.ORP}>{programLinks.ORP}</a>.
                </div>),
            CC: (
                <div style={{ paddingBottom:'20px' }}>
                    <div style={{ paddingBottom:'10px' }}>
                        Please submit a complete transcript with grades 9 through first semester/second quarter/first trimester (depending on school calendar) of your senior year from your high school completion program
                        (not a college transcript). These transcripts are typically available in January/February.  
                    </div>
                    <div>
                        For more information, please view our website <a href={programLinks.ORP}>{programLinks.ORP}</a>.
                    </div>
                </div>)
            }   
    }

    function getTranscriptInstructions(){
        let transcriptType;

        if(programCode?.includes("ORP")){
            if(highschoolType?.includes("GEDHM"))
            {
                transcriptType = "GED"
            }
            else if(highschoolType?.includes("ALT"))
            {
                transcriptType = "TRD"
            }
            else{
                transcriptType = highschoolType
            }
        }

        setTranscriptInstructions(instuctions[programCode][transcriptType]);
    }

    useEffect(() => {
        getTranscriptInstructions();
    }, [highschoolType, programCode]);

    return { transcriptInstructions };
}
