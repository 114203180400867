import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import TranscriptUploader from ".";
import { AnswerTypeTranscriptProps, FIELDS, TRANSCRIPT_QUESTIONS } from "../../store/form/fields";
import _get from "lodash.get";
import { getCollege } from "../Utility/ConvertCode";


const SchoolName = ({ schoolCode, name }) => {
  const [schoolName, setSchoolName] = useState("");
  useEffect(() => {
    if (schoolCode) {
      (async () => {
        const name = await getCollege(schoolCode);
        setSchoolName(name);
      })();
    }
  }, [schoolCode]);

  useEffect(() => {
    if (name) {
      setSchoolName(name);
    }
  }, [name]);

  return <strong>{schoolName}</strong>;
};

const CollegeTranscriptUploader = ({ transcriptField, titleHeader = null, displayOnly = false, isRequired = false, ...props }) => {
  const methods = useFormContext({ mode: "onBlur" });
  // const { getValues, watch, setValue, register, unregister } = methods;
  const reduxFormState = useSelector((state) => state.form);
  return (

      <TranscriptUploader transcriptField={transcriptField}
        transcriptPathName={AnswerTypeTranscriptProps.SCHOOL_CODE}
        transcriptColumnName="College or University"
        titleHeader={titleHeader}
        isRequired={isRequired}
        displayOnly={displayOnly}
      >
        {(fieldName) => {
          const schoolCode = _get(
            reduxFormState.fields,
            fieldName.transcriptFieldName + '.value'
          );
          return <SchoolName schoolCode={schoolCode} />;
        }}
      </TranscriptUploader>
     
  );
};

export default CollegeTranscriptUploader;
