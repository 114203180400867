import ReadOnlyField from '../ReadOnlyField';
import { FIELDS } from '../../store/form/fields';
import { useEffect } from 'react';
import { actions } from '../../store';
import { useSelector } from 'react-redux';
import ConvertCode from '../Utility/ConvertCode';

export default function AppSnapshotPromise ({ year }) {
  const formState = useSelector((state) => state.form);
  
  const fieldKeys = [
    FIELDS.FIRST_NAME,
    FIELDS.LAST_NAME,
    FIELDS.OTHER_NAMES,
    FIELDS.BIRTHDATE,
    FIELDS.GENDER__FEMALE,
    FIELDS.GENDER__MALE,
    FIELDS.GENDER__TRANSMAN,
    FIELDS.GENDER__TRANSWOMAN,
    FIELDS.GENDER__NON_BINARY,
    FIELDS.GENDER__NA,
    FIELDS.ALTERNATE_PHONE,
    FIELDS.HOME_PHONE,
    FIELDS.PERMANENT_ADDRESS,
    FIELDS.MAILING_ADDRESS,
    FIELDS.SSN,
    FIELDS.HISPANIC,
    FIELDS.ETHNICITY__AM_INDIAN,
    FIELDS.ETHNICITY__ASIAN,
    FIELDS.ETHNICITY__BLACK,
    FIELDS.ETHNICITY__HAWAIIAN_PI,
    FIELDS.ETHNICITY__WHITE,
    FIELDS.ETHNICITY__NA,   
    FIELDS.HIGHSCHOOL,
    FIELDS.HIGHSCHOOL_TYPE,
    FIELDS.HIGHSCHOOL_GRAD_DATE,
    FIELDS.CORRECTIONAL_FACILITY_HS,
    FIELDS.HIGHSCHOOL_GPA,
    FIELDS.COMM_COLLEGE_START_TERM
];

useEffect(() => {
  actions.fetchBasicFields(fieldKeys, year);
},[]);

const get = (fieldKey) => formState.fields[fieldKey]?.value;

const getNames = (fieldKey) => {
    const stateObject = formState.fields[fieldKey];
    let string = '';
    for (const i in stateObject) {
        string += stateObject[i].FirstName.value+' '+stateObject[i].LastName.value+'\n'
    }
    return string;
};

const getAddress = (fieldKey) => {
    const street = formState.fields[fieldKey]?.Address.value;
    const city = formState.fields[fieldKey]?.CityId.value;
    const county = formState.fields[fieldKey]?.CountyId.value;
    const zip = formState.fields[fieldKey]?.Zip.value;

    return { street, city, county, zip};
};

const getSchoolCode = (fieldKey) => {
  return formState.fields[fieldKey]?.SchoolCode?.value;
};

  return (
    <>
      <ReadOnlyField 
        name="name" 
        value={get(FIELDS.FIRST_NAME)+' '+get(FIELDS.LAST_NAME)}
      />
      <ReadOnlyField 
        name="other names used on submitted documents" 
        value={getNames(FIELDS.OTHER_NAMES)}
      />
      <ReadOnlyField 
        name="Birthday" 
        value={get(FIELDS.BIRTHDATE)}
      />
      <ReadOnlyField 
        name="Gender identity"
        value={
          ( get(FIELDS.GENDER__MALE) === true ? 'Male\n' : '') + 
          ( get(FIELDS.GENDER__FEMALE) === true ? 'Female\n' : '') + 
          ( get(FIELDS.GENDER__TRANSMAN) === true ? 'Trans Male/Trans Man\n' : '') + 
          ( get(FIELDS.GENDER__TRANSWOMAN) === true ? 'Trans Female/Trans Woman\n' : '') + 
          ( get(FIELDS.GENDER__NON_BINARY) === true ? 'Non-binary/Genderqueer/Gender non-conforming\n' : '') + 
          ( get(FIELDS.GENDER__NA) === true ? 'Different Identity' : '')
        }
      />
      <ReadOnlyField 
        name="Phone number" 
        value={
          ( get(FIELDS.ALTERNATE_PHONE) ? get(FIELDS.ALTERNATE_PHONE)+' (Mobile)\n' : '') + 
          ( get(FIELDS.HOME_PHONE) ? get(FIELDS.HOME_PHONE)+' (Home)\n' : '')
        }
      />
      <ReadOnlyField 
        name="permanent address"
        value={
          <ConvertCode 
            isAddress={true} 
            address={getAddress(FIELDS.PERMANENT_ADDRESS)}
          />
        }
      />
      <ReadOnlyField 
        name="mailing address"
        value={
          <ConvertCode 
            isAddress={true} 
            address={getAddress(FIELDS.MAILING_ADDRESS)}
          />
        }
      />
      <ReadOnlyField 
        name="social security number"
        value={get(FIELDS.SSN) ? '*********' : ''} 
      />
      <ReadOnlyField 
        name="are you hispanic or latino/a/x"
        value={
          get(FIELDS.HISPANIC) === "HISPAN" ? 'Yes' : 
          get(FIELDS.HISPANIC) === "NONHISPAN" ? 'No' : 
          'Choose not to say'
        }
      />
      <ReadOnlyField 
        name="racial group or ethnicity"
        value={
          ( get(FIELDS.ETHNICITY__AM_INDIAN) === true ? 'American Indian or Alaska Native\n' : '' ) +
          ( get(FIELDS.ETHNICITY__ASIAN) === true ? 'Asian\n' : '' ) +
          ( get(FIELDS.ETHNICITY__BLACK) === true ? 'Black or African-American\n' : '' ) +
          ( get(FIELDS.ETHNICITY__HAWAIIAN_PI) === true ? 'Native Hawaiian or Pacific Islander\n' : '' ) +
          ( get(FIELDS.ETHNICITY__WHITE) === true ? 'White\n' : '' ) +
          ( get(FIELDS.ETHNICITY__NA) === true ? 'Choose not to say\n' : '' )
      }
      />
      <ReadOnlyField 
        name="type of high school or equivalent"
        value={<ConvertCode codeType="HSTYPE" code={get(FIELDS.HIGHSCHOOL_TYPE)}/>}
      />
      <ReadOnlyField 
        name="high school"
        value={<ConvertCode isHS={true} code={getSchoolCode(FIELDS.HIGHSCHOOL)}/>}
      />
      <ReadOnlyField 
        name="date of high school completion or graduation"
        value={get(FIELDS.HIGHSCHOOL_GRAD_DATE)}
      />
      <ReadOnlyField 
        name="community college start term"
        value={get(FIELDS.COMM_COLLEGE_START_TERM )}
      />
      <ReadOnlyField 
        name="Did you (or will you) obtain your high school diploma or GED® test transcript while in a correctional facility in Oregon?"
        value={
          ( get(FIELDS.CORRECTIONAL_FACILITY_HS) === true || get(FIELDS.CORRECTIONAL_FACILITY_HS) === "True" ) ? 'Yes' :
          ( get(FIELDS.CORRECTIONAL_FACILITY_HS) === false || get(FIELDS.CORRECTIONAL_FACILITY_HS) === "False" ) ? 'No' : ''
        }
      />
      <ReadOnlyField 
        name="high school gpa"
        value={get(FIELDS.HIGHSCHOOL_GPA)}
      />
      
    </>
  );
};