import * as msal from "@azure/msal-browser";
import store, { actions } from "./store";

const {
  REACT_APP_B2C_REGISTRATION_LOGIN_FLOW_NAME: B2C_REGISTRATION_LOGIN_FLOW_NAME,
  REACT_APP_B2C_TENANT: B2C_TENANT,
  REACT_APP_B2C_CLIENT_ID: B2C_CLIENT_ID,
  REACT_APP_B2C_CHANGE_EMAIL_FLOW_NAME: B2C_CHANGE_EMAIL_FLOW_NAME,
  REACT_APP_B2C_PASSWORD_CHANGE_FLOW_NAME: B2C_PASSWORD_CHANGE_FLOW_NAME,
} = process.env;

const policies = {
  names: {
    signUpSignIn: B2C_REGISTRATION_LOGIN_FLOW_NAME,
    //editProfile: "B2C_1_edit_profile_v2"
    changeEmail: B2C_CHANGE_EMAIL_FLOW_NAME,
    changePassword: B2C_PASSWORD_CHANGE_FLOW_NAME,
  },
  authorities: {
    signUpSignIn: {
      authority: `https://${B2C_TENANT}.b2clogin.com/${B2C_TENANT}.onmicrosoft.com/${B2C_REGISTRATION_LOGIN_FLOW_NAME}`,
    },
    changeEmail: {
      authority: `https://${B2C_TENANT}.b2clogin.com/${B2C_TENANT}.onmicrosoft.com/${B2C_CHANGE_EMAIL_FLOW_NAME}`,
    },
    changePassword: {
      authority: `https://${B2C_TENANT}.b2clogin.com/${B2C_TENANT}.onmicrosoft.com/${B2C_PASSWORD_CHANGE_FLOW_NAME}`,
    },
    /*editProfile: {
            authority: "https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/B2C_1_edit_profile_v2"
        }*/
  },
  authorityDomain: `${B2C_TENANT}.b2clogin.com`,
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 * For more details on using MSAL.js with Azure AD B2C, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/working-with-b2c.md
 */
const baseMsalConfig = {
  auth: {
    clientId: B2C_CLIENT_ID, // This is the ONLY mandatory field; everything else is optional.
    // Choose sign-up/sign-in user-flow as your default.
    knownAuthorities: [policies.authorityDomain], // You must identify your tenant's domain as a known authority.
    //redirectUri: "http://localhost:6420", // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case msal.LogLevel.Error:
            // console.error(message);
            return;
          case msal.LogLevel.Info:
            // console.info(message);
            return;
          case msal.LogLevel.Verbose:
            // console.debug(message);
            return;
          case msal.LogLevel.Warning:
            // console.warn(message);
            return;
        }
      },
    },
  },
};

const msalConfig = {
  ...baseMsalConfig,
  auth: {
    ...baseMsalConfig.auth,
    authority: policies.authorities.signUpSignIn.authority,
    postLogoutRedirectUri: window.location.origin,
  },
};

const msalConfigChangeEmail = {
  ...baseMsalConfig,
  auth: {
    ...baseMsalConfig.auth,
    authority: policies.authorities.changeEmail.authority,
    redirectUri: window.location.origin,
  },
};

const msalConfigChangePassword = {
  ...baseMsalConfig,
  auth: {
    ...baseMsalConfig.auth,
    authority: policies.authorities.changePassword.authority,
    redirectUri: window.location.origin,
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
const loginRequest = {
  scopes: ["openid", "profile"],
};

/**
 * Scopes you add here will be used to request a token from Azure AD B2C to be used for accessing a protected resource.
 * To learn more about how to work with scopes and resources, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
const tokenRequest = {
  scopes: [], // e.g. ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
  forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
};

const msalInstance = new msal.PublicClientApplication(msalConfig);
const msalInstanceChangeEmail = new msal.PublicClientApplication(
  msalConfigChangeEmail
);
const msalInstanceChangePassword = new msal.PublicClientApplication(
  msalConfigChangePassword
);

msalInstance
  .handleRedirectPromise()
  .then((tokenResponse) => {
    // console.log('handleRedirectPromise',{ tokenResponse });
    if (tokenResponse) {
      actions.login();
    }
    // Check if the tokenResponse is null
    // If the tokenResponse !== null, then you are coming back from a successful authentication redirect.
    // If the tokenResponse === null, you are not coming back from an auth redirect.
  })
  .catch((error) => {
    // console.log({error})
    console.error(error);
    // handle error, either in the library or coming back from the server
  });

const getToken = () => {
  return msalInstance
    .acquireTokenSilent(tokenRequest)
    .then((response) => {
      // In case the response from B2C server has an empty accessToken field
      // throw an error to initiate token acquisition
      if (!response.accessToken || response.accessToken === "") {
        throw new msal.InteractionRequiredAuthError();
      } else {
        // console.log("access_token acquired at: " + new Date().toString());
        //const accessToken = response.accessToken;
      }
    })
    .catch((error) => {
      // console.log("Silent token acquisition fails. Acquiring token using popup. \n", error);
      if (error instanceof msal.InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return msalInstance.acquireTokenRedirect(tokenRequest);
      } else {
        // console.log(error);
      }
    });
};

const login = async () => {
  console.log('login request', loginRequest)
  const res = await msalInstance.loginRedirect(loginRequest);
  console.log(res);
  return res;
};

const logout = () => {
  actions.logout();
  return msalInstance.logoutRedirect(loginRequest);
};

const changeEmail = () => {
  return msalInstanceChangeEmail.loginRedirect(loginRequest);
};

const changePassword = () => {
  return msalInstanceChangePassword.loginRedirect(loginRequest);
};

const getIdToken = () => {
  let idToken;
  Object.entries(localStorage).forEach(([key, value]) => {
    try {
      const credentials = JSON.parse(value);
      if (credentials.credentialType.toLowerCase() === "idtoken") {
        idToken = credentials.secret;
      }
    } catch (err) {}
  });
  return idToken;
};

const decodeToken = (idToken) => {
  let decodedToken;
  const payloads = (idToken || "").split(".");
  if (!payloads.length || !payloads[1]) {
    return null;
  }
  try {
    decodedToken = JSON.parse(Buffer.from(payloads[1], "base64").toString());
  } catch (err) {
    // suppress, just return undefined if cannot decode
  }
  return decodedToken;
};
const getUsername = () => {
  const idToken = getIdToken();
  const decodedToken = decodeToken(idToken);
  if (typeof decodedToken === "object" && decodedToken) {
    return decodedToken.oid;
  }
};

const getTokenExpiration = () => {
  const idToken = getIdToken();
  const decodedToken = decodeToken(idToken);
  if (typeof decodedToken === "object" && decodedToken) {
    //const timeEx = new Date(decodedToken.exp*1000);
    return decodedToken.exp*1000;
  }
};

const getUserEmail = () => {
  const idToken = getIdToken();
  const decodedToken = decodeToken(idToken);
  if (
    typeof decodedToken === "object" &&
    decodedToken &&
    Array.isArray(decodedToken.emails) &&
    decodedToken.emails.length
  ) {
    return decodedToken.emails[0];
  }
};

const isAuthenticated = () => {
  return !!getIdToken();
};

export default {
  login,
  logout,
  isAuthenticated,
  getUsername,
  getIdToken,
  getUserEmail,
  changeEmail,
  changePassword,
  getTokenExpiration,
};
