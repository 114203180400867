import { useEffect } from "react";
import { useState } from "react";
import apiClient from "../../../utils/API";

/**
 *
 * @param {*} param0
 */
function DegreeDisplay({ answer }) {

    const [degreeName, setDegreeName] = useState(null);

    useEffect(() => {
        if (!answer.CIPMajorId)
            return;
        (async () => {
            var degree = await apiClient.get(`/major/${answer.CIPMajorId}`);
            setDegreeName(degree.Title)

        })();
    }, []);
    return <>{degreeName}</>
}
export default DegreeDisplay;
