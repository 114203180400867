import { useFormContext } from "react-hook-form";
import { useState, useEffect, useRef } from "react";
import { compositeFieldname, getMimeInstance } from "../../utils";
import { AnswerTypeDocumentProps, DocumentDeliveryTypes } from "../../store/form/fields";
import apiClient from "../../utils/API";
import _get from "lodash/get";
import { FieldRow, FieldSlot } from "../Forms/FieldSection";
import HiddenInput from "../Utility/HiddenInput";
import Button from "./../../components/Button";
import { Delete } from "@material-ui/icons";

const ScholarshipUploader = ({
  name = "FundUploads",
  maxFileSizeInMb = 25,
  allowedFileExtensions = ["pdf"],
  isRequired = false,
  ...props
}) => {
  const { watch, errors } = useFormContext({ mode: "onBlur" });

  const hiddenFileInput = useRef();

  const [uploadError, setUploadError] = useState({
    message: "",
  });
  const [file, setFile] = useState(null);

  const triggerUpload = () => {
    hiddenFileInput.current.click();
  };

  const allowedMimes = allowedFileExtensions.map((ext) =>
    getMimeInstance().getType(ext)
  );

  const handleUpload = (event) => {
    if ([...event.target.files].length) {
      const fileUploaded = event.target.files[0];
      const sizeInMb = fileUploaded.size / 1024 / 1024 / 1.048576;
      const mime = fileUploaded.type;
      if (sizeInMb > maxFileSizeInMb) {
        setUploadError({
          message: `Exceeds maximum file size of ${maxFileSizeInMb} Mb`,
        });
      } else if (!allowedMimes.includes(mime)) {
        setUploadError({
          message: `Can only upload files of format: ${allowedFileExtensions.join(
            ", "
          )}`,
        });
      } else {
        setUploadError({ message: "" });
        setFile(fileUploaded);
      }
    }
  };

  const download = function (filename, blob) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, filename);
    } else {
      var elem = window.document.createElement("a");
      elem.href = window.URL.createObjectURL(blob);
      elem.download = filename;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    }
  };

  const fileId = watch(compositeFieldname(name, AnswerTypeDocumentProps.ID));
  useEffect(() => {
    const questionKey = name.replace(`_${DocumentDeliveryTypes.UPLOAD}`, "");
    (async () => {
      if (!fileId) return;
      const blob = await apiClient.getFileFromQuestionKey({
        id: fileId,
        questionKey,
      });
      if (blob.size) {
        const file = new File([blob], `${questionKey}.pdf`);
        setFile(file);
      }
    })();
  }, [fileId]);


  const handleDownload = (e) => {
    e.preventDefault();
    download(file.name, file);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    setFile(null);
  };

  const verifyFile = (file) => {
    if (isRequired && !file) {
      return false;
    }
    return true;
  };

  const error = _get(
    errors,
    compositeFieldname(name, AnswerTypeDocumentProps._FILE)
  );

  return (
    <FieldRow>
      <FieldSlot>
        {error && (
          <p style={{ color: "red", marginBottom: "10px" }}>
            * File Is Required
          </p>
        )}
        <HiddenInput
          rules={{ validate: (value) => verifyFile(value) }}
          name={compositeFieldname(name, AnswerTypeDocumentProps._FILE)}
          value={file}
        />
      </FieldSlot>
      <FieldSlot>
        <FieldSlot>
          {file && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <a href="#" onClick={handleDownload}>{file.name}</a>
              <a href="#"><Delete className="pointer" style={{ color: "rgba(255, 0, 0, 0.5)" }} onClick={handleDelete} /></a>
            </div>
          )}
          {!file && (
            <div>
              <input
                required={isRequired}
                ref={hiddenFileInput}
                type="file"
                onChange={handleUpload}
                style={{ display: "none" }}
              />
              <Button
                variant="text"
                label="Upload File"
                style={{ position: "relative", bottom: "-10px" }}
                onClick={triggerUpload}
              />
              {uploadError.message && (
                <div
                  style={{
                    color: "red",
                    marginTop: "0.5rem",
                  }}
                >
                  *{uploadError.message}
                </div>
              )}
            </div>
          )}
        </FieldSlot>
      </FieldSlot>
    </FieldRow>
  );
};

export default ScholarshipUploader;
