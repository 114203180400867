import React from 'react';
import InputDecorator from './InputDecorator';
import NumericFormat from './NumericFormat';

const rules = { 
  pattern: {
    value: /^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$/,
    message: "Invalid SSN"
  }
};

function SSNtext({ description = 'SSN', label, control, ...props }) {
  
  return (
  <NumericFormat 
    label={label}
    format='###-##-####'
    aria-label={description}
    {...props}
    />
  );
}

export default InputDecorator(SSNtext, rules);