import React, { Fragment } from "react";
import { useFormContext } from "react-hook-form";
import { repeatableFieldName } from '../Utility/RepeatableField';
import { FieldRow, FieldSlot } from '../Forms/FieldSection';
import Input from '../Input';
import MaskedDatePicker from '../MaskedDatePicker';
import HiddenInput from '../Utility/HiddenInput';
import NumericDecimal from '../NumericDecimal';
import Checkbox from '../Checkbox';
import { AnswerTypeChildProps } from '../../store/form/fields';
import AnswerLock from "./status/AnswerLock";

/**
 * prompt for a dependent child who needs care.
 * 
 * @param {*} param0 
 * @returns 
 */
function ChildAnswer({ answer, disabled }) {

    const methods = useFormContext({ mode: 'onBlur' });
    const { getValues } = methods;

    const i = answer.Index;
    const firstNameFieldName = repeatableFieldName(answer.FieldKey, i, AnswerTypeChildProps.FIRST_NAME);
    const middleInitialFieldName = repeatableFieldName(answer.FieldKey, i, AnswerTypeChildProps.MIDDLE_INITIAL);
    const lastNameFieldName = repeatableFieldName(answer.FieldKey, i, AnswerTypeChildProps.LAST_NAME);
    const birthdayFieldName = repeatableFieldName(answer.FieldKey, i, AnswerTypeChildProps.BIRTHDATE);
    const genderFieldName = repeatableFieldName(answer.FieldKey, i, AnswerTypeChildProps.GENDER);
    const citizenshipFieldName = repeatableFieldName(answer.FieldKey, i, AnswerTypeChildProps.CITIZENSHIP);
    const childCareFieldName = repeatableFieldName(answer.FieldKey, i, AnswerTypeChildProps.CHILD_CARE_NEEDED);
    const disabilityFieldName = repeatableFieldName(answer.FieldKey, i, AnswerTypeChildProps.EXTRA_CARE_NEEDED);
    const amountRequestedFieldName = repeatableFieldName(answer.FieldKey, i, AnswerTypeChildProps.AMOUNT);
    const idFieldName = repeatableFieldName(answer.FieldKey, i, AnswerTypeChildProps.ID);

    const isDisabled = disabled || answer.IsLocked;

    return (
        <Fragment key={answer.Id}>
            <FieldRow>
                <FieldSlot>
                    <HiddenInput name={idFieldName} />
                    <HiddenInput name={genderFieldName} value="X" />
                    <HiddenInput name={citizenshipFieldName} value="X" />
                    <Input disabled={isDisabled} 
                        name={firstNameFieldName} 
                        defaultValue={getValues(firstNameFieldName)} 
                        key={firstNameFieldName} 
                        inputLabel={<>First Name <AnswerLock answer={answer} /></>}
                        placeholder='First Name' 
                        variant='outlined' isRequired={true} />
                  
                </FieldSlot>
                <FieldSlot>
                    <Input disabled={isDisabled} name={middleInitialFieldName} defaultValue={getValues(middleInitialFieldName)} key={middleInitialFieldName} fullWidth={false} inputLabel='Middle Initial' variant='outlined' placeholder='Middle Initial' inputProps={{ maxLength: 1, size: 1 }} />
                </FieldSlot>
                <FieldSlot>
                    <Input disabled={isDisabled} name={lastNameFieldName} defaultValue={getValues(lastNameFieldName)} key={lastNameFieldName} inputLabel='Last Name' variant='outlined' placeholder='Last Name' isRequired={true} />
                </FieldSlot>
            </FieldRow>
            <FieldRow>
                <FieldSlot>
                    <MaskedDatePicker disabled={isDisabled} name={birthdayFieldName} defaultValue={getValues(birthdayFieldName)} key={birthdayFieldName} label='Birthday' variant='outlined' isRequired={true} />
                </FieldSlot>
            </FieldRow>
            <FieldRow>
                <Checkbox disabled={isDisabled} name={childCareFieldName} label="This child requires child care" />
            </FieldRow>
            <FieldRow >
                <Checkbox disabled={isDisabled} name={disabilityFieldName} label="This child requires extra care due to disability" />
            </FieldRow>
            <FieldRow>
                <div>Amount Requested for child care per month</div>
                <FieldSlot>
                    <NumericDecimal disabled={isDisabled} 
                        name={amountRequestedFieldName} 
                        defaultValue={getValues(amountRequestedFieldName)} 
                        key={amountRequestedFieldName} 
                        inputLabel='Child Care Request' 
                        placeholder='Child Care Request' precision={1} isRequired={true} />
                </FieldSlot>
            </FieldRow>
        </Fragment>
    );
}

export default ChildAnswer;