import ProgressBar from "../progress/ProgressBar";
import ProgressBarLabel from "../progress/ProgressBarLabel";

/**
 * 
 * @param {*} param0 
 * @returns 
 */
function ProgressHeader({sectionGroup, app, submitRef }) {

    const pecentComplete = sectionGroup.isComplete && app.isSubmitted ? 100 : Math.round(25 + 74 * sectionGroup.percentComplete / 100 + (app.isSubmitted ? 1 : 0));

    return (
        <div className="application-progress component">
            <div className="progress-bar rhythm">
                <h3 className="heading heading--h3">
                    Application Progress
                </h3>
            
                <ProgressBar progress={pecentComplete} />
               
                <div className="progress-bar__labels">
                    <ProgressBarLabel title="Profile" state="complete" />
                    {sectionGroup.sections.filter(x => x.title != "Transcripts").map(section =>
                        <ProgressBarLabel title={section.title} state={section.completionState} sectionRef={section.ref} />
                    )}
                    <ProgressBarLabel title="Submit" state={app.isSubmitted ? "complete" : "incomplete"} sectionRef={submitRef} />
                </div>
            </div>
        </div>
    );
}

export default ProgressHeader;