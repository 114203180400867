import React from 'react';
import Input from './Input';

const rules = {
  pattern: {
    value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    message: "invalid email"
  }
};

// Pass additional rules to be added to the rule set. 
// Note: If rule is passed in parent component it will overwrite local rules value.
function addValidationToRules (additionalRules){
  let rulesCopy = {...rules};
  if(additionalRules){
    Object.assign(rulesCopy, additionalRules);
  }
  return rulesCopy;
}

export default ({ ...props }) => <Input onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} rules={addValidationToRules(props?.addrules)} {...props}/>;
