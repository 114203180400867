import { useState } from "react";
import { FormProvider, useForm } from 'react-hook-form';
import Button from "../../components/Button";
import Input from "../../components/Input";
import { CustomCheckbox } from '../../components/Checkbox';
import apiClient from "../../utils/API";
import { goBack } from "../../utils";
import { useHistory } from "react-router-dom";


/**
     * decline process if this is an offer for an initial award 
     * @returns {Component}
     */
function DeclineAward({ code, year, onCancel }) {

    const history = useHistory();
    const methods = useForm({ mode: 'onBlur' });
    const { register, watch, getValues, handleSubmit } = methods;
 
    const [isCertified, setCertified] = useState(false);

    const toggleCertification = () => {
        setCertified(!isCertified);
        //return true;
    }


    /**
     * form submit handler
     */
    const onSubmit = async (data) => {      
        const reason = data["reason"];

        await apiClient.declineApplicationAward(code, year, reason);
        goBack({
            history,
            defaultRoute: "/dashboard"
        })
    }


    return (
        <FormProvider {...methods}>
            <form>

                <div className="wrapper rhythm">

                    <div className="form-wrapper">
                        <h2 className="heading heading--h2">Decline Award</h2>

                        <div>
                            <label>Reason for declining award (optional)</label>
                            <div style={{ marginBottom: '3em' }}>
                                <Input name="reason" multiline rows={5} fullWidth={true}  />                                
                            </div>
                        </div>

                        <p>By declining your award, you will allow OSAC the opportunity to offer the award to another applicant.</p>

                        <ul className="list">
                            <li className="li">I understand that I am declining the award for the {year} academic year.</li>
                            <li className="li">I understand that once declined, the award will be offered to another applicant.</li>
                        </ul>
                        <p style={{ marginTop: '2em;' }}>
                            <label>
                                <CustomCheckbox onClick={toggleCertification} />
                                I have read the above statements and understand and agree to them.
                                <span className="required"> *</span>
                            </label>

                        </p>

                        <div className="grant-action-buttons" >
                            <div>

                                <Button
                                    name="Decline"
                                    label='Decline Award'
                                    disabled={!isCertified}
                                    onClick={handleSubmit(onSubmit)}
                                />

                                {onCancel &&
                                    <Button
                                        name="Cancel"
                                        label="Cancel"
                                        variant="text"
                                        onClick={onCancel}
                                    />
                                }


                            </div>
                        </div>
                    </div>


                </div>
            </form>
        </FormProvider>
    );
}

export default DeclineAward;