import CodeDropdown from './CodeDropdown';

export const HIGHSCHOOL_CODE_TYPES = {
    PUBLIC_PRIVATE_TRADITIONAL: "TRD",
    COMMUNITY_COLLEGE_HS: "CC",
    ALTERNATIVE: "ALT",
    HOME_SCHOOL: "HMSCH",
    GED: "GED",
    GED_AND_HOME_SCHOOL: "GEDHM",
    NOT_STANDARD: "UNSTD"
}

export default ({ listItems, ...props}) => <CodeDropdown codeType="HSTYPE" {...props}/>


/*
FIELDS.HIGHSCHOOL_TYPE
[
  {
    "Code": "TRD",
    "CodeType": "HSTYPE",
    "Message": "Public/Private Traditional high school",
    "SortOrder": 1
  },
  {
    "Code": "CC",
    "CodeType": "HSTYPE",
    "Message": "Community college high school",
    "SortOrder": 2
  },
  {
    "Code": "ALT",
    "CodeType": "HSTYPE",
    "Message": "Alternative high school",
    "SortOrder": 3
  },
  {
    "Code": "HMSCH",
    "CodeType": "HSTYPE",
    "Message": "Home school",
    "SortOrder": 4
  },
  {
    "Code": "GED",
    "CodeType": "HSTYPE",
    "Message": "GED",
    "SortOrder": 5
  },
  {
    "Code": "GEDHM",
    "CodeType": "HSTYPE",
    "Message": "GED<sup style={{ verticalAlign: "super", fontSize: ".9rem"}}>&reg;</sup> and home school",
    "SortOrder": 6
  },
  {
    "Code": "UNSTD",
    "CodeType": "HSTYPE",
    "Message": "None of the standard choices",
    "SortOrder": 7
  }
]
*/
