import Button from "./../../components/Button";
import RadioGroup from "./../../components/RadioGroup";
import React, { useRef, useState } from "react";
import { repeatableFieldName } from "../Utility/RepeatableField";
import { Delete } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import { getMimeInstance, compositeFieldname } from "../../utils";
import { AnswerTypeTranscriptProps, TranscriptDeliveryTypes, TRANSCRIPT_QUESTIONS } from "../../store/form/fields";
import HiddenInput from "../Utility/HiddenInput";
import { useEffect } from "react";
import apiClient from "../../utils/API";
import _get from "lodash.get";
import AnswerLock from "./status/AnswerLock";
import TranscriptCoverPagePdf from './../PDF/TranscriptCoverPagePdf';

/**
 * prompt for transcript for a specific school. assumes one of each relevant delivery option
 * 
 * This component was designed to work in tandem with TranscriptQuestion
 * 
 * @param {boolean} param0.disabled
 * @param {Array} param0.schoolAnswers  all answers associated with this school
 * @param {TRANSCRIPT_QUESTIONS} param0.question  specific transcript question being prompted for here
 * @param {boolean} param0.isRequired
 * @param {number} param0.index unique index within this question, as determined by TranscriptQuestion
 * @param {Component} param0.formLink a custom link to present student with any associated form for this option
 * 
 * @returns {Component}
 */
function TranscriptAnswer({ disabled, schoolAnswers, question, isRequired, index, isReviewed = true, formLink, titleHeader }) {

    const allowedFileExtensions = ["pdf"];
    const maxFileSizeInMb = 25;
    const allowedMimes = allowedFileExtensions.map((ext) => getMimeInstance().getType(ext));
    const isHighSchool = question.level === "HighSchool";

    const methods = useForm({ mode: "onBlur", });
    const { setValue, handleSubmit, register, watch } = methods;

    const [uploadError, setUploadError] = useState(null);
    const hiddenFileInput = useRef();

    
    const uploadAnswer = schoolAnswers.find(x => x.DeliveryType == TranscriptDeliveryTypes.UPLOAD);
    uploadAnswer.FieldKey = question.uploadField;
    uploadAnswer.TypeLabel = "I will upload my document."

    const mailAnswer = schoolAnswers.find(x => x.DeliveryType == TranscriptDeliveryTypes.MAIL);
    mailAnswer.FieldKey = question.mailField;
    mailAnswer.TypeLabel = "I will be mailing or hand-delivering this document."

    const registrarAnswer = schoolAnswers.find(x => x.DeliveryType == TranscriptDeliveryTypes.REGISTRAR);
    if (registrarAnswer) {
        registrarAnswer.FieldKey = question.registrarField;
        registrarAnswer.TypeLabel = "My high school registrar will provide this information.";
    }

    const initiallySelectedAnswer = schoolAnswers.find(x => x.IsSelected) ?? null;

    const answerOptions = schoolAnswers.map((x) => {
        return {
            value: x.DeliveryType,
            label: x.TypeLabel,
        }
    });
    if (!registrarAnswer && question.registrarField)
        answerOptions.push({
            value: TranscriptDeliveryTypes.REGISTRAR,
            label: "My high school registrar will provide this information. (not currently available)",
            disabled: true
        });



    const deliveryFieldName = repeatableFieldName("DELIVERY_METHOD", index);

    function getFieldName(field, property) {
        if (isHighSchool)
            return compositeFieldname(field, property);
        else
            return repeatableFieldName(field, index, property);
    }

    const idUploadFieldName = getFieldName(question.uploadField, AnswerTypeTranscriptProps.ID);
    const digitalDeliveryMethod = getFieldName(question.uploadField, AnswerTypeTranscriptProps.DELIVERY_TYPE);
    const isDigitalDeliveryMethodSelected = getFieldName(question.uploadField, AnswerTypeTranscriptProps.IS_SELECTED);
    const isDigitalDeliveryMethodCertified = getFieldName(question.uploadField, AnswerTypeTranscriptProps.IS_CERTIFIED);
    const fileFieldName = getFieldName(question.uploadField, AnswerTypeTranscriptProps._FILE);
    // register(fileFieldName, {
    //     validate: (value) => !isRequired || value,
    // });

    const idMailFieldName = getFieldName(question.mailField, AnswerTypeTranscriptProps.ID);
    const mailDeliveryMethod = getFieldName(question.mailField, AnswerTypeTranscriptProps.DELIVERY_TYPE);
    const isMailDeliveryMethodSelected = getFieldName(question.mailField, AnswerTypeTranscriptProps.IS_SELECTED);
    const isMailDeliveryMethodCertified = getFieldName(question.mailField, AnswerTypeTranscriptProps.IS_CERTIFIED);

    const idRegistrarFieldName = getFieldName(question.registrarField, AnswerTypeTranscriptProps.ID);
    const registrarDeliveryMethod = getFieldName(question.registrarField, AnswerTypeTranscriptProps.DELIVERY_TYPE);
    const isRegistrarDeliveryMethodSelected = getFieldName(question.registrarField, AnswerTypeTranscriptProps.IS_SELECTED);
    const isRegistrarDeliveryMethodCertified = getFieldName(question.registrarField, AnswerTypeTranscriptProps.IS_CERTIFIED);

    const [schoolName, setSchoolName] = useState(null);
    /**
     * set school name
     */
    useEffect(async () => {

        if (uploadAnswer.Description) {
            setSchoolName(uploadAnswer.Description);
            return;
        }

        if (!uploadAnswer.SchoolCode) {
            setSchoolName("Unlisted");
        }
        else if (isHighSchool) {
            const school = await apiClient.getHighSchool(uploadAnswer.Qualifier);
            setSchoolName(school.Name);
        }
        else {
            const school = await apiClient.getCollege(uploadAnswer.Qualifier);
            setSchoolName(school.Name);
        }
    }, [uploadAnswer.SchoolCode]);

    const [fileInfo, setFileInfo] = useState(null);
    /**
     * initialize prompt for downloading previously uploaded file
     */
    useEffect(async () => {
        if (uploadAnswer?.IsFileAvailable) {
            const blob = await apiClient.getFileFromQuestionKey({
                questionKey: uploadAnswer.QuestionKey,
                id: uploadAnswer.Id,
                year: uploadAnswer.Year,
            });
            if (blob.size) {
                const file = new File([blob], `${uploadAnswer.QuestionKey}.pdf`);
                setFileInfo(file);
            }
        }
    }, [uploadAnswer?.Id]);

    function download(filename, blob) {
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, filename);
        } else {
            var elem = window.document.createElement("a");
            elem.href = window.URL.createObjectURL(blob);
            elem.download = filename;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
        }
    };

    const handleDownload = (e) => {
        e.preventDefault();
        download(fileInfo.name, fileInfo);
    };

    const handleDelete = (e) => {
        e.preventDefault();
        setFileInfo(null);
    };

    const handleUpload = (event) => {
        if ([...event.target.files].length) {
            const fileUploaded = event.target.files[0];
            const sizeInMb = fileUploaded.size / 1024 / 1024 / 1.048576;
            const mime = fileUploaded.type;
            if (sizeInMb > maxFileSizeInMb) {
                setUploadError(`Exceeds maximum file size of ${maxFileSizeInMb} Mb`);
            } else if (!allowedMimes.includes(mime)) {
                setUploadError(`Can only upload files of format: ${allowedFileExtensions.join(", ")}`);
            } else {
                setFileInfo(fileUploaded);
            }
        }
    };

    const triggerUpload = (e) => {
        e.preventDefault();
        setUploadError(null);
        hiddenFileInput.current.click();
    };

    //TODO: why isn't watch working by itself?
    const [selectedDeliveryType, setSelectedDeliveryType] = useState(initiallySelectedAnswer?.DeliveryType);
    const updateDeliveryType = (event) => {
        setSelectedDeliveryType(event.target.value);
    }
    const selectedAnswer = schoolAnswers.find(x => x.DeliveryType === selectedDeliveryType);

    const isDisabled = disabled || schoolAnswers.some(x => x.IsLocked);
    const reviewFeedbackStyle = uploadAnswer.ReviewStatus !== 'COMPLETE' && uploadAnswer.ReviewStatus !== 'PEND_OSAC' && uploadAnswer.ReviewStatus !== 'NA' ? { color: '#da3754' } : {};

    const filename = fileInfo?.name;

    return (
        <div className="table__body">

            <input required={isRequired} type="file"
                ref={hiddenFileInput}
                onChange={handleUpload}
                style={{ display: "none" }}
            />

            {/* Digital Transcript fields */}
            <HiddenInput name={idUploadFieldName} value={uploadAnswer.Id} />
            <HiddenInput name={fileFieldName} value={fileInfo} />
            <HiddenInput name={digitalDeliveryMethod} value={uploadAnswer.DeliveryType} />
            <HiddenInput name={isDigitalDeliveryMethodSelected} value={!!(selectedDeliveryType === TranscriptDeliveryTypes.UPLOAD)} />
            <HiddenInput name={isDigitalDeliveryMethodCertified} value={!!(selectedDeliveryType === TranscriptDeliveryTypes.UPLOAD)} />

            {/* Mail Transcript fields */}
            <HiddenInput name={idMailFieldName} value={mailAnswer.Id} />
            <HiddenInput name={mailDeliveryMethod} value={mailAnswer.DeliveryType} />
            <HiddenInput name={isMailDeliveryMethodSelected} value={!!(selectedDeliveryType === TranscriptDeliveryTypes.MAIL)} />
            <HiddenInput name={isMailDeliveryMethodCertified} value={!!(selectedDeliveryType === TranscriptDeliveryTypes.MAIL)} />

            {/* Registrar Transcript fields*/}
            {registrarAnswer &&
                <>
                    <HiddenInput name={idRegistrarFieldName} value={registrarAnswer.Id} />
                    <HiddenInput name={registrarDeliveryMethod} value={registrarAnswer.DeliveryType} />
                    <HiddenInput name={isRegistrarDeliveryMethodSelected} value={!!(selectedDeliveryType === TranscriptDeliveryTypes.REGISTRAR)} />
                    <HiddenInput name={isRegistrarDeliveryMethodCertified} value={!!(selectedDeliveryType === TranscriptDeliveryTypes.REGISTRAR)} />
                </>
            }

            <div>
                <span style={{ fontWeight: 'bold' }}>{schoolName}  {titleHeader ? '['+ titleHeader + ']' : ''}  </span>
                <AnswerLock answer={selectedAnswer} />
            </div>
            <div>
                {isReviewed &&
                    <>
                        <p className="table__cell" style={{ textAlign: 'left' }}>
                            <div dangerouslySetInnerHTML={{ __html: selectedAnswer?.ReviewStatusDescription }} style={reviewFeedbackStyle}></div>
                        </p>
                        <p className="table__cell" style={{ textAlign: 'left' }}>
                            <div dangerouslySetInnerHTML={{ __html: selectedAnswer?.ReviewInstructions }} style={reviewFeedbackStyle}></div>
                        </p>
                    </>
                }
            </div>
            <div>
                <RadioGroup
                    disabled={isDisabled}
                    name={deliveryFieldName}
                    key={deliveryFieldName}
                    onChange={updateDeliveryType}
                    defaultValue={initiallySelectedAnswer?.DeliveryType}
                    value={selectedDeliveryType}
                    style={{
                        fontSize: "14px",
                        position: "relative",
                        color: "#5f5f5f",
                    }}
                    listItems={answerOptions}
                />
            </div>

            <div style={{ marginTop: "2em", marginLeft: "2em" }} className="rhythm">


                {(() => {
                    switch (selectedDeliveryType) {
                        case TranscriptDeliveryTypes.UPLOAD:
                            return (
                                <div>
                                    {!filename &&
                                        <div>
                                            <Button
                                                style={{ display: "inline-block" }}
                                                disabled={isDisabled}
                                                variant="text"
                                                label="Upload File"
                                                onClick={triggerUpload}
                                            />

                                            <div style={{ fontSize: ".75rem", color: "red" }}>{uploadError}</div>
                                        </div>
                                    }
                                    {filename &&
                                        <>
                                            <a
                                                style={{
                                                    fontsize: "14px",
                                                    color: "#5f5f5f",
                                                    textDecoration: "underline",
                                                }}
                                                href="#"
                                                onClick={handleDownload}
                                            >
                                                {filename}
                                            </a>
                                            {!isDisabled &&
                                                <a
                                                    style={{
                                                        fontsize: "14px",
                                                        position: "relative",
                                                        left: "0.5rem",
                                                    }}
                                                    href="#"
                                                    onClick={handleDelete}
                                                >
                                                    <Delete className="pointer" style={{ color: "rgba(255, 0, 0, 0.5)" }} />
                                                </a>
                                            }

                                        </>
                                    }
                                </div>
                            )
                        case TranscriptDeliveryTypes.REGISTRAR:
                            return (
                                <div>
                                    {registrarAnswer.IsFileAvailable && <p>File Received</p>}
                                    {!registrarAnswer.IsFileAvailable &&
                                        formLink
                                    }
                                </div>
                            )
                        case TranscriptDeliveryTypes.MAIL:
                            return (
                                <div style={{ textAlign: 'left' }}>
                                    {mailAnswer.IsFileAvailable && <p>File Received</p>}
                                    {!mailAnswer.IsFileAvailable &&                                     
                                        <div>
                                            {question !== TRANSCRIPT_QUESTIONS.PROOF_OF_GPA && <TranscriptCoverPagePdf transcriptsMail={mailAnswer} isHS={question.mailField.includes("FALL_HS_TRANSCRIPTS_MAIL")} /> }
                                            <p>
                                                OSAC suggests mailing the transcripts signature required for
                                                confirmation of delivery. OSAC can not confirm delivery of
                                                transcripts. Postmarks will not be considered as the document
                                                delivery date.
                                            </p>
                                            <div>
                                                Mail documents with the cover letter to:
                                                <address style={{ marginLeft: "2em" }}>
                                                    <br />
                                                    Oregon Office of Student Access and Completion
                                                    <br />
                                                    3225 25th Street SE
                                                    <br />
                                                    Salem, OR 97302
                                                </address>

                                            </div>
                                        </div>
                                    }

                                    
                                </div>
                            )
                        default:
                            return null
                    }
                })()}
            </div>


        </div>
    );
}

export default TranscriptAnswer;
