import React, { useEffect } from 'react';
import _debounce from 'lodash.debounce'
import Modal from '../Alerts/Modal';
import { useState } from 'react';
import auth from '../../auth';


function ActivityListener({
    onInactivity
}){

    const href = window.location.href;

    const [state, setState] = useState({
        isModalOpen: false,
        timer: null
    })

    let secondsToTimeout = 60*60; // an hour    

    const handleClose = () => {
        setState({
            ...state,
            isModalOpen: false
        })
    } 

    // assuming onInactivity prop not changing dynamically

    
    useEffect(()=>{

        // do not register listeners if not listening on inactivity
        if(!onInactivity || typeof onInactivity !== 'function'){
            //return
        }

        let timer;

        if(timer) clearInterval(timer);

        const createTimer = () => setInterval(()=>{
            //onInactivity();
            if(!state.isModalOpen){
                setState({
                    ...state,
                    isModalOpen: true
                });
            }
        },1000*secondsToTimeout);

        const activityHandler = _debounce(()=>{
            if(timer){
                clearInterval(timer);
            }
            timer = createTimer();
        },100,{
            maxWait: 1000,
            leading: false,
            trailing: true
        });

        document.addEventListener('mousemove', activityHandler);
        document.addEventListener('scroll',activityHandler);
        document.addEventListener('keyup',activityHandler);

        timer = createTimer();

        return () => {
            document.removeEventListener('mousemove', activityHandler);
            document.removeEventListener('scroll',activityHandler);
            document.removeEventListener('keyup',activityHandler);
        };

    },[]);

  return (
      <>
        <Modal open={state.isModalOpen} handleClose={handleClose} title="You are about to be logged out due to inactivity. " body="Please acknowledge you are still here to be prevented from signing out."/>
      </>
  )
}

export default ActivityListener;