import { useSelector } from "react-redux";
import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import Button from "../../components/Button";
import PrintIcon from "../../components/PrintIcon";
import GrantsDisplay from "../../components/GrantsDisplay";
import PortalView from "./../../components/Global/PortalView";
import ScholarshipTabs from "../../components/Scholarships/ScholarshipTabs";
import { useEffect } from "react";
import apiClient from "../../utils/API";
import {enrichProgramAwardStatus, enrichProgramStatus, IsTabletOrMobile} from "../../utils";
import { useHistory } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { CODES } from "./../../components/Utility/ProgramCodes";
import maxBy from "lodash/maxBy";
import WarningIcon from "@material-ui/icons/WarningRounded";
import ProgramInfo from '../../utils/dtos/ProgramInfo';
import Spinner from './../../components/Utility/Spinner';
//#3893/SP-293 : Enable expand/collapsed view of Grants & Scholarships sections.  
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';


const GRANT_CODES = [
  CODES.OREGON_PROMISE,
  CODES.CHILD_CARE,
  CODES.CHAFEE,
  CODES.NATIONAL_GUARD,
  CODES.OTSG,
  // CODES.OTSP
];

function Applications() {
  const methods = useForm({
    mode: "onBlur",
  });

    // Start: #3893/SP-293 : Enable expand/collapsed view of Grants & Scholarships sections.
    const [expandedGrants, setExpandedGrants] = React.useState(false);

    const toggleExpandGrants = (e) =>{
      if(e) e.preventDefault();
      setExpandedGrants(!expandedGrants);
    }
  
    const [expandedSCH, setExpandedSCH] = React.useState(false);
  
    const toggleExpandSCH = (e) =>{
      if(e) e.preventDefault();
      setExpandedSCH(!expandedSCH);
    }
  // End: #3893/SP-293 : Enable expand/collapsed view of Grants & Scholarships sections. 

  const { errors, formState, getValues, setValue, handleSubmit } = methods;
  const authState = useSelector((state) => state.auth);
  const reduxFormState = useSelector((state) => state.form);


  const [profileComplete, setProfileComplete] = useState(true)
  const [grantsLoaded, setGrantsLoaded] = useState(false)


  const [scholarshipOverviewLoaded, setScholarshipOverviewLoaded] = useState(false)
  const [scholarshipOverviewData, setScholarshipOverviewData] = useState(null);
  const scholarshipOverview = new ProgramInfo(scholarshipOverviewData);




  const [listGrants, setGrants] = useState([
    {
      title: "Oregon Promise",
      code: CODES.OREGON_PROMISE,
      description:
        "For recent Oregon high school graduates and GED&reg; recipients who enroll in an Oregon community college within six months of graduation. All Oregon Promise applicants must also submit a FAFSA/ORSAA by the application deadline.",
      progress: 0,
      deadline: "May 2nd 2021",
      terms: "Fall, Winter, Spring 2022",
      awardAmount: 284,
    },
    {
      title: "Student Child Care Grant",
      code: CODES.CHILD_CARE,
      description: "",
      progress: 0,
      deadline: "May 2nd 2021",
      terms: "Fall, Winter, Spring 2022",
      awardAmount: null,
    },
    {
      title: "Chafee ETG",
      code: CODES.CHAFEE,
      description: "",
      progress: 0,
      deadline: "May 2nd 2021",
      terms: "Fall, Winter, Spring 2022",
      awardAmount: null,
    },
    {
      title: "Oregon National Guard Grant",
      code: CODES.NATIONAL_GUARD,
      description: "",
      progress: 0,
      deadline: "May 2nd 2021",
      terms: "Fall, Winter, Spring 2022",
      awardAmount: null,
    },
    {
      title: "Oregon Tribal Student Grant",
      code: CODES.OTSG,
      description: "",
      progress: 0,
      deadline: "May 2nd 2021",
      terms: "Fall, Winter, Spring 2022",
      awardAmount: null,
    },
    // {
    //   title: "Oregon Teacher Scholars Program",
    //   code: CODES.OTSP,
    //   description: "",
    //   progress: 0,
    //   deadline: "May 2nd 2021",
    //   terms: "Fall, Winter, Spring 2022",
    //   awardAmount: null,
    // },
  ]);


  /**
   * load first scholarships tab
   * @param {*} options 
   * @returns 
   */
  const getScholarships = async(options)=>{
    //setScholarships([]);
    const result = await apiClient.getScholarships(options, 0, scholarshipOverview.currentYear);
    const scholarships = result.scholarships
    //setScholarships(scholarships);
    const totalPages = scholarships.totalCount / PAGE_SIZE

    return { scholarships, totalPages }
  }

  function isGrant(code) {
    return GRANT_CODES.some(g => g === code);
  }

  const history = useHistory();

  // get data from API

  const [state, setState] = useState({
    totalScholarshipPages: 0,
    isScholarshipsLoading: false,
    isGrantStatusLoading: false,
    isScholarshipStatusLoading: false,
  });
  const [scholarships, setScholarships] = useState([]);
  const [schStatus, setSchStatus] = useState([]);
  const [tabData, setTabData] = useState([null,null,null,null,null]);
  const [totalPageCount, setTotalPageCount] = useState(0)
  // const [schAwardSrtatuses, setSchAwardStatuses] = useState([]);
  // const [schApplicationStatuses, setSchApplicationStatuses] = useState([]);

  const FilteringOptions = {
    MOST_RECENT: "Most Recent",
    HIDDEN: "Hidden",
  };

  const PAGE_SIZE = 10;

  const [filteringParams, setFilteringParams] = useState({
    searchQuery: null,
    filteringOption: FilteringOptions.MOST_RECENT,
    pageSize: PAGE_SIZE,
    page: 1,
    tab: 0,
  });

  const [debouncedFilteringParams] = useDebounce(filteringParams, 1000);

  const getScholarshipOverviewData = async() =>{

    const result = await apiClient.getProgram('SCH');
    setScholarshipOverviewData(result);
    setScholarshipOverviewLoaded(true);
  }
  /**
   * determine if schoalrship season is currently open, based on current enriched scholarship state
   * @returns {Boolean}
   */


  const getGrantData = async (data, e) => {
    // Get Scholarships, and fetch status of hardcoded Grant Programs
    setState({
      ...state,
      isGrantStatusLoading: true,
    });
    try {
      const requests = [];
      GRANT_CODES.map((code) => requests.push(apiClient.getProgram(code)));
      const programResults = await Promise.all(requests);

      const activeGrants = [];
      programResults.map((p) => {
        const activeSeasons = p.ActiveSeasons;
        if (activeSeasons) {
          const year = maxBy(activeSeasons, "Year").Year;
          if (year) {
            const temp = { year };
            const grant = listGrants.find((g) => g.code === p.Code);
            if (grant) {
              activeGrants.push(Object.assign(temp, grant));
            }
          }
        }
      });

      /*--- Grab the next year for Chafee to get foster care flag
      let chafeeNextYear = {
        code:"CH",
        year: activeGrants[2].year + 1
      };
      activeGrants.push(chafeeNextYear)
      */

      let [...newGrantList ] = await Promise.all([
        ...enrichProgramStatus(activeGrants, "Code"),
      ]);

      //setGrants(newGrantList);

      // Asynchronously get award information for scholarships & grants
      const [ grantListWithAwards ] = await Promise.all([
        Promise.all(enrichProgramAwardStatus(newGrantList, "Code")),
      ]);

      /*--- Get Foster care flag from "profile active year"
      if(!grantListWithAwards[2].statusState.IsRecommended){
        if(grantListWithAwards[2].statusState.IsStarted){
          grantListWithAwards[2].status = "inProgress";
        }
        else{
          grantListWithAwards[2].status = grantListWithAwards[5].status
        }
        grantListWithAwards[2].statusState.IsRecommended = grantListWithAwards[5].statusState.IsRecommended;
        grantListWithAwards[2].programStatusResult.IsRecommended = grantListWithAwards[5].programStatusResult.IsRecommended;
      }
      grantListWithAwards.pop();
      */

      setGrants(grantListWithAwards);
      setGrantsLoaded(true)
    } catch (err) {
      console.error(err);
      setState({
        ...state,
        isGrantStatusLoading: false,
      });
    }
  };

  const getScholarshipData = async (e) => {
    // only get results if this is a search tab, or if the requested tab data is empty
    if(!tabData[debouncedFilteringParams.tab] || debouncedFilteringParams.tab > 2 ) {
      if(debouncedFilteringParams.tab === 0 || debouncedFilteringParams.tab === 1)
      {
        // Scholarship information is available on page load use it for both tab-0 (Selected Scholarships) & tab-1 (Scholarship Renewals).        
        let tmpArray = [...tabData];  
        tmpArray[0] = scholarships.filter(schCard => schCard?.programStatusResult?.Source !== 'Renew' ); ;
        tmpArray[1] = scholarships.filter(schCard => schCard?.programStatusResult?.Source === 'Renew' );         
        setTabData(tmpArray); 
        setTotalPageCount (Math.ceil(Number(tmpArray[1].length)/PAGE_SIZE)); 
      }
      else{        
      setState({
        ...state,
        isScholarshipsLoading: true,
      });
      try {
        let [
          { scholarships: newScholarships, totalPages }
        ] = await Promise.all([
          apiClient.getScholarships(
            {
              "phrases": debouncedFilteringParams.searchQuery,
              "currentPage": debouncedFilteringParams.page,
            },
            debouncedFilteringParams.tab,
            scholarshipOverview.currentYear
          ),
        ]);
        setTotalPageCount(totalPages)

        //setScholarships(newScholarships);
        setState((state) => ({
          ...state,
          totalScholarshipPages: totalPages,
        }));

        // Asynchronously get status information for scholarships
        newScholarships = await Promise.all(
          enrichProgramStatus(newScholarships, "Code")
        );

        //setScholarships(newScholarships);

        // Asynchronously get award information for scholarships & grants
        const [
          scholarshipsWithAwards,
        ] = await Promise.all([
          Promise.all(enrichProgramAwardStatus(newScholarships, "Code")),
        ]);

        setScholarships(scholarshipsWithAwards);

        let tmpArray = [...tabData]
        tmpArray[debouncedFilteringParams.tab] = scholarshipsWithAwards
        setTabData(tmpArray)

        // get the parent SCH status
        const schStatusResult = await apiClient.getApplication('SCH',  scholarshipOverview.currentYear)
        setSchStatus(schStatusResult)
        setState({
          ...state,
          isScholarshipsLoading: false,
        });

      } catch (err) {
        console.error(err);
        setState({
          ...state,
          isScholarshipsLoading: false,
        });
      }
    } // tab === 0 || tab === 1 ending.
    } //tab > 2 ending.
  };


  useEffect(() => {
    (async () => {
      if (!grantsLoaded) { // only run once
        await getGrantData();
      }
      if (!scholarshipOverviewLoaded){
        await getScholarshipOverviewData();
      }
      await getScholarshipData()

    })();
  }, [debouncedFilteringParams, scholarshipOverview.currentYear]);


  useEffect(() => {
    (async () => {
      setState({
        ...state,
        isScholarshipsLoading: true,
        isGrantStatusLoading: true,
        scholarships: []
      })
      try {
        // // console.log("Get scholarships", )
        const requests = []
        GRANT_CODES.map(code => requests.push(apiClient.getProgram(code)))
        const programResults = await Promise.all(requests)
        // // console.log("Program Results", programResults)
        const activeGrants = []
        programResults.map(p => {
          const activeSeasons = p.ActiveSeasons;
          if (activeSeasons) {
            const year = maxBy(activeSeasons, "Year").Year
            if (year) {
              const temp = { year }
              // const grant = listGrants.find(g => g.code === p.Code)
              if (isGrant(p.Code)) {
                activeGrants.push(Object.assign(temp, p))
              }
            }
          }
        })

        /*--- Grab the next year for Chafee to get foster care flag
        let chafeeNextYear = {
          code:"CH",
          year: activeGrants[2].year + 1
        };
        activeGrants.push(chafeeNextYear)
        */

        const [{ totalPages, scholarships }, ...newGrants] = await Promise.all([getScholarships(),...enrichProgramStatus(activeGrants)]);

        //setGrants(newGrants);

        // Enrich scholarships with status
        const newScholarships = await Promise.all(enrichProgramStatus(scholarships));
        //setScholarships(newScholarships);

        // Asynchronously get award information for scholarships & grants
        const [grantListWithAwards, scholarshipsWithAwards] = await Promise.all([
          Promise.all(enrichProgramAwardStatus(newGrants, 'Code')),
          Promise.all(enrichProgramAwardStatus(newScholarships, 'Code'))
        ])

        /*--- Get Foster care flag from "profile active year"
        if(!grantListWithAwards[2].statusState.IsRecommended){
          if(grantListWithAwards[2].statusState.IsStarted){
            grantListWithAwards[2].status = "inProgress";
          }
          else{
            grantListWithAwards[2].status = grantListWithAwards[5].status
          }
          grantListWithAwards[2].statusState.IsRecommended = grantListWithAwards[5].statusState.IsRecommended;
          grantListWithAwards[2].programStatusResult.IsRecommended = grantListWithAwards[5].programStatusResult.IsRecommended;
        }
        grantListWithAwards.pop();
        */ 

        setGrants(grantListWithAwards);
        setScholarships(scholarshipsWithAwards);

        // Scholarship information is available on page load use it for both tab-0 (Selected Scholarships) & tab-1 (Scholarship Renewals).        
        let tmpArray = [...tabData];  
        tmpArray[0] = scholarshipsWithAwards.filter(schCard => schCard?.programStatusResult?.Source !== 'Renew' ); 
        tmpArray[1] = scholarshipsWithAwards.filter(schCard => schCard?.programStatusResult?.Source === 'Renew' );         
        setTabData(tmpArray);
        setTotalPageCount (Math.ceil(Number(tmpArray[1].length)/PAGE_SIZE));        

      } catch (err) {
        console.error(err);
      } finally {
        setState({
          ...state,
          isScholarshipsLoading: false,
          isGrantStatusLoading: false
        })
      }
    })();
  }, [scholarshipOverview.currentYear]);

  const goToProfile = async (data, e) => {
    document.location.href = '/profile'
  }

  const handleSearch = (searchQuery, tab) => {
    setFilteringParams({
      ...filteringParams,
      searchQuery,
      tab,
    });
  };

  const handlePageChange = (page) => {
    setFilteringParams({
      ...filteringParams,
      page,
    });
  };

  const scholarshipOptions = {
    totalPages: totalPageCount,
    // listScholarships: scholarships,
    tabData,
    filteringParams,
    onSearch: handleSearch,
    onPageChange: handlePageChange,
    onSelect: (scholarship) => {
      history.push({
        pathname: `/scholarship/${scholarship.code}`,
        state: {
          from: history.location.pathname,
        },
      });
    },
  };

  const onScholarShipChange = (number) => {
    console.log("Scholarship change requested", number);
    setFilteringParams({
      ...filteringParams,
      tab: number,
    });
  };

  const isTabletOrMobile = IsTabletOrMobile();

  const handleStartScholarship = () => {
    history.push({ pathname: "/additional-scholarship-info" });
  };

    // Open 'step-by-step-guide-for-the-osac-sch-app.pdf' document in a new tab.
    const handleStudentGuidePdf = () => {
      window.open("https://oregonstudentaid.gov/media/tejbilku/step-by-step-guide-for-the-osac-sch-app.pdf");      
  };


  return (
    <FormProvider {...methods}>
      <PortalView>
        <div name="ApplicationsPage" className="applications-page">

          <div className="page-intro">
            <div className="wrapper">
              <div className="page-intro__layout">
                <div className="page-intro__icon">
                  <img src="/MyApplictionsIcon.svg" alt="Applications icon" style={{ marginTop: ".5rem" }} />
                </div>
                <div className="page-intro__content rhythm">
                  <h1 className="heading heading--h1">
                    My Applications
                    <PrintIcon />
                  </h1>
                  <a href="/profile">Return to Profile</a>
                </div>
              </div>
            </div>
          </div>
          {!profileComplete &&
            <div className="notifications component rhythm">
              <div className="wrapper rhythm">
                <div className="notifications__content">
                  <div className="notification">
                    <div className="notification__layout">
                      <div>
                        <div><WarningIcon sx={{ color: "#da3754" }} /></div>
                      </div>
                      <div className="rhythm">
                        <h4 className="heading heading--h5 notification__title">Please Complete Your Profile</h4>
                        <div className="notification__message rhythm">
                          <p>In order to apply for grants and scholarships, your profile questions need to be completed.</p>
                          <Button name="getStarted" label="Get Started" type="button" onClick={goToProfile} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          {/* Start: #3893/SP-293 : Enable expand/collapsed view of Grants & Scholarships sections. */}           
          <div className="component" style= {{padding: '50px 0px 40px 140px'}} >To access <b>Grants</b> and <b>Scholarships</b>, please click below (expand or collapse the sections as needed). <br></br>For helpful tips on applying for Scholarships, view our <a href='#' onClick={handleStudentGuidePdf}><b>Guide for Students Step-by-Step and Helpful Tips</b></a>.</div>
          <div>
            {profileComplete &&
              <div name="Grants" className="wrapper wrapper--narrow">
                <Accordion square expanded={expandedGrants}>
                  <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" className="component">
                    <div className="grants-display__layout">
                      <h3 className="heading heading--h3 pointer" onClick={toggleExpandGrants}>
                        {expandedGrants ? <ArrowDropDownIcon style={{color: '#0e95bc'}}/> : <ArrowRightIcon style={{color: '#0e95bc'}}/>}
                          Grants
                      </h3>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div name="GrantsDisplay" className="grants-list">
                        {listGrants.map((item, i) => (
                          <GrantsDisplay key={i} details={item}/>
                        ))}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            }
          </div>
          <div>
            {profileComplete &&
              <div name="Scholarships" className="wrapper wrapper--narrow">
                <Accordion square expanded={expandedSCH}>
                  <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" className="component">
                    <div className="grants-display__layout">
                      <h3 className="heading heading--h3 pointer" onClick={toggleExpandSCH}>
                        {expandedSCH ? <ArrowDropDownIcon style={{color: '#0e95bc'}}/> : <ArrowRightIcon style={{color: '#0e95bc'}}/>}
                        Scholarships
                      </h3>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      {!scholarshipOverviewLoaded ?
                          <Spinner/>
                        : scholarshipOverview.isStarted ?
                        <Button onClick={handleStartScholarship} label={ schStatus ? 'Edit' : 'Start' } >{ schStatus ? 'Edit' : 'Start' }</Button>
                        : <p>
                              Scholarships is currently closed. Come back on {scholarshipOverview.startEvent?.displayDate} to see our {scholarshipOverview.currentYear} catalog
                          </p>
                      }                  
                      {!scholarshipOverviewLoaded ?
                        <></>
                      : scholarshipOverview.isStarted ?
                        <ScholarshipTabs
                          onTabChange={onScholarShipChange}
                          {...scholarshipOptions}
                          isLoading={state.isScholarshipsLoading}
                        />
                      : <></>
                      }
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            }
          </div>   {/* End: #3893/SP-293 : Enable expand/collapsed view of Grants & Scholarships sections. */}   
        </div>
      </PortalView>
    </FormProvider>
  );
}

export default Applications;
