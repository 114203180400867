import React from 'react';
import TextField from '@material-ui/core/TextField';
import { InputLabel } from '@material-ui/core';
import InputDecorator from './InputDecorator';
import { useFormContext } from 'react-hook-form';
import _get from 'lodash.get';

export function Input({ label, inputLabel, inputProps, description, rules, isRequired = false, isSoftRequired = false, name, defaultValue, HelperTextComponent, placeholder,...props }) {

  const { errors } = useFormContext({ mode: 'onBlur' });
  const error = _get(errors,name);

  return (
    <div className="input-wrapper">
      <InputLabel>
        {inputLabel}
        { // required: show red * and prevent user from saving if null
          isRequired && ( <span className="required"> *</span> ) }
        { // isSoftRequired: show red * but do not prevent user from saving if null
          isSoftRequired && !isRequired
                        ? ( <span className="required"> *</span> ) 
                        : null }
      </InputLabel>

      <TextField
        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
        fullWidth={true}
        placeholder={placeholder ?? inputLabel}
        error={!!error}
        aria-label={description || name}
        inputProps={inputProps}
        {...props}
      />
      {
        error?.message && (
          <div style={{ color: 'red', fontSize: '0.875rem' }}>
            {error?.message}
          </div>
        )
      }
      {
        HelperTextComponent && (
          <div>
            {HelperTextComponent}
          </div>
        )
      }
    </div>
  );
}

export default InputDecorator(Input)
