import { getCurrentYear } from "../../utils";
import {
    FIELDS,
    REPEATABLE_FIELDS,
    AnswerTypeAddressProps,
    AddressFieldType,
    AnswerTypeNameProps,
    DateFieldType,
    AnswerTypeContactProps,
    ContactFieldType,
    CollegeFieldType,
    AnswerTypeCollegeProps,
    IntegerFieldType,
    QuestionKeyMapping,
    BooleanFieldType,
    NameFieldType,
    ActivityFieldType,
    AnswerTypeActivityProps,
    ActivityTypeCodes,
    TranscriptTypeCodes,
    TranscriptFieldType,
    AnswerTypeTranscriptProps,
    TranscriptDeliveryTypes,
    HighSchoolFieldType,
    MembershipFieldType,
    DocumentFieldType,
    AnswerTypeDocumentProps,
    DocumentDeliveryTypes,
    TextFieldType

} from "./fields";

import auth from './../../auth';
import xss from 'xss';
import moment from "moment";

const projectCompositeFieldValues = (obj) => {
    return Object.entries(obj).reduce((newObj, [key, value]) => {

        // console.log(`${key}=${value}`)
        if (key.startsWith('_')) {
            // if key starts with _ it is a private field not to be sent to API
            return newObj;
        }
        let newValue = value;
        if (typeof newValue === 'object') {
            // serialize searchable dropdown data structure of { value, label } -> value
            newValue = value?.value;
        }
        if (key === AnswerTypeContactProps.PHONE) {
            newValue = (newValue || '').replace(/[\(,\)-\s]/ig, '')
        }
        newObj[key] = newValue;
        return newObj;
    }, {})
}

export default class FormSerializer {

    static getQuestionKey(fieldKey) {
        return QuestionKeyMapping[fieldKey]?.QuestionKey || fieldKey;
    }

    static getQuestionKeyLowerCase(fieldKey) {
        return FormSerializer.getQuestionKey(fieldKey).toLowerCase();
    }

    static parseBulkAnswers(answers) {
        return answers.reduce((fieldDict, answerBody) => {

            const answerType = answerBody?.AnswerType;
            let fieldKey;
            // some cases of non-unique mappings between field <-> question key
            if (answerType === ActivityFieldType.AnswerType) {
                switch (answerBody[AnswerTypeActivityProps.TYPE]) {
                    case ActivityTypeCodes.SCHOOL:
                        fieldKey = FIELDS.ACTIVITY_SCHOOL
                        break;
                    case ActivityTypeCodes.WORK:
                        fieldKey = FIELDS.ACTIVITY_WORK
                        break;
                    case ActivityTypeCodes.VOLUNTEER:
                        fieldKey = FIELDS.ACTIVITY_VOLUNTEER
                        break;
                    default:
                        break;
                }
            }
            else if (answerType === TranscriptFieldType.AnswerType) {
                // console.log('parsebulkanswers tx field type start')
                let qk = answerBody?.QuestionKey.toLowerCase();
                // console.log('parsebulkanswers tx field type delivery mode', answerBody[AnswerTypeTranscriptProps.DELIVERY_TYPE])
                switch (answerBody[AnswerTypeTranscriptProps.DELIVERY_TYPE]) {
                    case TranscriptDeliveryTypes.MAIL:
                        switch (qk) {
                            case FormSerializer.getQuestionKeyLowerCase(FIELDS.FALL_HS_TRANSCRIPTS_MAIL):
                                fieldKey = FIELDS.FALL_HS_TRANSCRIPTS_MAIL;
                                break;
                            case FormSerializer.getQuestionKeyLowerCase(FIELDS.FALL_COLLEGE_TRANSCRIPTS_MAIL):
                                fieldKey = FIELDS.FALL_COLLEGE_TRANSCRIPTS_MAIL;
                                break;
                            case FormSerializer.getQuestionKeyLowerCase(FIELDS.FALL_COLLEGE_TRANSCRIPTS_DURING_HS_MAIL):
                                fieldKey = FIELDS.FALL_COLLEGE_TRANSCRIPTS_DURING_HS_MAIL;
                                break;
                            case FormSerializer.getQuestionKeyLowerCase(FIELDS.FALL_COLLEGE_TRANSCRIPTS_PRIOR_MAIL):
                                fieldKey = FIELDS.FALL_COLLEGE_TRANSCRIPTS_PRIOR_MAIL;
                                break;
                            case FormSerializer.getQuestionKeyLowerCase(FIELDS.FALL_COLLEGE_TRANSCRIPTS_RECENT_MAIL):
                                fieldKey = FIELDS.FALL_COLLEGE_TRANSCRIPTS_RECENT_MAIL;
                                break;
                            case FormSerializer.getQuestionKeyLowerCase(FIELDS.PROOF_GPA__MAIL):
                                fieldKey = FIELDS.PROOF_GPA__MAIL;
                                break;
                            case FormSerializer.getQuestionKeyLowerCase(FIELDS.SPRING_COLLEGE_TRANSCRIPTS_MAIL):
                                fieldKey = FIELDS.SPRING_COLLEGE_TRANSCRIPTS_MAIL;
                                break;
                            case FormSerializer.getQuestionKeyLowerCase(FIELDS.SPRING_HS_TRANSCRIPTS_MAIL):
                                fieldKey = FIELDS.SPRING_HS_TRANSCRIPTS_MAIL;
                                break;
                            default:
                                break;
                        }
                        break;
                    case TranscriptDeliveryTypes.REGISTRAR:
                        switch (qk) {
                            case FormSerializer.getQuestionKeyLowerCase(FIELDS.FALL_HS_TRANSCRIPTS_REGISTRAR):
                                fieldKey = FIELDS.FALL_HS_TRANSCRIPTS_REGISTRAR
                                break;                     
                            case FormSerializer.getQuestionKeyLowerCase(FIELDS.PROOF_GPA__REGISTRAR):
                                fieldKey = FIELDS.PROOF_GPA__REGISTRAR;
                                break;
                            default:
                                break;
                        }
                        break;
                    case TranscriptDeliveryTypes.UPLOAD:
                        switch (qk) {
                            case FormSerializer.getQuestionKeyLowerCase(FIELDS.FALL_HS_TRANSCRIPTS_DIGITAL):
                                fieldKey = FIELDS.FALL_HS_TRANSCRIPTS_DIGITAL
                                break;
                            case FormSerializer.getQuestionKeyLowerCase(FIELDS.FALL_COLLEGE_TRANSCRIPTS_DIGITAL):
                                fieldKey = FIELDS.FALL_COLLEGE_TRANSCRIPTS_DIGITAL;
                                break;
                            case FormSerializer.getQuestionKeyLowerCase(FIELDS.FALL_COLLEGE_TRANSCRIPTS_DURING_HS_DIGITAL):
                                fieldKey = FIELDS.FALL_COLLEGE_TRANSCRIPTS_DURING_HS_DIGITAL;
                                break;
                            case FormSerializer.getQuestionKeyLowerCase(FIELDS.FALL_COLLEGE_TRANSCRIPTS_PRIOR_DIGITAL):
                                fieldKey = FIELDS.FALL_COLLEGE_TRANSCRIPTS_PRIOR_DIGITAL;
                                break;
                            case FormSerializer.getQuestionKeyLowerCase(FIELDS.FALL_COLLEGE_TRANSCRIPTS_RECENT_DIGITAL):
                                fieldKey = FIELDS.FALL_COLLEGE_TRANSCRIPTS_RECENT_DIGITAL;
                                break;
                            case FormSerializer.getQuestionKeyLowerCase(FIELDS.PROOF_GPA__UPLOAD):
                                fieldKey = FIELDS.PROOF_GPA__UPLOAD;
                                break;
                            case FormSerializer.getQuestionKeyLowerCase(FIELDS.SPRING_HS_TRANSCRIPTS_DIGITAL):
                                fieldKey = FIELDS.SPRING_HS_TRANSCRIPTS_DIGITAL
                                break;
                            case FormSerializer.getQuestionKeyLowerCase(FIELDS.SPRING_COLLEGE_TRANSCRIPTS_DIGITAL):
                                fieldKey = FIELDS.SPRING_COLLEGE_TRANSCRIPTS_DIGITAL;
                                break;
                            default:
                                break;
                        }
                        break;
                    default:
                        break;
                }
            }
            else if (answerType === DocumentFieldType.AnswerType) {
                let qk = answerBody?.QuestionKey.toLowerCase();
                switch (answerBody[AnswerTypeDocumentProps.DELIVERY_TYPE]) {
                    case DocumentDeliveryTypes.MAIL:
                        switch (qk) {
                            case FormSerializer.getQuestionKeyLowerCase(FIELDS.CHILD_CARE_EXPENSES_MAIL):
                                fieldKey = FIELDS.CHILD_CARE_EXPENSES_MAIL;
                                break;
                            default:
                                fieldKey = answerBody?.QuestionKey + "_" + DocumentDeliveryTypes.MAIL;
                                break;
                        }
                        break;
                    case DocumentDeliveryTypes.UPLOAD:
                        switch (qk) {
                            case FormSerializer.getQuestionKeyLowerCase(FIELDS.CHILD_CARE_EXPENSES_DIGITAL):
                                fieldKey = FIELDS.CHILD_CARE_EXPENSES_DIGITAL
                                break;
                            default:
                                fieldKey = answerBody?.QuestionKey + "_" + DocumentDeliveryTypes.UPLOAD;
                                break;
                        }
                        break;
                    default:
                        break;
                }
            }
            else {
                fieldKey = FormSerializer.getFieldKey(answerBody?.QuestionKey);
            }

            // If we cannot map to field key from question key this indicates the question key might not be hardcoded, which is the case for scholarship question keys, so the field key becomes equivalent to question key in this case
            if (!fieldKey) {
                fieldKey = answerBody?.QuestionKey;
                // console.log(fieldKey)
            }

            let value = answerBody?.Response;

            // todo add proper format for parsing
            if ([FIELDS.BIRTHDATE].includes(fieldKey)) {
                value = moment(value).format('YYYY-MM-DD');
            }

            // console.log('parse bulk answers for '. fieldKey)
            if (FormSerializer.getAnswerType(fieldKey) === BooleanFieldType.AnswerType) {
                if (typeof value === 'string') {
                    value = value === "False" ? false : true;
                }
                // disabled else field because radio buttons need null values to be empty
                // else {
                //     value = !!value;
                // }
            }

            if ([FIELDS.OTHER_NAMES, 
                 FIELDS.ACTIVITY_VOLUNTEER, 
                 FIELDS.ACTIVITY_WORK, 
                 FIELDS.ACTIVITY_SCHOOL, 
                 FIELDS.DEGREES, 
                 FIELDS.PAST_COLLEGE, 
                 FIELDS.AGE_CHILD, 
                 FIELDS.CHILDREN, 
                 FIELDS.FALL_COLLEGE_TRANSCRIPTS_DIGITAL, 
                 FIELDS.FALL_COLLEGE_TRANSCRIPTS_MAIL, 
                 FIELDS.FALL_COLLEGE_TRANSCRIPTS_DURING_HS_DIGITAL, 
                 FIELDS.FALL_COLLEGE_TRANSCRIPTS_DURING_HS_MAIL, 
                 FIELDS.FALL_COLLEGE_TRANSCRIPTS_RECENT_DIGITAL, 
                 FIELDS.FALL_COLLEGE_TRANSCRIPTS_RECENT_MAIL, 
                 FIELDS.FALL_COLLEGE_TRANSCRIPTS_PRIOR_DIGITAL, 
                 FIELDS.FALL_COLLEGE_TRANSCRIPTS_PRIOR_MAIL, 
                 FIELDS.CHILD_CARE_EXPENSES_DIGITAL, 
                 FIELDS.CHILD_CARE_EXPENSES_MAIL, 
                 FIELDS.SPRING_COLLEGE_TRANSCRIPTS_DIGITAL, 
                 FIELDS.SPRING_COLLEGE_TRANSCRIPTS_MAIL
                ].includes(fieldKey)) {
                const id = answerBody?.Id;
                if (!Array.isArray(fieldDict[fieldKey])) {
                    fieldDict[fieldKey] = [];
                }
                let answer = { id };
                Object.entries(answerBody).forEach(([compositePropKey, propValue]) => {
                    if ([ContactFieldType.Props.CITY, ContactFieldType.Props.STATE, ContactFieldType.Props.COUNTY].includes(compositePropKey)) {
                        propValue = Number(propValue)
                    }
                    answer[compositePropKey] = {
                        value: propValue
                    }
                });
                fieldDict[fieldKey].push(answer)
            }
            // parse non-repeatable composite fields
            else if ([
                CollegeFieldType.AnswerType,
                ContactFieldType.AnswerType,
                MembershipFieldType.AnswerType,
                TranscriptFieldType.AnswerType,
                AddressFieldType.AnswerType,
                HighSchoolFieldType.AnswerType,
                DocumentFieldType.AnswerType
            ].includes(answerType)) {
                if (!fieldDict[fieldKey]) {
                    fieldDict[fieldKey] = {};
                }
                Object.entries(answerBody).forEach(([propKey, propValue]) => {
                    fieldDict[fieldKey][propKey] = {
                        value: propValue
                    }
                });
            }
            else {
                fieldDict[fieldKey] = {
                    value
                }
            }

            return fieldDict;

        }, {});
    }

    static getFieldKey(questionKey) {
        // todo(Alejandro): create service worker to create dictionary mapping on app lifecycle start
        const result = Object.entries(QuestionKeyMapping)
            .find(([fieldKey, questionBody]) => (questionBody?.QuestionKey || '').toLowerCase() === (questionKey || '').toLowerCase());
        if (result) {
            const [fieldKey] = result;
            return fieldKey;
        }
    }

    static getAnswerType(fieldKey) {
        const question = QuestionKeyMapping[fieldKey];
        return question?.AnswerType;
    }

    static buildBaseAnswer = (fieldKey) => {
        const question = QuestionKeyMapping[fieldKey];
        if (!question) return {};
        const AnswerType = question?.AnswerType;
        const QuestionKey = question?.QuestionKey;

        return {         
            AnswerType,
            QuestionKey,
        };
    }

    static isFieldComposite(fieldKey) {
        const isComposite = !!QuestionKeyMapping[fieldKey]?.Props;
        return isComposite;
    }

    static isDropdownDto(obj) {
        return typeof obj === 'object' && obj && obj.label && (typeof obj.value === 'number' || obj.value);
    }

    static serializeBasicFieldForUpdate(fieldKey, value, { id, year } = { id: undefined }) {

        //console.log('serialize basic field start')

        let question = QuestionKeyMapping[fieldKey];

        //console.log('serialize basic field queston', question)

        // if no question key mapping found, this is the case for scholarships - then fieldKey = questionKey
        if (!question) {
            if (typeof value === 'string') {
                question = {
                    AnswerType: "Text",
                    QuestionKey: fieldKey,
                }
            }
            // #4161: Limiting the #4139 fix scope to fund certifications only.            
            if (typeof value === 'boolean' && fieldKey.startsWith('FundEssays/') ) {
                question = {
                    AnswerType: "Boolean",
                    QuestionKey: fieldKey,
                }
            }
            // only composite field submitted in scholarships is Membership 
            else if (typeof value === 'object' && value) {
                question = {
                    AnswerType: "Membership",
                    QuestionKey: fieldKey
                }
            }
        }

        if (!question) {
            console.warn(`Unsupported field key ${fieldKey}, cannot submit field to API. Likely has no Question Key mapping assigned.`);
            return;
        }

        const AnswerType = question?.AnswerType;
        let QuestionKey = question?.QuestionKey;

        // console.log('=============== serialize form type and question value');
        // console.log(AnswerType)
        // console.log(QuestionKey)

        if (value && value.IsScholarshipDocument === true) {
           QuestionKey = QuestionKey.replace(`_${value.DeliveryType}`, "")
        }

        
        //console.log('serialize fields for update for  ' + QuestionKey + ' / ' +  AnswerType + ' / ' + value)
        if (AnswerType === BooleanFieldType.AnswerType && (typeof value === 'boolean' || value === null)) {
            //console.log('bool type bool val')        
            value = value == true ? true : value == false ? false : question.IsNullable ? null : false;
        }
        if (AnswerType === BooleanFieldType.AnswerType && (typeof value === 'string' || value === null)) {
            //console.log('bool type text val')
            value = value == 'True' ? true : value == 'False'? false : question.IsNullable ? null : false;
        }
        if (AnswerType === TextFieldType.AnswerType && typeof value === 'boolean') {
            //console.log('text type bool val')
            value = value === false ? "False" : "True";
        }
        if (AnswerType === IntegerFieldType.AnswerType) {
            //console.log('integer field type for ' + QuestionKey)
            value = parseInt(value);
            // // console.log('value: ' + value)
        }
        if (QuestionKey === QuestionKeyMapping[FIELDS.SSN].QuestionKey) {
            value = (value || '').split('-').join('');
        }
        if ([FIELDS.HOME_PHONE, FIELDS.ALTERNATE_PHONE, FIELDS.CHILD_CARE_PROVIDER__PHONE, FIELDS.CHILD_CARE_PROVIDER2__PHONE].includes(fieldKey)) {
            value = (value || '').replace(/[\(,\)-\s]/ig, '')
        }
        let additionalQuestionProps = {
            Response: value,
            Id: id,
            Year: year
        };

        if (typeof value === 'object' && value && !FormSerializer.isDropdownDto(value)) {
            //console.log('object value', value)
            // composite fields
            additionalQuestionProps = {
                Id: id,
                ...projectCompositeFieldValues(value)
            }
        }
        else if (FormSerializer.isDropdownDto(value)) {
            // searchable dropdown single value
            additionalQuestionProps = {
                Response: value?.value
            }
        }
        const baseAnswer = {           
            AnswerType,
            QuestionKey,
            Year: year,
        };
        const answer = {
            ...baseAnswer,
            ...additionalQuestionProps
        };
        //console.log(`Serializing ${fieldKey} for update...`, value, answer);
        return answer;
    }

    static parseReduxFieldValue() { }

}
