import MaskedDatePicker from "../MaskedDatePicker";
import MonthYearPicker from "../MonthYearPicker";
import AnswerLock from "./status/AnswerLock";
//import { useFormContext } from "react-hook-form";

/**
 * detail of the date we wish to prompt for
 */
export const DateResolution = {
    DAY : 'DAY',
    MONTH : 'MONTH',
    TERM : 'TERM'
}

/**
 * prompt for a date answer
 * 
 * @param {DateResolution} resolution 
 * @returns {Component}
 */
function DateAnswer({resolution = DateResolution.DAY, answer, label, disabled, ...props}){

    
    //useFormContext();
   
    const fullLabel = (
        <>
            {label}
            <AnswerLock answer={answer} />
        </>
    );

    const isDisabled = disabled || answer.IsLocked;

    if (!answer || answer.IsLoading === true)
        return null; //don't prompt for answer until we can get proper defaults
 
    if (resolution === DateResolution.MONTH)
        return <MonthYearPicker name={answer.FieldKey} {...props} label={fullLabel} disabled={isDisabled} defaultValue={answer.Response} />

    return <MaskedDatePicker name={answer.FieldKey} {...props} label={fullLabel} disabled={isDisabled} />                 
}

export default DateAnswer;