import React, {  } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { useHistory } from 'react-router';
import StatusButtons from "../AppStatusButtons";

export default function ScholarshipsDisplay({details, onSelect = ()=>{}, count, isSCHTab=false, ...props}) {

  const history = useHistory();
  const handleClick = () => {
    history.push({
      pathname: `/scholarship/${details.Code}`,
      state: {
        from: history.location.pathname,
      },
    });
  };

  return (
    <div className="grants-display grants-display--scholarships">
      <div className="grants-display__layout">
        <div>
          {/*<Checkbox name={`${details.title}-selected`} />*/}
        </div>
        <div>
          {details.Code && <span>#{details.Code} &nbsp;</span>}
          <div className="icons">
            {details.matched && <Tooltip title='Matched to Your Profile'><img src='/person_search.svg'/></Tooltip>}
            {details.docsRequired === true && <Tooltip title='Documents Required for This Scholarship'><img src='/text_snippet.svg'/></Tooltip>}
            {details.membershipRequired && <Tooltip title='Membership or Employment Required'><img src='/card_membership.svg'/></Tooltip>}
          </div>
        </div>
        <div className="rhythm">
          <h4 className="heading heading--h4">{details.Name}</h4>
          <div className="grant-display__status">
            {details?.Source?.toLowerCase() !== "renew" &&
              <div>
                {details.isComplete && details.docsRequired? <div style={{ color: '#429D5B' }}>Additional Documents Completed</div> : null}
                {details.isComplete === false && details.docsRequired? <div style={{ color: '#da3754' }}>Additional Documents needed</div> : null}
                {details.isComplete === null? <div>No Additional Documents Required</div> : null}
              </div>
            }              
            { (details.statusState?.IsStarted && details.statusState?.IsSubmitted) &&
              <div style={{color: '#377eda'}}>Applied</div> 
            }
          </div>
        </div>

        <div className='view-button' align='center'>
          <StatusButtons {...details} onClick={handleClick} isScholarship={true} count={count} isSCHTab={isSCHTab} />
        </div>
      </div>
    </div>
  );
}
