import React, { useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer'
import { useHistory } from 'react-router';
import _debounce from 'lodash.debounce';
import ActivityListener from './../Utility/ActivityListener';
import {useSelector} from "react-redux";
import apiClient from "../../utils/API";

function PortalView({children}) {

  const history = useHistory();

  const [showHeightOffsetDOM, setShowHeightOffsetDOM] = useState(false);
  const formState = useSelector((state) => state.form);
  const [isProfileComplete, setIsProfileComplete] = React.useState(true);
  const { isLoggedIn } = useSelector((state) => state.auth);


  const view = children.props.name;

  useEffect(()=>{
    /* TO DO JJB */
    const handleScroll = _debounce(()=>{
      const y = window.scrollY;

      const fromBottom = (document.body.scrollHeight - y);

      const footer = document.getElementsByTagName('footer');
      if(footer.length){
        const footerHeight = footer[0].offsetHeight;

        if(y > 90 && (fromBottom-footerHeight*2 - 200 > 0)){
          setShowHeightOffsetDOM(true);
        }
        else {
          setShowHeightOffsetDOM(false);
        }
      }
    },150,{
      trailing: true,
      leading: false
    });
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);

  },[]);

  return (
    <React.Fragment>
      <div className="pageshell pageshell--fullwidth">
        <Header isProfileComplete={isProfileComplete}/>
        <ActivityListener/>
        <div className="pageshell__main">
          <div className="pageshell__primary">
            {children}
          </div>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default PortalView;
