import { useEffect } from "react";
import { getQuestionInfo } from "../../../store/form/fields";

/**
 * 
 * @param {*} param0 
 * @returns {Component}
 */
function ResponseAnswerDisplay({answer}){
    
    
    return <>{answer?.SelectedOption ?? answer?.Response ?? 'None'}</>

}

export default ResponseAnswerDisplay;