import React, { Fragment } from "react";
import { useFormContext } from "react-hook-form";
import { FieldRow, FieldSection, FieldSlot } from "../Forms/FieldSection";
import Button from "../Button";
import Card from "../Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import ActivityAnswer from "../answer/ActivityAnswer";
import RepeatableQuestion from "../answer/question/RepeatableQuestion";
import DeleteTwoTone from '@material-ui/icons/DeleteTwoTone';
import IconButton from '@material-ui/core/IconButton';
import { Tooltip } from "@material-ui/core";
/**
 * prompt for all activity chart entries of a given type
 * @param {*} param0 
 * @returns {Component} 
 */
function ActivityEntryForm({
  section,
  label,
  defaultValues = {},
  type,
  name,
  rules,
  isRequired = true,
  description,
  ...props
}) {
  const methods = useFormContext({
    mode: "onBlur",
  });
  const { getValues, watch } = methods;

  return (
    <FieldSection withDivider={false}>
      <RepeatableQuestion section={section} name={name} answerFilter={(answer) => answer.ActivityTypeCode === type} >
        {(answers, handleAdd, handleDelete) => {
          return (
            <Fragment key={`${name}`}>
              {answers.map((answer) => 
                <Fragment key={answer.Id}>
                <Card>
                  <CardActions style={{ marginBottom:"-20px", marginTop:"-10px", justifyContent: "flex-end"}}>
                  <IconButton aria-label="Remove" size="large" onClick={handleDelete(answer)}>
                  <Tooltip title="Remove Activity">  
                    <DeleteTwoTone style={{ fontSize: "30px", color: "red" }}/>
                  </Tooltip>  
                  </IconButton>
                  </CardActions>
                  <CardContent>                    
                    <ActivityAnswer answer={answer} />
                  </CardContent>  
                </Card>
                </Fragment>
              )}

              <FieldRow>
                <FieldSlot md={4} style={{ marginTop: "0.5rem" }}>
                  <Button
                    type="button"
                    name="AddName"
                    label="+ Add activity"
                    variant="text"
                    style={{ textTransform: "capitalize" }}
                    onClick={handleAdd({Id: 0, ActivityTypeCode : type, AnswerType :'Activity'})}
                  />
                </FieldSlot>
              </FieldRow>
            </Fragment>
          );
        }}
      </RepeatableQuestion>
    </FieldSection>
  );
}

export default ActivityEntryForm;
