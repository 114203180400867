import { useEffect, useMemo, useState } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSelector } from "react-redux";
import auth from '../../auth';

export const LogoutWarning = ({ dateTime = new Date().toString() }) => {

    const parsedTime = useMemo(() => Date.parse(dateTime), [dateTime]);
    const [time, setTime] = useState(Date.now());
    const [expiredTime, setExpiredTime] = useState(null);
    const [expirationAlertTime, setExpirationAlertTime] = useState(null);
    const [open, setOpen] = useState(false);
    const [isAlerted, setIsAlerted] = useState(false);
    const isLoggedIn = useSelector((state)=> state.auth.isLoggedIn);
    
    useEffect(() => {
        const interval = setInterval(
            () => setTime(Date.now()),
            1000,
        );

        return () => clearInterval(interval);
    }, [parsedTime]);

    useEffect(() => {
        if(isLoggedIn){
            const expiration = auth.getTokenExpiration();
            setExpiredTime(new Date(expiration));

            let alertTime = new Date(expiration);
            // Notification time 5 min before expiration
            alertTime?.setMinutes(alertTime?.getMinutes() - 5);
            setExpirationAlertTime(alertTime);
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if(isLoggedIn && !isAlerted && expirationAlertTime && time > expirationAlertTime){
            setOpen(true);
            setIsAlerted(true);
        }

        if(isLoggedIn && expiredTime && time > expiredTime){
            auth.logout();
        }

    }, [time]);

    function handleClose ()  {
        setOpen(false);
    } 

    return (    
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            fullWidth={true}
            maxWidth={'sm'}
        >   
             <DialogTitle id="modal-title">
                Your session is about to expire
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Please take this time to save your work
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};