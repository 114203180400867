function Card({children, style, ...props}) {
  const defaultStyle = { margin: '3rem 0', backgroundColor: '#ededed', padding: '1.5rem'};

  return (
    <div className="background rhythm" style={style || defaultStyle} {...props}>
      {children}
    </div>
  );
};

export default Card;