import NumericDecimal from "../NumericDecimal";
import AnswerLock from "./status/AnswerLock";

/**
 * prompt for a decimal number
 * 
 * @param {*} param0 
 * @returns {Component}
 */
function DecimalAnswer({answer, disabled, inputLabel, ...props}){


    return <NumericDecimal    
            inputLabel={<>{inputLabel} <AnswerLock answer={answer} /></>}
            placeholder={inputLabel}
            precision={0.01}
            disabled={disabled || answer.IsLocked}
            allowNegative={false}
            name={answer.FieldKey}
            {...props}        
        />
    
}

export default DecimalAnswer;