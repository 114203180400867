import { actions } from "./../../store";
import { useSelector } from "react-redux";
import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import PrintIcon from "../../components/PrintIcon";
import PortalView from "./../../components/Global/PortalView";
import ProgressBar from "../../components/progress/ProgressBar";
import CompleteSubmission from "../../components/CompleteSubmission";
import { FIELDS } from "../../store/form/fields";
import ProgramInfo from "../../utils/dtos/ProgramInfo";
import ApplicationInfo from "../../utils/dtos/ApplicationInfo";
import Award from "../../utils/dtos/Award";
import apiClient from "../../utils/API";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { open } from "../../store/snack/actions";
import _get from "lodash.get";
import ProgressBarLabel from "../../components/progress/ProgressBarLabel";
import EditCollegePlan from "../additional-scholarship-info/EditCollegePlan";
import EditCollegeOfChoice from "../field-groups/EditCollegeOfChoice";
import SectionFormHelper from "../../utils/helper/SectionHelper";
import SectionStateIcon from "../../components/progress/SectionStateIcon";
import ApplicationSubmitButton from "../../components/Utility/ApplicationSubmitButton";

/**
 * early learning program code
 */
const PROGRAM_CODE = "ELP";

/**
 * early learning application component
 */
function EarlyLearning() {
    const history = useHistory();


    const methods = useForm({
        mode: "onBlur",
    });
    const {
        errors,
        formState,
        getValues,
        setValue,
        handleSubmit,
        watch,
    } = methods;
    const authState = useSelector((state) => state.auth);
    const formsState = useSelector((state) => state.form);

    const [showConfirmation, setShowConfirmation] = useState(false);
   
    const [programData, setProgramData] = useState(null);
    const program = new ProgramInfo(programData);
    const activeYear = program.currentYear;

    const [awardData, setAwardData] = useState(null);
    const award = new Award(awardData);
    
    const [applicationData, setApplicationData] = useState(null);
    const app = new ApplicationInfo(applicationData);
    const submitRef = useRef(null);


    const sectionGroup = new SectionFormHelper(useState(null), PROGRAM_CODE);

    sectionGroup.title = program.title;

    const collegeChoice = sectionGroup.create(useState(null), useRef(), [
        FIELDS.COLLEGE_CHOICE,
        FIELDS.COLLEGE_STANDING,
    ], "College Choice");

    const collegePlans = sectionGroup.create(useState(null), useRef(), [
        FIELDS.DEGREES,
        FIELDS.CAREER_GOAL
    ], "College Plans");


    useEffect(() => {
        (async () => {
            const program = await apiClient.getProgram(PROGRAM_CODE);
            setProgramData(program);
            const activeYear = new ProgramInfo(program).currentYear;

            const [awards, app, unused, unused2] = await Promise.all([
                apiClient.getApplicationAwardStatus(PROGRAM_CODE, activeYear),
                apiClient.getApplication(PROGRAM_CODE, activeYear),
                sectionGroup.fetchState(activeYear),
            ]);
            setApplicationData(app);
            setAwardData(awards);
        })()
    }, []);


    const applicationYear = `${activeYear}-${activeYear + 1}`;

    const dispatch = useDispatch();

    const onError = (errors, e) => {
        // console.log("ERROR", errors, e);
    };

    const [submitLoading, setSubmitLoading] = useState(false);


    const actualSubmit = async () => {
        const actualSubmission = async (data, e) => {
           
            await actions.submitForm(data, [], [], activeYear);
            await apiClient.submitApplication(PROGRAM_CODE, activeYear);
            // console.log(data, e);
            setShowConfirmation(true);
        };

        try {
            setSubmitLoading(true);
            const execSubmission = handleSubmit(actualSubmission, onError);
            await execSubmission();
            dispatch(
                open({
                    message: "Submitted Successfully",
                    severity: "success",
                })
            );
        } catch (e) {
            console.error(e);
            dispatch(
                open({
                    message: e.message,
                    severity: "success",
                })
            );
        } finally {
            setSubmitLoading(false);
        }
    };

    const onEdit = () => {
        setShowConfirmation(false);
        window.location.reload(true); /* 3722/SP236: Calling components aren't keeping application state so, reload the page from server. */
    }



    // percent complete, giving a fixed 25% to the profile and 1% to the submit button
    const pecentComplete = sectionGroup.isComplete && app.isSubmitted ? 100 : Math.round(25 + 74 * sectionGroup.percentComplete / 100 + (app.isSubmitted ? 1 : 0));

    return (

        <PortalView>
            <div name="ApplicationsPage">
                <div className="page-intro">
                    <div className="wrapper">
                        <div className="page-intro__layout">
                            <div className="page-intro__icon">
                                <img src="/MyApplictionsIcon.svg" alt="Applications icon" style={{ marginTop: ".5rem" }} />
                            </div>
                            <div className="page-intro__content rhythm">
                                <h1 className="heading heading--h1">
                                    {program.name}
                                    <PrintIcon />
                                </h1>
                                <h2 className="heading heading--h2">{applicationYear}</h2>
                                <a href="/dashboard">Return to Dashboard</a>
                            </div>
                        </div>
                    </div>
                </div>

                {showConfirmation ? (
                    <CompleteSubmission onEdit={onEdit} applicationHelper={sectionGroup} />
                ) : (

                    <div className="wrapper">
                        <div className="application-progress component">
                            <div className="progress-bar rhythm">
                                <h3 className="heading heading--h3">
                                    Application Progress
                                </h3>

                                <ProgressBar progress={pecentComplete} />

                                <div className="progress-bar__labels">
                                    <ProgressBarLabel title="Profile" state="complete" />
                                    {sectionGroup.sections.filter(x => x.title != "Transcripts").map(section =>
                                        <ProgressBarLabel title={section.title} state={section.completionState} sectionRef={section.ref} />
                                    )}
                                    <ProgressBarLabel title="Submit" state={app.isSubmitted ? "complete" : "incomplete"} sectionRef={submitRef} />
                                </div>
                            </div>
                        </div> {/* application progress */}

                        <div className="divider" />

                        {/* Questions */}
                    
                        <div className="college-choice" ref={collegeChoice.ref}>
                            <div className="form-wrapper rhythm">
                                <EditCollegeOfChoice section={collegeChoice} year={activeYear} status={<SectionStateIcon section={collegeChoice} />} onChange={() => collegeChoice.updateCompletion()} />
                            </div>
                        </div>

                        <div className="transcripts" ref={collegePlans.ref}>
                            <div className="form-wrapper rhythm">
                                <EditCollegePlan section={collegePlans} year={activeYear} status={<SectionStateIcon section={collegePlans} />} onChange={() => collegePlans.updateCompletion()} />
                            </div>
                        </div>

                     
                        <div ref={submitRef}>
                            <div className="form-wrapper rhythm">
                                <div className="grant-action-buttons">
                                    <ApplicationSubmitButton onClick={actualSubmit} app={app} sections={sectionGroup} loading={submitLoading} />
                                </div>
                            </div>
                        </div>


                    </div>
                )}
            </div>{/* applications-page */}
        </PortalView>
    );
}

export default EarlyLearning;
