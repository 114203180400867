import ReadOnlyField from '../ReadOnlyField';
import { FIELDS } from '../../store/form/fields';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { actions } from '../../store';
import Checkbox from '../Checkbox';
import ConvertCode from '../Utility/ConvertCode';

export default function AppSnapshotChafee ({ year }) {

  const formState = useSelector((state) => state.form);
  
  const fieldKeys = [
    FIELDS.FIRST_NAME,
    FIELDS.LAST_NAME,
    FIELDS.OTHER_NAMES,
    FIELDS.BIRTHDATE,
    FIELDS.PRIMARY_CONTACT,
    FIELDS.ALTERNATE_PHONE,
    FIELDS.HOME_PHONE,
    FIELDS.PERMANENT_ADDRESS,
    FIELDS.MAILING_ADDRESS,
    FIELDS.SSN,
    FIELDS.HISPANIC,  
    FIELDS.HIGHSCHOOL,
    FIELDS.HIGHSCHOOL_GPA,
    FIELDS.PRIMARY_CONTACT,
    FIELDS.PRIVACY_AGREEMENT,
    FIELDS.CITIZENSHIP_STATUS,
    FIELDS.MARITAL_STATUS,
    FIELDS.COLLEGE_CHOICE,
    FIELDS.COLLEGE_STANDING,
    FIELDS.OREGON_RESIDENT,
    FIELDS.CHAFEE__PRESUMMER_1,
    FIELDS.CHAFEE__FALL_1,
    FIELDS.CHAFEE__WINTER_1,
    FIELDS.CHAFEE__SPRING_1,
    FIELDS.CHAFEE__POSTSUMMER_1,
    FIELDS.CHAFEE__PRESUMMER_2,
    FIELDS.CHAFEE__FALL_2,
    FIELDS.CHAFEE__WINTER_2,
    FIELDS.CHAFEE__SPRING_2,
    FIELDS.CHAFEE__POSTSUMMER_2,
    FIELDS.CHAFEE__FOSTER_CARE,
    FIELDS.CHAFEE__FOSTER_DHS,
    FIELDS.CHAFEE__FOSTER_OTHER_STATE,
    FIELDS.CHAFEE__FOSTER_TRIBAL,
    FIELDS.CHAFEE__ADOPTED,
    FIELDS.COLLEGE_DUAL_ENROLLED
];

useEffect(() => {
  actions.fetchBasicFields(fieldKeys, year);
},[]);

const get = (fieldKey) => formState.fields[fieldKey]?.value;

const getAddress = (fieldKey) => {
  const street = formState.fields[fieldKey]?.Address.value;
  const city = formState.fields[fieldKey]?.CityId.value;
  const county = formState.fields[fieldKey]?.CountyId.value;
  const zip = formState.fields[fieldKey]?.Zip.value;

  return { street, city, county, zip};
};

const getNames = (fieldKey) => {
  const stateObject = formState.fields[fieldKey];
  let string = '';
  for (const i in stateObject) {
      string += stateObject[i].FirstName.value+' '+stateObject[i].LastName.value+'\n'
  }
  return string;
};

const getSchoolCode = (fieldKey) => {
  return formState.fields[fieldKey]?.SchoolCode.value;
}

  return (
    <>
      <ReadOnlyField 
        name="name" 
        value={get(FIELDS.FIRST_NAME)+' '+get(FIELDS.LAST_NAME)}
      />
      <ReadOnlyField 
        name="other names used on submitted documents" 
        value={getNames(FIELDS.OTHER_NAMES)}
      />
      <ReadOnlyField 
        name="Birthday" 
        value={get(FIELDS.BIRTHDATE)}
      />
      <ReadOnlyField 
        name="Phone number" 
        value={
          ( get(FIELDS.ALTERNATE_PHONE) ? get(FIELDS.ALTERNATE_PHONE)+' (Mobile)\n' : '') + 
          ( get(FIELDS.HOME_PHONE) ? get(FIELDS.HOME_PHONE)+' (Home)\n' : '')
        }
      />
      <ReadOnlyField 
        name="permanent address"
        value={
          <ConvertCode 
            isAddress={true} 
            address={getAddress(FIELDS.PERMANENT_ADDRESS)}
          />
        }
      />
      <ReadOnlyField 
        name="mailing address"
        value={
          <ConvertCode 
            isAddress={true} 
            address={getAddress(FIELDS.MAILING_ADDRESS)}
          />
        }
      />
      <ReadOnlyField 
        name="primary contact"
        value={<ConvertCode isContact={true} contact={formState.fields[FIELDS.PRIMARY_CONTACT]}/>}
      />
      <ReadOnlyField 
        name="social security number"
        value={get(FIELDS.SSN) ? '*********' : ''} 
      />
      <ReadOnlyField 
        name="citizenship status"
        value={<ConvertCode codeType="CITIZEN" code={get(FIELDS.CITIZENSHIP_STATUS)}/>}
      />
      <ReadOnlyField 
        name="marital status"
        value={<ConvertCode codeType="STUMS" code={get(FIELDS.MARITAL_STATUS)}/>}
      />
       
       <ReadOnlyField 
        name="oregon resident for 12 months preceding enrollment?"
        value={ 
          ( get(FIELDS.OREGON_RESIDENT) === "True" || get(FIELDS.OREGON_RESIDENT) === true ) ? 'Yes' : 
          ( get(FIELDS.OREGON_RESIDENT) === "False" || get(FIELDS.OREGON_RESIDENT) === false ) ? 'No' : ''
        }
      />
      <ReadOnlyField 
        name="high school"
        value={<ConvertCode isHS={true} code={getSchoolCode(FIELDS.HIGHSCHOOL)}/>}
      />
      <ReadOnlyField 
        name="high school gpa"
        value={get(FIELDS.HIGHSCHOOL_GPA)}
      />
      <ReadOnlyField 
        name="college or university name"
        value={<ConvertCode isCollege={true} code={getSchoolCode(FIELDS.COLLEGE_CHOICE)}/>}
      />
      <ReadOnlyField 
        name={`college standing as of fall ${year}`}
        value={<ConvertCode codeType="YRINCLG" code={get(FIELDS.COLLEGE_STANDING)}/>}
      />
      <ReadOnlyField 
        name="Are you currently in child welfare foster care?"
        value={
          ( get(FIELDS.CHAFEE__FOSTER_CARE) === 'True' || get(FIELDS.CHAFEE__FOSTER_CARE) === true ) ? 'Yes' :
          ( get(FIELDS.CHAFEE__FOSTER_CARE) === 'False' || get(FIELDS.CHAFEE__FOSTER_CARE) === false ) ? 'No' : ''
        }
      />
      <ReadOnlyField 
        name="Which entities provided care?"
        value={
          (( get(FIELDS.CHAFEE__FOSTER_DHS) === 'True' || get(FIELDS.CHAFEE__FOSTER_DHS) === true ) ? 'Oregon Department of Human Services Child Welfare (DHS, formerly referred to as SCF)\n' : '') +
          (( get(FIELDS.CHAFEE__FOSTER_TRIBAL) === 'True' || get(FIELDS.CHAFEE__FOSTER_TRIBAL) === true ) ? 'Tribal Foster Care\n' : '') +
          (( get(FIELDS.CHAFEE__FOSTER_OTHER_STATE) === 'True' || get(FIELDS.CHAFEE__FOSTER_OTHER_STATE) === true ) ? 'Child Welfare system in another state (not Oregon)' : '')
        }
      />
      <ReadOnlyField 
        name={`attending Summer ${year-1}?`}
        value={
          ( get(FIELDS.CHAFEE__PRESUMMER_1) === 'True' || get(FIELDS.CHAFEE__PRESUMMER_1) === true ) ? 'Yes' :
          ( get(FIELDS.CHAFEE__PRESUMMER_1) === 'False' || get(FIELDS.CHAFEE__PRESUMMER_1) === false ) ? 'No' : ''
        }
      />
      <ReadOnlyField 
        name="Oregon Child Welfare Adoption or Guardianship Finalized on or after your 13th birthday?"
        value={
          ( get(FIELDS.CHAFEE__ADOPTED) === 'True' || get(FIELDS.CHAFEE__ADOPTED) === true ) ? 'Yes' :
          ( get(FIELDS.CHAFEE__ADOPTED) === 'False' || get(FIELDS.CHAFEE__ADOPTED) === false ) ? 'No' : ''
        }
      />
      <ReadOnlyField 
        name={`attending Fall ${year-1}?`}
        value={
          ( get(FIELDS.CHAFEE__FALL_1) === 'True' || get(FIELDS.CHAFEE__FALL_1) === true ) ? 'Yes' :
          ( get(FIELDS.CHAFEE__FALL_1) === 'False' || get(FIELDS.CHAFEE__FALL_1) === false ) ? 'No' : ''
        }
      />
      <ReadOnlyField 
        name={`attending winter ${year}?`}
        value={
          ( get(FIELDS.CHAFEE__WINTER_1) === 'True' || get(FIELDS.CHAFEE__WINTER_1) === true ) ? 'Yes' :
          ( get(FIELDS.CHAFEE__WINTER_1) === 'False' || get(FIELDS.CHAFEE__WINTER_1) === false ) ? 'No' : ''
        }
      />
      <ReadOnlyField 
        name={`attending spring ${year}?`}
        value={
          ( get(FIELDS.CHAFEE__SPRING_1) === 'True' || get(FIELDS.CHAFEE__SPRING_1) === true ) ? 'Yes' :
          ( get(FIELDS.CHAFEE__SPRING_1) === 'False' || get(FIELDS.CHAFEE__SPRING_1) === false ) ? 'No' : ''
        }
      />
      <ReadOnlyField 
        name={`attending summer ${year}?`}
        value={
          ( get(FIELDS.CHAFEE__POSTSUMMER_1) === 'True' || get(FIELDS.CHAFEE__POSTSUMMER_1) === true ) ? 'Yes' :
          ( get(FIELDS.CHAFEE__POSTSUMMER_1) === 'False' || get(FIELDS.CHAFEE__POSTSUMMER_1) === false ) ? 'No' : ''
        }
      />
      <ReadOnlyField 
        name={`attending fall ${year}?`}
        value={
          ( get(FIELDS.CHAFEE__FALL_2) === 'True' || get(FIELDS.CHAFEE__FALL_2) === true ) ? 'Yes' :
          ( get(FIELDS.CHAFEE__FALL_2) === 'False' || get(FIELDS.CHAFEE__FALL_2) === false ) ? 'No' : ''
        }
      />
      <ReadOnlyField 
        name={`attending winter ${year+1}?`}
        value={
          ( get(FIELDS.CHAFEE__WINTER_2) === 'True' || get(FIELDS.CHAFEE__WINTER_2) === true ) ? 'Yes' :
          ( get(FIELDS.CHAFEE__WINTER_2) === 'False' || get(FIELDS.CHAFEE__WINTER_2) === false ) ? 'No' : ''
        }
      />
      <ReadOnlyField 
        name={`attending spring ${year+1}?`}
        value={
          ( get(FIELDS.CHAFEE__SPRING_2) === 'True' || get(FIELDS.CHAFEE__SPRING_2) === true ) ? 'Yes' :
          ( get(FIELDS.CHAFEE__SPRING_2) === 'False' || get(FIELDS.CHAFEE__SPRING_2) === false ) ? 'No' : ''
        }
      />
      <ReadOnlyField 
        name={`attending summer ${year+1}?`}
        value={
          ( get(FIELDS.CHAFEE__POSTSUMMER_2) === 'True' || get(FIELDS.CHAFEE__POSTSUMMER_2) === true ) ? 'Yes' :
          ( get(FIELDS.CHAFEE__POSTSUMMER_2) === 'False' || get(FIELDS.CHAFEE__POSTSUMMER_2) === false ) ? 'No' : ''
        }
      />
      <div style={{margin: '2rem 1rem'}}>
        <Checkbox 
          useController={false} 
          name={FIELDS.COLLEGE_DUAL_ENROLLED}
          disabled
          label="I will be dual enrolled with another school while attending this school."
        />
      </div>
      <div style={{margin: '2rem auto'}} className='divider'/>
      <div style={{margin: '2rem 0 0 1rem', textTransform: 'uppercase', fontSize: '14px', color: '#333333', letterSpacing: '1px'}}>
        <b>Privacy Agreement</b>
      </div>
      <ul className="list">
        <li className="li">
          I authorize OSAC to share my application with scholarship processing and review staff, 
          donors, high school partners, and selection committees for the purpose of determining 
          eligibility and selecting awardees. 
        </li>
        <li className="li">
          I understand that by providing contact information, I am giving my 
          permission to receive application status updates or general reminders.
        </li>
      </ul>
      <div style={{marginLeft: '1rem'}}>
        <Checkbox 
          useController={false} 
          name='PrivacyAgreement' 
          value={get(FIELDS.PRIVACY_AGREEMENT)}
          disabled
          label="I certify that I have read, understood, and agree to the above certification statements"
        />
      </div>
      <div style={{margin: '2rem auto'}} className='divider'/>
    </>
  );
};