import React from 'react';
import Input from './Input';

const addressRules = {
  pattern: {
    value: /^[a-zA-Z0-9\s-]+$/,
    message: "Invalid Address"
  }
};

export default ({ ...props }) => <Input onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} rules={addressRules} {...props}/>;
