import React, { Fragment } from "react";
import { useFormContext } from "react-hook-form";
import Input from "../Input";
import apiClient from "../../utils/API";
import { repeatableFieldName, } from "../Utility/RepeatableField";
import MaskedDatePicker from "../MaskedDatePicker";
import Checkbox from "../Checkbox";
import HiddenInput from "../Utility/HiddenInput";
import AnswerLock from "./status/AnswerLock";
import { FieldRow, FieldSlot } from '../Forms/FieldSection';
import { AnswerTypeCollegeProps } from '../../store/form/fields';
import DegreeDropdown, {DegreeVariant} from '../DataDriven/DegreeDropdown';
import { useEffect, useState } from "react";
import CollegeDropdown from "../DataDriven/CollegeDropdown";

/**
 * prompt for a college the student has already attended for some period of time
 * 
 * @param {*} param0 
 */
function CollegeHistoryAnswer({ answer, disabled, isPrior = false }) {

    const methods = useFormContext({ mode: "onBlur", });
    const { getValues, watch, setValue } = methods;
    const isDefaultUnlisted = !answer.SchoolCode && !answer.isNew;

    const i = answer.Index;
    const name = answer.FieldKey;
    const currentSchoolCodeFieldName = repeatableFieldName(name, i, AnswerTypeCollegeProps.SCHOOL_CODE);
    const currentCollegeNameOtherFieldName = repeatableFieldName(name, i, AnswerTypeCollegeProps.UNLISTED_NAME);
    const currentStartDateFieldName = repeatableFieldName(name, i, AnswerTypeCollegeProps.START);
    const currenteEndDateFieldName = repeatableFieldName(name, i, AnswerTypeCollegeProps.END);
    const currentDegreeFieldName = repeatableFieldName(name, i, AnswerTypeCollegeProps.DEGREE);
    const currentCreditsFieldName = repeatableFieldName(name, i, AnswerTypeCollegeProps.CREDITS);
    const idFieldName = repeatableFieldName(name, i, AnswerTypeCollegeProps.ID);
    const unlistedCollege = repeatableFieldName(name, i, "Unlisted");
   
    const watchUnlistedSchool = watch(unlistedCollege, isDefaultUnlisted)
    const [listedSchool, setListedSchool] = useState({value: answer.SchoolCode});
    useEffect(() => {

        if (watchUnlistedSchool) {
            const currentSchoolCode = getValues(currentSchoolCodeFieldName);
            setListedSchool(currentSchoolCode);
            setValue(currentSchoolCodeFieldName, '');
        }
        else
            setValue(currentSchoolCodeFieldName, listedSchool);

    }, [watchUnlistedSchool]);

    const isDisabled = disabled || answer.IsLocked;

    return (
        <Fragment key={answer.Id}>
        <div className="rhythm">
            <FieldRow >
                <FieldSlot>
                    <HiddenInput name={idFieldName} />
                    
                    <CollegeDropdown
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        placeholder="College"
                        name={currentSchoolCodeFieldName}
                        defaultValue={answer.SchoolCode}
                        inputLabel={<>College/University Name <AnswerLock answer={answer} /> </>}
                        variant='outlined'
                        isRequired = {!watchUnlistedSchool}
                    />

                </FieldSlot>
                <FieldSlot>
                    <Checkbox disabled={isDisabled} Controller={false}
                        checked={watchUnlistedSchool}
                        name={unlistedCollege}
                        defaultValue={isDefaultUnlisted}
                        label="My college is not listed" />
                </FieldSlot>
                {watchUnlistedSchool &&
                    <FieldSlot>
                        <Input disabled={isDisabled} name={currentCollegeNameOtherFieldName}
                            defaultValue={getValues(currentCollegeNameOtherFieldName)}
                            key={currentCollegeNameOtherFieldName}
                            inputLabel="Unlisted College/University Name"
                            variant='outlined'
                            placeholder='College/University Name' 
                            isRequired={true} />
                    </FieldSlot>
                }


                <FieldSlot >
                    <FieldRow >
                        <FieldSlot>
                            <MaskedDatePicker disabled={isDisabled} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} minDate={new Date("01-01-1934")} disableFuture={true} name={currentStartDateFieldName} defaultValue={getValues(currentStartDateFieldName)} key={currentStartDateFieldName} label='Date Started' variant='outlined' isRequired={true}  />
                        </FieldSlot>
                        {isPrior &&
                            <FieldSlot>
                                <MaskedDatePicker disabled={isDisabled} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                    minDate={new Date("01-01-1934")} disableFuture={true}
                                    name={currenteEndDateFieldName} defaultValue={getValues(currenteEndDateFieldName)}
                                    key={currenteEndDateFieldName}
                                    label='Date Ended' variant='outlined' 
                                    isRequired={true} />
                            </FieldSlot>
                        }
                    </FieldRow>

                </FieldSlot>
                <FieldSlot>
                    <FieldRow>
                        <FieldSlot>
                            <DegreeDropdown disabled={isDisabled} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} 
                                name={currentDegreeFieldName} key={currentDegreeFieldName} defaultValue={getValues(currentDegreeFieldName)} 
                                inputLabel='Degree Earned' variant='outlined' valueVariant={DegreeVariant.ByAbbreviation} />
                        </FieldSlot>
                        <FieldSlot>
                            <Input disabled={isDisabled} name={currentCreditsFieldName} defaultValue={getValues(currentCreditsFieldName)} key={currentCreditsFieldName} inputLabel='Credits Earned' variant='outlined' />
                        </FieldSlot>
                    </FieldRow>
                </FieldSlot>
            </FieldRow>
        </div>
        </Fragment>
    );
};

export default CollegeHistoryAnswer;