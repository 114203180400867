import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import Button from "../../components/Button";
import { FieldRow, FieldSlot } from "../../components/Forms/FieldSection";
import Card from "../../components/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import MaterialButton from "@material-ui/core/Button";
import { FIELDS } from "../../store/form/fields";
import { Alert, AlertTitle } from '@material-ui/lab';
import _get from "lodash.get";
import { useDispatch } from 'react-redux';
import { actions } from '../../store';
import FormSection from "../../components/FormSection";
import { open } from "../../store/snack/actions";
import {toBoolean} from "../../utils";
import TextAnswer, {TextVariant} from "../../components/answer/TextAnswer";
import DateAnswer from "../../components/answer/DateAnswer";
import BooleanAnswer from "../../components/answer/BooleanAnswer";
import DropdownAnswer from "../../components/answer/DropdownAnswer";


/**
 * child care providers component
 * 
 * @param {Component} arg.status
 * @param {Function} arg.onChange
 * @param {SectionHelper} arg.section 
 */
function ChildCareProviderSection({ status, onChange, section }) {
    const methods = useForm({
        mode: "onBlur",
    });
    const { errors, formState, getValues, setValue, handleSubmit, watch } = methods;
    const dispatch = useDispatch();

    const formsState = useSelector((state) => state.form);
   
    const provider1ListedWatch = toBoolean(watch(FIELDS.CHILD_CARE_PROVIDER__LISTED));
    const provider1RegisteredWatch = toBoolean(watch(FIELDS.CHILD_CARE_PROVIDER__REGISTERED));
    const provider1ParentWatch = toBoolean(watch(FIELDS.CHILD_CARE_PROVIDER__PARENT));
    const provider2ListedWatch = toBoolean(watch(FIELDS.CHILD_CARE_PROVIDER2__LISTED));
    const provider2RegisteredWatch = toBoolean(watch(FIELDS.CHILD_CARE_PROVIDER2__REGISTERED));
    const provider2ParentWatch = toBoolean(watch(FIELDS.CHILD_CARE_PROVIDER2__PARENT));
                
    /**
     * given these provider traits, is it valid for child care grant
     * @param {*} providerListed 
     * @param {*} providerRegistered 
     * @param {*} providerParent 
     * @returns 
     */
    function isProviderValid(providerListed, providerRegistered, providerParent) {
        // if (section.isAnswerLoading)
        //     return;
        if (providerListed === null || providerRegistered === null || providerParent === null) {
            return true; //innocent until proven guilty                            
        }
       
        const isValid = (providerListed === true || providerRegistered === true) && providerParent === false;
        return isValid;          
    }
   
    const providerValid = isProviderValid(provider1ListedWatch, provider1RegisteredWatch, provider1ParentWatch)
    const secondProviderValid = isProviderValid(provider2ListedWatch, provider2RegisteredWatch, provider2ParentWatch);
  
    const [show2ndProvider, setShow2ndProvider] = useState(false);
    useEffect(() => {
        formsState.fields[FIELDS.CHILD_CARE_PROVIDER2__NAME]?.value && setShow2ndProvider(true);
    }, [formsState]);

    useEffect(() => {
        if (show2ndProvider === false) {
            // console.log('show 2nd provider is false', show2ndProvider)
            setValue(FIELDS.CHILD_CARE_PROVIDER2__NAME, null);
            setValue(FIELDS.CHILD_CARE_PROVIDER2__ADDRESS, null);
            setValue(FIELDS.CHILD_CARE_PROVIDER2__STATE, null);
            setValue(FIELDS.CHILD_CARE_PROVIDER2__CITY, null);
            setValue(FIELDS.CHILD_CARE_PROVIDER2__ZIP, null);
            setValue(FIELDS.CHILD_CARE_PROVIDER2__PHONE, "");
            setValue(FIELDS.CHILD_CARE_PROVIDER2__START_DATE, "");
            setValue(FIELDS.CHILD_CARE_PROVIDER2__NEW, null);
            setValue(FIELDS.CHILD_CARE_PROVIDER2__LISTED, null);
            setValue(FIELDS.CHILD_CARE_PROVIDER2__REGISTERED, null);
            setValue(FIELDS.CHILD_CARE_PROVIDER2__PARENT, null);
        }
    }, [show2ndProvider]);


    const handleRemoveAdditionalProvider = () => {
        setShow2ndProvider(false);
    };

    const handleAddProvider = () => {
        setShow2ndProvider(true);
    };

    const watchERDC = watch(FIELDS.IsReceivingERDCSubsidy);
    const isERDCRecipient = toBoolean(watchERDC);


    const onSubmit = async (data, e) => {
        try {
            await actions.submitForm(data, [], [], section.year);
            
            if (onChange)
                onChange();

            dispatch(
                open({
                    message: "Saved Providers Successfully",
                    severity: "success",
                })
            );
        } catch (e) {
            dispatch(open({
                message: e.message,
                severity: "success",
            }))
        }

    }
    const onError = async (data, e) => {
        console.log(errors);
        //just display validations is enough
    }


    return (
        <FormProvider {...methods} >
            <form>
                <FormSection title='Providers' isEditable={true} onSaveClick={handleSubmit(onSubmit, onError)} loading={section.isAnswerLoading} displayOnLoad={true} status={status}>
                    <div className="input-field">
                        <FieldRow>
                            <FieldSlot>                                
                                <BooleanAnswer answer={section.getAnswerByField(FIELDS.IsReceivingERDCSubsidy)} isRequired={true}
                                    label="Are you currently receiving the Employment Related Daycare Subsidy (ERDC) from the Department of Early Learning and Care (formerly part of Oregon Department of Human Services, ODHS)?" />
                            </FieldSlot>
                        </FieldRow>
                        {isERDCRecipient &&
                            <FieldRow>
                                <FieldSlot>                                
                                    <DateAnswer answer={section.getAnswerByField(FIELDS.ERDCSubsidyExpirationDate)} isRequired={true} 
                                        label="When will your ERDC subsidy expire? (Please note, students cannot receive the ERDC subsidy and the Oregon Student Child Care Grant)" />
                                </FieldSlot>
                            </FieldRow>   
                        }                                            
                    </div>
                    <div className="input-field">
                        <FieldRow>
                            <FieldSlot>                                
                                <TextAnswer answer={section.getAnswerByField(FIELDS.CHILD_CARE_PROVIDER__NAME)} inputLabel="Company Name or Full Name" isRequired={true} />
                            </FieldSlot>
                        </FieldRow>
                        <FieldRow>
                            <FieldSlot>
                                <TextAnswer answer={section.getAnswerByField(FIELDS.CHILD_CARE_PROVIDER__ADDRESS)} inputLabel="Street Address" isRequired={true} variant={TextVariant.ADDRESS} />
                            </FieldSlot>
                        </FieldRow>
                        <FieldRow>
                            <FieldSlot>
                                <DropdownAnswer answer={section.getAnswerByField(FIELDS.CHILD_CARE_PROVIDER__STATE)} label="State" isRequired={true} />
                            </FieldSlot>
                        </FieldRow>
                        <FieldRow>
                            <FieldSlot>
                                <TextAnswer answer={section.getAnswerByField(FIELDS.CHILD_CARE_PROVIDER__CITY)} inputLabel="City" isRequired={true} />
                            </FieldSlot>
                        </FieldRow>
                        <FieldRow>
                            <FieldSlot>
                                <TextAnswer answer={section.getAnswerByField(FIELDS.CHILD_CARE_PROVIDER__ZIP)} variant={TextVariant.ZIPCODE}
                                        inputLabel="Zip Code" isRequired={true} placeholder="Zip Code" />
                            </FieldSlot>
                        </FieldRow>

                        <FieldRow>
                            <FieldSlot>
                                <TextAnswer answer={section.getAnswerByField(FIELDS.CHILD_CARE_PROVIDER__PHONE)} inputLabel="Phone Number" isRequired={true} 
                                        variant={TextVariant.TELEPHONE} placeholder="Phone Number with Area Code" />
                            </FieldSlot>
                        </FieldRow>
                        <FieldRow>
                            <FieldSlot>
                                <DateAnswer answer={section.getAnswerByField(FIELDS.CHILD_CARE_PROVIDER__START_DATE)} isRequired={true} label="Start Date" />
                            </FieldSlot>
                        </FieldRow>
                    </div>

                    <div className="input-field">
                        <BooleanAnswer answer={section.getAnswerByField(FIELDS.CHILD_CARE_PROVIDER__NEW)} isRequired={true}
                            label="Is this a new provider?" />
                    </div>
                    <div className="input-field">                       
                        <BooleanAnswer answer={section.getAnswerByField(FIELDS.CHILD_CARE_PROVIDER__LISTED)} isRequired={true}
                            label="Is this provider listed with Oregon Department of Human Services?" />
                    </div>
                    <div className="input-field">                      
                        <BooleanAnswer answer={section.getAnswerByField(FIELDS.CHILD_CARE_PROVIDER__REGISTERED)} isRequired={true} 
                            label="Is this provider registered or certified with the Child Care Division (CCD), Oregon Department of Employment?"/>
                    </div>
                    <div className="input-field">                     
                        <BooleanAnswer answer={section.getAnswerByField(FIELDS.CHILD_CARE_PROVIDER__PARENT)} isRequired={true} 
                            label=" Is this provider the child's/children's birth parent or your spouse/partner?"/>
                    </div>

                    {!providerValid && <div item>
                        <Alert severity="error" className="alert alert--error">
                            <AlertTitle>Not eligible</AlertTitle>
                            <p><strong>Provider does not appear to be eligible. Please check your answers. For more information visit <a href="https://www.OregonStudentAid.gov" target="_blank">www.OregonStudentAid.gov</a></strong></p>
                        </Alert>
                    </div>}

                    {show2ndProvider &&

                        <Card>
                            <CardContent>
                                <h4 className="heading heading--h4">
                                    Additional Child Care Provider
                                </h4>
                                <div container className="input-field">
                                    <FieldRow>
                                        <FieldSlot>
                                            <TextAnswer answer={section.getAnswerByField(FIELDS.CHILD_CARE_PROVIDER2__NAME)} isRequired={true} inputLabel="Company Name or Full Name" />
                                        </FieldSlot>
                                    </FieldRow>
                                    <FieldRow>
                                        <FieldSlot>
                                            <TextAnswer variant={TextVariant.ADDRESS} answer={section.getAnswerByField(FIELDS.CHILD_CARE_PROVIDER2__ADDRESS)} isRequired={true} inputLabel="Street Address" placeholder="Street Address" />
                                        </FieldSlot>
                                    </FieldRow>

                                    <FieldRow>
                                        <FieldSlot>
                                            <DropdownAnswer answer={section.getAnswerByField(FIELDS.CHILD_CARE_PROVIDER2__STATE)} isRequired={true} label="State" placeholder="State" />
                                        </FieldSlot>
                                    </FieldRow>

                                    <FieldRow>
                                        <FieldSlot>
                                            <TextAnswer answer={section.getAnswerByField(FIELDS.CHILD_CARE_PROVIDER2__CITY)} isRequired={true} inputLabel="City" placeholder="City" />
                                        </FieldSlot>
                                    </FieldRow>

                                    <FieldRow>
                                        <FieldSlot>
                                            <TextAnswer variant={TextVariant.ZIPCODE} answer={section.getAnswerByField(FIELDS.CHILD_CARE_PROVIDER2__ZIP)} isRequired={true} inputLabel="Zip Code" placeholder="Zip Code" />
                                        </FieldSlot>
                                    </FieldRow>

                                    <FieldRow>
                                        <FieldSlot>
                                            <TextAnswer variant={TextVariant.TELEPHONE} answer={section.getAnswerByField(FIELDS.CHILD_CARE_PROVIDER2__PHONE)} isRequired={true} inputLabel="Phone Number" placeholder="Phone Number with Area Code" />
                                        </FieldSlot>
                                    </FieldRow>

                                    <FieldRow>
                                        <FieldSlot>
                                            <DateAnswer answer={section.getAnswerByField(FIELDS.CHILD_CARE_PROVIDER2__START_DATE)} isRequired={true} label="Start Date" />
                                        </FieldSlot>
                                    </FieldRow>
                                </div>

                                <div className="input-field">
                                    <div className="input-field">                          
                                        <BooleanAnswer answer={section.getAnswerByField(FIELDS.CHILD_CARE_PROVIDER2__NEW)} isRequired={true} label="Is this a new provider?"/>
                                    </div>
                                    <div className="input-field">                                        
                                        <BooleanAnswer answer={section.getAnswerByField(FIELDS.CHILD_CARE_PROVIDER2__LISTED)} isRequired={true}
                                            label="Is this provider listed with Oregon Department of Human Services?" />
                                    </div>
                                    <div className="input-field">                                      
                                        <BooleanAnswer answer={section.getAnswerByField(FIELDS.CHILD_CARE_PROVIDER2__REGISTERED)} isRequired={true} 
                                            label="Is this provider registered or certified with the Child Care Division (CCD), Oregon Department of Employment?"/>
                                    </div>
                                    <div className="input-field">                                        
                                        <BooleanAnswer answer={section.getAnswerByField(FIELDS.CHILD_CARE_PROVIDER2__PARENT)} isRequired={true}
                                            label="Is this provider the child's/children's birth parent or your spouse or partner?"/>
                                    </div>
                                    {!secondProviderValid && <div item>
                                        <Alert severity="error">
                                            <AlertTitle>Not eligible</AlertTitle>
                                            <p><strong>Provider does not appear to be eligible. Please check your answers. For more information visit <a href="https://www.OregonStudentAid.gov" target="_blank">www.OregonStudentAid.gov</a></strong></p>
                                        </Alert>
                                    </div>}
                                </div>
                            </CardContent>
                            <CardActions style={{ justifyContent: "flex-end" }}>
                                <MaterialButton
                                    size="small"
                                    color="secondary"
                                    onClick={handleRemoveAdditionalProvider}
                                >
                                    Remove
                                </MaterialButton>
                            </CardActions>
                        </Card>
                    }
                    {!show2ndProvider &&
                        <Button name="Add2ndProvider" label="+ Add Child Care Provider" variant="text" onClick={handleAddProvider} />
                    }
                </FormSection>
            </form>
        </FormProvider>
    );
}

export default ChildCareProviderSection;