import { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import Button from "../../components/Button";
import PortalView from "./../../components/Global/PortalView";
import { useEffect } from "react";
import apiClient from "../../utils/API";
import { goBack } from "../../utils";
import { useHistory, useParams } from "react-router-dom";
import SpinnerSection from "../../components/Utility/SpinnerSection";
import ProgramInfo from "../../utils/dtos/ProgramInfo";
import ApplicationInfo from "../../utils/dtos/ApplicationInfo";
import DeclineAward from "./DeclineAward";

/**
 * page to prompt user to decline and award offer.
 * 
 * Note: both renewals and initial awards (inc. Grants where applicable) are both being offered here on one page, to make it impossible to "cross the streams"
 * between the two kinds of offers.
 * @returns {Component}
 */
function Decline() {
    const methods = useForm({
        mode: "onBlur",
    });

    const { code, year } = useParams();
    const [isLoading, setLoading] = useState(true);
    const [programData, setProgramData] = useState(null);
    const [applicationData, setApplicationData] = useState(null);

    const program = new ProgramInfo(programData);
    const application = new ApplicationInfo(applicationData);


    const history = useHistory();
    const handleCancel = () => {
        goBack({
            history,
            defaultRoute: "/dashboard",
        });
    };

    // Decline the award & go backto dashboard.
    const handleDeclineAward = async () => {
        await apiClient.declineApplicationAward(code, year);
        goBack({
            history,
            defaultRoute: "/dashboard"
        })
    }

    useEffect(() => {
        (async () => {
            try {
                const programOverview = await apiClient.getProgram(code);
                const app = await apiClient.getApplication(code, year);

                setProgramData(programOverview);
                setApplicationData(app);

            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    /**
     * decline process if this is a RENEWAL application
     * 
     * @returns {Component}
     */
    function DeclineRenewal() {

        return (
            <div className="wrapper rhythm">

                <div className="form-wrapper">
                    <h2 className="heading heading--h2">Decline Renewal</h2>

                    Confirm that you wish to decline the renewal for this fund.  Once you decline the funds will be awarded to another recipient and will not be available to you.

                    <div
                        container
                        className="grant-action-buttons"
                        marginTop="1rem"
                    >

                        <div>
                            <Button
                                name="Decline"
                                label='Decline Renewal'
                                onClick={handleDeclineAward}
                            />
                            <Button
                                name="Cancel"
                                label="Cancel"
                                variant="text"
                                onClick={handleCancel}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }



    return (
        <FormProvider {...methods}>
            <PortalView>
                <div name="ApplicationsPage" className="Scholarships">
                    <div className="rhythm">
                        <div className="page-intro">
                            <div className="wrapper">
                                <div className="page-intro__layout">
                                    <div className="page-intro__icon">
                                        <img src="/MyApplictionsIcon.svg" alt="Student Portal" style={{ marginTop: ".25rem" }} />
                                    </div>
                                    <div className="page-intro__content rhythm">
                                        <h1 className="heading heading--h1">{program.name}</h1>
                                        <a href="/profile">Return to Profile</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {(() => {
                            if (isLoading)
                                return <SpinnerSection />
                            else if (application.isRenewal && application.declineableRenewalStatuses.includes(application.reviewStatus))
                                return <DeclineRenewal />
                            else if (!application.isAwardOffer)
                                return <h3 className="heading heading--h3">This award has not been offered and cannot be declined</h3>

                            return <DeclineAward onCancel={handleCancel} code={code} year={year} />
                        })()
                        }

                    </div>
                </div>
            </PortalView>
        </FormProvider>
    );
}

export default Decline;
