import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useFormContext } from 'react-hook-form';
import InputDecorator from './InputDecorator';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import { get } from 'lodash';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    input: (props) => {
    }
}));

function DropdownSearch({ name, rules, isRequired = false, fontSize = '12px', standardVariant = false, description, label = '', listItems, variant = 'outlined', placeholder, inputLabel, style, value, includeUnlisted, ...props }) {
    
    const classes = useStyles({
        standardVariant
    });
    const theme = useTheme();
    const methods = useFormContext({ mode: 'onBlur' });
    const { errors } = methods;
    const error = get(errors, name);

    const { setValue } = useFormContext({ mode: 'onBlur' });

    // Autocomplete requires a value of type { label: '...', value: '...' }, the backend only returns value, so we need to add the missing label to the object for it to be selected in the dropdown
    useEffect(() => {
        if ((typeof value === 'object' && value && !value.label) || (includeUnlisted && value === null)) {
            const resolvedValueDict = listItems.find((item) => item.value == ((includeUnlisted && value === null) ? value : value.value));
            if (resolvedValueDict && resolvedValueDict.label) {
                try {
                    setValue(name, resolvedValueDict);
                } catch (err) {
                    if (typeof props.onChange === 'function') {
                        props.onChange(resolvedValueDict);
                    }
                    // suppress - as error only thrown when useController is set to false - i.e not controlled with react hook form 
                }
            }
        }
    }, [value, listItems]);


    return (

        <div className={classes.root} style={{ marginBottom: !!error && '.5rem' }}>
            <InputLabel>
                {inputLabel}
                {
                    isRequired && (
                        <span style={{ color: 'red', backgroundColor: 'transparent' }}> * </span>
                    )
                }
            </InputLabel>
            <Autocomplete
                {...props}
                variant="standard"
                fullWidth={true}
                className={`${classes.input} field`}
                options={listItems}
                getOptionLabel={(option) => option.label || ''}
                getOptionSelected={(option) => {
                    const isMatch = option.value == value?.value;
                    //// console.log(`Dropdown comparison ${option.value} == ${value?.value}`,isMatch)
                    return isMatch;
                }}
                value={value}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={placeholder}
                        label={label}
                        aria-label={description}
                        variant={!standardVariant ? variant : 'standard'}
                        style={style}
                        error={!!error}
                    />
                )}
                blurOnSelect={true}
                onChange={(_, data) => props.onChange(data)}
            />
            {
                error?.message && (
                    <div style={{ color: 'red', fontSize: '0.875rem' }}>
                        {error?.message}
                    </div>
                )
            }
        </div>
    );
}

export default InputDecorator(DropdownSearch, null, { isValueObject: true }); 