import { useState } from "react";
import Checkbox from "../../Checkbox";
import { useEffect } from "react";
import HiddenInput from "../../Utility/HiddenInput";
import AnswerLock from "../status/AnswerLock";
import { repeatableFieldName } from "../../Utility/RepeatableField";
import { useFormContext } from "react-hook-form";
import { Component } from "react";

/**
 * A selection of qualified boolean answers that together form a group under one question
 * @param {*} param0 
 * @returns {Comment}
 */
function BooleanGroupQuestion({ section, fieldKey, label, disabled, ...props }) {

    const allAnswers = section.getAllAnswersByField(fieldKey);

    const lockExample = allAnswers.find(a => a.IsLocked);
    const isDisabled = disabled || lockExample;
    
    return (
        <div>
            <h3>{label} <AnswerLock answer={lockExample}/></h3>
            <ul>
                {allAnswers.map((a, i) =>
                    <li>
                        <QualifiedBooleanAnswer answer={a} disabled={isDisabled} index={i} />
                    </li>
                )}
            </ul>
        </div>


    );

}

/**
 * just one boolean answer in the larger group
 * @param {*} param0 
 * @returns {Component}
 */
function QualifiedBooleanAnswer({answer, disabled, index, ...props}){

 
    const idFieldName = repeatableFieldName(answer.FieldKey, index, "Id");
    const qualifierFieldName = repeatableFieldName(answer.FieldKey, index, "Qualifier");
    const responseFieldName = repeatableFieldName(answer.FieldKey, index, "Response");


    const { errors, formState, getValues, setValue, handleSubmit, watch } = useFormContext();   
   
    useEffect(() => {
      
        setValue(responseFieldName, answer.Response);
    
    }, [])
    
    return (
        <>
            <HiddenInput name={idFieldName} value={answer.Id} />
            <HiddenInput name={qualifierFieldName} value={answer.Qualifier} />
            <Checkbox name={responseFieldName} {...props} disabled={disabled} label={answer.Description} />
        </>
    )
}

export default BooleanGroupQuestion;