import NumericFormat from './NumericFormat';
import React from 'react';
import InputDecorator from './InputDecorator';

const rules = {
  pattern: {
    value: /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/,
    message: "Invalid phone number"
  }
};

function Telephone({ description, format = '(###) ###-####', control, ...props }) {

  return (
    <NumericFormat
      onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
      format={format}
      aria-label={description}
      {...props}
    />
  );
}

export default InputDecorator(Telephone, rules);
