import React, { Fragment } from "react";
import MaskedDatePicker from "./../../components/MaskedDatePicker";
import { useForm } from "react-hook-form";
import {
  FieldSection,
  FieldRow,
  FieldSlot,
} from "../../components/Forms/FieldSection";
import {
  repeatableFieldName,
} from "../../components/Utility/RepeatableField";
import { AnswerTypeDegreeProps } from "../../store/form/fields";
import MajorsDropdown from "../../components/DataDriven/MajorsDropdown";
import DegreeDropdown from "../../components/DataDriven/DegreeDropdown";
import HiddenInput from "../../components/Utility/HiddenInput";
import AnswerLock from "./status/AnswerLock";

/**
 * prompt for a degree the student is hoping to earn in the future
 * 
 * @param {*} param0 
 * @returns 
 */
function DegreeAnswer({ answer, disabled }) {

    const methods = useForm({ mode: "onBlur", });
    const { getValues, handleSubmit } = methods;

    const i = answer.Index;
    const name = answer.FieldKey;
    const degreeFieldName = repeatableFieldName(name, i, AnswerTypeDegreeProps.DEGREE);       
    const primaryMajorFieldName = repeatableFieldName(name, i, AnswerTypeDegreeProps.PRIMARY_MAJOR);  
    const secondaryMajorFieldName = repeatableFieldName(name, i, AnswerTypeDegreeProps.SECONDARY_MAJOR);  
    const graduationDateFieldName = repeatableFieldName(name, i, AnswerTypeDegreeProps.EXPECTED_GRADUATION_DATE);  
    const idFieldName = repeatableFieldName(name, i, AnswerTypeDegreeProps.ID);  

    const isDisabled = disabled || answer.IsLocked;

    return (
        <Fragment key={answer.Id}>
        <FieldSection withDivider={false}>
            <FieldRow>
                <FieldSlot md={12}>
                    <HiddenInput name={idFieldName} value={answer.Id}/>
                    <DegreeDropdown
                        name={degreeFieldName}
                        key={degreeFieldName}
                        defaultValue={getValues(degreeFieldName)}
                        inputLabel={<>Intended Degree <AnswerLock answer={answer} /></>}
                        variant="outlined"
                        isRequired={true}
                        disable={isDisabled}
                    />
                </FieldSlot>
            </FieldRow>
            <FieldRow>
                <FieldSlot md={6}>
                    <MajorsDropdown
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        name={primaryMajorFieldName}
                        key={primaryMajorFieldName}
                        defaultValue={getValues(
                            primaryMajorFieldName
                        )}
                        inputLabel="Major"
                        variant="outlined"
                        isRequired={true}
                        disable={isDisabled}
                    />
                </FieldSlot>
                <FieldSlot md={6}>
                    <MajorsDropdown
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        name={secondaryMajorFieldName}
                        key={secondaryMajorFieldName}
                        defaultValue={getValues(
                            secondaryMajorFieldName
                        )}
                        inputLabel="Second or Double Major (optional)"
                        variant="outlined"
                        disable={isDisabled}
                    />
                </FieldSlot>
            </FieldRow>
            <FieldRow>
                <FieldSlot md={6}>
                    <MaskedDatePicker
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        minDate={new Date("01-01-1934")}
                        disableFuture={false}
                        name={graduationDateFieldName}
                        label="Expected Completion Date"
                        isRequired={true}
                        disable={isDisabled}
                    />
                </FieldSlot>
            </FieldRow>
        </FieldSection>
        </Fragment>
    );
}

export default DegreeAnswer;