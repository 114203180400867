import { useSelector } from 'react-redux';
import PortalView from './../../components/Global/PortalView';
import CompleteSubmission from '../../components/CompleteSubmission';

const details = {
    //title: 'Oregon National Guard',
     title: 'Chafee',
    // title: 'Oregon Promise',
    // title: 'Student Child Care',
    year: 2021,
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nunc mi ipsum faucibus vitae aliquet.',
}

function Placeholder() {

    const authState = useSelector((state) => state.auth);

    return (
        <PortalView>
            <CompleteSubmission details={details}/>
        </PortalView>
    );
}

export default Placeholder;