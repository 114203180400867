import React from 'react';
import InputDecorator from './InputDecorator';
import Input from './../components/Input';


function Numeric({ placeholder, min, max, ...props }) {

 if(props.value && props?.value.length > max) {
   props.value = props.value.substring(0, max)
 }
  return (
    <Input inputProps={{type: "number", max: max, min: min, placeholder: placeholder}} {...props}/>
  );
}

export default InputDecorator(Numeric);
