import { FormProvider, useForm } from "react-hook-form";
import FormSection from "../../components/FormSection";
import BooleanAnswer from "../../components/answer/BooleanAnswer";
import { FIELDS } from "../../store/form/fields";
import DropdownAnswer from "../../components/answer/DropdownAnswer";
import { FieldRow, FieldSection, FieldSlot } from "../../components/Forms/FieldSection";
import SectionStateIcon from "../../components/progress/SectionStateIcon";
import { useDispatch } from "react-redux";

/**
 * 
 * @param {SectionHelper} param0.section 
 * @returns {Component}
 */
function EditWorkingStudent({ section }) {

    const dispatch = useDispatch();
    const methods = useForm({ mode: 'onBlur' });
    const { errors, formState, getValues, setValue, handleSubmit, watch } = methods;

    const onSubmit = (data) => {
        section.saveAnswers(data, dispatch);
    }

    const onError = (e) => {

    }

    const teacherWorking = watch(FIELDS.TEACHER_WORKING);
    const teacherWorkingK12 = watch(FIELDS.TEACHER_WORKINGKTHRU12);

    return (
        <FormProvider {...methods} >
            <form onSubmit={handleSubmit(onSubmit, onError)} >
                <FormSection title={section.title} isEditable={true} onSaveClick={handleSubmit(onSubmit, onError)}
                    status={<SectionStateIcon section={section} />}
                    loading={section.isLoading} >


                    <FieldSection>
                        <FieldRow>
                            <BooleanAnswer isRequired={true} answer={section.getAnswerByField(FIELDS.TEACHER_WORKING)}
                                label={`Are you planning to work or student teach during the ${section.year}-${section.year + 1} academic year?`} />
                        </FieldRow>
                        {teacherWorking === 'True' &&
                            <>
                                <FieldRow>
                                    <BooleanAnswer isRequired={true} answer={section.getAnswerByField(FIELDS.TEACHER_WORKING20HOURS)}
                                        label="Are you working or student teaching more than 20 hours per week?" />
                                </FieldRow>
                                <FieldRow>
                                    <BooleanAnswer isRequired={true} answer={section.getAnswerByField(FIELDS.TEACHER_WORKINGKTHRU12)}
                                        label="Are you currently employed in a K-12 school district OR an early learning and care facility?" />
                                </FieldRow>
                            </>
                        }
                        {teacherWorkingK12 === 'True' &&
                            <>
                                <FieldRow>
                                    <DropdownAnswer isRequired={true} answer={section.getAnswerByField(FIELDS.TEACHER_ROLE)}
                                        label="What type of role do you hold?" />
                                </FieldRow>
                                <FieldRow>
                                    <DropdownAnswer isRequired={true} answer={section.getAnswerByField(FIELDS.TEACHER_DISTRICT)}
                                        label="What school or district are you currently employed in?" />
                                </FieldRow>
                            </>
                        }

                    </FieldSection>
                </FormSection>
            </form>
        </FormProvider>
    );
}

export default EditWorkingStudent;