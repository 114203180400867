import AnswerLock from "./status/AnswerLock";
import Checkbox from "../Checkbox";

/**
 * prompt for boolean answer that is a ceritification of some fact
 * 
 * @returns {Component}
 */
function CertifyAnswer({answer, disabled, label, ...props}){
    
    const isDisabled = disabled || answer.IsLocked;

    return (             
        <Checkbox name={answer.FieldKey} {...props} disabled={isDisabled} label={<>{label} <AnswerLock answer={answer} /></>} />                
    );
}
export default CertifyAnswer;