/**
 * 
 * @param {*} param0 
 * @returns 
 */
function DateDisplay({ answer }) {

    if (!answer?.Response)
        return (<></>);

    const dateValue = new Date(Date.parse(answer.Response));
    return <>{dateValue.toLocaleDateString("en-US")}</>;

}

export default DateDisplay;
