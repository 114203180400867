import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useSelector, useDispatch } from "react-redux";
import { close } from "../store/snack/actions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SapSnackbar = () => {
  const snack = useSelector((state) => state.snack);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(close());
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: snack.vertical,
          horizontal: snack.horizontal,
        }}
        open={snack.open}
        onClose={handleClose}
        message={snack.message}
        autoHideDuration={4000}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      ></Snackbar>
    </div>
  );
};
export default SapSnackbar;
