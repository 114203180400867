import React, { useEffect } from 'react';
import { useState } from 'react';
import apiClient from '../../utils/API';
import Dropdown from '../Dropdown';


export default ({ listItems, codeType, ...props}) => {
    const [items,setItems] = useState([]);
    
    useEffect(()=>{
        (async ()=>{
            // real route -> code/codes?codeType=HSTYPE
            const codes = await apiClient.get(`/code/codes?codeType=${codeType}`);
            setItems(codes.map(r => ({
                ...r,
                label: r.Message,
                value: r.Code
            })))
        })();
    },[]);

    return <Dropdown {...props} listItems={items}/>
        
}
