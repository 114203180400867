import { Page, Text, View, Image, Document, StyleSheet, Font, PDFDownloadLink } from '@react-pdf/renderer';
import { useEffect, useState } from 'react';
import { FIELDS } from "../../store/form/fields";
import apiClient from '../../utils/API';
import AnswerSet from '../../utils/dtos/AnswerSet';
import FormSerializer from '../../store/form/serializer';
import moment from 'moment';

/**
 * GPA Verification PDF
 * 
 * @returns {Component}
 */
function GpaVerificationDocument({ app, answers, school }) {

    const answerSet = new AnswerSet(answers);

    function getResponse(fieldKey) {
        const questionKey = FormSerializer.getQuestionKey(fieldKey);
        const answer = answerSet.get(questionKey);
        if (!answer)
            return null;

        if (answer.AnswerType == 'HighSchool')
            return answer;

        return answer.Response
    }

    function formatDate(date, formatString = 'M/D/YYYY') {
        return moment(date).format(formatString);
    }

    function formatPhone(number){
        if (!number || number === '')
            return '          ';

        var cleaned = ('' + number).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return number;
    }

    Font.register({
        family: 'Open Sans',
        fonts: [
            { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
            { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 },
            { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf', fontWeight: 800 }
        ]
    });

    const styles = StyleSheet.create({
        page: {
            backgroundColor: '#FFFFFF',
            marginLeft: 20,
            marginRight: 30,
            fontFamily: 'Open Sans',
            fontSize: 9
        },
        section: {
            marginTop: 10,
            marginBottom: 0,
            marginLeft: 20,
            marginRight: 30,
            padding: 0,
        },
        header: {
            width: '95%',
            height: 'auto'
        },
        mainHeader: {
            textAlign: 'center',
            fontSize: 12,
            marginBottom: 10,
            marginTop: 10
        },
        sectionHeader: {
            fontWeight: 800,
            width: '95%',
            fontSize: 9,
            marginBottom: 10
        },
        paragraph: {
            width: '95%',
            fontSize: 9,
            marginBottom: 10
        },
        listItem: {
            width: '85%',
            fontSize: 9,
            marginBottom: 10,
            marginLeft: 20
        },
        bold: {
            fontWeight: 800
        },
        underlined: {
            textDecoration: 'underline',
            marginLeft: 10,
            marginRight: 10
        },
        footer:{
            width: '85%',
            fontSize: 8,
            marginTop: 15
        }
    });



    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <Image src={'/pdf_header.png'} style={styles.header}></Image>
                </View>
                <View>
                    <Text style={styles.mainHeader} >
                        OREGON PROMISE APPLICATION HIGH SCHOOL GPA VERIFICATION REQUEST
                    </Text>
                    <Text style={{ ...styles.sectionHeader, textAlign: 'center' }}>
                        Deadline: {formatDate(app?.Deadline)}*
                    </Text>
                    <Text style={styles.paragraph}>
                        To be eligible for Oregon Promise you must verify that your cumulative, unweighted high school GPA is at least 2.00. GPA Verification is
                        completed by your high school registrar using our secure Partner Portal. If your high school chooses to not participate in GPA Verification,
                        you will need to submit a transcript to OSAC via your Student Portal.
                    </Text>
                </View>
                <View>
                    <Text style={styles.paragraph}>
                        <Text style={styles.bold}>High School Name:&nbsp;</Text>
                        <Text style={styles.underlined}>&nbsp;&nbsp;&nbsp;{school.Name}&nbsp;&nbsp;&nbsp;</Text>

                        <Text>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Class of&nbsp;&nbsp;</Text>
                        <Text  style={styles.underlined}>{formatDate(getResponse(FIELDS.HIGHSCHOOL_GRAD_DATE), 'YYYY')}</Text>

                        <Text style={styles.bold}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Application #</Text>
                        <Text style={styles.underlined}>{app?.AppId}</Text>
                    </Text>
                    <Text style={styles.paragraph}>
                        <Text style={styles.bold}>GPA Verification For:&nbsp;</Text>
                        <Text style={styles.underlined}>
                            &nbsp;&nbsp;&nbsp;{getResponse(FIELDS.FIRST_NAME)}
                            &nbsp;&nbsp;&nbsp;{getResponse(FIELDS.MIDDLE_INITIALS)}
                            &nbsp;&nbsp;&nbsp;{getResponse(FIELDS.LAST_NAME)}
                            &nbsp;&nbsp;&nbsp;
                        </Text>
                        <Text style={styles.bold}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Birthdate: &nbsp;</Text>
                        <Text style={styles.underlined}>
                            &nbsp;&nbsp;&nbsp;{formatDate(getResponse(FIELDS.BIRTHDATE))}&nbsp;&nbsp;
                        </Text>
                    </Text>
                    <Text style={styles.paragraph}>
                        <Text style={styles.bold}>SSID (Student ID #):&nbsp;</Text>
                        <Text style={styles.underlined}>_____________________________</Text>

                    </Text>
                    <Text style={styles.paragraph}>
                        <Text style={styles.bold}>Student's Primary Phone:&nbsp;</Text>
                        <Text style={styles.underlined}>&nbsp;&nbsp;{formatPhone(getResponse(FIELDS.HOME_PHONE))}&nbsp;&nbsp;</Text>
                        <Text style={styles.bold}>&nbsp;&nbsp;&nbsp;&nbsp;Student's Alternate Phone:&nbsp;</Text>
                        <Text style={styles.underlined}>&nbsp;&nbsp;{formatPhone(getResponse(FIELDS.ALTERNATE_PHONE))}&nbsp;&nbsp;</Text>
                    </Text>
                    <Text style={styles.paragraph}>
                        <Text style={styles.bold}>Student's Signature </Text>
                        <Text style={styles.underlined}>_____________________________</Text>
                    </Text>
                    <Text style={styles.paragraph}>
                        <Text style={styles.bold}>Registrar's or Transcript Processor's Signature:&nbsp;</Text>
                        <Text style={styles.underlined}>________________________________&nbsp;&nbsp;&nbsp;&nbsp;</Text>
                        <Text style={styles.bold}>&nbsp;&nbsp;&nbsp;Date Received:&nbsp;</Text>
                        <Text style={styles.underlined}>___________</Text>
                    </Text>
                </View>
                <View>
                    <Text style={styles.paragraph}>
                        The student is responsible for ensuring the GPA Verification is transmitted to OSAC by the deadline above. OSAC does not need this form returned.
                        This form is for the student to give to their high school registrar as a reminder for them verify the student's GPA online.
                    </Text>
                    <Text style={styles.sectionHeader} >Notes to the Student</Text>
                    <Text style={styles.listItem} >
                        1. It is your responsibility to ensure GPA Verification is submitted by your high school. You always have the option to go back into your application to submit
                        a transcript instead of GPA Verification, in order to meet the deadline.
                    </Text>
                    <Text style={styles.listItem} >
                        2. Sign this form and give it to the Registrar or staff person at your school who can provide your GPA verification in time to meet the OSAC deadline. Make a
                        copy for your records and be sure your Registrar or Transcript Processor signs and dates the form. (You may need this if your application is marked incomplete
                        because the transcript did not arrive on time.)
                    </Text>
                    <Text style={styles.listItem} >
                        3. OSAC will update your verification status after we receive the online GPA Verification from your high school.
                    </Text>
                    <Text style={styles.listItem} >
                        4. Log in to your Student Dashboard at https://app.oregonstudentaid.gov/ to check that your verification arrived at OSAC, if the verification is correct, or if there is a problem.
                    </Text>
                    <Text style={styles.listItem} >
                        5. OSAC will not review your GPA verification until you submit your Oregon Promise application.
                    </Text>

                    <Text style={styles.sectionHeader} >Notes to the High School Registrar/Transcript Processor</Text>
                    <Text style={styles.paragraph}>OSAC offers several methods to deliver the GPA verification to OSAC:</Text>
                    <Text style={styles.listItem} >
                        1. Use OSAC's Partner Portal, a secure portal for transmitting student data for scholarship and grant eligibility, to quickly and easily submit the
                        student's GPA Verification. No physical transcript is required for this method. For more information, see the documentation at
                        https://partners.oregonstudentaid.gov/. Or;
                    </Text>
                    <Text style={styles.listItem} >
                        2. Provide a hard copy/electronic transcript to the student for them to upload into their Oregon Promise application.
                    </Text>
                </View>
                <View >
                    <Text style={styles.footer} >
                        *In the event that an Oregon Promise deadline falls on a weekend or holiday, the deadline will automatically be extended to 5:00 p.m. (PST) of the following business day.
                    </Text>                    
                </View>
            </Page>
        </Document>
    );
}

/**
 * 
 * @param {*} param0 
 * @returns {Component}
 */
function GpaVerificationFormLink({ app }) {

    const fieldKeys = [
        FIELDS.HIGHSCHOOL,
        FIELDS.HIGHSCHOOL_GRAD_DATE,
        FIELDS.FIRST_NAME,
        FIELDS.MIDDLE_INITIALS,
        FIELDS.LAST_NAME,
        FIELDS.BIRTHDATE,
        FIELDS.HOME_PHONE,
        FIELDS.ALTERNATE_PHONE,
    ];

    const [answerData, setAnswerData] = useState(null);
    const [highSchool, setHighSchool] = useState(null);

    useEffect(async () => {
        if (!app)
            return;
        const questionKeys = fieldKeys.map(x => FormSerializer.getQuestionKey(x));
        const answers = await apiClient.get("/answer", { questionKeys: questionKeys, year: app.Year });        
        const answerSet = new AnswerSet(answers);
        const schoolCode = answerSet.get('HighSchool').SchoolCode;
        const schoolInfo = await apiClient.getHighSchool(schoolCode);

        setAnswerData(answers);
        setHighSchool(schoolInfo);
    }, [app]);

    if (!answerData || !highSchool)
        return <span>GPA Verification Reminder Form</span>;

    return (
        <>
            &nbsp;
            <PDFDownloadLink document={<GpaVerificationDocument app={app} answers={answerData} school={highSchool}/>} fileName="gpa_verification_form.pdf">
                {({ blob, url, loading, error }) => (loading ? 'Generating PDF...' : 'GPA Verification Reminder Form')}
            </PDFDownloadLink>
            &nbsp;
        </>

    );
}

export default GpaVerificationFormLink;