import Checkbox from "../../ReadOnlyCheckbox";


/**
 * 
 * @param {*} param0 
 * @returns 
 */
function BooleanDisplay({answer}){
    
    return <Checkbox value={answer.Response}  />

}

export default BooleanDisplay;