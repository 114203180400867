
/**
 * interpret an answer option as provided by the API
 */
class AnswerOption {

    /**
     * Wrapping constructor
     * @param {*} data 
     */
    constructor(data){
        this._data = data;
    }

    
    get value(){
        return this._data.Value;
    }

    get name(){
        return this._data.Name;
    }

    get disabled(){
        return !this._data.Enabled;
    }

    /**
     * get a specific option property
     * @param {String} category name of the note category
     * @return {String}
     */
    getNote(category){
        if (category == null)
            return null;

        for (let note of this._data.Notes)
        {
            if (note.Category.toUpperCase() == category.toUpperCase())
            {
                return note.Description;
            }
        }
        return null;
    }
}

export default AnswerOption