import Button from "../../components/Button";
import ApplicationInfo from "../../utils/dtos/ApplicationInfo";
import SectionFormHelper from "../../utils/helper/SectionHelper";

/**
 * component to do common work of displaying or replacing application submit button
 * @param {Object} param0 
 * @param {ApplicationInfo} param0.app 
 * @param {SectionFormHelper} param0.sections 
 * @param {function} param0.onClick 
 * @param {boolean} param0.loading 
 * @param {*} param0.style 
 */
function ApplicationSubmitButton({app, sections, onClick, loading, style}){


    const disableSubmit = app.isSubmitted || sections?.isComplete === false || app.isDeadlinePassed;
    
    if (app.isLoading)
        return (<></>);

    if (app.isSubmitted)
        return (<></>);
    
    if (app.isDeadlinePassed)
        return (<span style={{paddingLeft : '2em'}} >Deadline has passed</span>);
    
    return (
        <Button loading={loading} name="Submit" label="Submit" variant="outline" disabled={disableSubmit} onClick={onClick} style={style}/>
    );
              
}

export default ApplicationSubmitButton;