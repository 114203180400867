import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import { InputLabel } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginTop: theme.spacing(1.5),
    '& > * > *': {
      borderRadius: "5px",
      background: 'white',
    },
    '& input': {
      height: '10px',
    },
  },
}));

export default function NumericFormat({ name, description, label, format, mask = '_', inputLabel, isRequired = false, ...props }) {
  const classes = useStyles();
  const methods = useFormContext({ mode: 'onBlur' });
  const { errors } = methods;
  const error = errors[name];

  return (
    <div className={classes.root}>
      <InputLabel>
        {inputLabel}
        {
          isRequired && (
            <span style={{color:'red'}}>*</span>
          )
        }
      </InputLabel>
      <NumberFormat
        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
        label={label}
        customInput={TextField}
        error={!!error}
        format={format}
        //allowEmptyFormatting
        mask={mask}
        aria-label={description}
        fullWidth={true}
        {...props}
      />
      {
        error?.message && (
          <div style={{ color: 'red', fontSize: '0.875rem' }}>
              {error?.message}
          </div>
        )
      }
    </div>
  );
};
