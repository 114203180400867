import { useState, useEffect } from "react";
import PortalView from "./../../components/Global/PortalView";
import MessageList from "../../components/MessageList";
import MailIcon from "@material-ui/icons/MailOutline";
import apiClient from "../../utils/API";
import Spinner from "../../components/Utility/Spinner";

function Messages() {
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([
    // {
    //   title: "Your 2021-22 Scholarship Application Status",
    //   from: "Noreply@hecc.oregon.gov",
    //   sent: "2/16/2021 12:49PM",
    //   message: `Dear Anthony Mendoza,\n
    //         Congratulations on being awarded a scholarship through OSAC.\n
    //         According to your scholarship application you plan to attend Concordia University for the 2020-2021 Academic Year.
    //         We will be sending your scholarship funds to the college listed in your scholarship application. If the school in your application is incorrect, your scholarship will go to the wrong school and will cause a delay in receiving your scholarship funds.\n
    //         If you need to change your college, please do so by:
    //         Logging into your OSAC Student Portal
    //         Proceed to Section 9: College Plans in the 2020-21 Scholarship Application
    //         Update College of Choice to the college you plan on attending for 2020-21 Application\n
    //         You can also check or update your college of choice at any time during the 2020-21 academic year.\n
    //         If you have any questions or need assistance please contact OSAC by replying to this email.\n\n
    //         Thank you,\nOSAC Scholarship Team`,
    // },
  ]);

  const FilteringOptions = {
    MOST_RECENT: "Most Recent",
    HIDDEN: "Hidden",
  };

  //Object.values(FilteringOptions) = ["Most Recent", "Hidden"]; // <- can be used for the dropdown
  let sortOptions = [];
  for (const [value, label] of Object.entries(FilteringOptions)) {
    sortOptions = [...sortOptions, { value: label, label }];
  }

  const [filteringParams, setFilteringParams] = useState({
    searchQuery: "",
    filteringOption: FilteringOptions.MOST_RECENT,
    totalPages: 3, // can set default -1
  });

  const tips = document.getElementById('tips');
  const tipsTrigger = document.getElementById('tipslist-trigger');
  const toggleTips = () => {
    tips.classList.toggle('tipslist--expanded');
    tipsTrigger.classList.toggle('active');
  };

  const [hideIntro, setHideIntro] = useState(true)
  useEffect(() => {
    // this will be called when filteringParams updates
    (async () => {
      try {
        const communications = await apiClient.get("/communication");
        setMessages([
          ...communications.map((comm) => ({
            from: comm.Sender,
            title: comm.Subject,
            sent: comm.TimeSent,
            message: comm.Body,
          })),
          // {
          //   title: "Your 2021-22 Scholarship Application Status",
          //   from: "Noreply@hecc.oregon.gov",
          //   sent: "2/16/2021 12:49PM",
          //   message: `Dear Anthony Mendoza,\n
          //           Congratulations on being awarded a scholarship through OSAC.\n
          //           According to your scholarship application you plan to attend Concordia University for the 2020-2021 Academic Year.
          //           We will be sending your scholarship funds to the college listed in your scholarship application. If the school in your application is incorrect, your scholarship will go to the wrong school and will cause a delay in receiving your scholarship funds.\n
          //           If you need to change your college, please do so by:
          //           Logging into your OSAC Student Portal
          //           Proceed to Section 9: College Plans in the 2020-21 Scholarship Application
          //           Update College of Choice to the college you plan on attending for 2020-21 Application\n
          //           You can also check or update your college of choice at any time during the 2020-21 academic year.\n
          //           If you have any questions or need assistance please contact OSAC by replying to this email.\n\n
          //           Thank you,\nOSAC Scholarship Team`,
          // },
        ]);
        if (communications.length === 0){
          setHideIntro(false)
        }
      } catch (err) {
        console.error(err);
      }
      setLoading(false);
    })();
  }, [filteringParams]);

  return (
    <PortalView>
      <div name="MessagesPage" className="messages">
        {/* Page Intro */}
        <div className="page-intro">
          <div className="wrapper">
            <div className="page-intro__layout">
              <div className="page-intro__icon">
                <MailIcon fontSize="large" style={{ marginTop: ".5rem", color: "#D6743D" }} />
              </div>
              <div className="page-intro__content rhythm">
                <h1 className="heading heading--h1">Messages</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper">

          {loading? 
            <div style={{ marginTop: ".75rem" }}>
              <Spinner />
            </div> 
          : null}

          { !hideIntro &&
            <div className="rhythm component component--top-small-padding text text--small">

              <h2 className="heading heading--h5 pointer" id="tipslist-trigger" onClick={toggleTips}>
                <span>
                  <svg style={{'width': '1.5rem'}} fontSize="small" className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall css-ptiqhd-MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="ChevronRightIcon"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path></svg>
                </span>
                OSAC has not yet sent you any email messages.
              </h2>
              <ul className="list tipslist" id="tips">
                <li className="li">Make sure your email address is correct.</li>
                <li className="li">Check that any spam filtering allows emails from OSAC.</li>
                <li className="li">OSAC emails may originate from these email domains: osac.state.or.us, state.or.us, hecc.oregon.gov</li>
                <li className="li">OSAC may use generic email addresses, such as for Awardinfo@hecc@oregon.gov used to award scholarships</li>
                <li className="li">An OSAC staff member may use their OSAC email address to assist you with your application</li>
                <li className="li">OSAC will never contact you to ask for money</li>
              </ul>
            </div>
          }

          <div className="component message-list">
            <MessageList listMessages={messages} />
          </div>
        </div>
      </div>
    </PortalView>
  );
}
export default Messages;
