import { useState } from "react";
import { useEffect } from "react";
import apiClient from "../../utils/API";
import {
    FieldSlot,
    FieldSection,
    FieldRow,
} from "../../components/Forms/FieldSection";
import SpinnerSection from "../../components/Utility/SpinnerSection";
import AnswerSet from "../../utils/dtos/AnswerSet";
import Question from "../../utils/dtos/Question";
import AnswerDisplay from "../answer/readonly";
import { getQuestionInfo } from "../../store/form/fields";


/**
 * 
 * @param {*} param0 
 * @returns 
 */
function Scholarship({ year, code }) {

    const [questionData, setQuestionData] = useState({});
    const questions = questionData.questions?.map(q => new Question(q));
    const answerSet = new AnswerSet(questionData.answers);

    const isLoading = answerSet.isLoading;

    useEffect(() => {
        (async () => {
            const questions = await apiClient.get(`question/questions/${code}/${year}`);
            const fieldKeys = questions.map(q => q.Name);
            const data = {
                "questionKeys": fieldKeys,
                "year": year,
                "IsVerbose": true
            };
            const answers = await apiClient.get("/answer", data);
            setQuestionData({questions, answers});

        })();
    }, []);

    /**
     * get some resonable description for question
     * @param {Question} question 
     * @returns {string}
     */
    function getTitleForQuestion(question){    
        if (question.description)
            return question.description;
      
        return getQuestionInfo(question.name)?.Title ?? question.name;
    }

   

    /**
     * is this question even worth displaying
     * @param {Question} question 
     */
    function isFluff(question){
        const fluffQuestions = ['activitieschartflag', 'gedinprogressflag', 'hstypecode', 'hasnoschoolactivities', 'hasnoworkactivities', 'hasnovolunteeractivities', 'iscollegehistorycomplete'];
        const name = question.name.toLowerCase();
        if (name === 'testappflag')
            return false;        
        if (name.startsWith('aspire'))
            return true;
        if (fluffQuestions.includes(name))
            return true;       
        if (name.includes('correctional'))
            return true;
        if (question.hasCategory('Contact'))
            return false;
        if (question.hasCategory('HighSchool'))
            return false;
        if (question.hasCategory('Profile'))
            return true;
        if (question.type === 'Transcript')
            return true;

        return false;
    }


    if (isLoading)
        return <SpinnerSection />

    return (
        <div>
            {questions.filter(q => !isFluff(q)).map((question, index) =>
                <FieldSection
                    title={getTitleForQuestion(question)}
                    withDivider={(index + 1) !== questions.length}
                >
                    {answerSet.getAll(question.name).map(answer =>

                        <FieldRow>
                            <FieldSlot md={12}>
                                <div className="outlined-box">
                                    <AnswerDisplay answer={answer} />
                                </div>
                            </FieldSlot>
                        </FieldRow>

                    )}
                </FieldSection>
            )}
        </div>
    );
}

export default Scholarship;
