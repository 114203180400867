import ProgramInfo from "./ProgramInfo";
import ApplicationInfo from "./ApplicationInfo";
import { combineProgramStatus } from '../../utils';

/**
 * replacement for RevTech's "enriched" anonymous objects that combine programs and their applications together
 */
class ProgramApplicationInfo {

    /**
     * wrapping constructor
     * @param {*} applicationData application DTO from API
     * @param {*} programData program info DTO from API
     */
    constructor(applicationData, programData){
        this._program = new ProgramInfo(programData);
        this._application = new ApplicationInfo(applicationData);
    }

    
    /**
     * applicaiton year
     */
    get year(){
        return this._application.year;
    }

    /**
     * abbreviated key for program
     */
    get programCode(){
        return this._program.code;
    }

    /**
     * human readable review status description
     */
    get reviewMessage(){
        return this._application.reviewMessage;
    }

    /**
     * actual review status code
     */
    get reviewStatus(){
        return this._application.reviewStatus;
    }

    /**
     * is this tentatively application awarded to the student
     * @returns {Boolean}
     */
    get isAwarded(){
        return this._application.isAwarded;
    }

    /**
     * is this app under the private scholarships program
     * @returns {Boolean}
     */
    get isScholarship(){
        return this._program._data?.ParentProgramCode === "SCH";
    }

    /**
     * is this a grant
     * @returns {boolean}
     */
    get isGrant(){
        if (!this._program._data)
            return false;

        return this._program._data.ParentProgramCode == null && this._program._data.Code !== "SCH";
    }

    /**
     * get the season this application was applied for in
     * @returns {ProgramSeason}
     */
    get season(){
        return this._program.getSeason(this.year);
    }

    /**
     * has student started this application
     * @returns {boolean}
     */
    get isStarted(){
        return this._application.isStarted;
    }

    /**
     * has stuent submitted this applicatino
     * @returns {boolean}
     */
    get isSubmitted(){
        return this._application.isSubmitted;
    }

    /**
     * is this season still open (including appeals)
     * @returns {boolean}
     */
    get isSeasonOpen(){
        if (!this.season)
            return false;

        return this.startEvent.isPassed && !this.isDeadlinePassed;
    }

    /**
     * has the deadline passed
     * @returns {boolean}
     */
    get isDeadlinePassed(){
        return this._application.isDeadlinePassed;
    }

    /**
     * @returns {}
     */
    get startEvent(){
        return this.season?.startEvent;
    }

    /**
     * get event date
     * @param {*} eventCode 
     * @param {*} term 
     * @param {*} format 
     * @returns {String}  
     */
    getDate(eventCode, term, format){
        return this.season.getDate(eventCode, term, format);
    }

    /**
     * get old style RevTech enriched appliation object
     * @returns {*}
     * @deprecated try to use this object directly if possible, feel free to add properties as needed
     */
    get legacyEnrichedData(){
        return combineProgramStatus(this._program._data, this._application._data);
    }

}

export default ProgramApplicationInfo