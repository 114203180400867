import { ProgramSeason } from "./ProgramSeason";

/**
 * description of a funding program being offered to student. Interprets program info as provided by Program API
 */
class ProgramInfo {

    /**
     * constructor
     * @param {*} data the raw DTO provided by the Programs API
     */
    constructor(data){
        this.updateDto(data);  
    }

    /**
     * is this info ready for use?
     * @returns {Boolean}
     */
    get isLoaded(){
        return this._data != null;
    }

    get name(){
        return this._data?.Name;
    }
    get code(){
        return this._data?.Code;
    }
    
    get description(){
        return this._data?.Description
    }
    /**
     * if we've just received the latest program dto, go ahead and update it!
     * @param {*} data 
     */
    updateDto(data){
        this._data = data;
    }
   
    /**
     * most recent available  year
     * @returns {Number}
     * @deprecated year must be specified by context. could be multiple currently available years
     */
    get currentYear(){
        return this.currentSeason?.year;
    }

    /**
     * get most recently available season. Others may also be currently available.
     * @returns {ProgramSeason}
     */
    get recentSeason(){
        if (!this._data) return null;
        if (!this._data.ActiveSeasons) return null; //probably just a stub data in there to start
        if (this._data.ActiveSeasons.length == 0) return null; //configuration error on OSAC's side

        const seasonsInOrder = this._data.ActiveSeasons.sort((a,b) => b.Year - a.Year);
        const mostRecentSeason = seasonsInOrder[0];
        return new ProgramSeason(mostRecentSeason);
    }

    /**
     * get all available seasons
     * @returns {Array<ProgramSeason>}
     */
    get seasons(){
        if (!this._data) return new Array();
        if (!this._data.ActiveSeasons) return new Array(); //probably just a stub data in there to start
      
        return this._data.ActiveSeasons.map(x => new ProgramSeason(x))
    }

    /**
     * get the season for the specified year, if it happens to already be part of the program definition.
     * 
     * If season is not already loaded into program info -- likely because it is not currently available -- you will need to fetch it directly from apiClient.getSeason
     * @param {Number} year 
     * @returns {ProgramSeason}
     */
    getSeason(year){
        return this.seasons.find(x => x.year === year);
    }
    
    /**
     * running on the assumption, for now, that there is a single, current season active at any one time
     * @returns {ProgramSeason}
     * @deprecated could be multiple seasons currently
     */
    get currentSeason(){
        return this.recentSeason;
    }

    /**
     * convenience method to get current start event 
     * @returns {ProgramEvent}
     * @deprecated could be multiple seasons currently, specify season first
     */
    get startEvent(){
        return this.currentSeason?.getEvent('StartDate') ?? this.currentSeason?.getEvent('START') ;
    }

    /**
     * convenience method to get current start status
     * @returns {Boolean}
     */
    get isStarted(){
        if (this.startEvent == null) return false;

        return this.startEvent.isPassed;
    }
    
}


export default ProgramInfo;