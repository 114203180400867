
import React, { useEffect, Fragment } from 'react';
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { Route } from "react-router-dom";
import store from "./store";
import { LogoutWarning } from './components/Utility/LogoutWarning';
import "./index.css";

// import view components
import ProtectedRoute from "./components/Utility/ProtectedRoute";
import Dashboard from "./views/dashboard";
import Awards from "./views/awards";
import Applications from "./views/applications";
import Feedback from "./views/feedback";
import Glossary from "./views/glossary";
import History from "./views/history";
import Messages from "./views/messages";
import Profile from "./views/profile";
import Registration from "./views/registration";
import Placeholder from "./views/placeholder";
import StudentPortalLanding from "./views/login";
import AdditionalScholarshipInfo from "./views/additional-scholarship-info";
import OregonPromise from "./views/oregon-promise";
import Chafee from "./views/chafee";
import OTSG from "./views/otsg";
import OTSP from "./views/otsp";
import ChildCare from "./views/child-care-grant";
import NationalGuard from "./views/national-guard";
import Scholarship from "./views/scholarship";
import EarlyLearning from "./views/early-learning";
import Renew from "./views/renew";
import Decline from "./views/decline";
import ThankTheDonor from "./views/thankyou";
import SapSnackbar from "./components/SapSnackbar";
import { withRouter, useHistory } from 'react-router-dom';
import ReactGA from 'react-ga4';
import Offer from './views/offer';

const analyticsKey = process.env.REACT_APP_GOOGLE_ANALYTICS_KEY;
ReactGA.initialize(analyticsKey, {
  gaOptions: {
    siteSpeedSampleRate: 100
  }
});
ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname + window.location.search,
});

function ScrollToTop({ children }) {
  const history = useHistory();
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  }, [history]);

  return <Fragment>{children}</Fragment>;
}

export default withRouter(ScrollToTop);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <SapSnackbar />
      <LogoutWarning/>
      <Router>
        <ScrollToTop>
        <Switch>
          <ProtectedRoute path="/dashboard" component={Dashboard} scrollTop={true} />
          <ProtectedRoute path="/awards" component={Awards} scrollTop={true} />
          <ProtectedRoute path="/feedback" component={Feedback} scrollTop={true}  />
          <ProtectedRoute path="/glossary" component={Glossary} scrollTop={true}  />
          <ProtectedRoute path="/history" component={History} scrollTop={true}  />
          <ProtectedRoute path="/messages" component={Messages} scrollTop={true}  />
          <ProtectedRoute path="/profile" component={Profile} scrollTop={true}  />
          <ProtectedRoute path="/registration" component={Registration} scrollTop={true}  />
          <ProtectedRoute path="/additional-scholarship-info/:year" component={AdditionalScholarshipInfo} />
          <ProtectedRoute path="/oregon-promise" component={OregonPromise} />
          <ProtectedRoute path="/chafee/:year" component={Chafee} />
          <ProtectedRoute path="/otsg/:year" component={OTSG} />
          <ProtectedRoute path="/otsp/:year" component={OTSP} />
          <ProtectedRoute path="/child-care/:year" component={ChildCare} />
          <ProtectedRoute path="/national-guard/:year" component={NationalGuard} />
          <ProtectedRoute path="/early-learning/:year" component={EarlyLearning} />
          <ProtectedRoute path="/placeholder" component={Placeholder} />
          <ProtectedRoute path="/scholarship/:code/:year" component={Scholarship} />
          <ProtectedRoute path="/renew/:code/:year" component={Renew} />
          <ProtectedRoute path="/decline/:code/:year" component={Decline} />
          <ProtectedRoute path="/offer/:code/:year" component={Offer} />
          <ProtectedRoute path="/thankyou/:code/:year" component={ThankTheDonor} />
          <Route path="/" component={StudentPortalLanding} />
        </Switch>
        </ScrollToTop>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
