import { actions } from "./../../store";
import { useForm, FormProvider } from "react-hook-form";
import Password from "./../../components/Password";
import { FieldSection, FieldRow, FieldSlot } from "../../components/Forms/FieldSection";
import { FIELDS } from "../../store/form/fields";
import FormSection from "../../components/FormSection";
import auth from "../../auth";
import { open } from "../../store/snack/actions";
import { useDispatch } from "react-redux";
import { useState } from "react";
import DropdownAnswer from "../../components/answer/DropdownAnswer";
import CertifyAnswer from "../../components/answer/CertifyAnswer";

function EditAccountInfo({ section, onChange, onCancel }) {
  
  const methods = useForm({
    mode: "onBlur",
  });
  const { errors, formState, getValues, setValue, handleSubmit } = methods;

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = async (data, e) => {
    try {
      setLoading(true);
      const result = await actions.submitForm(data);
      // console.log(data, e);
      if (typeof onChange === "function") {
        onChange(true);
      }
    } catch (e) {
      dispatch(
        open({
          message: e.message,
          severity: "success",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const onError = (errors, e) => {
    // console.log(errors, e);
  };

  const handleChangePassword = (e) => {
    e.preventDefault();
    auth.changePassword();
  };

  const ChangePassLink = (
    <a href="#" className="helperTextLink" onClick={handleChangePassword}>Change Password</a>
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <FormSection
          title="Account Info, Password, Privacy"
          isEditable={true}
          onSaveClick={handleSubmit(onSubmit, onError)}
          onCancelClick={onCancel}
          loading={loading}
        >
          <FieldSection title="Password?">
            <FieldRow>
              <FieldSlot>
                <Password
                  onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                  useController={false}
                  inputLabel="Password"
                  variant="outlined"
                  disabled={true}
                  value="*********"
                  HelperTextComponent={ChangePassLink}
                />
              </FieldSlot>
            </FieldRow>
          </FieldSection>

          <FieldSection title="How did you hear about OSAC Grants and Scholarships?">
            <FieldRow>
              <FieldSlot>
                <DropdownAnswer answer={section.getAnswerByField(FIELDS.OSAC_REFERRAL)} label="How did you hear about us?" />              
              </FieldSlot>
            </FieldRow>
          </FieldSection>

          <FieldSection title="Certification" withDivider={false}>
            <FieldRow column align="flex-start">
              <FieldSlot>
                <CertifyAnswer answer={section.getAnswerByField(FIELDS.PRIVACY_AGREEMENT)}               
                  isRequired={true}
                  row={false}
                  label="This certification takes the place of a signature. By checking
                    this box, you certify that you have read, understood, and agree
                    to the following statements:"
                />

                <ul className="list list--checklist">
                  <li className="li">
                    {" "}
                    I certify the accuracy of my application(s) and
                    all accompanying documents and, if requested, agree to
                    provide proof of this information.
                  </li>
                  <li className="li">
                    {" "}
                    I give permission to the Oregon Office of Student Access and
                    Completion (OSAC) to request and use data from my Free
                    Application for Federal Student Aid (FAFSA) or Oregon
                    Student Aid Application (ORSAA).
                  </li>
                  <li className="li">
                    {" "}
                    I understand that by providing my contact information, I am
                    giving permission to receive application status updates or
                    general reminders.
                  </li>
                  <li className="li">
                    {" "}
                    OSAC cannot guarantee applicants will receive OSAC grants or
                    scholarships. I agree to hold harmless, defend, and
                    indemnify OSAC for any acts, failures to act, or omissions
                    of OSAC, its employees, agents, volunteers, or any State of
                    Oregon employee.
                  </li>
                  <li className="li">
                    {" "}
                    I authorize OSAC to share my application information with
                    high school and college officials for additional financial
                    and educational information consistent with federal and
                    state laws. Information may also be shared with the
                    Department of Human Services (DHS) for the Chafee ETG,
                    childcare providers for the Child Care Grant, 
                    Oregon Tribes for the Oregon Student Tribal Grant, 
                    Oregon National Guard officials for the Oregon National 
                    Guard State Tuition Assistance program, Educator Advancement Council (EAC) for the Oregon Teachers Scholars Program, and
                    scholarship selection committees and donors. I understand
                    that data may be shared through the Oregon Financial Aid
                    Exchange (OFAX). I also allow my information to be shared
                    with OSAC-approved researchers.
                  </li>
                  <li className="li">
                    {" "}
                    Under the Privacy Act of 1974, I understand that I am not
                    required to provide my Social Security Number, however, if I
                    don’t provide it, I understand I will not be considered for
                    OSAC grants or scholarships that require that information. I
                    also understand that if I have not completed the FAFSA or
                    ORSAA, I will not be considered for OSAC need-based grants
                    or scholarships.
                  </li>
                </ul>
              </FieldSlot>
            </FieldRow>
          </FieldSection>

          <FieldSection title="Publicity Release (optional)">
            <FieldRow>
              <FieldRow>
                <FieldSlot>
                  <CertifyAnswer answer={section.getAnswerByField(FIELDS.PUBLICITY_RELEASE)}
                    isRequired={false}
                    row={false}
                    label="If selected to receive an OSAC grant or scholarship award, I
                    give permission to OSAC to publicize the award through a
                    press release, media story, report, or other promotional
                    materials."
                  />
                </FieldSlot>
              </FieldRow>
            </FieldRow>
          </FieldSection>
          
        </FormSection>
      </form>
    </FormProvider>
  );
}

export default EditAccountInfo;
