import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import StatusButtons from "../AppStatusButtons";
import { useHistory } from "react-router-dom";

export default function Scholarships({ details, ...props }) {
  const history = useHistory();

  const handleClick = () => {
    history.push({
      pathname: `/scholarship/${details.Code}`,
      state: {
        from: history.location.pathname,
      },
    });
  };

  return (
    <div className="grant grant--scholarship">
      <div className="grant__layout">
      <div className="grant__content rhythm" style={{ display:"flex", flexDirection:"column", alignItems: "center", justifyContent: "center" }}>
          <h4 className="heading heading--h5">{details.Name}</h4>
          <div className="icons-desktop">
            {!details.matched && !details.docsRequired && !details.membershipRequired? <span style={{ margin: "4px 3px -3px 0" }}/> : null}
            {details.matched && (
              <Tooltip title="Matched to Your Profile">
                <img
                  style={{ margin: "4px 3px -3px 0" }}
                  src="/person_search.svg"
                  alt=""
                />
              </Tooltip>
            )}
            {details.docsRequired && (
              <Tooltip title="Documents Required for This Scholarship">
                <img
                  style={{ margin: "4px 6px -3px 0" }}
                  src="/text_snippet.svg"
                  alt=""
                />
              </Tooltip>
            )}
            {details.membershipRequired && (
              <Tooltip title="Membership or Employment Required">
                <img
                  style={{ margin: "4px 6px -3px 0" }}
                  src="/card_membership.svg"
                  alt=""
                />
              </Tooltip>
            )}
          </div>
          <div>
            {details?.Source?.toLowerCase() !== "renew" &&
              <div>
                {details.isComplete && details.docsRequired? <div style={{ color: '#429D5B' }}>Additional Documents Completed</div> : null}
                {details.isComplete === false && details.docsRequired? <div style={{ color: '#da3754' }}>Additional Documents needed</div> : null}
                {details.isComplete === null? <dd>No Additional Documents Required</dd> : null}
              </div>
            }
            <div dangerouslySetInnerHTML={ { __html: details.Description }}></div>
          </div>
        </div>
        <em>#{details.Code}</em>
        <StatusButtons {...details} onClick={handleClick} isScholarship={true} />
      </div>
    </div>
  );
}
