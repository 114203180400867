/**
 * description of answer set responses from API
 */
class AnswerSet {

    /**
     * wrapping constructor
     * @param {*} data AnswerSet as provided by the API answers endpoint
     */
    constructor(data) {
        this._data = data;
    }

    /**
     * are answers still loading
     * @returns {Boolean}
     */
    get isLoading(){
        return !this._data;
    }

    /**
     * answer sets are fetched only from a given year block. Which year do these answers appear to be from?
     * @return {number}
     */
    get year(){
        if (this._data == null)
            return null;
        if (this._data.length == 0)
            return null;
        return this._data[0].Year;
    }
    
    /**
     * get single state for give question
     * @param {String} questionKey 
     * @return {*}
     */
    get(questionKey) {
        if (questionKey == null)
            return null;
        if (!this._data)
            return null;        

        for (let answer of this._data){
            if (answer.QuestionKey?.toUpperCase() === questionKey?.toUpperCase())            
                return answer;
                        
        }
           
        return null;
    }
    

    /**
     * get all answers responding to a given question
     * @param {String} questionKey 
     * @return {Array<*>} all the dto's from the API that reference this question key
     */
    getAll(questionKey){
        if (questionKey == null)
            return [];
        if (this._data == null)
            return [];
        
        return this._data.filter(answer => answer.QuestionKey.toUpperCase() === questionKey.toUpperCase());       
    }
   
    getCompletedAnswers(){
        if (this._data === null)
            return [];

        let completed = this._data?.filter(a=> (a.Status || '').toLowerCase() === 'complete')
        return completed;       
    }

    getAnswers(questionKeys){
        // pass in a list of question keys to a list of answers
        if (this._data === null)
            return [];

        let list = this._data?.filter(a=> questionKeys.includes(a.QuestionKey))
        return list;       
    }
}



export default AnswerSet;