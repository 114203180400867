import Button from "./../../components/Button";
import React, { useRef, useState } from "react";
import MaterialDropdown from "../MaterialDropdown";
import RepeatableField, { repeatableFieldName } from "../Utility/RepeatableField";
import { Delete } from "@material-ui/icons";
import { useFormContext } from "react-hook-form";
import { getMimeInstance, IsTabletOrMobile } from "../../utils";
import { AnswerTypeTranscriptProps, FIELDS, QuestionKeyMapping, TranscriptDeliveryTypes } from "../../store/form/fields";
import HiddenInput from "../Utility/HiddenInput";
import { useEffect } from "react";
import apiClient from "../../utils/API";
import _get from "lodash.get";
import { useSelector } from "react-redux";

const EmptyTranscripts = ({ onAdd }) => {
  return (
    <div style={{ fontSize: "16px", padding: "3rem", textAlign: "center" }}>
      <p style={{ margin: 0, fontStyle: "italic" }}>
        Nothing here yet! Please add a College in College History to add a
        transcript submission method.
      </p>
    </div>
  );
};

const FileFetcher = ({ fileFieldName, idFieldName, fieldKey }) => {
  const methods = useFormContext({ mode: "onBlur" });
  const { watch, setValue } = methods;

  const id = watch(idFieldName);

  useEffect(() => {
    if (fileFieldName && idFieldName && id) {
      // console.log('----> Got file id',id);
      const questionKey = QuestionKeyMapping[fieldKey].QuestionKey;
      (async () => {
        const blob = await apiClient.getFileFromQuestionKey({
          id,
          questionKey,
        });
        if (blob.size) {
          const file = new File([blob], `${questionKey}.pdf`);
          setValue(fileFieldName, file);
        }
      })();
    }
  }, [id]);

  return null;
};
const TranscriptUploader = ({ transcriptField,   
                              titleHeader = null,        
                              transcriptPathName,
                              transcriptColumnName,
                              children,
                              displayOnly = false,
                              isRequired = false,
                              ...props
                            }) => {
  
  const allowedFileExtensions = ["pdf"];
  const maxFileSizeInMb = 25;
  if (!transcriptPathName || !transcriptColumnName) {
    throw new Error(
      "Either name or transcriptPathName or transcriptColumnName prop missing from TranscriptUploader component."
    );
  }
  const reduxFormState = useSelector((state) => state.form);
  const getReduxField = (fieldKey) => {
    return _get(reduxFormState, `fields.${fieldKey}.value`);
  };

  const methods = useFormContext({ mode: "onBlur" });
  const { watch, setValue, register, errors } = methods;

  const [activeFileField, setActiveFileField] = useState(null);
  const [uploadError, setUploadError] = useState({
    fieldName: null,
    message: null,
  });

  const isTabletOrMobile = IsTabletOrMobile();

  const allowedMimes = allowedFileExtensions.map((ext) =>
    getMimeInstance().getType(ext)
  );

  const handleUpload = (event) => {
    if ([...event.target.files].length) {
      const fileUploaded = event.target.files[0];
      const sizeInMb = fileUploaded.size / 1024 / 1024 / 1.048576;
      const mime = fileUploaded.type;
      if (sizeInMb > maxFileSizeInMb) {
        setUploadError({
          fieldName: activeFileField,
          message: `Exceeds maximum file size of ${maxFileSizeInMb} Mb`,
        });
      } else if (!allowedMimes.includes(mime)) {
        setUploadError({
          fieldName: activeFileField,
          message: `Can only upload files of format: ${allowedFileExtensions.join(
            ", "
          )}`,
        });
      } else {
        setValue(activeFileField, fileUploaded);
        setActiveFileField(null);
      }
    }
  };

  const hiddenFileInput = useRef();

  const triggerUpload = (fileFieldName) => (e) => {
    e.preventDefault();
    setUploadError({
      fieldName: null,
      message: null,
    });
    setActiveFileField(fileFieldName);
    hiddenFileInput.current.click();
  };

  const download = function (filename, blob) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, filename);
    } else {
      var elem = window.document.createElement("a");
      elem.href = window.URL.createObjectURL(blob);
      elem.download = filename;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    }
  };

  const handleDelete = (fieldName) => (e) => {
    e.preventDefault();
    setValue(fieldName, null);
  };

  const verifyFile = (file) => {
    console.log('verify file', file)
    if (!file && isRequired) {
      return false;
    }
    return true;
  };

  const error = _get(errors, transcriptField.uploadField);
  // console.log("Error", error)

  return (
    <>
      {error && (
        <p style={{ color: "red", marginBottom: "10px" }}>
          * Please provide all required transcripts
        </p>
      )}
        < input
        required={isRequired}
        type="file"
        ref={hiddenFileInput}
        onChange={handleUpload}
        style={{display: "none"}}
        />
      {/*both digital and mail fields have same array length so we'll arbitrarily choose digital here*/}
      <RepeatableField initialCount={0} name={transcriptField.uploadField}>
        {(indices, handleAddIndex ) =>
          <div className="table-wrapper">
            <table className="table">
              {!displayOnly &&
                <caption class="table__caption">
                  {titleHeader ?? transcriptField.description} 
                </caption>
              }
              <thead className="table__header">
              <tr className="table__row">
                <th className="table__cell--header">{transcriptColumnName}</th>
                {!displayOnly &&
                  <>
                    <th className="table__cell--header" width="30%">Delivery</th>
                    <th className="table__cell--header" width="230" style={{textAlign: 'center'}}>Upload (pdf)</th>
                  </>
                }
                <th className="table__cell--header" width="20%" style={{textAlign: 'left'}}>Review Status</th>
                <th className="table__cell--header" width="20%" style={{textAlign: 'left'}}>Review Instructions</th>
              </tr>
              </thead>

              {(indices.length === 0 && ( <EmptyTranscripts onAdd={handleAddIndex} /> )) || null }

              {indices.map((i, orderedIndex) => {
                const deliveryFieldName = repeatableFieldName(
                  "DELIVERY_METHOD",
                  i
                );
                const selectedDeliveryType = watch(deliveryFieldName);
                let initialResolvedDeliveryMethod;

                const hasDigitalSelected = getReduxField(
                  repeatableFieldName(
                    transcriptField.uploadField,
                    i,
                    AnswerTypeTranscriptProps.IS_SELECTED
                  )
                );
                const hasMailSelected = getReduxField(
                  repeatableFieldName(
                    transcriptField.mailField,
                    i,
                    AnswerTypeTranscriptProps.IS_SELECTED
                  )
                );

                if (hasDigitalSelected) {
                  initialResolvedDeliveryMethod =
                    TranscriptDeliveryTypes.UPLOAD;
                }
                if (hasMailSelected) {
                  initialResolvedDeliveryMethod =
                    TranscriptDeliveryTypes.MAIL;
                }

                const [
                  transcriptFieldName,
                  fileFieldName,
                  idFieldName,
                  digitalDeliveryMethod,
                  isDigitalDeliveryMethodSelected,
                  isDigitalDeliveryMethodCertified,
                  answerIdFieldName,
                ] = [
                  repeatableFieldName(transcriptField.uploadField, i, transcriptPathName),
                  repeatableFieldName(
                    transcriptField.uploadField,
                    i,
                    AnswerTypeTranscriptProps._FILE
                  ),
                  repeatableFieldName(
                    transcriptField.uploadField,
                    i,
                    AnswerTypeTranscriptProps.ID
                  ),
                  repeatableFieldName(
                    transcriptField.uploadField,
                    i,
                    AnswerTypeTranscriptProps.DELIVERY_TYPE
                  ),
                  repeatableFieldName(
                    transcriptField.uploadField,
                    i,
                    AnswerTypeTranscriptProps.IS_SELECTED
                  ),
                  repeatableFieldName(
                    transcriptField.uploadField,
                    i,
                    AnswerTypeTranscriptProps.IS_CERTIFIED
                  ),
                  repeatableFieldName(
                    transcriptField.uploadField,
                    i,
                    AnswerTypeTranscriptProps.SCHOOL_ANSWER_ID
                  ),
                ];

                const [
                  idMailFieldName,
                  mailDeliveryMethod,
                  isMailDeliveryMethodSelected,
                  isMailDeliveryMethodCertified,
                  answerIdMailFieldName,
                ] = [
                  repeatableFieldName(
                    transcriptField.mailField,
                    i,
                    AnswerTypeTranscriptProps.ID
                  ),
                  repeatableFieldName(
                    transcriptField.mailField,
                    i,
                    AnswerTypeTranscriptProps.DELIVERY_TYPE
                  ),
                  repeatableFieldName(
                    transcriptField.mailField,
                    i,
                    AnswerTypeTranscriptProps.IS_SELECTED
                  ),
                  repeatableFieldName(
                    transcriptField.mailField,
                    i,
                    AnswerTypeTranscriptProps.IS_CERTIFIED
                  ),
                  repeatableFieldName(
                    transcriptField.mailField,
                    i,
                    AnswerTypeTranscriptProps.SCHOOL_ANSWER_ID
                  ),
                ];

                register(fileFieldName, {
                  validate: (value) => verifyFile(value),
                });

                const file = watch(fileFieldName);

                const schoolAnswerId = watch(answerIdFieldName);

                let filename;
                if (file) {
                  filename = file.name;
                }

                const handleDownload = (e) => {
                  e.preventDefault();
                  const file = watch(fileFieldName);
                  download(file.name, file);
                };

                return (
                  <tbody className="table__body">
                  <tr className="table__row">
                    <td className="table__cell">
                      {children({ transcriptFieldName, schoolAnswerId })}

                        {/* Mail Transcript for College Row */}
                        <HiddenInput name={idMailFieldName} />
                        <HiddenInput name={mailDeliveryMethod} />
                        <HiddenInput name={isMailDeliveryMethodSelected} value={!!( selectedDeliveryType === TranscriptDeliveryTypes.MAIL )} />
                        <HiddenInput name={isMailDeliveryMethodCertified} value={!!( selectedDeliveryType === TranscriptDeliveryTypes.MAIL )} />
                        <HiddenInput name={answerIdMailFieldName} />
                      {/* Digital Transcript for College Row */}

                    </td>
                    {!displayOnly &&
                      <>
                        <td className="table__cell">
                          <FileFetcher
                            fieldKey={transcriptField.uploadField}
                            idFieldName={idFieldName}
                            fileFieldName={fileFieldName}
                          />
                          <HiddenInput name={idFieldName}/>
                          <HiddenInput name={digitalDeliveryMethod}/>
                          <HiddenInput name={answerIdFieldName}/>
                          <HiddenInput
                            name={isDigitalDeliveryMethodSelected}
                            value={selectedDeliveryType === TranscriptDeliveryTypes.UPLOAD}
                          />
                          <HiddenInput
                            name={isDigitalDeliveryMethodCertified}
                            value={selectedDeliveryType === TranscriptDeliveryTypes.UPLOAD}
                          />
                          <MaterialDropdown
                            // isRequired={props.isRequired}
                            name={deliveryFieldName}
                            key={deliveryFieldName}
                            defaultValue={initialResolvedDeliveryMethod}
                            style={{
                              fontSize: "14px",
                              position: "relative",
                              color: "#5f5f5f",
                            }}
                            listItems={[
                              {
                                value: TranscriptDeliveryTypes.MAIL,
                                label: "Mail",
                              },
                              {
                                value: TranscriptDeliveryTypes.UPLOAD,
                                label: "Digital Upload",
                              },
                            ]}
                          />
                        </td>
                        <td className="table__cell" style={{textAlign: 'center'}}>
                      {!filename &&
                        (watch(deliveryFieldName) || "") !==
                        TranscriptDeliveryTypes.MAIL && (
                        <div>
                        <Button
                        style={{display: "inline-block"}}
                        variant="text"
                        label="Upload File"
                        onClick={triggerUpload(fileFieldName)}
                        />
                      {uploadError.fieldName === fileFieldName && (
                        <div style={{fontSize: ".75rem", color: "red"}}> * {uploadError.message}</div>
                        )}
                        </div>
                        )}
                      {filename &&
                        (watch(deliveryFieldName) || "") !==
                        TranscriptDeliveryTypes.MAIL && (
                        <>
                        <a
                        style={{
                        fontsize: "14px",
                        color: "#5f5f5f",
                        textDecoration: "underline",
                      }}
                        href="#"
                        onClick={handleDownload}
                        >
                      {filename}
                        </a>
                        <a
                        style={{
                        fontsize: "14px",
                        position: "relative",
                        left: "0.5rem",
                      }}
                        href="#"
                        onClick={handleDelete(fileFieldName)}
                        >
                        <Delete
                        className="pointer"
                        style={{color: "rgba(255, 0, 0, 0.5)"}}
                        />
                        </a>
                        </>
                        )}
                        </td>
                      </>
                    }
                    <td className="table__cell" style={{textAlign: 'left'}}>
                    {/* SP-311/3853: Scholarship Review Process: Error message (in RED) needs to stand out */}
                    {(getReduxField(repeatableFieldName(transcriptField.uploadField, i, 'ReviewStatus' )) === 'COMPLETE' || getReduxField(repeatableFieldName(transcriptField.uploadField, i, 'ReviewStatus' )) === 'PEND_OSAC' || getReduxField(repeatableFieldName(transcriptField.uploadField, i, 'ReviewStatus' )) === 'NA') && 
                    <div dangerouslySetInnerHTML={{__html: getReduxField(repeatableFieldName(transcriptField.uploadField, i, 'ReviewStatusDescription' ))}}></div>}
                    {(getReduxField(repeatableFieldName(transcriptField.uploadField, i, 'ReviewStatus' )) !== 'COMPLETE' && getReduxField(repeatableFieldName(transcriptField.uploadField, i, 'ReviewStatus' )) !== 'PEND_OSAC' && getReduxField(repeatableFieldName(transcriptField.uploadField, i, 'ReviewStatus' )) !== 'NA') && 
                    <div style= {{color: '#da3754'}}  dangerouslySetInnerHTML={{__html: getReduxField(repeatableFieldName(transcriptField.uploadField, i, 'ReviewStatusDescription' ))}}></div>}
                    </td>
                    <td className="table__cell" style={{textAlign: 'left'}}>
                    {/* SP-311/3853: Scholarship Review Process: Error message (in RED) needs to stand out */}
                    {(getReduxField(repeatableFieldName(transcriptField.uploadField, i, 'ReviewStatus' )) === 'COMPLETE' || getReduxField(repeatableFieldName(transcriptField.uploadField, i, 'ReviewStatus' )) === 'PEND_OSAC' || getReduxField(repeatableFieldName(transcriptField.uploadField, i, 'ReviewStatus' )) === 'NA') && 
                    <div dangerouslySetInnerHTML={{__html: getReduxField(repeatableFieldName(transcriptField.uploadField, i, 'ReviewInstructions' ))}}></div>}
                    {(getReduxField(repeatableFieldName(transcriptField.uploadField, i, 'ReviewStatus' )) !== 'COMPLETE' && getReduxField(repeatableFieldName(transcriptField.uploadField, i, 'ReviewStatus' )) !== 'PEND_OSAC' && getReduxField(repeatableFieldName(transcriptField.uploadField, i, 'ReviewStatus' )) !== 'NA') && 
                    <div  style= {{color: '#da3754'}} dangerouslySetInnerHTML={{__html: getReduxField(repeatableFieldName(transcriptField.uploadField, i, 'ReviewInstructions' ))}}></div>}
                    </td>
                  </tr>
                  </tbody>
                );
              })}
              <FileFetcher />
            </table>
          </div>
        }
      </RepeatableField>
    </>
  );
};

export default TranscriptUploader;


