import React from 'react';
import { useSelector } from 'react-redux';
import auth from './../../auth';

function Header({isProfileComplete = false}) {
  const { isLoggedIn } = useSelector((state) => state.auth);

  const handleLogout = (e) => {
    e.preventDefault();
    auth.logout();
  };

  /* handle mobile navigation toggle */
  const navigation = document.getElementById('navigation');
  const body = document.getElementsByTagName('body')[0];

  const toggleNav = () => {
    navigation.classList.toggle('nav-open');
    body.classList.toggle('lock-body');
  };

  return (
    <div className="global-header global-header--portal" id="global-header">
      <div className="wrapper">
      <div className="global-header__layout">
        <div className="global-header__brand">
          <a className="brand" href="#"><span className="sr-only">Oregon Student Aid</span></a>
        </div>

        <div className="global-header__utility">
          <a href="//oregonstudentaid.gov/feedback" className="link global-header__feedback">Feedback</a>
          <a target="_blank" href="//www.oregon.gov/highered/institutions-programs/ccwd/Documents/Academic%20Approval/Policy%20and%20Process%20Book%20Documents/Glossary.pdf" className="link global-header__glossary">Glossary</a>
          <a href="//oregonstudentaid.gov/contact-us/" className="link global-header__help">Help</a>
            {
              isLoggedIn && (
                <a href="#logout" className="link global-header__logout" onClick={handleLogout}>
                  <span>Logout &nbsp; </span> <img src='/Vector.svg' />
                </a>
              )
            }
        </div>

        <div className="global-header__navigation">
          <div className="navigation navigation--default" id="navigation">
            { isLoggedIn && (
            <button aria-haspopup="true" aria-expanded="false" className="navigation__toggle" id="navigation__toggle" tabIndex={0} onClick={toggleNav}>
              <span />
              <span />
              <span />
              <span className="sr-only">Toggle Navigation</span>
            </button> )
            }

            <div className="navigation__drawer">
              <div className="nav-item">
                <a className="nav-item__link nav-item__inner" name="Dashboard" href="/dashboard">
                  <span className="nav-item__label">Dashboard</span>
                </a>
              </div>
              <div className="nav-item">
                <a className="nav-item__link nav-item__inner" href="/profile">
                  <span className="nav-item__label">Profile</span>
                </a>
              </div>            
              <div className="nav-item">
                <a className="nav-item__link nav-item__inner" href="/awards">
                  <span className="nav-item__label">Awards</span>
                </a>
              </div>
              <div className="nav-item">
                <a className="nav-item__link nav-item__inner" href="/history">
                  <span className="nav-item__label">History</span>
                </a>
              </div>
              <div className="nav-item">
                <a className="nav-item__link nav-item__inner" href="/messages">
                  <span className="nav-item__label">Messages</span>
                </a>
              </div>
              <div className="nav-item nav-item--mobile-only">
                <a href="/feedback" className="nav-item__link nav-item__inner">
                  <span className="nav-item__label">Feedback</span>
                </a>
              </div>
              <div className="nav-item nav-item--mobile-only">
                <a href="#glossary" className="nav-item__link nav-item__inner">
                  <span className="nav-item__label">Glossary</span>
                </a>
              </div>
              <div className="nav-item nav-item--mobile-only">
                <a href="#help" className="nav-item__link nav-item__inner">
                  <span className="nav-item__label">Help</span>
                </a>
              </div>
              <div className="nav-item nav-item--mobile-only">
                {
                  isLoggedIn && (
                    <a href="#logout" className="nav-item__link nav-item__inner global-header__logout" onClick={handleLogout}>
                      <span className="nav-item__label">Logout &nbsp; </span> <img src='/Vector.svg' />
                    </a>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default Header;
