import { propsToClassKey } from '@material-ui/styles';
import React from 'react';
import Input from './Input';

const NumericalDecimal = (props) => {

  let numberPattern = null;
  let message = "invalid input";
  if (props.precision && props.scale) {
    var wholePart = props.precision - props.scale;
    numberPattern = '\\d{0,' + wholePart + '}(\\.\\d{0,' + props.scale +'})?';

    const maxNumber = 10**wholePart-1;
    const minNumber = props.allowNegative === false ? 0 : -maxNumber;
    message = "Number must be between " + minNumber + " and " + maxNumber + " and only goes to " + props.scale + " decimal place" + (props.scale > 1 ? "s" : "");
  }  
  else {
    numberPattern = '\\d+([.]\\d*)?|[.]\\d+';
  }


  if (props.allowNegative === false) {
    numberPattern = '[+]?' + numberPattern;
  }
  else {
    numberPattern = '[+-]?' + numberPattern;
  }
  numberPattern = '^' + numberPattern + '$';

  const decimalRules = {
    pattern: {
      value: new RegExp(numberPattern),
      message: message
    }
  };

  return (
    <Input {...props} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} rules={decimalRules} />
  );
}


export default NumericalDecimal
