import AnswerLock from "./status/AnswerLock";
import Input from "../Input";
import Telephone from "../Telephone";
import LargeTextEditor from "../LargeTextEditor";

/**
 * 
 */
export const TextVariant = {
  ZIPCODE: 'ZIPCODE',
  ADDRESS: 'ADDRESS',
  TELEPHONE: 'TELEPHONE',
  ESSAY: 'ESSAY'
};

/**
 * render a simple text field for the given answer
 * 
 * @param {*} param0.answer api DTO description of the Text-type answer
 * @param {string} param0.inputLabel label to prompt student for info
 * @param {TextVariant} param0.varian  what, if any, structure of text are we prompting for. no variant means small text box as default
 * @returns {Component}
 */
function TextAnswer({ answer, inputLabel, disabled, variant, ...props }) {

  const isDisabled = disabled || answer.IsLocked;

  const fullLabel = <>{inputLabel} <AnswerLock answer={answer} /></>;

  let rules = null;
  if (variant == TextVariant.ZIPCODE)
    rules = {
      pattern: {
        value: /(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/,
        message: 'Invalid zipcode'
      }
    };

  else if (variant == TextVariant.ADDRESS)
    rules = {
      pattern: {
        value: /^[a-zA-Z0-9\s-]+$/,
        message: "Invalid Address"
      }
    };

  else if (variant == TextVariant.TELEPHONE)
    return <Telephone name={answer.FieldKey} inputLabel={fullLabel} {...props} disabled={isDisabled} />

  else if (variant == TextVariant.ESSAY)
    //indicate requirement of essays, but we always allow students to save as they go if they want.
    return (
      <>
        {fullLabel}
        {props.isRequired && <span className="required">*</span> }        
        <br />
        <LargeTextEditor name={answer.FieldKey} {...props} variant="outlined" isRequired={false} disabled={isDisabled}  /> 
      </>
    );

  return <Input
    name={answer.FieldKey}
    inputLabel={fullLabel}
    placeholder={inputLabel}
    disabled={isDisabled} 
    rules={rules}
    {...props}
  />
}

export default TextAnswer;