import { FIELDS } from "../../store/form/fields";
import { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import Button from "../../components/Button";
import PortalView from "./../../components/Global/PortalView";
import CompleteSubmission from "../../components/CompleteSubmission";
import apiClient from "../../utils/API";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { open } from "../../store/snack/actions";
import EditCollegeChoice from "./EditCollegeChoice";
import EditCollegePlan from "../additional-scholarship-info/EditCollegePlan";
import AppHeader from "../../components/application/AppHeader";
import SectionFormHelper from "../../utils/helper/SectionHelper";
import { useRef } from "react";
import ProgressHeader from "../../components/application/ProgressHeader";
import ApplicationInfo from "../../utils/dtos/ApplicationInfo";
import EditAboutMe from "./EditAboutMe";
import EditWorkingStudent from "./EditWorkingStudent";
import EditLicensePlans from "./EditLicensePlans";
import SectionStateIcon from "../../components/progress/SectionStateIcon";
import ProgramInfo from "../../utils/dtos/ProgramInfo";


const PROGRAM_CODE = "OTSP";

/**
 * full Oregon Teacher Scholars application
 * @returns {Component}
 */
function OTSP() {
    const { year } = useParams();
    const activeYear = parseInt(year);

    const methods = useForm({ mode: "onBlur", });

    const dispatch = useDispatch();


    const sectionGroup = new SectionFormHelper(useState(null), PROGRAM_CODE);
    const collegeChoiceSection = sectionGroup.create(useState(null), useRef(), [
        FIELDS.COLLEGE_CHOICE,
        FIELDS.COLLEGE_DUAL_ENROLLED,
        FIELDS.COLLEGE_STANDING,
        FIELDS.OTSG__ATTENDING_FALL_TERM,
        FIELDS.OTSG__ATTENDING_WINTER_TERM,
        FIELDS.OTSG__ATTENDING_SPRING_TERM
    ], 'College Choice');
    const collegePlansSection = sectionGroup.create(useState(null), useRef(), [
        FIELDS.DEGREES,
        FIELDS.CAREER_GOAL
    ], 'College Plans');
    const workingSection = sectionGroup.create(useState(null), useRef(), [
        FIELDS.TEACHER_WORKING,
        FIELDS.TEACHER_WORKING20HOURS,
        FIELDS.TEACHER_WORKINGKTHRU12,
        FIELDS.TEACHER_ROLE,
        FIELDS.TEACHER_DISTRICT
    ], 'Working Student');
    const licensingSection = sectionGroup.create(useState(null), useRef(), [
        FIELDS.TEACHER_LICENSE_TYPE,
        FIELDS.TEACHER_LICENSE_ENDORSEMENTS,
        FIELDS.TEACHER_LISENCE_BEGIN,
        FIELDS.TEACHER_LICENSE_COMPLETE
    ], 'Licensing');
    const aboutMeSection = sectionGroup.create(useState(null), useRef(), [
        FIELDS.ED_LEVEL_FATHER,
        FIELDS.ED_LEVEL_MOTHER,
        FIELDS.TEACHER_FLUENCY,
        FIELDS.TEACHER_SCHOLARS_REFERRAL,
    ], 'About Me');



    const [programData, setProgramData] = useState(null);
    const [applicationData, setApplicationData] = useState(null);
    const application = new ApplicationInfo(applicationData);
    const program = new ProgramInfo(programData);
    const season = program.getSeason(activeYear);

    useEffect(() => {
        (async () => {
            
            const [program, answers, application] = await Promise.all([
                apiClient.getProgram(PROGRAM_CODE),
                sectionGroup.fetchState(activeYear),
                apiClient.getApplication(PROGRAM_CODE, activeYear)
            ]);
            if (!application.IsStarted){
                await apiClient.startApplication(PROGRAM_CODE, activeYear);
                application.IsStarted = true;
            }
            setProgramData(program);
            setApplicationData(application);

        })();
    }, [])



    const onError = (errors, e) => {
        // console.log("ERROR", errors, e);
    };

    const [showConfirmation, setShowConfirmation] = useState(false);
    const onEdit = () => {
        setShowConfirmation(false);
        window.location.reload(true);
    }

    const onApplicationSubmit = (e) => {

        (async () => {
            try {
                await apiClient.submitApplication(PROGRAM_CODE, activeYear);
                setShowConfirmation(true);
                dispatch(
                    open({
                        message: "Submitted Successfully",
                        severity: "success",
                    })
                );
            }
            catch (ex) {
                dispatch(
                    open({
                        message: ex.message,
                        severity: "success",
                    })
                );
            }

        })();
    }


    return (
        <FormProvider {...methods}>
            <PortalView>
                <div name="ApplicationsPage">
                    <AppHeader program={programData} year={activeYear} />


                    {showConfirmation ? (
                        <CompleteSubmission onEdit={onEdit} applicationHelper={sectionGroup} />
                    ) : (
                        <div className="wrapper">

                            <ProgressHeader sectionGroup={sectionGroup} app={application} />

                            <div className="divider" />

                            <div className="form-wrapper rhythm">

                                <div ref={collegeChoiceSection.ref} >
                                    <EditCollegeChoice section={collegeChoiceSection} season={season} />
                                </div>


                                <div ref={collegePlansSection.ref} >
                                    <EditCollegePlan year={year} status={<SectionStateIcon section={collegePlansSection} />} section={collegePlansSection} />
                                </div>

                                <div ref={workingSection.ref}>
                                    <EditWorkingStudent section={workingSection} />
                                </div>

                                <div ref={licensingSection.ref}>
                                    <EditLicensePlans section={licensingSection} />
                                </div>

                                <div ref={aboutMeSection.ref}>
                                    <EditAboutMe section={aboutMeSection} />
                                </div>

                                {/* Save/Cancel Buttons */}
                                <div className="grant-action-buttons">


                                    {!application.isSubmitted &&
                                        <Button
                                            name="Submit"
                                            label="Submit"
                                            variant="outline"
                                            type="submit"
                                            disabled={!sectionGroup.isComplete}
                                            onClick={onApplicationSubmit}
                                        />
                                    }

                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </PortalView>
        </FormProvider>
    );
}

export default OTSP;
