import { useState, useEffect } from "react";
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from "react-redux";
import Button from "../../components/Button";
import Input from "../../components/Input";
import apiClient from "../../utils/API";
import { open } from "../../store/snack/actions";
import { goBack } from "../../utils";
import { useHistory, useParams } from "react-router-dom";
import PortalView from "../../components/Global/PortalView";
import ProgramInfo from "../../utils/dtos/ProgramInfo";
import Award from "../../utils/dtos/Award";


/**
     * decline process if this is an offer for an initial award 
     * @returns {Component}
     */
function ThankTheDonor() {

    const { code, year } = useParams();
    const notificationLink = `/offer/${code}/${year}`;
    const dispatch = useDispatch();
    const history = useHistory();

    const methods = useForm({ mode: "onBlur", });
    const { watch, handleSubmit } = methods;
    const [programData, setProgramData] = useState(null);
    const [awardsData, setAwardsData] = useState(null);
    const [isMessageSent, setMessageSent] = useState(false);

    const program = new ProgramInfo(programData);
    const award = new Award(awardsData);

    useEffect(() => {
        (async () => {
            const [program, awards] = await Promise.all([
                apiClient.getProgram(code),
                apiClient.getApplicationAwardStatus(code, year),
            ]);

            setProgramData(program);
            setAwardsData(awards);
        })();
    }, []);


    /**
     * form submit handler
     */
    const onSubmit = async (data) => {
        const message = data["message"];

        try {
            await apiClient.post('/award/send', { programCode: code, year, message });
            setMessageSent(true);

            dispatch(
                open({
                    message: "Message sent",
                    severity: "success",
                })
            );

        } catch (e) {
            dispatch(
                open({
                    message: e.message,
                    severity: "success",
                })
            );
        }
    }

    const onCancel = () => {
        goBack({
            history,
            defaultRoute: notificationLink
        });
    }


    return (
        <FormProvider {...methods}>
            <form>
                <PortalView>
                    <div name="ApplicationsPage" className="Scholarships">
                        <div className="rhythm">
                            <div className="page-intro">
                                <div className="wrapper">
                                    <div className="page-intro__layout">
                                        <div className="page-intro__icon">
                                            <img src="/dashboard.svg" alt="Student Portal" style={{ marginTop: ".25rem" }} />
                                        </div>
                                        <div className="page-intro__content rhythm">
                                            <h1 className="heading heading--h1">
                                                {program.name}
                                                &nbsp; &mdash; &nbsp;
                                                ${award.authorizedAmount?.toFixed(2)}
                                            </h1>
                                            <a href={notificationLink}>Return to Award</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="wrapper rhythm">

                                <div className="form-wrapper">                                    
                                    <div style={{ marginBottom: '3em' }}>
                                        <Input name="message" multiline rows={10} fullWidth={true} isRequired={true} disabled={isMessageSent}
                                            placeholder="Send a thank you to your scholarship donor. Your message will be forwarded to the donor and the fund manager."
                                            inputLabel={<span className="heading heading--h3">Send the Donor a Thank You Note</span>} />
                                    </div>

                                    {!isMessageSent &&
                                        <div className="grant-action-buttons" >
                                            <div>

                                                <Button
                                                    name="Send"
                                                    label='Send Thank You'
                                                    onClick={handleSubmit(onSubmit)}
                                                />

                                                <Button
                                                    name="Cancel"
                                                    label="Cancel"
                                                    variant="text"
                                                    onClick={onCancel}
                                                />

                                            </div>
                                        </div>
                                    }
                                </div>


                            </div>

                        </div>
                    </div>
                </PortalView>

            </form>
        </FormProvider>
    );
}

export default ThankTheDonor;