import React, { useState } from "react";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "./Button";
import { Alert } from "@material-ui/lab";
import { useEffect } from "react";
import { FIELDS } from "../store/form/fields";
import { actions } from "../store";
import { useSelector } from "react-redux";
import ConvertCode from "./Utility/ConvertCode";
import apiClient from "../utils/API";

function AwardAnnouncement({
  open,
  onClose,
  onAccept,
  details,
  loading,
  ...props
}) {
  // console.log("Announcement", details);
  const formState = useSelector((state) => state.form);
  const get = (fieldKey) => formState.fields[fieldKey]?.value;
  const [collegeName, setCollegeName] = useState("");

  const getAddress = (fieldKey) => {
    const street = formState?.fields[fieldKey]?.Address.value;
    const city = formState?.fields[fieldKey]?.CityId.value;
    const county = formState?.fields[fieldKey]?.CountyId.value;
    const zip = formState?.fields[fieldKey]?.Zip.value;
    return { street, city, county, zip };
  };

  const fieldKeys = [
    FIELDS.FIRST_NAME,
    FIELDS.LAST_NAME,
    FIELDS.PERMANENT_ADDRESS,
  ];

  useEffect(() => {
    actions.fetchBasicFields(fieldKeys);
  }, []);

  useEffect(() => {
    const collegeId = details?.awardState?.programAwardResults[0]?.CollegeId;
    if (collegeId) {
      apiClient.getCollege(collegeId).then((college) => {
        setCollegeName(college.Name);
      });
    }
  }, []);
  return (
    <>
      <DialogTitle id="alert-dialog-title">Award Announcement</DialogTitle>
      {details ? (
        <>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Alert severity="danger" color="warning">
                <p>
                  Failure to accept this award or to notify OSAC of a change in
                  college in a timely manner may delay or terminate your award
                </p>
              </Alert>
              <div className="table-wrapper">
                <table className="table">
                  <tbody>
                    <tr>
                      <td valign="top" style={{ fontWeight: "bold" }}>
                        Award For:
                      </td>
                      <td>
                        {get(FIELDS.FIRST_NAME) + " " + get(FIELDS.LAST_NAME)}
                        <span style={{ display: "block" }}>
                          <ConvertCode
                            isAddress={true}
                            address={getAddress(FIELDS.PERMANENT_ADDRESS)}
                          />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>Award name:</td>
                      <td>{details.title}</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>Academic Year:</td>
                      <td>{details.awardState.semesterYearDescriptor}</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>Award Amount:</td>
                      <td>
                        $
                        {details.awardState &&
                          details.awardState.totalAmountAwarded &&
                          details.awardState.totalAmountAwarded.toLocaleString()}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>
                        Award Notification Date:
                      </td>
                      <td>N/A</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>
                        Award Accept/Decline Date:
                      </td>
                      <td>You still need to accept/decline your award</td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>Current Status:</td>
                      <td>
                        Selected as a recipient - award notification not sent to
                        recipient yet
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>Current School:</td>
                      <td>{collegeName}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h5 className="heading heading--h5">Scholarship Acceptance</h5>
              <ul className="list">
                <li className="li">Failure to accept this award may delay or terminate your award and delay awarding an alternate recipient.</li>
                <li className="li">If you are not sure about accepting or cannot accept the award, contact OSAC as soon as possible at <a href="mailto:AwardInfo@hecc.oregon.gov">AwardInfo@hecc.oregon.gov</a></li>
              </ul>
              <h5 className="heading heading--h5">Enrollment</h5>
              <ul className="list">
                <li className="li">If you transfer schools or become dual enrolled during the academic year, notify OSAC early to allow time to process change. School changes may result in adjusted or cancelled award amounts.</li>
                <li className="li">If you considering a leave of absence, contact OSAC at{" "} <a href="mailto:AwardInfo@hecc.oregon.gov">AwardInfo@hecc.oregon.gov</a>{" "} to determine if that will jeopardize your scholarship eligiblity.</li>
              </ul>
              <h5 className="heading heading--h5">Scholarship Disbursement</h5>
              <ul className="list">
                <li className="li">OSAC sends funds to your school financial aid office prior to the beginning of each term/semester. If you do not attend school for a term(s), you will not receive that portion of your award.</li>
                <li className="li">You must maintain the required enrollment status (see Conditions of Award above) and satisfactory academic progress to receive disbursements throughout the year.</li>
                <li className="li">Scholarships must be used for educational expenses as defined by your school. These include tuition and fees, room and board, books, transportation, and education-related personal costs.</li>
              </ul>
              <h5 className="heading heading--h5">Accept or Decline Award</h5>
              <ul className="list">
                <li className="li">If you accept your award in compliance with the Conditions of the Award, click Accept button at the bottom to indicate your understanding of the Conditions.</li>
                <li className="li">If you are not able to meet the Conditions of the Award or are not attending a valid school, click close and click on decline on the award. This will allow OSAC to award an alternate.</li>
                <li className="li">If you are not attending school this year but will in the future and want to apply for a deferral, contact OSAC at{" "} <a href="mailto:AwardInfo@hecc.oregon.gov">AwardInfo@hecc.oregon.gov</a></li>
                <li className="li">If you are unsure at this time, you may return to this page at before your acceptance deadline has passed.</li>
              </ul>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} label="Close" variant="outline"></Button>
            <Button
              loading={loading}
              onClick={onAccept}
              label="Accept"
              variant="solid"
            ></Button>
          </DialogActions>
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default AwardAnnouncement;
