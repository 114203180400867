import moment from 'moment';

/**
 * interpret a program season DTO
 */
export class ProgramSeason {

    /**
     * costructor
     * @param {*} data ProgramSeasonDto
     */
    constructor(data) {
        this._data = data;
    }

    /**
     * is this info ready for use?
     * @returns {Boolean}
     */
    get isLoaded(){
        return this._data != null;
    }

    /**
     * awarding year this season refers to
     */
    get year() {
        return this._data.Year;
    }

    /**
     * is this season's application currently available/active in any capacity
     */
    get isAvailable(){
        return this._data.IsAvailable;
    }

    /**
     * all notes defined for this program
     * @returns {Array<*>}
     */
    get notes(){
        return this._data.Notes ?? []; //we might have a summary description, on which no notes are included
    }

    /**
     * get a specific event
     * @param {String} eventCode
     * @param {String} term
     * @returns {ProgramEvent}
     */
    getEvent(eventCode, term = 'None') {
        if (this._data == null)
            return null;
        if  (this._data.Calendar == null)
            return null;

        const compareCode = eventCode.toUpperCase();
        
        for (const calendarEvent of this._data.Calendar) {
            var calendarEventCode = calendarEvent.Code.toUpperCase();
            if (calendarEventCode !== compareCode 
                && !(calendarEventCode == "END" && compareCode === "ENDDATE")
                && !(calendarEventCode == "ENDDATE" && compareCode === "END")
                )
                continue;
            if (calendarEvent.Term.toUpperCase() !== term.toUpperCase())
                continue;

            return new ProgramEvent(calendarEvent, this.year);
        }
        return null;
    }

    /**
     * convenience method to just quickly get a display-friendly date for a given event
     * @param {String} eventCode 
     * @param {String} term 
     * @param {String} format 
     * @returns {String}
     */
    getDate(eventCode, term = 'None', format = "MMMM Do, YYYY h:mm a"){
        const evnt =  this.getEvent(eventCode, term);
        if (evnt == null)
            return null;
            
        return moment(evnt._data.Date).format(format);
    }
    /**
     * get a special program configuration option
     * @param {String} configurationCode 
     * @returns {*} usually a string or int
     */
    getConfiguration(configurationCode){
        let tmp = this._data.Configurations;
        for (const i in tmp) {
            if(tmp[i].Name == configurationCode) {
                return tmp[i].Value;
            }           
        }
        return null;
    }

    /**
     * every program should at least have a start event
     * @returns {ProgramEvent}
     */
    get startEvent() {
        // look for new-style variable date before old legacy date. 
        // TODO: clean these up in the API
        return this.getEvent('StartDate', 'None') || this.getEvent('START');
    }

    /**
     * every program should at least have an end event
     * TODO: should i look for a specific one if many are given?? Or should i leave that up to context?
     * @returns {ProgramEvent}
     */
    get endEvent() {
        return this.getEvent('END');
    }

    /**
     * has this program started (convenience method)
     * @returns {Boolean}
     */
    get isStarted() {
        if (this.startEvent == null)
            return false;

        return this.startEvent.isPassed;
    }

    /**
     * convenience method to just quickly get a display-friendly date for a given event
     * @param {String} eventCode 
     * @param {String} term 
     * @param {String} format 
     * @returns {String}
     */
      getDate(eventCode, term = 'None', format = "MMMM Do, YYYY h:mm a"){
        const evnt =  this.getEvent(eventCode, term);
        if (evnt == null)
            return null;
            
        return moment(evnt._data.Date).format(format);
    }


    getDeadline(term = 'None'){
        return this.getEvent('END', term);
    }
    /**
     * 
     * @param {string} term 
     * @returns {string}
     */
    getDeadlineDisplay(term = 'None'){
        if (!this.isLoaded) //not ready yet
            return {
                term: term,
                deadline: null,
            };

        var programEvent = this.getDeadline(term);
        if (!programEvent)
            return {
                term: term,
                deadline: null,
            };

        return {
            term: term + " " + programEvent.year,
            deadline: programEvent.displayDate
        }
    }
}

/**
 * events that apply to a given program 
 */
 class ProgramEvent {

    /**
     * constructor
     * @param {*} data raw dto provided by API describing one specific event 
     * @param {Numeric} year what year did this event come from? help qualify for display purposes
     */
    constructor(data, year){
        this._data = data;
        this._year = year;
    }
    
    /**
     * standard text-formatted date
     * @returns {String}
     */
    get displayDate(){
        return moment(this._data.Date).format('MMM Do, YYYY');
    }
    /**
     * actual date
     * @returns {Date}
     */
    get date(){
        return new Date(this._data.Date);
    }

    /**
     * term, if any
     */
    get term(){
        return this._data.Term;
    }

    /**
     * year with term offset
     */
    get year(){        
        const compareTerm = this.term.toUpperCase();
        if (compareTerm == 'FALL' || compareTerm == 'NONE' || compareTerm == 'SUMMER' || !compareTerm)        
            return this._year;        

        return this._year + 1;
    }

    /**
     * has this date passed
     * @returns {Boolean}
     */
    get isPassed() {
        var now = Date.now();        
        return this.date <= now;
    }


}

export default ProgramSeason;