import React, { useEffect } from 'react';
import _debounce from 'lodash.debounce'
import Modal from '../Alerts/Modal';
import { useState } from 'react';
import auth from '../../auth';


function Overlay({
    children,
    visible = false
}){
    useEffect(()=>{
        if(visible){
            document.documentElement.style.overflow = 'hidden';
        }
        else {
            document.documentElement.style.overflow = 'auto';
        }
        return () => document.documentElement.style.overflow = 'auto';
    },[visible])

  return (
    visible ? <div className="overlay">{children}</div> : null
  )
}

export default Overlay;