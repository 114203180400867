import { Controller } from 'react-hook-form';
import FieldValueAdapter from './Utility/FieldValueAdapter';

export const checkName = (name, props) => {
    if (!name) {
        throw new Error(`no name selected for field with label ${props.label || props.inputLabel}`);
    };
};
export const checkRules = (rules, isRequired) => {
    let defaultRules = { required: { value: isRequired || false, message: 'Field required' } };
    const isRulesSet = typeof rules === 'object' && rules;
    return rules = isRulesSet ? Object.assign(defaultRules, rules) : defaultRules;
}

/**
 * 
 * @param {*} Component  child component
 * @param {*} rules 
 * @param {*} options 
 * @returns {Func<Component>} functional syntax to create a new decorated component index of type Component
 */
export default function InputDecorator(Component, rules, options = {}) {

    /**
     * @returns {Component} in functional syntax 
     */
    return function ({ useController = true, withAutoPopulate, ...props }) {
        useController && checkName(props.name, props);
        const newRules = checkRules(props.rules || rules, props.isRequired);


        const isValueObject = options.isValueObject;
        return (
            useController ?
                <>
                    <Controller
                        name={props.name}
                        defaultValue={props.defaultValue || null}
                        rules={newRules}
                        render={({ onChange, onBlur, value, ref }) => (
                            <Component onChange={onChange} onBlur={onBlur} value={value} ref={ref} {...props} />
                        )}
                    />
                    <FieldValueAdapter name={props.name} isValueObject={isValueObject} withAutoPopulate={withAutoPopulate} />
                </>
                :
                <Component rules={newRules} {...props} />
        );
    };
};

