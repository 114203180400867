/**
 * quick summary of activity answer
 * @param {*} param0 
 * @returns {Component}
 */
function ActivityDisplay({ answer }) {

    return <>{answer?.Title}</>;
}

export default ActivityDisplay;
