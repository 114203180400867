import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _get from 'lodash.get';
import { actions } from "../../store";

export const repeatableFieldName = (parentName, index = null, ...properties) => {
  return `${parentName}[${index}]${properties.length ? '.' : ''}${properties.join('.')}`
}

const RepeatableField = ({ name, initialCount = 0, children }) => {

  const [count, setCount] = useState(initialCount);
  const [deletedIndices, setDeletedIndices] = useState([]);
  const [availableIndices, setAvailableIndices] = useState([...new Array(count)].map((_, i) => i));

  // keep track of previously deleted indices to disable auto population of value from redux store
  const [previouslyDeletedIndices, setPreviouslyDeletedIndices] = useState({});

  // get redux state and enuerate field array if exists.
  const formState = useSelector(state => state.form);
  const existingFieldLength = _get(formState, `fields.${name}.length`, 0);


  useEffect(() => {
    if (existingFieldLength > 0) {
      setAvailableIndices([...new Array(existingFieldLength)].map((_, i) => i))
      setCount(existingFieldLength);
    }
  }, [existingFieldLength])

  const handleAdd = () => {
    let newIndex;
    // re-use past deleted indices
    if (deletedIndices.length) {
      // gets the lowest valued index in deletedIndices
      newIndex = Math.min(...deletedIndices);
      setDeletedIndices(deletedIndices.filter(i => i !== newIndex));
    }
    else {
      newIndex = count;
      setCount(newIndex + 1);
    }
    setAvailableIndices([
      ...availableIndices,
      newIndex
    ])
    // stateChanger(true)
  }
  const handleDelete = (index) => () => {
    const id = _get(formState, `fields.${name}.[${index}].id`, -1);
    if (id !== -1) {
      actions.addDeletedRepeatableFieldId({
        name,
        id
      })
    }

    setDeletedIndices([
      ...deletedIndices,
      index
    ]);
    setAvailableIndices(availableIndices.filter(i => i !== index));
    setPreviouslyDeletedIndices({
      ...previouslyDeletedIndices,
      [index]: 1
    })
  }

  return children(availableIndices, handleAdd, handleDelete, previouslyDeletedIndices);
}

export default RepeatableField;
