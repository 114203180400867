import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputDecorator from "./InputDecorator";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1.5),
  },
}));

function MaterialDropdown({
  label,
  name,
  rules,
  isRequired = false,
  description,
  listItems,
  helperText,
  inputLabel,
  ...props
}) {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {isRequired === true ? <span style={{ color: "red" }}>*</span> : ""}
      <Select
        required={isRequired}
        variant="standard"
        fullWidth={true}
        aria-label={description}
        {...props}
      >
        {listItems.map((item, i) => (
          <MenuItem key={`${name}-${i}}`} value={item.value} label={item.label}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

export default InputDecorator(MaterialDropdown);
