import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import auth from '../../auth';

const TIME_UNTIL_LOGOUT_SECONDS = 30;

export default function Modal({ open = false, title = '', body = '', handleClose = ()=>{} }) {

    const createLogoutTimer = () => setTimeout(()=>{
        auth.logout();
    },1000*TIME_UNTIL_LOGOUT_SECONDS);

    useEffect(()=>{
        let timer;
        if(open){
            timer = createLogoutTimer();
        }
        return () => timer && clearTimeout(timer);
    },[open])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            fullWidth={true}
            maxWidth={'sm'}
        >   
            {
                title && <DialogTitle id="modal-title">{title}</DialogTitle>
            }
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {body}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    I'm still here
                </Button>
            </DialogActions>
        </Dialog>
    );
}