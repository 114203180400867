/* eslint-disable no-undef */
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { getCollege, getDegree } from './Utility/ConvertCode';
import moment from 'moment';
import get from 'lodash/get'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#333333',
    textTransform: 'uppercase',
    opacity: '75%',
  },
  details: {
    opacity: '1',
    textTransform: 'none',
    fontWeight: 'normal',
    fontSize: '14px',
  },
  section: {
    padding: '.5rem 0',
  },

}));

function CollegeDisplay({listColleges, details, ...props}) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  return (
    <div className={classes.root} style={{width: '100%'}}>
      <div style={{ paddingBottom: '2rem' }}>
        <Accordion square onChange={() => setExpanded(!expanded)}  style={{ borderRadius: expanded ? '4px 4px 0 0' : '4px'}}>
          <AccordionSummary
            expandIcon={expanded ? <RemoveIcon style={{color: 'white'}}/> : <AddIcon style={{color: 'white'}}/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{background: 'rgba(44, 114, 116, 1)',  borderRadius: expanded ? '4px 4px 0 0' : '4px'}}
          >
            <div
              style={{}}>
              {details.college} <span className={classes.details} > — {`${details.dates.start} - ${details.dates.end ? details.dates.end : 'Present'}`}</span>
            </div>
          </AccordionSummary>
          <AccordionDetails style={{alignText: 'left'}}>
            <div className={classes.section}>
              <div className={classes.heading} >College/University Name</div>
              <div className={classes.details} >{details.college}</div>
            </div>
            <div className={classes.section}>
              <div className={classes.heading} >Dates Attended</div>
              <div className={classes.details} >{`${details.dates.start} - ${details.dates.end}`}</div>
            </div>
            <div className={classes.section}>
              <div className={classes.heading} >Degree Earned</div>
              <div className={classes.details} >{details.degree}</div>
            </div>
            <div className={classes.section}>
              <div className={classes.heading} >Credits Earned</div>
              <div className={classes.details} >{details.credits}</div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ colleges })=>{
  const [listColleges, setListColleges] = useState([]);
  useEffect(()=>{
    (async ()=>{
      const _listColleges = [];
      for (const i in colleges) {
        const college = {
          degree: colleges[i].DegreeEarned?.value ? await getDegree(colleges[i].DegreeEarned?.value) : '',
          college: colleges[i].SchoolCode?.value ? await getCollege(colleges[i].SchoolCode?.value) : get(colleges[i], 'UnlistedName.value') || '',
          dates: {
            start: colleges[i]?.AttendedStart?.value ? moment(colleges[i]?.AttendedStart?.value).format('MMMM, YYYY') : '',
            end: colleges[i]?.AttendedStop?.value ? ( moment(colleges[i]?.AttendedStop?.value).format('MMMM, YYYY') || " Current" ) : ''
          },
          credits: colleges[i]?.CreditsEarned?.value ? colleges[i]?.CreditsEarned?.value : ''
        }
        _listColleges.push(college);
      }
      setListColleges(_listColleges)
    })();
  },[colleges]);

  return (
    listColleges
    .slice(0, colleges?.length)
    .map((item, i) =>
      <CollegeDisplay details={item} key={i}/>
    )
  )
}
