import { useEffect, useState } from "react";
import apiClient from "../../utils/API";
import DropdownSearch from "../DropdownSearch";

export const UNLISTED_COLLEGECODE = "999999";

export default ({
  listItems,
  includeUnlisted = false,
  placeholder,
  ...props
}) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    (async () => {
      const colleges = await apiClient.get(`/college/colleges`);
      let items = includeUnlisted
        ? [{ label: "Other, Unlisted", value: UNLISTED_COLLEGECODE }] //ideally we'll scrub this out during save, but if not, at least the data will still make sense
        : [];
      items = items.concat(
        colleges.map((r) => ({
          ...r,
          label: r.Name,
          value: r.Code,
        }))
      );

      // Remove duplicate schools
      let duplicateNames = [];
      let uniqueSchools = items.reduce((unique, o) => {
        if (
          !unique.some((obj) => obj.label === o.label && obj.State === o.State)
        ) {
          unique.push(o);
        }
        return unique;
      }, []);

      // Remove duplicate names (w/ different state)
      uniqueSchools = uniqueSchools.reduce((unique, o) => {
        if (!unique.some((obj) => obj.label === o.label)) {
          unique.push(o);
        } else {
          duplicateNames.push(o);
          let original = unique.filter((item) => item.label === o.label);
          for (const i in original) duplicateNames.push(original[i]);
          unique = unique.filter((item) => item.label !== o.label);
        }
        return unique;
      }, []);

      // Update label to include State
      for (const i in duplicateNames) {
        duplicateNames[i].label =
          duplicateNames[i].label + " " + duplicateNames[i].State;
        uniqueSchools.push(duplicateNames[i]);
      }
      setItems(uniqueSchools);
    })();
  }, []);

  return (
    <DropdownSearch
      {...props}
      placeholder={placeholder || "College"}
      listItems={items}
      includeUnlisted={includeUnlisted}
    />
  );
};
