import { actions } from './../../store';
import { useSelector } from 'react-redux';
import Input from './../../components/Input';
import Checkbox from './../../components/Checkbox';
import { useForm, FormProvider } from "react-hook-form";
import { useState, useEffect } from 'react';
import { FieldSection, FieldRow, FieldSlot } from '../../components/Forms/FieldSection';
import { FIELDS, AnswerTypeCollegeProps } from '../../store/form/fields';
import { compositeFieldname } from "../../utils";
import CollegeDropdown, { UNLISTED_COLLEGECODE } from '../../components/DataDriven/CollegeDropdown';
import HiddenInput from '../../components/Utility/HiddenInput';
import FormSection from '../../components/FormSection';
import StatePicker from './../../components/DataDriven/StatePicker';
import _get from 'lodash.get';
import { open } from "../../store/snack/actions";
import { useDispatch } from 'react-redux';
import DropdownAnswer from '../../components/answer/DropdownAnswer';
import AnswerUpdateResult from '../../utils/dtos/AnswerUpdateResult';

/**
 * Standard college of choice section
 * 
 * @param {*} param0 
 * @returns 
 */
function EditCollegeOfChoice({ year = 0, hideDualEnrolled = true, onChange, onCancel, status, section, isUnlistedCollegeChoice }) {

    // const authState = useSelector((state) => state.auth);
    const reduxFormState = useSelector((state) => state.form);

    const awardImpactedMessage = "Thank you for updating your information. This change may affect your eligibility for the grants and/or scholarships you have submitted an application for and/or may have been awarded. Your information is currently being reviewed by OSAC staff to determine your continued eligibility."

    const methods = useForm({
        mode: 'onBlur'
    });
    const { errors, formState, getValues, setValue, handleSubmit, watch } = methods;

    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();

    const onSubmit = async (data, e) => {
        data[FIELDS.COLLEGE_DUAL_ENROLLED] = (data[FIELDS.COLLEGE_DUAL_ENROLLED] == 'True' || data[FIELDS.COLLEGE_DUAL_ENROLLED] == true) ? true : false
    
        try {
            setLoading(true)
            const result = await actions.submitForm(data, [], [], year);
            console.log(data, e);
            result.map(result => {
                const impacted = result.map(r => new AnswerUpdateResult(r)).find(r => r.IsAwardImpacted === true)
                console.log('checking for award impact:', impacted)
                if (impacted) {
                    console.log('impacted!')
                    dispatch(open({
                        message: awardImpactedMessage,
                        severity: "success",
                    }))
                } else {
                    dispatch(
                        open({
                            message: "Saved College Choice Successfully",
                            severity: "success",
                        })
                    );
                }
                return null
            })
            if (typeof onChange === 'function') {
                onChange(true);
            }
        } catch (e) {
            dispatch(open({
                message: e.message,
                severity: "success",
            }))
        } finally {
            setLoading(false)
        }

    };

    const onError = (errors, e) => {
        // console.log('ERROR',errors, e);
    }

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
        }
    }, [])
    const alertUser = e => {
        e.preventDefault()
        e.returnValue = ''
    }

    const collegeOfChoiceCode = watch(compositeFieldname(FIELDS.COLLEGE_CHOICE, AnswerTypeCollegeProps.CODE));
    const unlistedCollegeOfChoiceCode = _get(reduxFormState, `fields.${compositeFieldname(FIELDS.COLLEGE_CHOICE, AnswerTypeCollegeProps.UNLISTED_CODE)}.value`);
    const preserveNoCollegeCodeflag = isUnlistedCollegeChoice && (unlistedCollegeOfChoiceCode === ""); //Unlisted College Choice can exist with & without college code.
    const noCollegeCodeFlag = watch('noCollegeCode');
    const isUnlistedCollegeSelected = collegeOfChoiceCode?.value === UNLISTED_COLLEGECODE;

    useEffect(() => {
        if (noCollegeCodeFlag) {
            setValue(compositeFieldname(FIELDS.COLLEGE_CHOICE, AnswerTypeCollegeProps.UNLISTED_CODE), "")
        }
    }, [noCollegeCodeFlag])


    useEffect(() => {
        // If year changes and if year is not null-- fetch basic fields
        if (year) {
            (async () => {
                try {
                    const fieldKeys = [
                        FIELDS.COLLEGE_CHOICE,
                        FIELDS.COLLEGE_STANDING,
                        FIELDS.COLLEGE_DUAL_ENROLLED
                    ]
                    const result = actions.fetchBasicFields(fieldKeys, year);

                } catch (e) {
                }
            })()
        }
        // Trigger useEffect if year updates
    }, [year])

    return (
        <FormProvider {...methods} >
            <form onSubmit={handleSubmit(onSubmit, onError)} >
                {/* NOTE: extra save button is located within FormSection component */}
                <FormSection title='College Choice' isEditable={true} onSaveClick={handleSubmit(onSubmit, onError)} onCancelClick={onCancel} loading={loading} status={status}>
                    <FieldSection title={`Your current college choice for the ${year}-${(year + 1).toString().substr(-2)} school year`}>
                        <FieldRow>
                            <FieldSlot>
                                <HiddenInput name={compositeFieldname(FIELDS.COLLEGE_CHOICE, AnswerTypeCollegeProps.TYPE)} defaultValue="College" />
                                <CollegeDropdown isRequired={true} placeholder="College" name={compositeFieldname(FIELDS.COLLEGE_CHOICE, AnswerTypeCollegeProps.CODE)} includeUnlisted={true} inputLabel='College or University Name' variant='outlined' />
                            </FieldSlot>
                        </FieldRow>
                        {isUnlistedCollegeSelected && (
                            <>
                                <FieldRow>
                                    <FieldSlot>
                                        <Input isRequired={!noCollegeCodeFlag} name={compositeFieldname(FIELDS.COLLEGE_CHOICE, AnswerTypeCollegeProps.UNLISTED_CODE)} disabled={noCollegeCodeFlag} inputLabel='College Code' />
                                    </FieldSlot>
                                    <FieldSlot>
                                        <div>
                                            <Checkbox name='noCollegeCode' label="College is not a Title IV college and has no college code" defaultValue={preserveNoCollegeCodeflag} />
                                        </div>
                                    </FieldSlot>
                                </FieldRow>
                                <FieldRow>
                                    <FieldSlot>
                                        <Input isRequired={true} name={compositeFieldname(FIELDS.COLLEGE_CHOICE, AnswerTypeCollegeProps.UNLISTED_NAME)} inputLabel='College Name' />
                                    </FieldSlot>
                                </FieldRow>
                                <FieldRow>
                                    <FieldSlot>
                                        <StatePicker isRequired={true} name={compositeFieldname(FIELDS.COLLEGE_CHOICE, AnswerTypeCollegeProps.UNLISTED_STATE_NAME)} placeholder="State" label="State" variant="outlined" />
                                    </FieldSlot>
                                    <FieldSlot>
                                        <Input isRequired={true} name={compositeFieldname(FIELDS.COLLEGE_CHOICE, AnswerTypeCollegeProps.UNLISTED_CITY_NAME)} inputLabel='City' placeholder='City' variant='outlined' />
                                    </FieldSlot>
                                </FieldRow>
                            </>
                        )}
                        {!hideDualEnrolled &&
                            <FieldRow>
                                <FieldSlot>
                                    <Checkbox name={FIELDS.COLLEGE_DUAL_ENROLLED} label="I will be dual enrolled with another school while attending this school." />
                                </FieldSlot>
                            </FieldRow>
                        }
                        <FieldRow className="rhythm">
                            <FieldSlot className="rhythm rhythm--deep">
                                <div className="rhythm">
                                    <p>If unlisted college above, check the <a href="https://studentaid.gov/fafsa-app/FSCsearch" target="_blank">Federal Database</a> to get the college code, college name, and college city.  Select the state of your college choice from the first drop-down list above.</p>
                                    <p>If you can't find the college in the Federal database, contact your college's financial aid staff to ensure that the college is eligible to receive Title IV funds.</p>
                                </div>
                            </FieldSlot>
                        </FieldRow>
                    </FieldSection>

                    <FieldSection title={`College Standing as of Fall Term ${year}`} withDivider={true}>
                        <FieldRow>
                            <FieldSlot>
                                <DropdownAnswer isRequired={true} answer={section.getAnswerByField(FIELDS.COLLEGE_STANDING)} label="College Standing" />
                            </FieldSlot>
                        </FieldRow>
                    </FieldSection>
                    NOTE: College choice saves seperately from application. Save college choice before submitting application.
                </FormSection>
            </form>
        </FormProvider>
    );
}

export default EditCollegeOfChoice;
