import { FormProvider, useForm } from "react-hook-form";
import FormSection from "../../components/FormSection"; 
import { FieldSection, FieldRow, FieldSlot } from '../../components/Forms/FieldSection';
import { FIELDS } from '../../store/form/fields';
import DropdownAnswer from '../../components/answer/DropdownAnswer';
import TextAnswer, { TextVariant } from "../../components/answer/TextAnswer";
import SectionStateIcon from "../../components/progress/SectionStateIcon";
import { useDispatch } from "react-redux";
/**
 * 
 * @param {SectionHelper} param0.section 
 * @returns {Component}
 */
function EditAboutMe({ section }) {


    const dispatch = useDispatch();
    const methods = useForm({ mode: 'onBlur' });
    const { errors, formState, getValues, setValue, handleSubmit, watch } = methods;

    const onSubmit = (data) => {
        section.saveAnswers(data, dispatch);
    }

    const onError = (e) => {

    }
    return (
        <FormProvider {...methods} >
            <form onSubmit={handleSubmit(onSubmit, onError)} >
                <FormSection title={section.title} isEditable={true} onSaveClick={handleSubmit(onSubmit, onError)} 
                    status={<SectionStateIcon section={section} />} loading={section.isLoading} >
                    <FieldSection title="Parent or Guardians Highest Level of Completed Education" withDivider={true}>
                        <FieldRow>
                            <FieldSlot>
                                <DropdownAnswer isRequired={true} answer={section.getAnswerByField(FIELDS.ED_LEVEL_FATHER)} label='Parent 1' />
                            </FieldSlot>
                            <FieldSlot>
                                <DropdownAnswer isRequired={true} answer={section.getAnswerByField(FIELDS.ED_LEVEL_MOTHER)} label='Parent 2' />
                            </FieldSlot>
                        </FieldRow>                        
                    </FieldSection>
                    <FieldSection title="Language Fluency">
                        <FieldRow>
                            <FieldSlot>
                                <TextAnswer answer={section.getAnswerByField(FIELDS.TEACHER_FLUENCY)} variant={TextVariant.ESSAY} 
                                    isRequired={true}  characterLimit={500}
                                    inputLabel="If you grew up speaking a language other than English at home, what is that language? Are you able to write, read, and speak that language fluently?  Are you able to pass a college-level verification test if asked to? (You may answer 'N/A' if not applicable)  " />
                            </FieldSlot>
                        </FieldRow>
                    </FieldSection>
                    <FieldSection title="Outreach">
                        <FieldRow>
                            <FieldSlot>
                                <DropdownAnswer isRequired answer={section.getAnswerByField(FIELDS.TEACHER_SCHOLARS_REFERRAL)}
                                    label='How did you find out about the Oregon Teacher Scholars program?' />
                            </FieldSlot>
                        </FieldRow>
                    </FieldSection>
                </FormSection>
            </form>
        </FormProvider>
    );
}

export default EditAboutMe;