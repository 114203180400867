import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import _get from 'lodash.get';
import InputDecorator from '../InputDecorator';


function HiddenInput({ name, defaultValue, value, isRequired, ...props }) {


  const { register, setValue, unregister } = useFormContext({ mode: 'onBlur' });
  
  useEffect(()=>{
    let resolvedValue = value || defaultValue;
    register(name);
    if(resolvedValue){
      setValue(name,resolvedValue);
    }

    return () => unregister(name);
  },[value]);

  // This allows the hidden input to unregister if not required 
  // or else the field will be required until input is entered
  useEffect(()=>{
    if(isRequired === false){
      unregister(name);
    }
  },[isRequired]);

  return null;
}

export default InputDecorator(HiddenInput)