import { useState, Fragment } from "react";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputDecorator from '../../components/InputDecorator';
import React from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import { useFormContext } from 'react-hook-form';
import { get } from 'lodash';

/**
 *
 * @param {*} options Option set from API
 * @param {String} name field name
 * @param {*} answer Answer description from api
 * @returns
 */
 function CohortTable({ name, listItems, label, description, control, disabled= false, style, ...props}) {

  const [selected, setSelected] = useState("");
  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  const getNoteFromOption = (option, category) => {
    if (!option || !option.Notes) {
      return null;
    }
    const notes = option.Notes
    const note = notes.find(note => note.Category === category)
    return note?.Description
  }

  const methods = useFormContext({ mode: 'onBlur' });
  const errors = methods?.errors
  let error = null;
  if (errors) {
    error = get(errors, name)
  }
  return (
    <FormControl component="fieldset" className="radio-group">     
      { error && <div style={{ color: 'red'}}>* Selection is required</div>}      
      <RadioGroup aria-label={description} {...props}>
        <table>
          <thead>
            <tr>
              <th>Intended Starting Term</th>
              <th>Application Deadline</th>
              <th>Notes</th>
            </tr>
          </thead>
          {listItems.map((item,i) =>
            <Fragment key={`${label}-option-${i}`}>
              <tr>
                <td>
                  <FormControlLabel
                    value={item.value}
                    control={
                      <Radio
                        color="primary"
                      />
                    }
                    label={item.name}
                    disabled={item.disabled}
                  />
                </td>
                <td style={item.disabled ? { opacity: .4} : {}}>
                  {item.getNote("deadline")}
                </td>
                <td style={item.disabled ? { opacity: .4} : {}}>
                  {item.getNote("LegacyStatusMessage")}
                </td>
              </tr>
            </Fragment>
            )}
        </table>
        
      </RadioGroup>

    </FormControl>
  );
};

export default InputDecorator(CohortTable);
