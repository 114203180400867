import React from 'react';

const FieldRow = ({ spacing = 3, xs = 12, children, ...props}) =>{
  return (
    <div className="fieldrow rhythm" {...props}>
      {children}
    </div>
  )
}

export default FieldRow;