import { FormProvider, useForm, useFormContext } from "react-hook-form";
import FormSection from "../../components/FormSection";
import { FIELDS } from "../../store/form/fields";
import DateAnswer, { DateResolution } from "../../components/answer/DateAnswer";
import DropdownAnswer from "../../components/answer/DropdownAnswer";
import { FieldRow } from "../../components/Forms/FieldSection";
import { useDispatch } from "react-redux";
import SectionStateIcon from "../../components/progress/SectionStateIcon";
import { useState } from "react";
import BooleanGroupQuestion from "../../components/answer/question/BooleanGroupQuestion";


/**
 * 
 * @param {SectionHelper} param0.section 
 * @returns {Component}
 */
function EditLicensePlans({ section }) {

    const dispatch = useDispatch();
    const methods = useFormContext({ mode: 'onBlur' });
    const { errors, formState, getValues, setValue, handleSubmit, watch } = methods;

    const onSubmit = (data) => {
        section.saveAnswers(data, dispatch);
    }

    const onError = (e) => {

    }
     
    const licenseType = watch(FIELDS.TEACHER_LICENSE_TYPE);

    return (
        <FormProvider {...methods} >
            <form onSubmit={handleSubmit(onSubmit, onError)} >
                <FormSection title={section.title} isEditable={true} status={<SectionStateIcon section={section} />}
                    onSaveClick={handleSubmit(onSubmit, onError)} loading={section.isLoading} >

                    <FieldRow>
                        <DropdownAnswer answer={section.getAnswerByField(FIELDS.TEACHER_LICENSE_TYPE)} isRequired={true}                         
                            label="What license are you pursuing?" />
                    </FieldRow>

                    {licenseType === 'Teaching' &&
                        <FieldRow>
                            <BooleanGroupQuestion section={section} fieldKey={FIELDS.TEACHER_LICENSE_ENDORSEMENTS} isRequired={true}
                                label="Tell us the endorsement or preliminary license type" />
                        </FieldRow>
                    }

                    <FieldRow>
                        <DateAnswer answer={section.getAnswerByField(FIELDS.TEACHER_LISENCE_BEGIN)}
                            isRequired={true} resolution={DateResolution.MONTH}
                            label="The date your preliminary licensure program began or will begin" />
                    </FieldRow>
                    <FieldRow>
                        <DateAnswer answer={section.getAnswerByField(FIELDS.TEACHER_LICENSE_COMPLETE)}
                            isRequired={true} resolution={DateResolution.MONTH}
                            label="The date you anticipate graduating the program with your license" />

                    </FieldRow>

                </FormSection>
            </form>
        </FormProvider>
    );
}

export default EditLicensePlans;