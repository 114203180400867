import React, { useEffect, useState } from "react";
import { actions } from './../../store';
import { useSelector } from 'react-redux';
import Input from './../../components/Input';
import Checkbox from './../../components/Checkbox';
import { useForm, FormProvider } from "react-hook-form";
import Email from './../../components/Email';
import Telephone from './../../components/Telephone';
import DropdownSearch from './../../components/DropdownSearch';
import ZipCode from './../../components/ZipCodeInput';
import AddressInput from './../../components/AddressInput';
import { FieldSection, FieldRow, FieldSlot } from "../../components/Forms/FieldSection";
import {FIELDS, AnswerTypeAddressProps, AnswerTypeContactProps, ContactFieldType} from "../../store/form/fields";
import AddressPicker from '../../components/DataDriven/AddressPicker';
import {compositeFieldname, getReduxField} from "../../utils";
import FormSection from '../../components/FormSection';
import auth from '../../auth'
import { useDispatch } from "react-redux";
import { open } from "../../store/snack/actions";
import HiddenInput from "../../components/Utility/HiddenInput";
import _get from 'lodash/get'
import Dropdown from '../../components/Dropdown';
import apiClient from "../../utils/API";


function EditContactInfo({ onChange, onCancel }) {
  const reduxFormState = useSelector((state) => state.form);
  const isFetchingData = reduxFormState.isLoading;
    const methods = useForm({
      mode: 'onBlur'
    });

    const [email, setEmail] = useState('');
    const { errors, handleSubmit, handleChange, watch } = methods;

    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();

  const onSubmit = async (data, e) => {
      try {
        setLoading(true)
        

        // No different Mailing Address, then clear Mailing address if it already exists.
        if (!differentMailingAddress) {
          data.MAILING_ADDRESS = null;
        }

      // #4230: Split the Zip Code into Zip and ZipPlus4 when it's length is more than 5.
      if(data.PERMANENT_ADDRESS?.Zip)
      {
          let zipCode = data.PERMANENT_ADDRESS.Zip;
          if (zipCode.length > 5){
            data.PERMANENT_ADDRESS.Zip = zipCode.substr(0, 5);
            data.PERMANENT_ADDRESS.ZipPlus4 = zipCode.substr(zipCode.length-4, 4);
          }
      }
      if(data.PRIMARY_CONTACT?.Zip)
      {
          let zipCode = data.PRIMARY_CONTACT.Zip;
          if (zipCode.length > 5){
            data.PRIMARY_CONTACT.Zip = zipCode.substr(0, 5);
            data.PRIMARY_CONTACT.ZipPlus4 = zipCode.substr(zipCode.length-4, 4);
          }
      }
      if(data.MAILING_ADDRESS?.Zip)
      {
          let zipCode = data.MAILING_ADDRESS.Zip;
          if (zipCode.length > 5){
            data.MAILING_ADDRESS.Zip = zipCode.substr(0, 5);
            data.MAILING_ADDRESS.ZipPlus4 = zipCode.substr(zipCode.length-4, 4);
          }
      }
        const result = await actions.submitForm(data,[],['PHONE_VALIDATION']);
        // console.log(data, e);
        if(typeof onChange === 'function'){
          onChange(true);
        }
      } catch(e) {
        dispatch(open({
          message: e.message,
          severity: "success",
        }))
        console.error({e})
      } finally {
        setLoading(false)
      }
    };

    const onError = (errors, e) => {
      console.log(errors, e);
      dispatch(open({
        message: 'Some fields are empty or missing. Please scroll up and look for instructions in red text.',
        severity: "success",
      }))
    }

    const handleChangeEmail = (e) => {
      e.preventDefault();
      // console.log(auth);
      auth.changeEmail();
    }
    const ChangeEmailLink = (<a href="#" className='helperTextLink' onClick={handleChangeEmail}>Change Email Address</a>);

    useEffect(()=>{
      // this method may be async in the future to get from graph api, as getting from token relies on having the email user claim enabled in b2c.
      const userEmail = auth.getUserEmail();
      setEmail(userEmail);
    },[]);

    const [relationshipItems, setRelationshipItems] = useState([]);
    const [relationshipOtherId, setRelationshipOtherId] = useState(null);
    useEffect(async () => {       
        let relationshipTypes = await apiClient.get(`relationship-type/relationship-types`);
        relationshipTypes = relationshipTypes.filter(r => r.IsObsolete !== true); //.sort((a,b) => a.IsOther ? b.IsOther ? 0 : 1 : 0 );
        var relationshipItems = relationshipTypes.map(r => ({                    
            label: r.Description,
            value: '' + r.Id
        }));    
        relationshipItems.unshift({label: ' -- ', value: ''});
        var otherId = '' + relationshipTypes.find(x => x.IsOther)?.Id;
        setRelationshipOtherId(otherId);
        setRelationshipItems(relationshipItems);
    },[]);

    const watchAlternatePhone = watch(FIELDS.ALTERNATE_PHONE)
    const watchPrimaryPhone = watch(FIELDS.HOME_PHONE)

    const validatePhoneNumber = (v) => {
      // console.log("Phones", watchAlternatePhone, watchPrimaryPhone, !watchAlternatePhone && !watchPrimaryPhone)
      if (!watchAlternatePhone && !watchPrimaryPhone) {
          return "At least one number is required"
      } else {
        return true
      }
    }

    const phoneError = _get(errors, 'PHONE_VALIDATION.message')

    const verifyAdditionalEmail = (v) => {
      if (v === email) {
        return "You cannot use the same email address"
      } else {
        return true
      }
    }
    const poBoxRules = {
      pattern: {
        value: /^(?!.*(?:(.*((p|post)[-.\s]*(o|off|office)[-.\s]*(box|bin)[-.\s]*)|.*((p |post)[-.\s]*(box|bin)[-.\s]*)))).*$/i,
        message: "invalid input"
      }
    };

    const get = (fieldKey) => reduxFormState.fields[fieldKey]?.value;
    const [differentMailingAddress, setDifferentMailingAddress] = useState(getReduxField(compositeFieldname(FIELDS.MAILING_ADDRESS,AnswerTypeAddressProps.ADDRESS)) !== null);

    const toggleMailingAddress = () => {
      setDifferentMailingAddress(!differentMailingAddress)
    }

    const relationshipTypeWatch = watch(compositeFieldname(FIELDS.PRIMARY_CONTACT,AnswerTypeContactProps.RELATION));

    return (
      <FormProvider {...methods} >
        <form onSubmit={handleSubmit(onSubmit, onError)} >
        <FormSection title='Contact Information' isEditable={true} onSaveClick={handleSubmit(onSubmit, onError)} onCancelClick={onCancel} loading={loading}>

          <FieldSection title="Email">
              <FieldRow>
                <FieldSlot>
                  <Email
                    isRequired={true}
                    useController={false}
                    inputLabel='Email'
                    placeholder='Email'
                    HelperTextComponent={ChangeEmailLink}
                    disabled={false}
                    value={email}
                  />
                </FieldSlot>
              </FieldRow>
            </FieldSection>

          <FieldSection title="Phone Number (one phone number required)" isRequired>
            { phoneError &&
              <FieldRow>
                <FieldSlot>
                  <strong style={{ color: 'red' }}>At least one number is required</strong>
                </FieldSlot>
              </FieldRow>
            }
            <FieldRow>
              <FieldSlot>
                <HiddenInput name="PHONE_VALIDATION" rules={{ validate: value => validatePhoneNumber(value) }} />
                <Telephone name={FIELDS.ALTERNATE_PHONE} inputLabel='Mobile Phone Number' placeholder='Mobile Phone Number' variant='outlined' />
                <Checkbox name={FIELDS.PRIMARY_PHONE_CELL_FLAG} label="Text about updates" />
              </FieldSlot>
              <FieldSlot>
                <Telephone name={FIELDS.HOME_PHONE} inputLabel='Home Phone Number' placeholder='Home Phone Number' variant='outlined' />
              </FieldSlot>
            </FieldRow>
          </FieldSection>

          <FieldSection>
            <FieldRow>
              <FieldSlot md={6}>
                <AddressPicker name={FIELDS.PERMANENT_ADDRESS}>
                  {({
                    states,
                    counties,
                    cities,
                    state,
                    county,
                    city,
                    noCounties
                  }) => {
                    return (
                      <FieldSection title="Permanent Address" withDivider={false} >
                        <FieldRow>
                          <FieldSlot>
                            <AddressInput
                              name={compositeFieldname(FIELDS.PERMANENT_ADDRESS,AnswerTypeAddressProps.ADDRESS)}
                              inputLabel='Street Address'
                              placeholder='Street Address'
                              variant='outlined'
                              isRequired={true}
                              rules={poBoxRules}
                            />
                          </FieldSlot>
                        </FieldRow>
                        <h4 className='heading heading--h5'>State/Province</h4>
                          <FieldRow>
                            <FieldSlot>
                              <DropdownSearch
                                onKeyPress={(e) => {
                                  e.key === 'Enter' && e.preventDefault();
                                }}
                                isRequired={true}
                                inputLabel='State'
                                placeholder='State'
                                name={compositeFieldname(FIELDS.PERMANENT_ADDRESS, AnswerTypeAddressProps.STATE)}
                                listItems={states}
                                variant='outlined'/>
                            </FieldSlot>
                          </FieldRow>
                        { state?.value == -1 &&
                          <div style={{
                            backgroundColor: 'lightgrey',
                            padding: '1rem'
                          }}>
                            <label><strong>Unlisted State or Foreign Country?</strong></label>
                            <Input name={compositeFieldname(FIELDS.PERMANENT_ADDRESS,AnswerTypeAddressProps.UNLISTED_STATE_NAME)} placeholder='Enter the name of your unlisted state/province here.' isRequired={true} ></Input>
                        </div>
                        }
                        { state && !noCounties &&
                          <FieldRow>
                            <FieldSlot>
                              <DropdownSearch
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                isRequired={true}
                                inputLabel='County'
                                placeholder='County'
                                name={compositeFieldname(FIELDS.PERMANENT_ADDRESS,AnswerTypeAddressProps.COUNTY)}
                                listItems={counties}
                                variant='outlined'
                              />
                            </FieldSlot>
                          </FieldRow>
                        }
                        { (county || noCounties) &&
                          <FieldRow>
                            <FieldSlot>
                              <DropdownSearch
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                isRequired={true}
                                inputLabel='City'
                                placeholder='City'
                                name={compositeFieldname(FIELDS.PERMANENT_ADDRESS,AnswerTypeAddressProps.CITY)}
                                listItems={cities}
                                variant='outlined' />
                            </FieldSlot>
                          </FieldRow>
                        }
                        {city?.value == -1 &&
                          <div
                            style={{
                              backgroundColor: 'lightgrey',
                              padding: '1rem'
                            }}>
                            <label><strong>Unlisted City?</strong></label>
                            <Input
                              name={compositeFieldname(FIELDS.PERMANENT_ADDRESS, AnswerTypeAddressProps.UNLISTED_CITY_NAME)}
                              isRequired={true}
                              placeholder='Enter the name of your unlisted city here.'></Input>
                          </div>
                        }
                        { city &&
                          <FieldRow>
                            <FieldSlot>
                              <ZipCode
                                isRequired={state?.value !== -1}
                                name={compositeFieldname(FIELDS.PERMANENT_ADDRESS,AnswerTypeAddressProps.ZIP)}
                                inputLabel='Zip Code'
                                placeholder='Zip Code'
                                variant='outlined' />
                            </FieldSlot>
                          </FieldRow>
                        }
                        <FieldRow style={{marginTop: '4rem'}}>
                          <FieldSlot>
                            <Checkbox name='differentMailingAddress' value={differentMailingAddress} onChange={toggleMailingAddress} label="Different Mailing Address" />
                          </FieldSlot>
                        </FieldRow>
                      </FieldSection>
                    )
                  }}
                </AddressPicker>
              </FieldSlot>

              {/* Mailing Address Section*/}
              { differentMailingAddress &&
              <FieldSlot>
                <br/>
                <AddressPicker name={FIELDS.MAILING_ADDRESS}>
                  {({
                    states,
                    counties,
                    cities,
                    state,
                    county,
                    city,
                    noCounties
                  }) => {
                    return (
                      <FieldSection title="Mailing Address" withDivider={false} >
                        <FieldRow>
                          <FieldSlot>
                            <AddressInput
                              name={compositeFieldname(FIELDS.MAILING_ADDRESS,AnswerTypeAddressProps.ADDRESS)}
                              inputLabel='Street Address'
                              placeholder='Street Address'
                              variant='outlined'
                              isRequired={differentMailingAddress}
                              rules={poBoxRules}
                            />
                          </FieldSlot>
                        </FieldRow>
                        <h4 className='heading heading--h5'>State/Province</h4>
                        <FieldRow>
                            <FieldSlot>
                              <DropdownSearch
                                onKeyPress={(e) => {
                                  e.key === 'Enter' && e.preventDefault();
                                }}
                                isRequired={differentMailingAddress}
                                name={compositeFieldname(FIELDS.MAILING_ADDRESS, AnswerTypeAddressProps.STATE)}
                                inputLabel='State'
                                placeholder='State'
                                listItems={states}
                                variant='outlined'                                
                              />
                            </FieldSlot>
                          { state?.value == -1 &&
                            <div style={{
                              backgroundColor: 'lightgrey',
                              padding: '1rem'
                            }}>
                              <label><strong>Unlisted State or Foreign Country?</strong></label>
                              <Input isRequired={differentMailingAddress} name={compositeFieldname(FIELDS.MAILING_ADDRESS,AnswerTypeAddressProps.UNLISTED_STATE_NAME)} placeholder='Enter the name of your unlisted state/province here.'></Input>
                            </div>
                          }
                        </FieldRow>
                        { state && !noCounties &&
                          <FieldRow>
                            <FieldSlot>
                              <DropdownSearch
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                isRequired={differentMailingAddress}
                                name={compositeFieldname(FIELDS.MAILING_ADDRESS,AnswerTypeAddressProps.COUNTY)}
                                inputLabel='County'
                                placeholder='County'
                                listItems={counties}
                                variant='outlined'                                
                              />
                            </FieldSlot>
                          </FieldRow>
                        }
                        { (county || noCounties) &&
                          <FieldRow>
                            <FieldSlot>
                              <DropdownSearch
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                isRequired={differentMailingAddress}
                                name={compositeFieldname(FIELDS.MAILING_ADDRESS,AnswerTypeAddressProps.CITY)}
                                inputLabel='City'
                                placeholder='City'
                                listItems={cities}
                                variant='outlined'                                
                              />
                            </FieldSlot>
                          </FieldRow>
                        }
                        {city?.value == -1 &&
                          <div
                            style={{
                              backgroundColor: 'lightgrey',
                              padding: '1rem'
                            }}>
                            <label><strong>Unlisted City?</strong></label>
                            <Input
                              name={compositeFieldname(FIELDS.MAILING_ADDRESS, AnswerTypeAddressProps.UNLISTED_CITY_NAME)}
                              isRequired={differentMailingAddress}
                              placeholder='Enter the name of your unlisted city here.'></Input>
                          </div>
                        }
                        { city &&
                          <FieldRow>
                            <FieldSlot>
                              <ZipCode
                                name={compositeFieldname(FIELDS.MAILING_ADDRESS,AnswerTypeAddressProps.ZIP)}
                                inputLabel= 'Zip Code'
                                placeholder='Zip Code'
                                variant='outlined'
                                isRequired={state?.value !== -1}
                              />
                            </FieldSlot>
                          </FieldRow>
                        }
                      </FieldSection>
                    )
                  }}
                </AddressPicker>
              </FieldSlot>
              }
            </FieldRow>
          </FieldSection>

          <FieldSection title="Additional Contact" >
            <FieldRow>
              <FieldSlot>          
                <Dropdown name={compositeFieldname(FIELDS.PRIMARY_CONTACT,AnswerTypeContactProps.RELATION)} label="Relationship To Applicant" listItems={relationshipItems}/>        
            </FieldSlot>
              { relationshipTypeWatch === relationshipOtherId && (
                <FieldSlot>
                  <Input onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} 
                    name={compositeFieldname(FIELDS.PRIMARY_CONTACT,AnswerTypeContactProps.RELATION_UNLISTED)} inputLabel='Specify Relationship' placeholder='Specify Relationship' 
                    variant='outlined' />
                </FieldSlot>
              ) || null
              }
            </FieldRow>
            <FieldRow>
              <FieldSlot>
                <Input onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} name={compositeFieldname(FIELDS.PRIMARY_CONTACT,AnswerTypeContactProps.FIRST_NAME)} inputLabel='First Name' placeholder='First Name' variant='outlined' />
              </FieldSlot>
              <FieldSlot>
                <Input onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} name={compositeFieldname(FIELDS.PRIMARY_CONTACT,AnswerTypeContactProps.LAST_NAME)} inputLabel='Last Name' placeholder='Last Name' variant='outlined' />
              </FieldSlot>
            </FieldRow>
            <FieldRow>
              <FieldSlot md={6}>
                <Email addrules={{ validate: value => verifyAdditionalEmail(value) }} isRequired={false} name={compositeFieldname(FIELDS.PRIMARY_CONTACT,AnswerTypeContactProps.EMAIL)} inputLabel='Email' placeholder='Email' variant='outlined' />
              </FieldSlot>
            </FieldRow>

            <AddressPicker name={FIELDS.PRIMARY_CONTACT}>
              {({
                states,
                counties,
                cities,
                state,
                county,
                city,
                noCounties
              }) => {
                return (
                  <>
                    <FieldRow>
                      <FieldSlot md={6}>
                        <Input
                          onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                          name={compositeFieldname(FIELDS.PRIMARY_CONTACT,AnswerTypeContactProps.ADDRESS)}
                          inputLabel='Street Address'
                          placeholder='Street Address'
                          variant='outlined'
                          
                        />
                      </FieldSlot>
                    </FieldRow>
                    <FieldRow>
                        <FieldSlot md={6}>
                          <DropdownSearch
                            onKeyPress={(e) => {
                              e.key === 'Enter' && e.preventDefault();
                            }}
                            name={compositeFieldname(FIELDS.PRIMARY_CONTACT, AnswerTypeContactProps.STATE)}
                            inputLabel='State'
                            placeholder='State'
                            listItems={states}
                            variant='outlined'
                          />
                        </FieldSlot>
                      { state?.value == -1 &&
                        <div style={{
                          backgroundColor: 'lightgrey',
                          padding: '1rem'
                        }}>
                          <label><strong>Unlisted State or Foreign Country?</strong></label>
                          <Input name={compositeFieldname(FIELDS.PRIMARY_CONTACT,AnswerTypeAddressProps.UNLISTED_STATE_NAME)} 
                            placeholder='Enter the name of your unlisted state/province here.'></Input>
                        </div>
                      }
                    </FieldRow>
                    { state && !noCounties &&
                      <FieldRow>
                        <FieldSlot md={6}>
                          <DropdownSearch
                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                       
                            name={compositeFieldname(FIELDS.PRIMARY_CONTACT,AnswerTypeContactProps.COUNTY)}
                            inputLabel='County'
                            placeholder='County'
                            listItems={counties}
                            variant='outlined'
                          />
                        </FieldSlot>
                      </FieldRow>
                    }
                    { (county || noCounties) &&
                      <FieldRow>
                        <FieldSlot md={6}>
                          <DropdownSearch
                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}           
                            name={compositeFieldname(FIELDS.PRIMARY_CONTACT,AnswerTypeContactProps.CITY)}
                            inputLabel='City'
                            placeholder='City'
                            listItems={cities}
                            variant='outlined'
                          />
                        </FieldSlot>
                      </FieldRow>
                    }
                    {city?.value == -1 &&
                      <div
                        style={{
                          backgroundColor: 'lightgrey',
                          padding: '1rem'
                        }}>
                        <label><strong>Unlisted City?</strong></label>
                        <Input
                          name={compositeFieldname(FIELDS.PRIMARY_CONTACT, AnswerTypeAddressProps.UNLISTED_CITY_NAME)}
                          placeholder='Enter the name of your unlisted city here.'></Input>
                      </div>
                    }
                    { city &&
                      <FieldRow>
                        <FieldSlot md={6}>
                          <ZipCode
                            name={compositeFieldname(FIELDS.PRIMARY_CONTACT,AnswerTypeContactProps.ZIP)}
                            inputLabel='Zip Code'
                            placeholder='Zip Code'
                            variant='outlined'
                          />
                        </FieldSlot>
                      </FieldRow>
                    }
                  </>
                )
              }}
            </AddressPicker>

            <FieldRow>
              <FieldSlot md={6}>
                <Telephone name={compositeFieldname(FIELDS.PRIMARY_CONTACT,AnswerTypeContactProps.PHONE)} inputLabel='Phone Number' variant='outlined' placeholder='Phone Number' />
              </FieldSlot>
            </FieldRow>
            <FieldRow>
              <FieldSlot md={12}>
                <div className="mt-05">
                  <Checkbox name={compositeFieldname(FIELDS.PRIMARY_CONTACT,AnswerTypeContactProps.SEND_TEXTS)} label="Text about updates" />
                </div>
                <div >
                  <Checkbox name={compositeFieldname(FIELDS.PRIMARY_CONTACT,AnswerTypeContactProps.INCLUDE_EMAILS)} label="I approve this contact to also receive my application related emails and/or speak with OSAC staff about my account." />
                </div>
              </FieldSlot>
            </FieldRow>
          </FieldSection>
         
        </FormSection>
      </form>
    </FormProvider>
  );
}

export default EditContactInfo;
