import { useEffect, useState, Fragment } from "react";
import { actions } from './../../store';
import { useSelector } from 'react-redux';
import { useForm, FormProvider } from "react-hook-form";
import { FieldSection } from '../../components/Forms/FieldSection';
import FormSection from '../../components/FormSection';
import ActivityPicker from '../../components/ActivityPicker/Editor'
import { ActivityTypeCodes, FIELDS } from '../../store/form/fields';
import { open } from "../../store/snack/actions";
import { useDispatch } from 'react-redux';
import Button from '../../components/Button';
import CertifyAnswer from '../../components/answer/CertifyAnswer';
import apiClient from "../../utils/API";

const activityBlurb = {
  school: 'List all school activities that you would like the selection committee to see. This information is used to help the selection committee make scholarship selections.',
  work:'List all paid work history that you would like the selection committee to see. This information is used to help the selection committee make scholarship selections.',
  volunteer: 'List all volunteer activities you would like the selection committee to see. This information is used to help the selection committee make scholarship selections.'
}

function EditActivities({section, onChange, onCancel, year }) {
    const reduxFormState = useSelector((state) => state.form);
    const isFetchingData = reduxFormState.isLoading;
    const methods = useForm({
      mode: 'onBlur'
    });

    const { handleSubmit, watch, setValue } = methods;
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();

    // #4149: Send only valid Activity Fields. To avoid 400 error on Activity Page.
    const activityFields = [      
      FIELDS.ACTIVITY_SCHOOL,      
      FIELDS.ACTIVITY_VOLUNTEER,
      FIELDS.ACTIVITY_WORK,
      FIELDS.ACTIVITIES_COMPLETE,
      FIELDS.HAS_NO_SCHOOL_ACTIVITIES,
      FIELDS.HAS_NO_VOLUNTEER_ACTIVITIES,
      FIELDS.HAS_NO_WORK_ACTIVITIES
    ]

    const onSubmit = async (data, e) => {
      console.log('activity submit fired', data)
      if(schoolTotal > 0) {
        setValue(FIELDS.HAS_NO_SCHOOL_ACTIVITIES, false)
      }
      if(workTotal > 0) {
        setValue(FIELDS.HAS_NO_WORK_ACTIVITIES, false)
      }
      if(volunteerTotal > 0) {
        setValue(FIELDS.HAS_NO_VOLUNTEER_ACTIVITIES, false)
      }
      try {
        setLoading(true);

        // #4149: Prepare excluding list from 'data' object. Send only valid Activity Fields. To avoid 400 error on Activity Page.
        const excludeList = ['presentActivity'];
        const allKeys = Object.keys(data);
        for (let i = 0; i < allKeys.length; i++) {
          const fieldKey = allKeys[i];
          if (!activityFields.includes(fieldKey)) excludeList.push(fieldKey); 
        }
        const result = await actions.submitForm(data, [], excludeList, year)

        if(typeof onChange === 'function'){
          onChange(true);
        }
      } catch(e) {
          dispatch(open({
            message: e.message,
            severity: "success",
          }))
      } finally {
        setLoading(false)
      }
    };

    const onError = (errors, e) => {
      if(errors.ACTIVITY_SCHOOL || errors.ACTIVITY_VOLUNTEER || errors.ACTIVITY_WORK){
        dispatch(open({
          message: "Please complete the required fields associated with your added activities.",
          severity: "success",
        }))
      }
    }

    const [schoolTotal, setSchoolTotal] = useState(0)
    const [volunteerTotal, setVolunteerTotal] = useState(0)
    const [workTotal, setWorkTotal] = useState(0)

    // Used local variable to capture the most recent data.
    const exceedsMaximumActivities = (schoolTotal + volunteerTotal + workTotal) > 20;

    const watchActivitySchool = watch(FIELDS.ACTIVITY_SCHOOL)
    const watchActivityVolunteer= watch(FIELDS.ACTIVITY_VOLUNTEER)
    const watchActivityWork = watch(FIELDS.ACTIVITY_WORK)

    useEffect(() => {
      setSchoolTotal(watchActivitySchool?.length !== undefined ? watchActivitySchool?.length : 0)
      setVolunteerTotal(watchActivityVolunteer?.length !== undefined ? watchActivityVolunteer?.length : 0)
      setWorkTotal(watchActivityWork?.length !== undefined ? watchActivityWork?.length : 0)
    },[watchActivitySchool?.length,watchActivityVolunteer?.length,watchActivityWork?.length]);

    useEffect(() => {
      window.addEventListener('beforeunload', alertUser)
      return () => {
        window.removeEventListener('beforeunload', alertUser)
      }
    }, [])
    const alertUser = e => {
      e.preventDefault()
      e.returnValue = ''
    }

    const [activityInstructions, setActivityInstructions] = useState(null)

    useEffect(() => {
      (async () => {
            const result = await apiClient.get("code?lookup.code=ActivityChartInstructions&lookup.codeType=StudentMessage");
            setActivityInstructions(result?.Message);                
      })()
    }, [])

    const activityList = [
      {
        title: "School Activities",
        label:'I have no school activities',
        subtitle: activityBlurb.school,
        fieldName: FIELDS.ACTIVITY_SCHOOL,
        activityType: ActivityTypeCodes.SCHOOL,
        total: schoolTotal,
        noActivities: FIELDS.HAS_NO_SCHOOL_ACTIVITIES,
      },
      {
        title: "Volunteer Activities",
        label:'I have no volunteer activities',
        subtitle: activityBlurb.volunteer,
        fieldName: FIELDS.ACTIVITY_VOLUNTEER,
        activityType: ActivityTypeCodes.VOLUNTEER,
        total: volunteerTotal,
        noActivities: FIELDS.HAS_NO_VOLUNTEER_ACTIVITIES,
      },
      {
        title: "Paid Work Activities",
        label:'I have no work activities',
        subtitle: activityBlurb.work,
        fieldName: FIELDS.ACTIVITY_WORK,
        activityType: ActivityTypeCodes.WORK,
        total: workTotal,
        noActivities: FIELDS.HAS_NO_WORK_ACTIVITIES,
      },
    ]

    function mapActivityPickers(activity){
      const {title, label, subtitle, fieldName, activityType, total} = activity;
      return(
        <Fragment key={`${activity.title}${fieldName}`}>
          <FieldSection title={title} subtitle={subtitle} withGrid={false}>
            <ActivityPicker section={section} name={fieldName} type={activityType}/>
            <div>
              { total === 0 &&
                <CertifyAnswer answer={section.getAnswerByField(activity.noActivities)} label={label}
                          disabled={total > 0} />
              }
            </div>
            <div className="save-edit-end">          
              <Button disabled={isFetchingData || loading} name='SaveBasicInfo' onClick={handleSubmit(onSubmit, onError)} label='Save' loading={loading} />          
            </div>
          </FieldSection>
        </Fragment>
    )}

    const mappedActivityList = activityList.map(mapActivityPickers);

    return (
      <FormProvider {...methods} >
        <form onSubmit={handleSubmit(onSubmit, onError)} >

          <FormSection title='Activities' isEditable={true} onSaveClick={handleSubmit(onSubmit, onError)} onCancelClick={onCancel} loading={loading}>
            <FieldSection>
              <div dangerouslySetInnerHTML={{ __html: activityInstructions }} />
            </FieldSection>
            {mappedActivityList}
            {exceedsMaximumActivities &&
              <FieldSection>
                <div className={'required'}>
                  You can save a maximum of 20 activities total. Please reduce your activities to 20 or fewer to proceed.
                </div>
              </FieldSection>
            }
   
          </FormSection>
        </form>
      </FormProvider>
    );
}

export default EditActivities;
