import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useFormContext } from 'react-hook-form';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputDecorator from './InputDecorator';
import { get } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginTop: theme.spacing(1.5),
    
    '& > * > *': {
      borderRadius: "5px",
    },
    '& input': {
      height: '10px',
    }
  },
})); 

function SimpleSelect({label, name, rules, isRequired = false, isSoftRequired = false, description, listItems, helperText, inputLabel, ...props}) {

  const theme = useTheme();
  const classes = useStyles();
  const methods = useFormContext({ mode: 'onBlur' });
  const { errors } = methods;
  const error = get(errors, name);

  return (
    <div className={classes.root} style={{ marginTop: theme.spacing(2) }}>
      <InputLabel>
        {label}
        {// required: show red * and prevent user from saving if null
          isRequired && (
            <span style={{color:'red'}}>&nbsp;*</span>
          )
        } 
        {// isSoftRequired: show red * but do not prevent user from saving if null
          isSoftRequired && !isRequired
                          ? (<span style={{color:'red'}}>&nbsp;*</span>)
                          : null
        } 
      </InputLabel>
      <Select fullWidth={true} aria-label={description} style={{ height: '42px', borderRadius: "5px", margin: 0, background: 'white' }} error={!!error} {...props}>
        {listItems.map((item,i) => <MenuItem key={`${name}-${i}}`} value={item.value} label={item.label}>{item.label}</MenuItem>)}
      </Select>
      <FormHelperText style={{paddingLeft: '.5rem'}}>{helperText}</FormHelperText>
      {
        error?.message && (
          <div style={{ color: 'red', fontSize: '0.875rem' }}>
            {error?.message}
          </div>
        )
      }
    </div>
  );
};

export default InputDecorator(SimpleSelect);