import React, { useEffect, useState } from 'react';
import Header from '../../components/Global/Header';
import Footer from '../../components/Global/Footer';
import auth from '../../auth';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import apiClient from '../../utils/API';
import Overlay from '../../components/Utility/Overlay';
import Spinner from '../../components/Utility/Spinner';
import store, { actions } from './../../store';
import { FIELDS } from '../../store/form/fields';
import { getReduxField } from '../../utils';

import ImgLoginStudent from '../../images/login-student.jpg';

function StudentPortalLanding() {

  const history = useHistory();
  const { isLoggedIn } = useSelector((state) => state.auth);

  const [isLoginInProgress, setIsLoginInProgress] = useState(false);

  console.log('is logged in?', isLoggedIn)


  const handleLogin = (e) => {
    console.log('handle login fired')
    console.log('is logged in true')
    setIsLoginInProgress(true)
    e.preventDefault();
    auth.login();
  }

  useEffect(()=>{
    (async()=>{
      console.log('useffect login called')
      if(isLoggedIn){
        setIsLoginInProgress(true)
        console.log('loggedin true')
        let isUserRegistered;
        const username = auth.getUsername();
        console.log('username', username)
        const email = auth.getUserEmail();
        console.log('email', email)
        try {
          const account = await apiClient.get(`/account`, {
            username
          })
          isUserRegistered = !!account;
          console.log('got account info')
        } catch(err){
          console.log('account request error');
          const status = err?.request?.status;
          // if 403, and 409 http status - we need to trigger account verification and this implies existing account
          switch (status) {
            case 403:
              console.log('login case 403');
              history.push('/dashboard');
            break;
            case 409:
              console.log('login case 409');
              history.push('/dashboard');
            break;
            default:
              console.log('login case default');
              isUserRegistered = false;
            break;
          }

        } finally {
          console.log('set login in progess');
          //setIsLoginInProgress(false)
        }
        if(!isUserRegistered){
          console.log('user not registered');
          try{
            console.log('getting account info');
            await apiClient.post(`/account`, {
              "Username": username,
              "Email": email,
              "Phone": (Math.round(Math.random()*10000000000))
            })
            console.log('got account info');
          } catch(err){
            setIsLoginInProgress(false)
            alert('An error has occured. Please try again later, or contact SA.Feedback@hecc.oregon.gov for assistance')
          }
        }

        await actions.fetchBasicFields([
          FIELDS.FIRST_NAME,
          FIELDS.LAST_NAME,
          FIELDS.BIRTHDATE,
        ]);

        const firstName = getReduxField(FIELDS.FIRST_NAME);
        const lastName = getReduxField(FIELDS.LAST_NAME);      
        const birthdate = getReduxField(FIELDS.BIRTHDATE);

        if ([!!firstName, !!lastName, !!birthdate].includes(false)){
          console.log('registration redirect');
          history.push('/registration');
        }
        else {
          console.log('dashboard redirect');
          history.push('/dashboard');
        }
        //setIsLoginInProgress(false)
      }
    })();
  },[isLoggedIn])

  return (
    <React.Fragment>
      <div className="pageshell pageshell--fullwidth">
        <Header />
        <Overlay visible={isLoginInProgress}>
          <div style={{ height: '100vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ 'textAlign': 'center'}}><Spinner/><p>Logging In...</p></div>
          </div>
        </Overlay>
        <div className="pageshell__main">
          <div className="marquee">
            <div className="marquee__layout">
              <div className="marquee__media"><img alt="Lorem ipsum dolor sit amet..." className="image marquee__image something" src={ImgLoginStudent} /></div>
              <div className="marquee__content rhythm">
                <div className="marquee__text rhythm">
                  <h1 className="heading heading--h1">Student Portal</h1>
                  <h2 className="heading heading--h2">Apply for over <i>$38 million</i> in Grants and Scholarships</h2>
                  <div className="marquee__cta">
                    <div className="richtext">
                      <p>Login to see your applications</p>
                    </div>
                    <button href="#marquee-cta" className="link link--button button--outline" onClick={handleLogin} disabled={isLoginInProgress}>Returning users login</button>
                    <button href="#marquee-cta" className="link link--button button--outline" onClick={handleLogin} disabled={isLoginInProgress}>Make a new account</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />

      </div>
    </React.Fragment>
  );
};

export default StudentPortalLanding;
