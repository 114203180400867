import { useState } from 'react';
import { usePagination } from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  ul: {
    listStyle: 'none',
    padding: 0,
    display: 'flex',
  },
  button: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.5)',
    background: 'white',
    height: '32px',
    width: '32px',
    border: '1px solid #EFEFEF',
    boxSizing: 'border-box',
    borderRadius: '4px',
    margin: '4px',
  }
});

export default function UsePagination({ count, hideNextButton = true, hidePrevButton = true, onChange = () => {},  ...props }) {
  const classes = useStyles();

  const handlePageChange = (e,page) => {
    if(typeof onChange === 'function'){
      onChange(page);
    }
  }

  const { items } = usePagination({
    count,
    hideNextButton,
    hidePrevButton,
    onChange: handlePageChange,
    ...props
  });
  const repairedItems = items.map(item => {
    const selected = item.page === props.page;
    return {...item, selected};
  }); 



  return (
    <nav>
      <ul className={classes.ul}>
        {repairedItems.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = '…';
          } else if (type === 'page') {
            children = (
              <button type="button" className={classes.button}
                style={{
                  fontWeight: selected ? 'bold' : undefined,
                  color: selected && '#2C7274',
                  background: selected && '#E6F9F6',
                  border: selected && '1px solid #E6F9F6'
                  }} {...item}>
                {page}
              </button>
            );
          } else {
            children = (
              <button type="button" {...item}>
                {type}
              </button>
            );
          }

          return <li key={index}>{children}</li>;
        })}
      </ul>
    </nav>
  );
}
