import { createStore, compose, applyMiddleware } from 'redux';
//import { routerMiddleware } from 'react-router-redux'
import rootReducer from './reducers';
import { bindActionCreators } from 'redux';
import * as unbindedActions from '../store/actions';
import thunkMiddleware from 'redux-thunk';


// TODO(Alejandro): Factor out to env var
const PRODUCTION = true;

//----Development
const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose);
//---------
const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(
        thunkMiddleware,
    )
  )
);

// export binded (with dispatch) actions
export const actions = bindActionCreators(unbindedActions, store.dispatch);

export default store;

