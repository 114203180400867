/*import React, { Fragment, useEffect } from "react";
import InputDecorator from './InputDecorator';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

function InlineDatePickerDemo({ label, format='MM/dd/yyyy', placeholder, ...props}) {
  if (props.value ==='') props.value = new Date();

  return (
    <div onBlur={props.onBlur} >
    <MuiPickersUtilsProvider utils={DateFnsUtils} >
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format={format}
        margin="normal"
        label={label}
        value={props.value}
        onChange={props.onChange}
        inputVariant="outlined"
        placeholder={placeholder}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        {...props}
        />
    </MuiPickersUtilsProvider>
    </div>
  );
}

export default InputDecorator(InlineDatePickerDemo);
*/

import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizaitonProvider from '@material-ui/lab/LocalizationProvider';
import DatePicker from '@material-ui/lab/DatePicker';
import InputDecorator from './InputDecorator';
import { useTheme } from '@material-ui/core/styles';
import { InputLabel, makeStyles } from '@material-ui/core';
import moment from 'moment';
import { get } from 'lodash'
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 0,
    background: 'white',
    borderRadius: "5px",
    '& > * > *': {
      borderRadius: "5px",
    },
    '& input': {
      height: '10px',
      fontSize: "0.9rem",
    },
  },
}));

function MaskedDatePicker({name, label, helperText = null, style, isRequired, minDate, disableFuture, disabled, ...props}) {
  const classes = useStyles();
  const theme = useTheme();
  const methods = useFormContext({ mode: 'onBlur' });
  const { errors } = methods;
  const error = get(errors, name);
  const [value, setValue] = React.useState(new Date());
  if (props.value ==='') props.value = null;


  return (
    <div onBlur={props.onBlur} className="masked-date-picker" style={{ marginTop: '1.5rem' }}>
      <LocalizaitonProvider dateAdapter={AdapterDateFns}>
        <InputLabel>
          <span style={{whiteSpace: 'normal'}}>{label}</span>
          {
            isRequired && (
              <span style={{color:'red'}}>&nbsp; *</span>
            )
          }
        </InputLabel>
        <DatePicker
          disabled={disabled}
          views={['year','month','day']}
          value={moment(props.value)}
          minDate={minDate}
          maxDate={disableFuture === true ? (new Date()) : undefined }
          onChange={(date)=>{ props.onChange(moment(date).format('YYYY-MM-DD')) }}
          renderInput={(params) => (
            <TextField
              onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
              disabled={disabled}
              {...params}
              fullWidth={true}
              margin="normal"
              helperText={helperText}
              variant="outlined"
              placeholder={label}
              style={style}
              className={classes.root}
              error={!!error}
            />
          )}
        />
      </LocalizaitonProvider>
      {
        error?.message && (
          <div style={{ color: 'red', fontSize: '0.875rem' }}>
              {error?.message}
          </div>
        )
      }
    </div>
  );
}

export default InputDecorator(MaskedDatePicker);
