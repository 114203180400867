import React from 'react';
import InputDecorator from './InputDecorator';
import { Checkbox } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';

const checkBoxStyles = theme => ({
  root: {
    '&$checked': {
      color: 'rgba(44, 114, 116, 1)',
    },
  },
  checked: {},
  }
)

export const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);

function Checkboxes({name, description, label, disabled = false, isRequired = false, isSoftRequired = false, ...props }) {

  const [booleanValue, setBooleanValue] = React.useState(false);
  useEffect(() => {
    let val = props.value;

    if (val === 'False' || val === null) {
      val = false
    }
    if (val === 'True') {
      val = true
    }
    setBooleanValue(val);
  }, [props.value])

  return (
    <div className="checkbox-wrapper">
      { // required: show red * and prevent user from saving if null
        !label && isRequired && ( <span className="required"> *</span> ) 
      }
      { // isSoftRequired: show red * but do not prevent user from saving if null
        !label && !isRequired && isSoftRequired? ( <span className="required"> *</span> ) : null
      }
      <CustomCheckbox
        onChange={(e) => props.onChange(e.target.checked)}
        checked={booleanValue}
        onBlur={props.onBlur}
        inputRef={props.ref}
        disabled={disabled}
        label={label}
      />
      { label && 
        <span className="checkbox-wrapper__label">
          {label}
          { isRequired && ( <span className="required"> *</span> ) }
          { !isRequired && isSoftRequired? ( <span className="required"> *</span> ) : null }
        </span>
      }
      
    </div>
  );
}

export default InputDecorator(Checkboxes);


