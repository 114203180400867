import auth from "../../auth";

let idToken = localStorage.getItem('idToken');

const Migration = {
   complete: "complete",
   required: "required",
   failed: "failed",
   initial: "initial"
}

// Oddly, idToken sets as undefined when logging in and somehow ends up in local storage as undefined but parseable
const initialUserState = {
   idToken: idToken || auth.isAuthenticated() || '',
   isLoggedIn: !!idToken,
   migration: Migration.initial
}

export { initialUserState, Migration };