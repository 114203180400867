import { actions } from "./../../store";
import { useState, useEffect, useRef } from "react";
import { useForm, FormProvider } from "react-hook-form";
import Button from "../../components/Button";
import PrintIcon from "../../components/PrintIcon";
import PortalView from "./../../components/Global/PortalView";
import ProgressBar from "../../components/progress/ProgressBar";
import ProgressBarLabel from "../../components/progress/ProgressBarLabel";
import RadioGroup from "../../components/RadioGroup";
import Checkbox from "../../components/Checkbox";
import NumericDecimal from "../../components/NumericDecimal";
import { FieldRow, FieldSlot } from "../../components/Forms/FieldSection";
import CompleteSubmission from "../../components/CompleteSubmission";
import { FIELDS } from "../../store/form/fields";
import { goBack } from "../../utils";
import apiClient from "../../utils/API";
import { useHistory, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { open } from "../../store/snack/actions";
import moment from "moment";
import DropdownSearch from "../../components/DropdownSearch";
import ApplicationSubmitButton from "../../components/Utility/ApplicationSubmitButton";
import ProgramInfo from "../../utils/dtos/ProgramInfo";
import ApplicationInfo from "../../utils/dtos/ApplicationInfo";
import CollegeChoiceAnswer from "../../components/answer/CollegeChoiceAnswer";
import ClassStanding from "../../components/answer/question/ClassStanding";
import { Component } from "react";
import SectionFormHelper from "../../utils/helper/SectionHelper";
import AnswerSet from "../../utils/dtos/AnswerSet";
import FormSerializer from "../../store/form/serializer";
import { Alert } from "@material-ui/core";
import RequirementSet from "../../utils/dtos/RequirementSet";

const PROGRAM_CODE = "ONGSTA";

const integers = [
    FIELDS.NATIONAL_GUARD__CREDITS_PRESUMMER,
    FIELDS.NATIONAL_GUARD__CREDITS_FALL,
    FIELDS.NATIONAL_GUARD__CREDITS_WINTER,
    FIELDS.NATIONAL_GUARD__CREDITS_SPRING,
    FIELDS.NATIONAL_GUARD__CREDITS_POSTSUMMER,
];


/**
 * render the national guard application
 * @returns {Component}
 */
function NationalGuard() {
    const { year } = useParams();
    const activeYear = parseInt(year);
    
    const methods = useForm({ mode: "onBlur", });
    const { errors, getValues, setValue, handleSubmit, watch } = methods;

    const history = useHistory();
    const handleCancel = () => {
        goBack({
            history,
            defaultRoute: "/dashboard",
        });
    };

    const [completionData, setCompletionData] = useState(null);
    const sectionCompletion = new RequirementSet(completionData);
    const collegeCompletion = sectionCompletion.getField(FIELDS.COLLEGE_CHOICE);

    const sectionGroup = new SectionFormHelper(useState(null));
    const allAppSection = sectionGroup.create(useState(null), null, [
        FIELDS.NATIONAL_GUARD__UNIT,
        FIELDS.NATIONAL_GUARD__BRANCH,
        FIELDS.NATIONAL_GUARD__CREDITS_PRESUMMER,
        FIELDS.NATIONAL_GUARD__CREDITS_FALL,
        FIELDS.NATIONAL_GUARD__CREDITS_WINTER,
        FIELDS.NATIONAL_GUARD__CREDITS_SPRING,
        FIELDS.NATIONAL_GUARD__CREDITS_POSTSUMMER,
        FIELDS.COLLEGE_CHOICE,
        FIELDS.COLLEGE_STANDING,
    ]);

    const [grantDetails, setGrantDetails] = useState(null);
    const program = new ProgramInfo(grantDetails); 
    const currentSeason = program.getSeason(activeYear);
    const details = {
        title: program.name,
        year: activeYear,
        description: program.description,
        code: PROGRAM_CODE
    };


    const [applicationData, setApplicationData] = useState(null);
    const app = new ApplicationInfo(applicationData);

    class OngstaTerm{

        constructor(year, name, term, offset = 0){
            this._term = term;
            this._year = year;
            this._name = name;         
            this._offset = offset;
        }

        /**
         * display of term
         */
        get term(){
            return `${this._term} ${this._year}`;
        }

        /**
         * the field name
         */
        get name(){
            return this._name;
        }

        get deadline(){
            const event = currentSeason?.getEvent("EndDate", this._term);
            if (!event)
                return null;
            return moment(event.date).subtract(this._offset, 'years').format('MM/DD/YYYY');
        }
        
        get creditHours(){
            return getValues(this.name);
        }
        set creditHours(value){
            setValue(this.name, value);
        }

        get isNotAttending(){
            return this.creditHours === 0 || this.creditHours === "0";
        }

        set isNotAttending(value) {
            if (value)
                this.creditHours = 0;
            else
                this.creditHours = "";
        }

        get isTermAnswered(){            
            var credits = watch(this.name);
            return credits >= 0;
        }
        
    }
    
    const listTerms = [
        new OngstaTerm(activeYear, FIELDS.NATIONAL_GUARD__CREDITS_PRESUMMER, 'Summer', 1),
        new OngstaTerm(activeYear, FIELDS.NATIONAL_GUARD__CREDITS_FALL, 'Fall'),
        new OngstaTerm(activeYear + 1, FIELDS.NATIONAL_GUARD__CREDITS_WINTER, 'Winter'),
        new OngstaTerm(activeYear + 1, FIELDS.NATIONAL_GUARD__CREDITS_SPRING, 'Spring'),
        new OngstaTerm(activeYear + 1, FIELDS.NATIONAL_GUARD__CREDITS_POSTSUMMER, 'Summer'),
    ];              



    /**
     * pull initial application state from API
     */
    useEffect(async () => {
        const programResult = await apiClient.getProgram(PROGRAM_CODE);
        const season = new ProgramInfo(programResult).getSeason(activeYear);
        if (!season){
            dispatch(
                open({
                    message: "This application is no longer available",
                    severity: "success",
                })
            );
            handleCancel();
            return;
        }
        setGrantDetails(programResult);

        const [appResult, answers, collegeCompletion] = await Promise.all([            
            apiClient.getApplication(PROGRAM_CODE, activeYear),
            sectionGroup.fetchAnswers(activeYear),
            apiClient.getCompletionReport(PROGRAM_CODE, activeYear, [FIELDS.COLLEGE_CHOICE])
        ]);
        
        setApplicationData(appResult);
        setCompletionData(collegeCompletion);  

        const answerSet = new AnswerSet(answers);
        for (let term of listTerms){    
            var response = answerSet.get(FormSerializer.getQuestionKey(term.name)).Response;
            term.creditHours = response;
        }
        
    }, []);
   
   
    const watchCollegeChoice = watch(FIELDS.COLLEGE_CHOICE);
    const watchCollegeStanding = watch(FIELDS.COLLEGE_STANDING);
    const watchBranch = watch(FIELDS.NATIONAL_GUARD__BRANCH);
    const progress = {
        profile: "complete",
        serviceHistory: watchBranch && watch(FIELDS.NATIONAL_GUARD__UNIT) ? "complete" : "incomplete",
        enrollment: listTerms.every(x => x.isTermAnswered) ? "complete" : "incomplete",
        collegePlans: watchCollegeChoice && watchCollegeStanding ? "complete" : "incomplete",
        submit: app.isSubmitted ? "complete" : "incomplete"
    };

    for (let key in progress) {
        if (progress[key] === "incomplete") {
            progress[key] = "inProgress";
            break;
        }
    }

    let completeSectionCount = 0;
    for (const i in progress) {
        if (progress[i] === "complete")
            completeSectionCount++;
    }
    const progressPercent = Math.round(100 * completeSectionCount / 5)
    const disableSubmit = !(progress.serviceHistory === "complete" && progress.enrollment === "complete" && progress.collegePlans == "complete");

    const [units, setUnits] = useState(true);
    useEffect(async () => {

        const result = await apiClient.get('/code/codes?codeType=NationalGuardUnit_' + watchBranch, {});
        var resultUnits = result.map(r => ({
            label: r.Message,
            value: r.Code
        }));
        setUnits(resultUnits);
    }, [watchBranch])


    const dispatch = useDispatch();
    const [saveLoading, setSaveLoading] = useState(false);

    const onSave = async (data, e) => {
        try {
            setSaveLoading(true);

            await actions.submitForm(data, [], ["Agreement"], activeYear);
            var completionReport = await apiClient.getCompletionReport(PROGRAM_CODE, activeYear, [FIELDS.COLLEGE_CHOICE])
            setCompletionData(completionReport);
            
            for (const prop in data) {
                if (integers.find(i => i === prop)) {
                    data[prop] = parseInt(data[prop]);
                }
            }
            dispatch(
                open({
                    message: "Saved Successfully",
                    severity: "success",
                })
            );
        } catch (e) {
            console.error(e);
            dispatch(
                open({
                    message: e.message,
                    severity: "success",
                })
            );
        } finally {
            setSaveLoading(false);
        }
    };

    const onError = (errors, e) => {
        // console.log("ERROR", errors, e);
    };



    const [submitLoading, setSubmitLoading] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const actualSubmit = async () => {

        const actualSubmission = async (data, e) => {
            await actions.submitForm(data, [], [], activeYear);
            await apiClient.submitApplication(PROGRAM_CODE, activeYear);
            for (const prop in data) {
                if (integers.find(i => i === prop)) {
                    data[prop] = parseInt(data[prop]);
                }
            }
            // console.log(data, e);
            setShowConfirmation(true);
        };
        try {
            setSubmitLoading(true);
            const execSubmission = handleSubmit(actualSubmission, onError);
            await execSubmission();
            dispatch(
                open({
                    message: "Submitted Successfully",
                    severity: "success",
                })
            );
        } catch (e) {
            console.error(e);
            dispatch(
                open({
                    message: e.message,
                    severity: "success",
                })
            );
        } finally {
            setSubmitLoading(false);
        }
    };

    const onEdit = () => {
        setShowConfirmation(false);
        window.location.reload(true); /* 3722/SP236: Calling components aren't keeping application state so, reload the page from server. */
    }



    const serviceRef = useRef();
    const enrollmentRef = useRef();
    const collegeRef = useRef();
    const submitRef = useRef();

    return (
        <FormProvider {...methods}>
            <PortalView>
                <div name="ApplicationsPage" className="national-guard">
                    <div className="page-intro">
                        <div className="wrapper">
                            <div className="page-intro__layout">
                                <div className="page-intro__icon">
                                    <img src="/MyApplictionsIcon.svg" alt="Applications icon" style={{ marginTop: ".4rem" }} />
                                </div>
                                <div className="page-intro__content rhythm">
                                    <h1 className="heading heading--h1">
                                        {details.title}
                                        <PrintIcon />
                                    </h1>
                                    <p className="heading">{details.description}</p>
                                    <h2 className="heading heading--h2">{activeYear} – {activeYear+1}</h2>
                                    <a href="/dashboard">← Return to Dashboard</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {showConfirmation ? (
                        <CompleteSubmission onEdit={onEdit} details={details} />
                    ) : (
                        <div className="wrapper">
                            <div className="progress-bar rhythm">
                                <h3 className="heading heading--h3">Application Progress</h3>

                                <ProgressBar progress={progressPercent} />
                                <div className="progress-bar__labels">
                                    <ProgressBarLabel title="Profile" state="complete" />
                                    <ProgressBarLabel title="Service History" state={progress.serviceHistory} sectionRef={serviceRef} />
                                    <ProgressBarLabel title="Planned Enrollment" state={progress.enrollment} sectionRef={enrollmentRef} />
                                    <ProgressBarLabel title="College" state={progress.collegePlans} sectionRef={collegeRef} />
                                    <ProgressBarLabel title="Submit" state={progress.submit} sectionRef={submitRef} />
                                </div>

                            </div>

                            <div className="divider" />

                            {/* Questions */}
                            <div className="form-wrapper rhythm">
                                {/* Input Field - Service History*/}
                                <div className="rhythm" ref={serviceRef}
                                    style={{ pointerEvents: progress.collegeHistory === "incomplete" && "none", opacity: progress.collegeHistory === "incomplete" && "0.4", }}>
                                    <h3 className="heading heading--h3">Service History</h3>

                                    <div container className="input-field">
                                        <h4 className="heading heading--h4">Branch</h4>
                                        <RadioGroup name={FIELDS.NATIONAL_GUARD__BRANCH}
                                            isRequired={true}
                                            listItems={[{ value: "ArmyNG", label: "Army National Guard" }, { value: "AirNG", label: "Air National Guard" },]}
                                            row />
                                    </div>
                                    {watch(FIELDS.NATIONAL_GUARD__BRANCH) &&
                                        <div container className="input-field">
                                            <h4 className="heading heading--h4">Assigned Unit</h4>
                                            <FieldRow>
                                                <FieldSlot>
                                                    <DropdownSearch name={FIELDS.NATIONAL_GUARD__UNIT} inputLabel="Unit" placeholder="Unit" listItems={units} />
                                                </FieldSlot>
                                            </FieldRow>
                                        </div>
                                    }
                                </div>

                                {/* Input Field - Planned Enrollment */}
                                <div className="component rhythm" ref={enrollmentRef}
                                    style={{ pointerEvents: progress.enrollment === "incomplete" && "none", opacity: progress.enrollment === "incomplete" && "0.4", }}>
                                    <h3 className="heading heading--h3">Planned Enrollment</h3>
                                    <div className="table-wrapper">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Term</th>
                                                    <th>Anticipated Credit Hours</th>
                                                    <th>&nbsp;</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {listTerms.map(item => {
                                                    const isNotAttendingFieldName = "IsNotAttending" + item.name;
                                                    
                                                    return (
                                                        <tr>
                                                            <td>{item.term}<br /><small>Application Deadline: {item.deadline}</small></td>
                                                            <td>
                                                                <NumericDecimal
                                                                    name={item.name}
                                                                    placeholder="Credit Hours"
                                                                    precision={1}
                                                                    value={item.creditHours} // Pass credit hours value as prop
                                                                    onChange={(event) => item.creditHours = event.target.value}
                                                                />
                                                            </td>
                                                            <td>
                                                                <Checkbox
                                                                    name={isNotAttendingFieldName}
                                                                    label="Not attending"
                                                                    value={item.isNotAttending || false} // Pass checkbox value as prop
                                                                    disabled={item.creditHours > 0}
                                                                    onChange={(isChecked) => item.isNotAttending = isChecked}
                                                                />
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="college-terms" ref={collegeRef}>
                                    <div className="form-section">
                                        <div className="form-section__header">
                                            <h2 className="heading heading--h3">College Choice</h2>
                                        </div>
                                        {collegeCompletion?.isDisqualifying === true &&
                                        <div item>
                                            <Alert severity="error" className="alert alert--error">
                                                <p>
                                                    <strong>
                                                        The&nbsp;
                                                        <a href="https://oregonstudentaid.gov/grants/oregon-opportunity-grant/list-of-participating-schools">college you selected</a>
                                                        &nbsp;is not eligible to receive the Oregon National Guard Tuition Assistance
                                                    </strong>
                                                </p>
                                            </Alert>
                                        </div>
                    }
                                        <div className="wrapper rhythm">
                                            <CollegeChoiceAnswer answer={allAppSection.getAnswerByField(FIELDS.COLLEGE_CHOICE)} />

                                            <ClassStanding answer={allAppSection.getAnswerByField(FIELDS.COLLEGE_STANDING)} />
                                        </div>
                                    </div>
                                </div>

                                <div className="grant-action-buttons" ref={submitRef}>
                                    <Button name="Cancel" label="Cancel" variant="text" onClick={handleCancel} />
                                    <Button loading={saveLoading} name="Save" label="Save" type="submit" onClick={handleSubmit(onSave, onError)} />
                                    <ApplicationSubmitButton loading={submitLoading} sections={{ isComplete: !disableSubmit }} onClick={actualSubmit} app={app} />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </PortalView>
        </FormProvider>
    );
}

export default NationalGuard;
