import { actions } from "../../store";
import { useSelector } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import React, { useState, useEffect } from "react";
import { FieldSection, FieldRow, FieldSlot } from "../../components/Forms/FieldSection";
import FormSection from "../../components/FormSection";
import { FIELDS, TRANSCRIPT_QUESTIONS } from "../../store/form/fields";
import _get from "lodash.get";
import { open } from "../../store/snack/actions";
import { useDispatch } from "react-redux";
import moment from "moment";
import TranscriptQuestion from "../../components/answer/question/TranscriptQuestion";
import SectionStateIcon from "../../components/progress/SectionStateIcon";
import apiClient from "../../utils/API";
import RequirementSet from "../../utils/dtos/RequirementSet";

/**
 * section to prompt for just spring transcripts form a ChildCare perspective
 * @param {SectionHelper} param0.section 
 * @param {Function} param0.onChange
 * @param {Function} param0.onCancel
 * @param {Number} param0.fafsaYear (optional)
 * @returns {Component}
 */
function EditSpringTranscripts({ section, onChange, onCancel, fafsaYear = null }) {

    const methods = useForm({ mode: "onBlur", });
    const { setValue, handleSubmit, watch } = methods;

    const reduxFormState = useSelector((state) => state.form);

    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const [transcriptCompletionData, setTranscriptCompletionData] = useState(null);   
    const transcriptRequirements = new RequirementSet(transcriptCompletionData);

    const orderedTranscriptQuestions = [   
        TRANSCRIPT_QUESTIONS.SPRING_COLLEGE,
        TRANSCRIPT_QUESTIONS.SPRING_HS,        
    ].map(x => ({transcript: x, requirement: transcriptRequirements.get(x.name)}));

    /**
     * fetch latest completion data
     */
    const fetchTranscriptRequirements = async () => {
        const completionData = await apiClient.getCompletionReport("CC", section.year, section.fieldKeys);
        setTranscriptCompletionData(completionData);
    }

    useEffect(() =>{
        (async () => {
            await fetchTranscriptRequirements();
        })();        
    }, [section.answers._data]);

    const onSubmit = async (data, e) => {
        try {
            setLoading(true);
            const result = await actions.submitForm(data, [], []);
            if (typeof onChange === "function") {
                onChange(true);
            }       
            dispatch(
                open({
                    message: "Transcripts Saved",
                    severity: "success",
                })
            );
        } catch (e) {
            dispatch(
                open({
                    message: e.message,
                    severity: "success",
                })
            );
        } finally {
            setLoading(false);
        }
    };

    const onError = (errors, e) => {
        // console.log('ERROR', errors, e);
    };

    useEffect(() => {
        window.addEventListener("beforeunload", alertUser);
        return () => {
            window.removeEventListener("beforeunload", alertUser);
        };
    }, []);

    const alertUser = (e) => {
        e.preventDefault();
        e.returnValue = "";
    };

    const [isAttendingCollege, setIsAttendingCollege] = useState(null);
    const [clgWithInTenYears, setClgWithInTenYears] = useState(null);
    const [clgOverTenYears, setClgOverTenYears] = useState(null);


    useEffect(() => {
        const pastColleges = _get(reduxFormState, `fields.${FIELDS.PAST_COLLEGE}`);



        const currentColleges = _get(
            reduxFormState,
            `fields.${FIELDS.CURRENT_COLLEGE}`
        );
        if (
            currentColleges &&
            Array.isArray(currentColleges) &&
            currentColleges.length > 0
        ) {
            setIsAttendingCollege(true);
            setClgWithInTenYears(true);
        } else {
            setIsAttendingCollege(false);
        }

        if (
            pastColleges &&
            Array.isArray(pastColleges) &&
            pastColleges.length > 0
        ) {
            const now = moment();
            const overTenYears = pastColleges.some((college) => {
                const gradDate = _get(college, `AttendedStop.value`);
                const startDate = _get(college, `AttendedStart.value`);

                if (gradDate) {
                    const elapsedYears = now.diff(moment(gradDate), "years");
                    if (elapsedYears > 10) {
                        return true;
                    }
                }

                if (startDate) {
                    const elapsedYears = now.diff(moment(startDate), "years");
                    if (elapsedYears > 10) {
                        return true;
                    }
                }
            });
            setClgOverTenYears(overTenYears);
        }
    }, [reduxFormState]);

    return (
        <FormProvider {...methods}>     
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <FormSection
                    title="Transcripts"
                    isEditable={true}
                    onSaveClick={handleSubmit(onSubmit, onError)}
                    onCancelClick={onCancel}
                    loading={loading}
                    status={<SectionStateIcon section={section} />}
                >
                    <FieldSection>
                        <FieldRow>
                            <FieldSlot md={12}>
                                <h4 className="heading heading--h4">Transcript Instructions</h4>
                                <ul className="list">
                                    {clgWithInTenYears && (
                                        <li className="li">
                                            <div>College Grades, Recent (within last 10 years)</div>
                                            <div>
                                                <ul className="list">
                                                    <li className="li">
                                                        Submit transcripts of all college coursework taken
                                                        between August {fafsaYear - 11} and Fall
                                                        Quarter/Semester {fafsaYear - 1}.
                                                    </li>
                                                    <li className="li">
                                                        If you attended more than one college, submit a
                                                        transcript for each college, including work on prior
                                                        degrees.
                                                    </li>
                                                    <li className="li">
                                                        Transfer credits are not accepted. Each college must
                                                        have a complete transcript.
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    )}
                                    {isAttendingCollege && (
                                        <li className="li">
                                            <div>College Grades, Started within last year</div>
                                            <div>
                                                <p>
                                                    If you have completed only one semester or term of
                                                    college, a complete high school/home school transcript
                                                    or GED<sup style={{ verticalAlign: "super", fontSize: ".9rem" }}>&reg;</sup> scores is optional, but recommended as part of
                                                    the selection process.
                                                </p>
                                            </div>
                                        </li>
                                    )}
                                    {clgOverTenYears && (
                                        <li className="li">
                                            <div>College Grades, Prior (before 10 years ago)</div>
                                            <div>
                                                <p>
                                                    You may also submit pre-{fafsaYear - 11} college
                                                    transcripts that document academic achievement.
                                                </p>
                                                <p>
                                                    Submit transcripts of all college coursework taken
                                                    before August {fafsaYear - 11} or your complete high
                                                    school transcript. If you attended more than one
                                                    college, submit a transcript for each college,
                                                    including work on prior degrees.
                                                </p>
                                            </div>
                                        </li>
                                    )}
                                    {isAttendingCollege &&
                                        (clgOverTenYears || clgWithInTenYears) && (
                                            <li className="li">
                                                <div>
                                                    College Grades, Prior Grades but Recently started
                                                    again
                                                </div>
                                                <div>
                                                    <p>
                                                        If you have completed only one semester or term of
                                                        college, a complete high school/home school
                                                        transcript or GED<sup style={{ verticalAlign: "super", fontSize: ".9rem" }}>&reg;</sup> transcript is optional, but
                                                        recommended as part of the selection process.
                                                    </p>
                                                </div>
                                            </li>
                                        )}
                                </ul>
                            </FieldSlot>
                        </FieldRow>
                    </FieldSection>

       
                    <FieldSection>
                        <FieldRow>
                            <FieldSlot md={12}>
                                <div className="mt-05">
                                    {orderedTranscriptQuestions.filter(x => x.requirement?.isSkipped === false).map(question =>
                                        <TranscriptQuestion
                                            titleHeader={question.transcript.description}
                                            section={section}
                                            transcriptField={question.transcript}
                                            isRequired={question.requirement?.isRequired === true}
                                        />
                                    )}
                                </div>                             
                            </FieldSlot>
                        </FieldRow>
                    </FieldSection>

                    <FieldSection>
                        <FieldRow>
                            <FieldSlot md={12}>
                                <div className="mt-05">                                   
                                    <TranscriptQuestion
                                        titleHeader={TRANSCRIPT_QUESTIONS.CHILDCARE_EXPENSES.description}
                                        section={section}
                                        transcriptField={TRANSCRIPT_QUESTIONS.CHILDCARE_EXPENSES}
                                        isRequired={true}
                                    />                                
                                </div>                             
                            </FieldSlot>
                        </FieldRow>
                    </FieldSection>


                </FormSection>
            </form>
        </FormProvider>
    );
}

export default EditSpringTranscripts;
