import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import _get from 'lodash.get';
import { useSelector } from 'react-redux';
import FormSerializer from '../../store/form/serializer';
import { getQueryStrings, getRepeatableFieldIndex, getRepeatableFieldRootName } from '../../utils';
import { ID, REPEATABLE_FIELDS } from '../../store/form/fields';
import { repeatableFieldName } from './RepeatableField';


function FieldValueAdapter({ name, isValueObject = false, withAutoPopulate = true }) {

    

    const { setValue, getValues, formState: reactHookFormState } = useFormContext({ mode: 'onBlur' });

    const formState = useSelector((state) => state.form);
    const reduxFieldValue = _get(formState, `fields.${name}.value`);

    const isTruthy = (value) => typeof value === 'number' || value;
    const isFalsy = (value) => !isTruthy(value);

    const getRepeatableFieldId = (rootName, index) => {
        return getValues(repeatableFieldName(rootName,index,ID));
    }




    useEffect(() => {

        // if the field is repeatable it should not autopopulate previously deleted fields
        const rootName = getRepeatableFieldRootName(name);
        const deletedIdArray = _get(formState,`deletedRepeatableFieldIds.${rootName}`,[]);
        if(deletedIdArray.length){
            const repeatableFieldIndex = getRepeatableFieldIndex(name);
            // if the underlying input field is NOT the HiddenInput representing the ID value, then it shouldn't autopopulate
            if(name !== repeatableFieldName(rootName,repeatableFieldIndex,ID)) {
                const isDeletedRepeatableField  = deletedIdArray.includes(getRepeatableFieldId(rootName,repeatableFieldIndex));
                if(isDeletedRepeatableField) return;
            }
        }

        //setTimeout(()=>{
            if(!withAutoPopulate) {
                return;
            }
            const reactHookFormValue = getValues(name);
            if (isFalsy(reactHookFormValue) && isTruthy(reduxFieldValue)) {            
                if(isValueObject){
                    console.debug('Setting value object', name, {
                        value: reduxFieldValue
                    })
                    setValue(name, {
                        value: reduxFieldValue
                    })
                } else {
                    console.debug('Setting value', name, {
                        value: reduxFieldValue
                    })
                    setValue(name, reduxFieldValue);
                }
            }
            else if(isFalsy(reduxFieldValue)){
                console.debug(`Did not find redux value for ${`fields.${name}.value`}`)
            }
            else {
                console.debug(`Found existing react hook form value for ${`fields.${name}.value`}`)
            }
        //})
    }, [reduxFieldValue]);

    const displayKeys = getQueryStrings()?.displayKeys;
    let debugMessage = displayKeys === 'field' ? name : (displayKeys === 'question' ? FormSerializer.getQuestionKey(name) : '');
    return debugMessage ? <span>|{debugMessage}| {reduxFieldValue}</span> : null;
}

export default FieldValueAdapter;