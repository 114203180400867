import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputDecorator from './InputDecorator';
import { useFormContext } from 'react-hook-form';
import { get } from 'lodash';

function RadioButtonsGroup({ name, listItems, label, description, control, disabled= false, style, ...props}) {

 
  const methods = useFormContext({ mode: 'onBlur' });
  const errors = methods?.errors
  let error = null;
  if (errors) {
    error = get(errors, name)
  }
  return (
    <FormControl component="fieldset" className="radio-group">
      { label && 
        <FormLabel component="legend">
          <h4 className="heading heading--h5"> {label}
          { props.isRequired? <span className="required"> *</span> : null }
          { !props.isRequired && props.isSoftRequired? <span className="required"> *</span> : null }
          </h4>
        </FormLabel>
      }
      { error && <div style={{ color: 'red'}}>* Selection is required</div>}
      <RadioGroup aria-label={description} {...props}>
        {listItems.map((item,i) =>
          <FormControlLabel
            style={props.labelStyle}
            key={`${label}-option-${i}`}
            value={item.value}
            control={
              <Radio
                color="primary"
                style={style}
              />

            }
            label={item.label}
            disabled={item.disabled === true || item.disabled === 'True' || disabled === true}
          />)}
      </RadioGroup>
    </FormControl>
  );
};

export default InputDecorator(RadioButtonsGroup);
