import { actions } from './../../store';
import { useSelector } from 'react-redux';
import { useForm, useFormContext, FormProvider, useWatch } from "react-hook-form";
import React, { useState, useEffect } from 'react';
import Button from '../../components/Button';
import FormSection from '../../components/FormSection';
import { open } from "../../store/snack/actions";
import { useDispatch } from 'react-redux';
import {FieldRow, FieldSection, FieldSlot} from "../../components/Forms/FieldSection";
import BooleanRadioGroup from "../../components/BooleanRadioGroup";
import {FIELDS} from "../../store/form/fields";
import BooleanAnswer from "../../components/answer/BooleanAnswer";

function EditWorkingStudentData({ onChange, onCancel, section }) {

    const year = section.year;
    const reduxFormState = useSelector((state)=> state.form);
    const isFetchingData = reduxFormState.isLoading;

    const methods = useForm({
      mode: 'onBlur'
    });
    const { errors, formState, getValues, setValue, handleSubmit, watch } = methods;

    const watchWorkingCurrent = watch(FIELDS.WORKING__CURRENT)
    const watchWorkingNextYear = watch(FIELDS.WORKING__NEXT_YEAR)

    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();

    const onSubmit = async (data, e) => {
      try {
        setLoading(true)
        const result = await actions.submitForm(data,[],[]);
        if(typeof onChange === 'function'){
          onChange(true);
        }
        // console.log(data, e);
      } catch (e) {
        dispatch(open({
          message: e.message,
          severity: "success",
        }))
      } finally {
        setLoading(false)
      }
    };

  const onError = (errors, e) => {
      // console.log('ERROR',errors, e);
    }

    useEffect(() => {
      window.addEventListener('beforeunload', alertUser)
      return () => {
        window.removeEventListener('beforeunload', alertUser)
      }
    }, [])
    const alertUser = e => {
      e.preventDefault()
      e.returnValue = ''
    }

    return (
      <FormProvider {...methods} >
        <form onSubmit={handleSubmit(onSubmit, onError)} >
          <FormSection title='Working Student Questions' isEditable={true} onSaveClick={handleSubmit(onSubmit, onError)} onCancelClick={onCancel} loading={loading}>

            <FieldSection>
              <FieldRow>
                <FieldSlot>
                  <FieldSection>
                    <strong>Are you working 20+ hours/week in the current {year-1} - {year} academic year?<span className="required">*</span></strong>
                    <FieldRow>
                      <FieldSlot>
                        <BooleanAnswer
                          answer={section.getAnswerByField(FIELDS.WORKING__CURRENT)}
                          value={watchWorkingCurrent}
                          isRequired={true}
                        />
                      </FieldSlot>
                    </FieldRow>
                  </FieldSection>

                  <FieldSection>
                    <strong>Are you planning to work 20+ hours a week in the next academic year {year} - {year+1}?<span className="required">*</span></strong>
                    <FieldRow>
                      <FieldSlot>
                        <BooleanAnswer
                          answer={section.getAnswerByField(FIELDS.WORKING__NEXT_YEAR)}
                          value={watchWorkingNextYear}
                          isRequired={true}
                        />
                      </FieldSlot>
                    </FieldRow>
                  </FieldSection>
                </FieldSlot>
              </FieldRow>
            </FieldSection>
          </FormSection>
        </form>
      </FormProvider>
    );
}

export default EditWorkingStudentData;
