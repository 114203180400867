import React from 'react';
import MuiAccordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from './Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const Accordion = (MuiAccordion);

export default function MessageList({ listMessages }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="message-list">
      {listMessages.map((item, i) =>
        <Accordion className="message" expanded={expanded === `panel${i}`} onChange={handleChange(`panel${i}`)}>
          <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
            <div className="message__header">
              <div className="message__header-from">
                {expanded === `panel${i}` ?
                  <ExpandMoreIcon fontSize='small'/> :
                  <ChevronRightIcon fontSize='small'/>}
                <strong>{item.from}</strong>
              </div>
              <div className="message__header-date">{(item.sent).substr(0, 9)}</div>
              <div className="message__header-title">{item.title}</div>
            </div>
          </AccordionSummary>

          <AccordionDetails className="message__body rhythm">

            <div className="message__body-content" dangerouslySetInnerHTML={ { __html: item.message }}/>
            <div><Button label='Close' className="button button--text" onClick={handleChange(`panel${i}`)}/></div>
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
}
