import React, { Fragment } from "react";
import FormSection from '../../components/FormSection';
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch } from 'react-redux';
import { actions } from '../../store';
import { open } from "../../store/snack/actions";
import { FIELDS } from '../../store/form/fields';
import RepeatableQuestion from "../../components/answer/question/RepeatableQuestion";
import ChildAnswer from "../../components/answer/ChildAnswer";
import Card from "../../components/Card";
import { FieldRow, FieldSlot } from '../../components/Forms/FieldSection';
import Button from '../../components/Button';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import MaterialButton from '@material-ui/core/Button';


/**
 * returns section prompting for all dependent children
 * 
 * @param {*} param0 
 * @returns {Component}
 */
function ChildrenSection({ section, year, status, onChange }) {
    const methods = useForm({ mode: "onBlur", });
    const { errors, formState, getValues, setValue, handleSubmit, watch } = methods;
    const dispatch = useDispatch();

    const onSubmit = async (data, e) => {
        try {
            const result = await actions.submitForm(data, [], [], year);
            if (onChange)
                onChange();

            dispatch(
                open({
                    message: "Saved Children in Household Successfully",
                    severity: "success",
                })
            );
        } catch (e) {
            dispatch(open({
                message: e.message,
                severity: "success",
            }))
        }

    }

    const onError = async (data, e) => {
        //just display validations is enough
    }

    const answers = section.getAllAnswersByField(FIELDS.CHILDREN);
    const isAddDisabled = answers.some(x => x.IsLocked); //any locked children is ground to lock the add too

    return (
        <FormProvider {...methods} >
            <form onSubmit={handleSubmit(onSubmit, onError)} >
                <FormSection title='Children In Household' isEditable={true} onSaveClick={handleSubmit(onSubmit, onError)} loading={false} status={status}>
                    <p>List all of your legally dependent children younger than age 13 and your legally dependent children older than age 12 requiring extra care due to disability.</p>

                    <RepeatableQuestion section={section} name={FIELDS.CHILDREN} >
                        {(answers, handleAdd, handleDelete) => {
                            return (
                                <Fragment>
                                    {answers.map((answer) =>
                                        <Fragment key={answer.Id}>
                                            <Card>
                                                <CardContent>
                                                    <ChildAnswer answer={answer} />
                                                </CardContent>
                                                <CardActions style={{ justifyContent: 'flex-end' }}>
                                                    <MaterialButton size="small" color="secondary" 
                                                        disabled={answer.IsLocked}
                                                        style={{ position: 'relative', bottom: '-1rem' }}
                                                        onClick={handleDelete(answer)} >Remove</MaterialButton>
                                                </CardActions>
                                            </Card>
                                        </Fragment>
                                    )}
                                    <FieldRow>
                                        <FieldSlot>
                                            <Button name='AddChild' label='+ Add Child' variant='text' style={{ textTransform: "capitalize" }}
                                                disabled={isAddDisabled}
                                                onClick={handleAdd({ Id: 0, AnswerType: 'Dependent' })} />
                                        </FieldSlot>
                                    </FieldRow>
                                </Fragment>
                            )
                        }}
                    </RepeatableQuestion>
                </FormSection>
            </form>
        </FormProvider>
    );
}

export default ChildrenSection;