import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Migration } from '../../store/auth/state'
import { setMigration } from '../../store/auth/actions'
import apiClient from '../../utils/API';
import { useEffect } from 'react';
import MigrationView from '../../views/migrations'

const ProtectedRoute = (props) =>{
    
    
    const authState = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    

    const { isLoggedIn, migration } = authState;
    
    useEffect(() => {

        if (isLoggedIn === true && migration === Migration.initial) {
            async function getAccount() {
                try {        
                    await apiClient.getAccount();
                    dispatch(setMigration(Migration.complete))
                } catch (e) {
                    const { status } = e.request
                    // console.log("Status", status)
                    switch(status) {
                        case 403:
                            dispatch(setMigration(Migration.failed))
                            break;
                        case 409:
                            dispatch(setMigration(Migration.required))
                            break;
                        default:
                            break;
                    }
                }
            }
            getAccount();
        }
    }, [isLoggedIn, migration])

    useEffect(() => {
        if (props.scrollTop === true) {
            // console.log("Got here")
            window.scrollTo(0,0);
        }
    }, [])

    if ((migration === Migration.failed || migration === Migration.required) && isLoggedIn) {
    return <Route render={() => <MigrationView status={migration} />} />
    }

    return (isLoggedIn ? <Route {...props} /> : <Redirect to={'/'} />)
}

export default ProtectedRoute;