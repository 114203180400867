import { useMediaQuery } from 'react-responsive';
import mime from 'mime';
import moment from 'moment';
import _get from 'lodash.get';
import { STATUS } from '../components/AppStatusButtons';
import apiClient from './API';
import store from './../store'
import { CODES } from '../components/Utility/ProgramCodes';
import sumBy from 'lodash/sumBy'
import maxBy from 'lodash/maxBy'
import striptags from "striptags";
import ProgramInfo from './dtos/ProgramInfo';

export const goBack = ({ history, defaultRoute }) => {
    if (history.location.state && history.location.state.from) {
        history.push(history.location.state.from)
    } else {
        history.push(defaultRoute || "/dashboard")
    }
}

/**
 * interpret value as some kind of boolean info and return as actual boolean, forces out nulls
 * @param {*} val 
 * @returns {Boolean}
 */
export const truthy = (val) => {
    if (val == 'True' || val == true) {
        return true
    }
    return false
}

/**
 * interpret value as some kind of boolean info and return as actual boolean, repsects null-state
 * @param {*} val 
 * @returns {Boolean}
 */
export const toBoolean = (val) => {
    if (val === 'True' || val === true) {
        return true;
    }
    if (val === 'False' || val === false) {
        return false;
    }
    return null;
}

/**
 * interpret value as some kind of boolean info and return as serialized boolean, forces out nulls
 * @param {*} val 
 * @returns {String} serialized boolean descriptor (API friendly)
 */
export const stringy = (val) => {
    if (val == 'True' || val == true) {
        return 'True';
    }
    return 'False';
}
/**
 * interpret value as some kind of boolean info and return as serialized boolean, respects null state
 * @param {*} val 
 * @returns {String}
 */
export const toBooleanString = (val) => {
    if (val === 'True' || val === true) {
        return "True";
    }
    if (val === 'False' || val === false) {
        return "False";
    }
    return null;
}

export const IsTabletOrMobile = () => {
    return useMediaQuery({ query: '(max-width: 1224px)' });
}

export const getMimeInstance = () => {
    // in case we need to add custom type mappings.
    return mime;
}

/**
 * BAD INFORMATION!!! DO NOT CALL. API.getActiveYear has more accurate info if current
 * calls do not provide what you need
 * @deprecated
 * @returns {Number}
 */
export const getCurrentYear = () => {
    return Number(moment().format('YYYY'));
}

export const capitalize = (string) => {
    return (string || '').charAt(0).toUpperCase() + (string || '').slice(1);
}

export const capitalizeAll = (string) => {
    return (string || '').split(' ').map(s => capitalize(s)).join(' ');
}

export const compositeFieldname = (parentFieldKey, property) => {
    return `${parentFieldKey}.${property}`;
}

export const fieldValueTemplateRender = (template = '', values) => {
    const regex = /{{\s?([A-Za-z0-9_\.\[\]]+)\s?}}/ig;
    function replacer(match, group) {
        if (typeof group === 'string' && group) {
            group = group.trim();
        }
        return _get(values, group, '')
    }
    return template?.replace(regex, replacer);
}

// i.e OTHER_NAMES[0].prop.prop -> OTHER_NAMES
export const getRepeatableFieldRootName = (fieldName) => {
    return (fieldName || '').split('[')[0];
}

export const getRepeatableFieldIndex = (fieldName) => {
    const fieldResidue = (fieldName || '').split('[');
    const isRepeatableFieldName = fieldResidue.length > 1;
    return isRepeatableFieldName ? fieldResidue[1].split(']')[0] : null;
}

export const getQueryStrings = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    return params;
}

export const getReduxField = (fieldKey, initialReduxFormState) => {
    const reduxFormState = initialReduxFormState || store.getState().form;
    return _get(reduxFormState, `fields.${fieldKey}.value`)
};

/**
 * merge the program and application info into a single object with some display-worthy summaries
 * 
 * @param {*} program Program DTO from API
 * @param {*} application Application DTO from API
 * @returns {*}
 */
export const combineProgramStatus = (program, application) => {

    const { IsStarted, IsSubmitted, Deadline, IsRecommended, ReviewInstructions, ReviewStatus } = application;
    let status = STATUS.ELIGIBLE;
    let errorMessage = null;

    if (IsStarted) {
        status = STATUS.IN_PROGRESS
    }
    if (IsSubmitted) {
        status = STATUS.SUBMITTED;
    }
    const isPrivateScholarship = program.ParentProgramCode === 'SCH' || program.Code === 'SCH';
    if ((IsRecommended === null || IsRecommended === false) &&  !isPrivateScholarship && !IsStarted) {
        status = STATUS.INELIGIBLE;
    }

    const programInfo = new ProgramInfo(program);    
    const listRequirements = programInfo.getSeason(application.Year)?.notes.map(({ Description }) => ({ value: striptags(Description), }));

    return {
        ...program,
        status,
        programStatusResult: application,
        Source: application.Source,
        matched: application.IsRecommended,
        docsRequired: null,
        isComplete: null,
        year: application.Year,
        membershipRequired: program.IsMembershipVerified,
        isScholarship: program.ParentProgramCode === 'SCH',
        statusState: {
            IsStarted,
            IsSubmitted,
            Deadline,
            IsRecommended,
            ReviewInstructions,
            ReviewStatus
        },
        awardState: {          
            awardStatus: application.AwardSummaryStatus,
            isAwarded: (application.AwardSummaryStatus === 'Authorized' || application.AwardSummaryStatus === 'Hold'),
            terms: [],
            totalAmountAwarded: null,
            semesterYearDescriptor: null,
            programAwardResults: null
        },
        listRequirements: listRequirements,
        errorMessage,
    }
}

/**
 * given a set of already-enriched applications
 * @param {Array<*>} applicationList already enriched programs
 * @param {*} program raw program DTO
 * @param {*} stubApp if app is not available, do we know any properties that should appear in the app
 * @returns {*} application-enriched program
 */
export function combineWithLocalApp(combinedPrograms, program, stubApp = {}) {
    const app = combinedPrograms.find(a => a.Code == program.Code);
    if (app)
        return app; //already enriched

    const fullStubApp = {
        IsRecommended: stubApp?.IsRecommended ?? null,
        IsStarted: false,
        IsSubmitted: false,
        ReviewInstructions: null,
        ReviewStatus: null,
        Deadline: null, //do we need SCH deadline passed here
        Year: stubApp?.Year,
    };
    return combineProgramStatus(program, fullStubApp);
}

/**
 * load associated additional doc info for programs
 * @param {*} programList 
 * @param {Number} year specific year if program info won't provide a resliable, unique year we can use
 */
export const enrichProgramsWithDocInfo = async (programList, year = null) => {

    const addOnQuestions = async (program) => {
        const info = new ProgramInfo(program);
        const activeYear = year ?? info.currentYear
        const questions = await apiClient.get(`question/questions/${info.code}/${activeYear}`);
        program.docsRequired = null;
        program.isComplete = null;
        if (questions.length > 0) {
            const stage = program.Source === "Renew" ? "Renewal" : "Application";
            const isComplete = await apiClient.get("application/iscomplete", { programCode: info.code, stage: stage, year: activeYear });
            program.docsRequired = questions.some(q=> q.RequirementType.toLowerCase() === "required");
            program.isComplete = isComplete;
        }
        program.questions = questions;

        return program;
    }
    const docAwareProgramList = await Promise.all(programList.map(p => addOnQuestions(p)));
    return docAwareProgramList;
}

export const enrichProgramStatus = (programList, codeProp = 'Code') => {
    return programList.map(program => {
        let actualCode = program[codeProp]
        if (program[codeProp] == undefined) {
            actualCode = program['code']
        }
        return apiClient.getApplicationStatus(actualCode, program.year).then(async programStatusResultArray => {

            if (!Array.isArray(programStatusResultArray) || !programStatusResultArray.length) {
                console.log('get app status first condition true')
                return {
                    ...program,
                    status: STATUS.ELIGIBLE
                }
            }
            console.log('get app status first condition NOT true')
            const programStatusResult = programStatusResultArray[0];
            return combineProgramStatus(program, programStatusResult);
        })
            .catch(() => program);
    });
}

/**
 * create a RevTech-style "details" for use with older components that are enriched with awards
 * @param {*} program 
 * @param {*} awards 
 */
export const combineWithLocalAwards = (program, awards) => {
    let year;
    let totalAmountAwarded = 0;
    let terms = [];
    let semesterYearDescriptor = ''; // i.e Fall, Winter, Spring 2022
    awards.forEach(programAwardResult => {
        const { Term, AuthorizedAmount, Year } = programAwardResult;
        totalAmountAwarded += AuthorizedAmount;
        year = Year;
        terms.push(Term);
    })

    semesterYearDescriptor = `${terms.join(',')} ${year}`;


    const totalAuthAmount = sumBy(awards, 'AuthorizedAmount');
    const totalDisbAmount = sumBy(awards, 'DisbursedAmount');
    const totalAmount = totalAuthAmount + totalDisbAmount
    const isAwarded = totalAmount > 0;


    return {
        ...program,
        awardState: {
            ...program.awardState,
            isAwarded,
            terms,
            totalAmountAwarded,
            semesterYearDescriptor,
            programAwardResults: awards
        }
    }
}

export const enrichProgramAwardStatus = (programList, codeProp = 'Code') => {
    return programList.map(program => {
        // console.log('program', program)
        let actualCode = program[codeProp]
        if (program[codeProp] == undefined) {
            actualCode = program['code']
        }
        return apiClient.getApplicationAwardStatus(actualCode, program.year)
            .then(programAwardResults => combineWithLocalAwards(program, programAwardResults))
            .catch(() => program);
    });
}

function isGrant(code) {
    const grants = [
        CODES.OREGON_PROMISE,
        CODES.CHAFEE,
        CODES.NATIONAL_GUARD,
        CODES.CHILD_CARE,
        CODES.OTSG
    ]
    return grants.some(g => g === code);
}

export const returnSentence = (string, index) => {
    let result = string.replace(/\b(\w\.\w\.|\d+(?:\.\d+){1,2}\.?)|([.?!])\s+(?=[A-Za-z])/g, function (m, g1, g2) {
        return g1 ? g1 : g2 + "\r"
    });
    let arr = result.split("\r")
    // return all but first sentence for accordion details
    if (index !== '*') {
        return arr[index]
    }
    arr.shift()
    return arr.join(' ')
}

/**
 * DO NOT call unless you ONLY want the year. If you want ANY other program info, use getProgram instead
 * @deprecated
 * @param {String} code 
 * @returns {Number}
 */
export const getLatestActiveYear = async (code) => {
    if (!code) {
        return null
    }

    try {
        const result = await apiClient.getProgram(code)
        const activeSeasons = result.ActiveSeasons

        if (!activeSeasons) return null

        const year = maxBy(activeSeasons, "Year").Year

        if (year) {
            console.log('returning year', year)
            return year
        }

        return null

    } catch (e) {
        console.error(e);
        return null
    }
}

