export const AuthActionTypes = {
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    SET_MIGRATION: 'SET_MIGRATION'
}

export const login = (idToken) => async dispatch => {
    dispatch({
        type: AuthActionTypes.LOGIN,
        idToken
    });
  }

export const logout = () => async dispatch => {
    dispatch({
        type: AuthActionTypes.LOGOUT
    });
  }

export const setMigration = (status) => async dispatch => {
    dispatch({
        type: AuthActionTypes.SET_MIGRATION,
        status
    })
}

